
<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <div class="card">
        <h5 class="titlecard">Detalle del pedido</h5>
        <div class="table-responsive profile-table">
          <table class="table table-responsive">
            <tbody>
                <tr>
                  <td>Estado actual:</td>
                  <td><span [ngClass]="{'completed': courses?.SaleOrderStatus == 'Abierto', 'pending': courses?.SaleOrderStatus == 'pendiente', 'cancelled': courses?.SaleOrderStatus == 'cancelado'}">{{courses?.SaleOrderStatus}}</span></td>
                </tr>
                <tr>
                  <td>Fecha de compra:</td>
                  <td>{{courses?.SaleOrderDate | date:'dd/MM/yyyy'}}</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-6">
      <div class="card">
        <h5 class="titlecard">Detalle del pago</h5>
        <div class="table-responsive profile-table">
          <table class="table table-responsive">
            <tbody>
                <tr>
                  <td>Subtotal:</td>
                  <td>{{courses?.SubtotalAmount| currency}} {{courses?.CurrencyCode}}</td>
                </tr>
                <tr>
                  <td>IVA:</td>
                  <td>{{courses?.TaxesAmount | currency}} {{courses?.CurrencyCode}}</td>
                </tr>
                <tr>
                  <td>Total:</td>
                  <td><strong>{{courses?.TotalAmount | currency}} {{courses?.CurrencyCode}}</strong></td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Cantidad</th>
                        <th>Curso</th>
                        <th>Total</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let course of courses?.Products">
                        <td>{{course.Quantity}}</td>
                        <td>{{course.Title}}</td>
                        <td>{{course.TotalAmount | currency}} {{courses.CurrencyCode}}</td>
                        <td class="actions-cell">
                            <span *ngIf="course.Family == 'Event'" (click)="setModalContent(content, course.id)"
                            title="Ver QR" >
                            <i class="fa fa-qrcode"></i>
                            </span>
                            <span *ngIf="course.Family == 'Course'" (click)="setModalInvites(invites, course.SaleOrderid, course.Productid)"
                            title="Ver Invitaciones">
                            <i class="fa fa-address-card-o"></i>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Compartir QR</h4>
  </div>
  <div class="modal-body text-center">
    <!-- <qrcode [qrdata]="CodeQR" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> -->
    <p>{{CodeQR}}</p>
  </div>
  <ng-container>
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cerrar</a>
    </div>
  </ng-container>
</ng-template>

<ng-template #invites
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title"><strong>Invitaciones</strong></h4>
  </div>
  <div class="row padd">
    <div class="col-xs-12 col-md-6">
      <div class="card cardaccepted">
        <h5>Aceptadas</h5>
        <p class="number">{{subscriptions?.Activos}}</p>
      </div>
    </div>
    <div class="col-xs-12 col-md-6">
      <div class="card carddeclined">
        <h5>Pendientes</h5>
        <p class="number">{{subscriptions?.Inactivos}}</p>
      </div>
    </div>
  </div>
  <div class="modal-body text-center">
    <div class="table-responsive">
      <table datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger"
      class=" table table-lg table-hover row-border hover">
      <thead>
          <tr>
          <th>Nombre</th>
          <th>Correo</th>
          <th>Aceptado</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let subscription of subscriptions?.Subscriptions">
          <td>{{subscription.Status ? subscription.Name : 'Por definir'}}</td>
          <td>{{subscription.Status ? subscription.Email : 'Por definir'}}</td>
          <td>
            <span *ngIf="subscription.Status"><i class="fa fa-check-circle-o" aria-hidden="true"></i></span>
            <span *ngIf="!subscription.Status"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
          </td>
      </tbody>
      </table>
  </div>
  </div>
  <ng-container>
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cerrar</a>
    </div>
  </ng-container>
</ng-template>
