<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="px-3 pb-3">
                <h4 class="px-2">Nueva empresa |</h4>
            </div>
            <div class="card tab2-card">
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Cuenta">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="personalDetails" class="needs-validation user-add" novalidate>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Nombre(s)</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input formControlName="FirstName" type="text" class="form-control"
                                                    placeholder="Nombre" [ngClass]="{'error': personalDetails.controls['FirstName'].touched && personalDetails.controls['FirstName'].errors?.required
                                                }" />
                                                <div *ngIf="personalDetails.controls['FirstName'].touched && personalDetails.controls['FirstName'].errors?.required"
                                                    class="text-danger">
                                                    El nombre es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Apellido(s)</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input required formControlName="LastName" type="text"
                                                    class="form-control" placeholder="Apellido" [ngClass]="{'error': personalDetails.controls['LastName'].touched && personalDetails.controls['LastName'].errors?.required
                                                    }">
                                                <div *ngIf="personalDetails.controls['LastName'].touched && personalDetails.controls['LastName'].errors?.required"
                                                    class="text-danger">
                                                    El Apellido es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Empresa</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input required formControlName="BusinessName" type="text"
                                                    class="form-control" placeholder="Nombre de la empresa" [ngClass]="{'error': personalDetails.controls['BusinessName'].touched && personalDetails.controls['BusinessName'].errors?.required
                                                    }">
                                                <div *ngIf="personalDetails.controls['BusinessName'].touched && personalDetails.controls['BusinessName'].errors?.required"
                                                    class="text-danger">
                                                    El nombre de la empresa es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Colaboradores</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input required formControlName="Colabs" type="number"
                                                    class="form-control" placeholder="Número de colaboradores"
                                                    [ngClass]="{'error': personalDetails.controls['Colabs'].touched && personalDetails.controls['Colabs'].errors?.required
                                                    }">
                                                <div *ngIf="personalDetails.controls['Colabs'].touched && personalDetails.controls['Colabs'].errors?.required"
                                                    class="text-danger">
                                                    El numero de colaboradores es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Correo electrónico</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input required formControlName="Email" type="text" class="form-control"
                                                    placeholder="Correo electrónico corporativo" [ngClass]="{'error': personalDetails.controls['Email'].touched && personalDetails.controls['Email'].errors?.required || personalDetails.controls['Email'].errors?.pattern || emailMatch
                                                    }">
                                                <div *ngIf="personalDetails.controls['Email'].touched && personalDetails.controls['Email'].errors?.required"
                                                    class="text-danger">
                                                    El email es obligatorio.
                                                </div>
                                                <div *ngIf="personalDetails.controls['Email'].touched && personalDetails.controls['Email'].errors?.pattern"
                                                    class="text-danger">
                                                    Por favor, introduce un correo electrónico válido.
                                                </div>
                                                <span *ngIf="personalDetails?.controls['Email'].touched && emailMatch"
                                                    class="text-danger">
                                                    Ya existe un email registrado.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Numero telefónico</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input required formControlName="PhoneNumber" type="tel"
                                                    class="form-control" placeholder="Numero telefónico" maxlength="10"
                                                    [ngClass]="{'error': personalDetails.controls['PhoneNumber'].touched && personalDetails.controls['PhoneNumber'].errors?.required
                                                }" appOnlyNumbers>
                                                <div *ngIf="personalDetails.controls['PhoneNumber'].touched && personalDetails.controls['PhoneNumber'].errors?.required"
                                                    class="text-danger">
                                                    El numero telefónico es obligatorio.
                                                </div>
                                                <div *ngIf="personalDetails.controls['PhoneNumber'].errors?.minlength && personalDetails.controls['PhoneNumber'].touched"
                                                    class="text-danger">
                                                    El número de teléfono debe tener al menos 10 caracteres.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Contraseña</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="Password"
                                                    placeholder="Contraseña" type="password"
                                                    [ngClass]="{'error': personalDetails.controls['Password'].touched && personalDetails.controls['Password'].errors?.required || personalDetails.controls['Password'].errors?.minlength}">
                                                <div *ngIf="personalDetails.controls['Password'].touched && personalDetails.controls['Password'].errors?.minlength"
                                                    class="text-danger">
                                                    La contraseña debe tener mínimo 8 caracteres.
                                                </div>
                                                <div *ngIf="personalDetails.controls['Password'].touched && personalDetails.controls['Password'].errors?.required"
                                                    class="text-danger">
                                                    La contraseña es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row mb-5">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Confirmar contraseña</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="PasswordConfirm"
                                                    placeholder="Confirmar contraseña" type="password"
                                                    [ngClass]="{'error': personalDetails?.controls['PasswordConfirm'].touched && personalDetails.controls['PasswordConfirm'].errors?.required || passwordMatch}">
                                                <div *ngIf="personalDetails.controls['PasswordConfirm'].touched && passwordMatch"
                                                    class="text-danger">
                                                    Las contraseñas no coinciden.
                                                </div>
                                                <!--  <div *ngIf="personalDetails?.controls['PasswordConfirm'].touched && personalDetails.controls['PasswordConfirm'].errors" class="text-danger">
                                                    La contraseña debe tener mínimo 8 caracteres.
                                                </div>  -->
                                                <div *ngIf="personalDetails?.controls['PasswordConfirm'].touched && personalDetails.controls['PasswordConfirm'].errors?.required"
                                                    class="text-danger">
                                                    La contraseña es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <ngb-toast *ngIf="submittedSuccess" [class]="'ngb-toast bg-error'">
                                                {{messageSuccess}}
                                            </ngb-toast>
                                        </div>
                                        <div class="form-group col-12 mb-0 mt-3 text-right">
                                            <button (click)="onSubmit()" type="submit" class="btn btn-red"
                                                [disabled]="personalDetails.invalid">
                                                Crear empresa
                                                <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- <ng2-toasty></ng2-toasty> -->