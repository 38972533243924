import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ProgramsService } from 'src/app/shared/service/programs.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-content',
  templateUrl: './add-content.component.html',
  styleUrls: ['./add-content.component.scss']
})
export class AddContentComponent implements OnInit {

  public vimeoUploadForm: FormGroup;
  private data: any;
  public uploadPercent; 
  public uploadStatus: Number = 0;
  public content: any;

  public topicId: number;
  public requiredFileType: string;

  public viewLoaderVideo = false;
  public previewVideo: any;
  public responseVideo: string;
  public fileNameVideo = '';

  public viewLoaderFile = false;
  public previewFile: any;
  public responseFile: string;
  public fileName = '';

  private subs: Array<Subscription> = [];

  constructor(
    private fb: FormBuilder,
    private programService: ProgramsService,
    private activatedRoute: ActivatedRoute,
  ){
  }

  onVideoSelected(event) {
    const file:File = event.target.files[0];

    if(file.type == 'video/mp4' || file.type == 'video/mov' || file.type == 'video/wmv' || file.type == 'video/avi' || file.type == 'video/flv'){
      this.viewLoaderVideo = true;
      this.fileNameVideo = 'Cargando video...';
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('videoupload' , file);
          formData.append('name' , file.name);
          formData.append('descriptionn' , 'Prueba');
          formData.append('type' , 'video');

          this.subs.push(
            this.programService.uploadVideo(formData, this.topicId)
              .subscribe(resolve => {
                if (resolve.Responses== 'Archivo subido a carpeta local') {
                  this.responseVideo = resolve.FileUrl;
                  this.viewLoaderVideo = false;
                  this.previewVideo = "assets/images/check.png";
                  this.fileNameVideo = 'Video cargado correctamente';
                }
              })
          );
        }
      }, 1800);
      this.getContent();

    }else{
      this.NoTypeVideo()
    }
  }

  onFileSelected(event) {
    const file:File = event.target.files[0];

    if(file.type == 'application/pdf'){
      this.viewLoaderFile = true;
      this.fileName = 'Cargando archivo...';
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('Namefile' , file);
          formData.append('Titulo' , file.name);
          formData.append('type' , 'Document');

          this.subs.push(
            this.programService.uploadFile(formData, this.topicId)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseFile = resolve.FileUrl;
                  this.viewLoaderFile = false;
                  this.previewFile = "assets/images/check.png";
                  this.fileName = 'Archivo cargado correctamente';
                  setTimeout(() => {
                    window.location.reload();
                  }, 1800);
                }
              })
          );
        }
      }, 1800);
      this.getContent();
    }else{
      this.NoTypeFile()
    }
  }

  allowUpload(): void {
    this.uploadStatus = 0;
  }

  checkAllowedType(filetype: string): boolean {
    const allowed = ['mov', 'wmv', 'avi', 'flv', 'mp4'];
    const videoType = filetype.split('/').pop();
    return allowed.includes(videoType);
  }

  getFormValue(selector: string) {
    return this.vimeoUploadForm.get(selector).value;
  }

  ngOnInit() {
    this.vimeoUploadForm = this.fb.group({
        vimeoVideoName: ['', [Validators.required]],
        vimeoVideoDescription: ['', [Validators.required]]
    });
   this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
          this.topicId = data.params.topicId;
      })
    );
    this.getContent();

  }

  getContent(){
    this.programService
      .getContent(this.topicId)
      .subscribe(data => {
        this.content = data;
    })
  }

  ngOnDestroy(): void {
  }

  public get formState() {
    return this.vimeoUploadForm.controls
  }

  private NoTypeVideo() {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un video en los siguientes formatos mov, wmv, avi, flv o mp4',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions); */
  }

  private NoTypeFile() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un archivo de tipo pdf',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions); */
  }
}
