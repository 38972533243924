
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="row product-adding">
                <div class="col-xl-12">
                    <form class="needs-validation" [formGroup]="programForm" novalidate>
                        <div class="card bordertop">
                            <div class="add-product-form">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-8">
                                        <div class="form-group">
                                            <label for="validationCustom01" class="label">Nombre del módulo</label>
                                            <input class="form-control" id="validationCustom03" type="text" [formControl]="programForm.controls['Name']"
                                            [ngClass]="{'error': submitted && formState?.Name?.errors?.required}">
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="validationCustom4" class="label">Porcentaje de módulo</label>
                                            <input class="form-control" id="validationCustom04" type="number" [formControl]="programForm.controls['Percentage']"
                                            [ngClass]="{'error': submitted && formState?.Percentage?.errors?.required}">
                                            <span *ngIf="formState?.Percentage?.errors?.min" class="text-danger">No se permiten valores negativos</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="validationCustom03" class="label">Número de temas del módulo</label>
                                            <input class="form-control" id="validationCustom01" type="number" [(ngModel)]="totalTopics" [formControl]="programForm.controls['numberTopics']" min="1"
                                            [ngClass]="{'error': submitted && formState?.numberTopics?.errors?.required}">
                                            <span *ngIf="formState?.numberTopics?.errors?.min" class="text-danger">No se permiten valores negativos</span>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="validationCustom02" class="label">Total de horas del módulo</label>
                                            <input class="form-control" id="validationCustom02" type="number" [formControl]="programForm.controls['courseDuration']"
                                            [ngClass]="{'error': submitted && formState?.courseDuration?.errors?.required}">
                                            <span *ngIf="formState?.courseDuration?.errors?.min" class="text-danger">No se permiten valores negativos</span>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="Teacher" class="label">Selecciona el maestro</label>
                                            <ng-select name="Teacher" id="Teacher" class="form-control" placeholder="Selecciona el maestro" [formControl]="programForm.controls['Teacher']" [ngClass]="{'error': submitted && formState?.Teacher?.errors?.required}">
                                                <ng-option *ngFor="let teacher of teachers" [value]="teacher.id">
                                                  {{teacher.firstName}} {{teacher.lastName}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="topicsProgram.length !== 0">
                            <div class="card borderleft" *ngFor="let topic of topicsProgram; let i = index">
                                <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                                    <h3>Temas del módulo</h3>
                                </div>
                                <div class="col-xs-12">
                                    <div class="container-fluid">
                                        <div class="row" >
                                            <div class="col-xs-12 col-10">
                                                <h4>Tema Nº {{i+1}}</h4>
                                                <p>Nombre del tema: <strong>{{topic.Title}}</strong></p>
                                            </div>
                                            <div class="col-xs-12 col-2 tright">
                                                <span>
                                                    <button type="button" class="btn btn-primary mr-1 mat-btn mb-1" (click)="editTopic(topic, i)" ><i class="fa fa-pencil-square-o"></i></button>
                                                </span>
                                                <span>
                                                    <button type="button" class="btn btn-secondary mr-1 mat-btn mb-1" (click)="removeTopic(topic)"><i class="fa fa-trash"></i></button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card borderleft" *ngIf="totalTopics > topicsProgram.length || onEditTopic">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-lg-12 pb-3">
                                    <h3>{{ !onEditTopic ? 'Temas del módulo' : 'Editando tema: '+index }}</h3>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                                    <div class="row">
                                        <div class="col-xs-12 col-lg-9">
                                            <div class="form-group">
                                                <input class="form-control" id="validationCustom03" type="text" placeholder="Nombre del tema" [(ngModel)]="valueTitle" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <!-- <div class="col-xs-12 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="validationCustom04" type="time" placeholder="Duración del tema" [(ngModel)]="valueDuration" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div> -->
                                        <div class="col-xs-12 col-lg-3" *ngIf="!onEditTopic">
                                            <button type="button" class="btn btn-primary mr-1 w-100 btn-save" (click)="addTopic()">Guardar</button>
                                        </div>
                                        <div class="col-xs-12 col-lg-3" *ngIf="onEditTopic">
                                            <button type="button" class="btn btn-primary mr-1 btn-save" (click)="editingTopic()">Editar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="program?.topic?.length !== 0" class="card bordertop-blue">
                            <div class="add-product-form">
                                <div class="row">
                                    <div class="col-xl-6 col-sm-4 col-xs-12">
                                        <div *ngIf="previewVideo" class="box-input-file">
                                            <img class="imgUpload" alt="" [src]="previewVideo">
                                        </div>
                                        <img *ngIf="viewLoaderVideo" src="./assets/images/loading-9.gif" alt="" style="width: 30%;">
                                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onVideoSelected($event)" #fileUploadVideo>
                                        <div class="file-upload">
                                        {{fileNameVideo || "Seleccionar video...  "}}
                                            <button class="btn btn-primary mat-btn" (click)="fileUploadVideo.click()">
                                                <i class="fa fa-video-camera" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-sm-8 col-xs-12">
                                        <div *ngIf="program?.VideoUrl">
                                            <div class="file-upload">
                                                Ya cuentas con un video cargado <a [href]="program?.VideoUrl" target="_blank"><img class="resize" src="./assets/images/reproductor-de-video.png" alt=""></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12 mb-0 pr-0">
                            <div class="product-buttons col-xs-12 button-save text-right pr-0">
                                <button (click)="onSubmit()" class="btn btn-primary mright"
                                    [disabled]="isLoading" *ngIf="onEdit === false">
                                    {{ isLoading ? 'Creando módulo' : 'Agregar módulo'}}
                                    <i class="fa fa-spinner fa-spin"
                                    [hidden]="!isLoading"></i>
                                </button>
                            </div>

                            <div class="product-buttons col-xs-12 button-edit">
                                <button (click)="onSubmit()" type="submit" class="btn btn-primary mright" *ngIf="onEdit == true">
                                    {{ isLoading ? 'Editando módulo' : 'Editar módulo'}}
                                    <i class="fa fa-spinner fa-spin"
                                    [hidden]="!isLoading"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <ng2-toasty></ng2-toasty> -->
