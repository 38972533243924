<!-- Container-fluid starts-->
<div class="row paddd">
    <div class="col-xs-12 col-sm-6">
        <div class="card o-hidden widget-cards">
            <div class="bg-danger card-body">
                <div class="media static-top-widget row">
                    <div class="icons-widgets col-4">
                        <div class="align-self-center text-center">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="media-body col-8"><span class="m-0">Total de pedidos</span>
                        <h4 class="mb-0"><span class="counter">{{orders?.totalpedidos}}</span></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-6">
        <div class="card o-hidden widget-cards">
            <div class="bg-primary card-body">
                <div class="media static-top-widget row">
                    <div class="icons-widgets col-4">
                        <div class="align-self-center text-center">
                            <i class="fa fa-money" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="media-body col-8"><span class="m-0">Total pagado</span>
                        <h4 class="mb-0"><span class="counter">{{orders?.montopagado | currency}}</span></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Nº Pedido</th>
                        <th>Status</th>
                        <th>Fecha</th>
                        <th>Total</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders?.list">
                        <td>{{order.id}}</td>
                        <td>{{order.SaleOrderStatus}}</td>
                        <td>{{order.SaleOrderDate | date:'dd/MM/yyyy'}}</td>
                        <td>{{order.TotalAmount | currency}} {{order.CurrencyCode}}</td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['courses', order.id]"
                                title="Ver Orden">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->