<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row m-0">
                                    <div class="col-xl-6 col-sm-6 col-xs-12">
                                        <div *ngIf="previewImg" class="box-input-file">
                                            <img class="imgUpload" alt="" [src]="previewImg.base">
                                        </div>
                                        <div *ngIf="!previewImg && imageUrl">
                                            <img class="imgUpload" alt="" [src]="imageUrl">
                                        </div>
                                        <div *ngIf="viewLoaderImg" class="spinner-border text-danger" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <input type="file" class="file-input" [accept]="requiredFileType"
                                            (change)="onImgSelected($event,1)" #fileImgUpload>
                                            <div class="file-upload mt-3">
                                                <h4>Cargar imagen de encabezado</h4>
                                                <button class="btn btn-primary" (click)="fileImgUpload.click()" style="width: max-content;">
                                                    {{ImgfileName ? 'Reemplazar imagen' :  imageUrl ? 'Reemplazar imagen': "Cargar imagen"}}
                                                    <i class="ml-3 fa fa-cloud-upload" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tbImages">
                                <div class="file-upload mb-3" style="margin-left: -155px !important;">
                                    <div style="margin-left: 100px;">
                                        <input type="file" class="file-input" [accept]="requiredFileType"
                                            (change)="onImgSelected($event,2)" #fileImgUpload2>
                                        <h4>Cargar imágenes para contenido</h4>
                                        <div class="file-upload mt-3">
                                            <button class="btn btn-primary" (click)="fileImgUpload2.click()">
                                                {{ImgfileName2 ? 'Reemplazar imagen' : "Cargar imagen"}}
                                                <i class="ml-3 fa fa-cloud-upload" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="prevImages.length === 0 && !aditionalImages || aditionalImages.length === 0">
                                    <table class="border mt-4">
                                        <thead>
                                            <th>
                                                Espacio para las imágenes del contenido de la noticia
                                                <div class="add-product">
                                                    <div *ngIf="viewLoaderImg2" class="spinner-border text-danger"
                                                        role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </th>
                                        </thead>
                                    </table>
                                </div>

                                <div *ngIf="prevImages.length > 0 && !onEdit">
                                    <table class="table table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Imagen</th>
                                                <th>Pie de foto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of prevImages; let i = index">
                                                <td>
                                                    <div class="add-product">
                                                        <div *ngIf="item.previewImg2" class="box-input-file">
                                                            <img class="imgUpload" alt="" [src]="item?.previewImg2.base">
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <textarea [(ngModel)]="footers[i]" name="footer" id="footer" cols="30" rows="2"></textarea>
                                                    
                                                </td>
                                            </tr>
                                            <tr *ngIf="viewLoaderImg2">
                                                <div class="add-product">
                                                    <div *ngIf="viewLoaderImg2" class="spinner-border text-danger"
                                                        role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </tr>

                                        </tbody>

                                    </table>

                                </div>

                                <div *ngIf="aditionalImages && onEdit">

                                    <table class="table table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Imagen</th>
                                                <th>Pie de foto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let image of aditionalImages; let i = index">
                                                <td>
                                                    <div class="add-product">
                                                        <div class="box-input-file">
                                                            <img class="imgUpload" alt="" [src]="image.image">
                                                        </div>
                                                    </div>

                                                </td>
                                                <td>
                                                    <textarea [(ngModel)]="footers[i]" name="" id="" cols="30"
                                                        rows="2"></textarea>
                                                </td>
                                            </tr>
                                            <tr *ngIf="viewLoaderImg2">
                                                <div class="add-product">
                                                    <div *ngIf="viewLoaderImg2" class="spinner-border text-danger"
                                                        role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </tr>

                                        </tbody>

                                    </table>

                                </div>

                            </div>



                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="newsForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom01"
                                                    class="col-form-label pt-0">Título</label>
                                                <input class="form-control" id="validationCustom01" type="text"
                                                    [formControl]="newsForm.controls['Title']"
                                                    [ngClass]="{'error': submitted && formState?.Title?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom02"
                                                    class="col-form-label pt-0">Categoría</label>
                                                    <ng-select name="Category" id="validationCustom02" [formControl]="newsForm.controls['Type']" [ngClass]="{'error': submitted && formState?.Type?.errors?.required}">
                                                        <ng-option *ngFor="let category of categories" [value]="category.ValueAlpha">
                                                            {{category.ValueAlpha}}
                                                        </ng-option>
                                                    </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom03"
                                                    class="col-form-label pt-0">Autor</label>
                                                <input class="form-control" id="validationCustom03" type="text"
                                                    [formControl]="newsForm.controls['Author']"
                                                    [ngClass]="{'error': submitted && formState?.Author?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom04" class="col-form-label pt-0">Fuente de
                                                    información</label>
                                                <input class="form-control" id="validationCustom04" type="text"
                                                    [formControl]="newsForm.controls['Source']"
                                                    [ngClass]="{'error': submitted && formState?.Source?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="validationCustom05"
                                                    class="col-form-label pt-0">Introducción</label>
                                                <br>
                                                <angular-editor [formControl]="newsForm.controls['ShortDescription']"
                                                [placeholder]="'Escribe la Introducción aquí...'" [config]="editorConfig"
                                                [ngClass]="{'error': submitted && formState?.ShortDescription?.errors?.required}">
                                            </angular-editor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="validationCustom06" class="col-form-label pt-0">Cuerpo de la
                                                    noticia</label>
                                                <br>
                                                <angular-editor [formControl]="newsForm.controls['MainBody']"
                                                    [placeholder]="'Escribe la noticia aquí...'" [config]="editorConfig"
                                                    [ngClass]="{'error': submitted && formState?.MainBody?.errors?.required}">
                                                </angular-editor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-0">
                                    <div class="product-buttons col-sm-8 offset-xl-9 offset-sm-4">
                                        <button class="btn btn-primary" [disabled]="isLoading" *ngIf="onEdit == false">
                                            {{ isLoading ? 'Creando noticia' : 'Agregar noticia'}}
                                            <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                        </button>
                                    </div>

                                    <div class="product-buttons col-sm-8 offset-xl-9 offset-sm-4">
                                        <button type="submit" class="btn btn-primary" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando noticia' : 'Editar noticia'}}
                                            <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <ng2-toasty></ng2-toasty> -->
