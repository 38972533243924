<div class="page-wrapper">
    <div class="authentication-box paddd">
        <div class="container">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6" *ngIf="showForm">
                    <div class="card tab2-card background-login mb-5 ">
                        <div class="card-body">
                            <div class="text-center mt-1 mb-2">
                                <img src="../../../../assets/images/logo.png" style="width: 90%;" alt="">
                            </div>
                            <div class="text-center mb-4">
                                <p class="text-secondary">Bienvenido a Metramex<br>
                                    Ingresa tus datos para crear tu cuenta
                                </p>
                            </div>
                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                aria-labelledby="account-tab">
                                <form [formGroup]="personalDetails" (ngSubmit)="onSubmit()"
                                    class="form-horizontal auth-form" novalidate="">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <input required="" formControlName="FirstName" type="text"
                                                    class="form-control" placeholder="Nombre"
                                                    [ngClass]="{'error': submitted && formState?.FirstName?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <input required="" formControlName="LastName" type="text"
                                                    class="form-control" placeholder="Apellido"
                                                    [ngClass]="{'error': submitted && formState?.LastName?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <input required="" formControlName="BusinessName" type="text"
                                                    class="form-control" placeholder="Nombre de la empresa"
                                                    [ngClass]="{'error': submitted && formState?.BusinessName?.errors?.required}">
                                                <label class="errorRed"
                                                    *ngIf="submitted && formState?.BusinessName?.errors?.pattern">
                                                    El nombre de la empresa es requerido
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <input required="" formControlName="Colabs" type="number"
                                                    class="form-control" placeholder="Número de colaboradores"
                                                    [ngClass]="{'error': submitted && formState?.Colabs?.errors?.required}">
                                                <label class="errorRed"
                                                    *ngIf="submitted && formState?.Colabs?.errors?.pattern">
                                                    El número de colaboradores es requerido
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <input required="" formControlName="Email" type="text"
                                                    class="form-control" placeholder="Correo electrónico corporativo"
                                                    [ngClass]="{'error': submitted && formState?.Email?.errors?.required}">
                                                <label class="errorRed"
                                                    *ngIf="submitted && formState?.Email?.errors?.pattern">
                                                    Ingresa un correo válido.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <input required="" formControlName="PhoneNumber" type="tel"
                                                    class="form-control" placeholder="Celular corporativo"
                                                    maxlength="10"
                                                    [ngClass]="{'error': submitted && formState?.PhoneNumber?.errors?.required}"
                                                    appOnlyNumbers>
                                                <label class="errorRed"
                                                    *ngIf="submitted && formState?.PhoneNumber?.errors?.pattern">
                                                    Ingresa solo 10 números.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <input required="" formControlName="Password" type="password"
                                                    class="form-control" placeholder="Contraseña"
                                                    [ngClass]="{'error': submitted && formState?.Password?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <input required="" formControlName="ConfirmedPassword" type="password"
                                                    class="form-control" placeholder="Confirmar contraseña"
                                                    [ngClass]="{'error': submitted && formState?.ConfirmedPassword?.errors?.required}">
                                                <label class="errorRed"
                                                    *ngIf="submitted && formState?.ConfirmedPassword?.errors">
                                                    Las contraseñas no coinciden.
                                                </label>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </form>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <button type="submit" class="btn btn-primary" [disabled]="isLoading">
                                        {{ isLoading ? 'Guardando' : 'Guardar'}}
                                        <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                     <div class="row">
                        <small class="pl-3">¿Ya tienes una cuenta? <a [routerLink]="['/auth/login']">Inicia
                                sesión</a></small>
                    </div> 
                    <br/>
                    <small>Al registrarte estás aceptando nuestros
                        <a href="">Términos y Política de Privacidad</a>
                    </small>
                </div>
                <div class="col-sm-6 col-xs-12 animate__animated animate__fadeIn" *ngIf="showRegisterThanks">
                    <!-- <img src="../../../../assets/images/finish-register.png" alt="" class="mb-3 border-radius-cards"> -->
                    <img src="../../../../assets/images/logo.png" style="width: 90%;" alt="">
                    <h4>Gracias por registrarse en Metramex, nos podremos en contacto con usted para brindarle más
                        información</h4>
                </div>
                <div class="col-md-3">
                </div>
            </div>
        </div>
    </div>
</div>
