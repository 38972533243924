import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../utils/headers.functions';
import { WikiModel } from 'src/app/components/wiki/wiki.models';

const BASE_URL: string = environment.API_URL;


@Injectable({
  providedIn: 'root'
})
export class WikiService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }


  public uploadImg(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/uploadimg`, formData, { headers });
  }

  public uploadFile(formData: any,): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/saveDocument`, formData, { headers });
  }

  public createWiki(wiki: WikiModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/wiki`, wiki, { headers });
  }

  public updateWiki(id: number, change: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/wikicontent/${id}`, change, {headers});
  }

  public deleteWiki(id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<Observable<any>>(`${BASE_URL}/wiki/${id}`, { headers });
  }

  public getListWiki(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/wiki`, {headers});
  }

  public getWiki(wikiId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/wiki/${wikiId}`);
  }

  public addSubtitle(subtitle: any){
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/subtitle`, subtitle, { headers });
  }


  public mapRequiredValues(news: any): any {
   
    const newsInfo: any = {};

    newsInfo.id = news.id,
    newsInfo.Title = news.Title,
    newsInfo.ShortDescription = news.ShortDescription,
    newsInfo.MainBody = news.MainBody,
    newsInfo.Type = news.Type,
    newsInfo.editorId = news.editorId,
    newsInfo.Author = news.Author,
    newsInfo.Source = news.Source,
    newsInfo.ImageUrl = news.ImageUrl,
    newsInfo.aditionalImg = news.aditionalImg

    return newsInfo;
  }

  private addBackendStructure(news: any) {
    return {
      Title: news.Title,
      ShortDescription: news.ShortDescription,
      MainBody: news.MainBody,
      SubCategory: news.SubCategory,
      Type: news.Type,
      editorId: news.editorId,
      Author: news.Author,
      Source: news.Source,
      ImageUrl: news.ImageUrl,
    };
  }

}
