<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row d-flex custom-profile-direction justify-content-between align-item-center w-100 pr-0 pl-0">
        <div class="card-with-profile-image">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <div class="contenedor-profile-image-button">
                            <img *ngIf="!user?.ImgFileUrl" src="assets/images/dashboard/profile-user.png" alt=""
                                class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                            <img *ngIf="user?.ImgFileUrl" [src]="user?.ImgFileUrl" alt=""
                                class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                            <button class="button-edit border-0 bg-transparent" (click)="uploadImage.click()">
                                <app-feather-icons [icon]="'edit-2'" class="color-black"></app-feather-icons>
                            </button>
                            <input type="file" hidden accept=".png,.jpeg,.jpg," class="form-control"
                                [(ngModel)]="userImage" (change)="onFileChange($event)" #uploadImage />
                            <h5 class="f-w-600 mt-3 mb-0">{{user?.firstName}} {{user?.lastName}}</h5>
                        </div>
                        <hr>
                        <div class="contenedor-profile-image-button">
                            <label for="">Mi firma</label>
                            <h5 class="w-100" *ngIf="!user?.SignFileUrl">No has cargado tu firma</h5> <br>
                            <img *ngIf="user?.SignFileUrl" [src]="user?.SignFileUrl" alt=""
                                class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                            <button *ngIf="!user?.SignFileUrl" class="border-0 bg-transparent"
                                (click)="uploadImage2.click()">
                                <app-feather-icons [icon]="'upload'" class="color-black"></app-feather-icons>
                            </button>
                            <input type="file" hidden accept=".png" class="form-control" [(ngModel)]="userImage"
                                (change)="onFileChange2($event)" #uploadImage2 />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-with-profile">
            <div class="card">
                <div class="card-body">
                    <ngb-tabset>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Editar Perfil</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form [formGroup]="form" (ngSubmit)="updateUser()" class="form-horizontal auth-form"
                                        novalidate="">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Nombre(s)</label>
                                                    <input required="" type="text" class="form-control"
                                                        formControlName="firstName"
                                                        [ngClass]="{'error': form.controls['firstName'].touched && form.controls['firstName'].errors?.required}">
                                                    <div *ngIf="form.controls['firstName'].touched && form.controls['firstName'].errors?.required"
                                                        class="text-danger">
                                                        El nombre es obligatorio.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Apellido(s)</label>
                                                    <input required="" type="text" class="form-control"
                                                        formControlName="lastName"
                                                        [ngClass]="{'error': form.controls['lastName'].touched && form.controls['lastName'].errors?.required}">
                                                    <div *ngIf="form.controls['lastName'].touched && form.controls['lastName'].errors?.required"
                                                        class="text-danger">
                                                        El apellido es obligatorio.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Correo electrónico</label>
                                                    <input type="email" required="" class="form-control"
                                                        formControlName="email"
                                                        [ngClass]="{'error': form.controls['email'].touched && form.controls['email'].errors?.required}">
                                                    <label class="errorRed"
                                                        *ngIf="form.controls['email'].touched && form.controls['email'].errors?.pattern">
                                                        Ingresa un correo válido.
                                                    </label>
                                                    <div *ngIf="form.controls['email'].touched && form.controls['email'].errors?.required"
                                                        class="text-danger">
                                                        El correo electrónico es obligatorio.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Numero Teléfono</label>
                                                    <input type="tel" class="form-control" maxlength="10"
                                                        formControlName="phoneNumber"
                                                        oninput="validity.valid||(value='');"
                                                        [ngClass]="{'error': form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors?.required}">
                                                    <label class="errorRed"
                                                        *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors?.pattern">
                                                        Ingresa sólo 10 números.
                                                    </label>
                                                    <div *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors?.required"
                                                        class="text-danger">
                                                        El numero telefónico es obligatorio.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="userRoles === 'business'" class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Nombre de Empresa</label>
                                                    <input required="" type="text" class="form-control"
                                                        formControlName="businessname"
                                                        [ngClass]="{'error': form.controls['businessname'].touched && form.controls['businessname'].errors?.required}">
                                                    <div *ngIf="form.controls['businessname'].touched && form.controls['businessname'].errors?.required"
                                                        class="text-danger">
                                                        La empresa es obligatorio.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="userRoles === 'business'" class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Numero de colaboradores</label>
                                                    <input required="" type="text" class="form-control"
                                                        formControlName="collaborators"
                                                        [ngClass]="{'error': form.controls['collaborators'].touched && form.controls['collaborators'].errors?.required}">
                                                    <div *ngIf="form.controls['collaborators'].touched && form.controls['collaborators'].errors?.required"
                                                        class="text-danger">
                                                        Los colaboradores son obligatorio.
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- 
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Fecha de nacimiento</label>
                                                    <div class="input-group nopadd">
                                                        <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="birthday"
                                                        [ngClass]="{'error': submittedProfile && formState?.birthday?.errors?.required}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="d-flex justify-content-center align-item-center w-100 mb-3">
                                            <ngb-toast *ngIf="submittedMessageProfile" [class]="'ngb-toast bg-success'">
                                                {{messageProfile}}
                                            </ngb-toast>
                                        </div>
                                        <div class="d-flex justify-content-center align-item-center w-100 mb-3">
                                            <ngb-toast *ngIf="submittedMessageProfileError"
                                                [class]="'ngb-toast bg-error'">
                                                {{messageProfileError}}
                                            </ngb-toast>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="text-right mrg-top-5">
                                                    <button type="submit" class="btn btn-inverse btn-red mrg-right-15">
                                                        {{ isLoading ? 'Actualizando perfil' : 'Actualizar perfil'}}
                                                        <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>

                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'unlock'"></app-feather-icons>Actualizar
                                contraseña</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form [formGroup]="formpassword" class="form-horizontal auth-form"
                                        (ngSubmit)="onChangePassword()">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Nueva contraseña</label>
                                                    <input type="password" class="form-control"
                                                        formControlName="password" minlength="8"
                                                        [ngClass]="{'error': formpassword.controls['password'].touched && formpassword.controls['password'].errors?.required}">
                                                    <span
                                                        *ngIf="formpassword.controls['password'].touched  && formpassword.controls['password'].errors"
                                                        class="text-danger">
                                                        La contraseña debe tener mínimo 8 caracteres.
                                                    </span>
                                                    <div *ngIf="formpassword.controls['password'].touched && formpassword.controls['password'].errors?.required"
                                                        class="text-danger">
                                                        La contraseña son obligatoria.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Confirmar nueva contraseña</label>
                                                    <input type="password" class="form-control"
                                                        formControlName="confirmedPassword"
                                                        [ngClass]="{'error': formpassword.controls['confirmedPassword'].touched  && formpassword.controls['confirmedPassword'].errors?.required}">
                                                    <span
                                                        *ngIf="formpassword.controls['confirmedPassword'].touched  && formpassword.controls['confirmedPassword'].errors"
                                                        class="text-danger">
                                                        Las contraseñas no coinciden.
                                                    </span>
                                                    <br>
                                                    <span
                                                        *ngIf="formpassword.controls['confirmedPassword'].touched && formpassword.controls['confirmedPassword'].errors"
                                                        class="text-danger">
                                                        La contraseña debe tener mínimo 8 caracteres.
                                                    </span>
                                                    <div *ngIf="formpassword.controls['confirmedPassword'].touched && formpassword.controls['confirmedPassword'].errors?.required"
                                                        class="text-danger">
                                                        La contraseña son obligatoria.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center align-item-center w-100 mb-3">
                                            <ngb-toast *ngIf="submittedMessage" [class]="'ngb-toast bg-success'">
                                                {{message}}
                                            </ngb-toast>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="text-right mrg-top-5">
                                                    <button type="submit" class="btn btn-inverse btn-red mrg-right-15">
                                                        {{ isLoadingPass ? 'Actualizando contraseña' : 'Actualizar
                                                        contraseña'}}
                                                        <i class="fa fa-spinner fa-spin" [hidden]="!isLoadingPass"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>