import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SalesService } from 'src/app/shared/service/sales.service';
import { transactionsDB } from 'src/app/shared/tables/transactions';
import { AuthenticationService } from '../../services/auth/authentication.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {

  public transactions = []

  private subs: Array<Subscription> = [];
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public dtTrigger2: Subject<any> = new Subject();

  public count: number;
  public page = 1;
  public limit = 10;
  public offset = 0;

  public startDate: string = null;
  public endDate: string = null;

  public transtype: any;
  public transtype2: any;
  public paymentMethod: any = null;
  public paymentMethodValue: any;

  fileName = 'Pagos.xlsx';
  transactions2: any;
  count2: number;
  dtElement: any;

  constructor(
    private salesService: SalesService,
    private authService: AuthenticationService,
  ) {
    
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      lengthChange: false,
      responsive: true,
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: '',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order: [0, 'desc']

    };

    this.subs.push(
      this.salesService
        .getPayments(this.offset, this.limit,'')
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.transactions = data.payments;
          this.transtype = data.payments.map(item => {
            if (item.card) {
              return JSON.parse(item.card)
            }
          })
          this.count = data.total;
          this.dtTrigger.next();
        }),
      this.salesService
        .getPayments(0, 100000, '')
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.transactions2 = data.payments;
          this.transtype2 = data.payments.map(item => {
            if (item.card) {
              return JSON.parse(item.card)
            }
          })
          this.count2 = data.total;
        })

    );
  }

  filter() {
    this.paymentMethodValue = this.paymentMethod;

    if( this.startDate === null && this.endDate === null ){
      this.handleError("Se debe seleccionar un rango de fechas");
      return;
    }

    if (this.startDate !== null && this.paymentMethodValue === null) {

      this.subs.push(
        this.salesService
          .getPaymentsFiltered(this.offset, this.limit, this.startDate, this.endDate)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.transactions = data.payments;
            this.count = data.total;
          })
      );
    }
    if(this.paymentMethodValue !== null){
      this.subs.push(
        this.salesService
        .getPaymentsFilteredAll(this.offset, this.limit, this.paymentMethodValue, this.startDate, this.endDate)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.transactions = data.payments;
          this.transtype = data.payments.map(item => {
            if (item.card) {
              return JSON.parse(item.card)
            }
          })
          this.count = data.total;
        })
      )
    }
    else{
      this.handleError("Se debe seleccionar un rango de fechas");
      return;
    }

  }
  
  handleError(arg0: string) {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: arg0,
      showClose: true,
      timeout: 2000
    };
    this.toastyService.error(toastOptions); */
  }
     
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public loadPage(page: number) {
    this.page = page;

    if (this.startDate === null && this.paymentMethodValue === null) {

      this.subs.push(
        this.salesService
          .getPaymentsFiltered(page - 1, this.limit, this.startDate, this.endDate)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.transactions = data.payments;
            this.count = data.total;
          })
      );

    }
    if(this.paymentMethodValue !== null){
      this.subs.push(
        this.salesService
          .getPaymentsFilteredAll(page - 1, this.limit, this.paymentMethodValue, this.startDate, this.endDate)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.transactions = data.payments;
            this.transtype = data.payments.map(item => {
              if (item.card) {
                return JSON.parse(item.card)
              }
            })
            this.count = data.total;
          })
      );
    }

   
  }

  exportExcel(): void {

    let element = document.getElementById('excel-sales');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pagos');

    XLSX.writeFile(wb, this.fileName);

  }

}
