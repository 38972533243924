import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { UsersService } from 'src/app/components/services/users/users.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;

  collaboratorsList: any[] = [];
  deleteFilters: boolean = false;
  colaboratorsFilter: string = '';
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  public count: number;
  public page = 1;
  public limit = 10;
  public offset = 0;
  public userId: string;
  private subs: Array<Subscription> = [];
  deleteCollaborators: any;
  submittedError: boolean = false;
  submittedSuccess: boolean = false;
  messageError: string = '';
  messageSuccess: string = '';
  collaboratorTotal: number;
  public deleteIsSuccess = false;
  user: string;
  userRoles: string;
  currentUserId: string;
  dataSource: any;
  orderColumn: string = '';
  orderType = "asc";
  pageIndex = 0;
  orderText: string = 'firstName-asc';
  businessname: string;
  filterData = '';
  collaborador: any;

  displayedColumns: string[] = [
    'date',
    'reason',
    'actions',
  ];

  constructor(
    private usersService: UsersService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
  ) {
    this.currentUserId = this.activatedRoute.snapshot.paramMap.get('id');
    const currentUser = JSON.parse(localStorage['currentUser']);
    this.userRoles = currentUser.roles;
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    if (this.dataSource && this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  ngOnDestroy(): void {
  }

  async loadData() {
    const data = await this.usersService.getCollaborator(this.currentUserId).toPromise()
    this.collaborador = data;
    this.usersService.getCollaboratorHistories(this.currentUserId).subscribe((data: any) => {
      this.collaboratorsList = data;
      this.collaboratorTotal = data.length;
      this.dataSource = new MatTableDataSource(data);
    });
  }

  announceSortChange(sortState: Sort) {
    this.orderText = `${sortState.active}-${sortState.direction}`;
    this.pageIndex = this.page;
    this.orderColumn = sortState.active;
    this.orderType = sortState.direction;
    this.loadData();
  }

  filterBusiness($event: any) {
    let filteredData = [];
    this.usersService.getCollaboratorsList(this.limit, this.offset, this.orderText).subscribe((data: any) => {
      for (let i = 0; i < data.List.length; i++) {
        if (data.List[i].person_id === this.currentUserId) {
          filteredData.push(data.List[i]);
        }
      }
      this.deleteFilters = true;
      this.collaboratorsList = filteredData.filter(item =>
        item.firstName.toLowerCase().includes($event.toLowerCase()) ||
        item.lastName.toLowerCase().includes($event.toLowerCase()) ||
        item.Job.toLowerCase().includes($event.toLowerCase()) ||
        item.Business.businessname.toLowerCase().includes($event.toLowerCase()) ||
        item.phoneNumber.includes($event) ||
        item.email.toLowerCase().includes($event.toLowerCase())
      );
    });
  }

  filter(event: any) {
    this.filterData = event;
    this.deleteFilters = true;
    this.loadData();
  }

  getCollaborators() {
    this.deleteFilters = false;
    this.filterData = '';
    this.loadData();
    this.colaboratorsFilter = ''
  }

  public loadPage(page: number) {
    this.page = page;
    this.subs.push(
      this.usersService
        .getCollaboratorsList(this.limit, page - 1, this.orderText)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.collaboratorsList = data.List;
          this.dataSource = new MatTableDataSource(data.List);
        })
    );
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this.usersService.getEnterpriseId(this.userId).subscribe((data: any) => {
      this.deleteCollaborators = [data.displayName];
    })
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public activeUser() {
    const user: any = this.collaboratorsList.filter((item) => item.id === this.userId);
    this.subs.push(
      this.usersService.putEnterpriseId(user[0].id, { active: true })
        .subscribe(resolve => {
          this.deleteIsSuccess = true;
          this.modalService.dismissAll();
          this.submittedSuccess = true;
          this.messageSuccess = 'Colaborador dado de alta correctamente'
          this.loadData();
          this.deleteIsSuccess = false;
          setTimeout(() => {
            this.submittedSuccess = false;
            this.messageSuccess = ''
          }, 2000);
        })
    );
  }

  public deleteUser() {
    const body = { active: false }
    this.usersService.editCollaborator(this.userId, body)
      .subscribe(resolve => {
        this.modalService.dismissAll();
        this.addToast('Colaborador eliminado correctamente', 'success-snackbar');
        this.loadData();
      });
  }

  addToast(message: any, className: string) {
    this.zone.run(() => {
      this._snackBar.open(message, null, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [className],
      });
    });
  }

  async uploadImageFile(event: any) {
    const file: File = event.target.files[0];
    const maxSize = 1024 * 1024 * 10;
    if (file.size > maxSize) {
      this.addToast('Tus anexos no pueden pesar más de 10 MB', 'error-snackbar');
      return;
    }
    if (file) {
      const formData = new FormData();
      const filename = file.name.split(".");
      formData.append('file', file, filename.join(new Date().getTime() + '.'));
      formData.append('type', 'img');

      const resolve = await this.usersService.uploadFile(formData).toPromise();
      this.collaborador.imageFileUrl = resolve.ImageUrl;
      const body = {
        imageFileUrl: resolve.ImageUrl
      }
      this.usersService.editCollaborator(this.currentUserId, body).subscribe((data: any) => {
        this.addToast('Imagen cargada exitosamente', 'success-snackbar');
      })
    }
  }

}
