import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { PasswordModel } from '../auth.models';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  public resetPasswordForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedPassword = false;
  public token: string;
  public resetToken: string;
  public submittedSuccess: boolean = false;
  messageSuccess: string = '';
  private subs: Array<Subscription> = [];

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.resetToken = params['token'];
    });
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      ConfirmedPassword: ['', [Validators.required]]
    })

    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('token')),
        switchMap(token => this.token = token)
      ).subscribe(resolve => {
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.resetPasswordForm.controls
  }

  private passwordUser(): PasswordModel {
    return {
      resetToken: this.resetToken,
      password: this.resetPasswordForm.controls['password'].value
    }
  }

  private validatePasswords(): void {
    const password: string = this.resetPasswordForm.get('password').value;
    const confirmPassword: string = this.resetPasswordForm.get('ConfirmedPassword').value;

    if (password !== confirmPassword) {
      this.resetPasswordForm.controls['ConfirmedPassword'].setErrors({ incorrect: true });
    };
  }

  public onSubmit(): void {
    this.submittedPassword = true;
    this.validatePasswords();

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.onResetPassword();
  }

  private onResetPassword() {
    /* const password: PasswordModel = this.passwordUser(); */
    const body: PasswordModel = {
      resetToken: this.resetToken,
      password: this.resetPasswordForm.controls['password'].value,
    }
    this.isLoading = true;
    this.subs.push(
      this.authService.resetPassword(body)
        .subscribe(resolve => {
          this.handlePasswordChange();
        }, error => {
          this.handleError(error)
        })
    );
  }

  private handlePasswordChange() {
    this.isLoading = false;
    this.submittedSuccess = true;
    this.messageSuccess = 'Contraseña cambiada correctamente';
    setTimeout(() => {
    this.submittedSuccess = false;
    this.messageSuccess = '';
            this.router.navigateByUrl('/auth/login');
          }, 2500); 
  }

  private handleError(error: any) {
   /*  this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No hemos podido actualizar la contraseña',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions); */

    return [];
  }

}
