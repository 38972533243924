import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/shared/service/authentication.service';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { invoiceDB } from '../../shared/tables/invoice';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  public invoice = []
  dtOptions: { pagingType: string; pageLength: number; lengthChange: boolean; language: { emptyTable: string; zeroRecords: string; lengthMenu: string; search: string; info: string; infoEmpty: string; infoFiltered: string; paginate: { first: string; last: string; next: string; previous: string; }; }; };

  constructor(private dashboardService: DashboardService, private accountService: AccountService) {
  
    let user = JSON.parse(localStorage.getItem('currentUser'));

    this.dashboardService.getCoursesImparted( user.id ).subscribe( data => {
      this.invoice = data.filter( element => element != null );
    })


  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthChange: false,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
    };
  }

}
