<div class="container-fluid">
    <div class="row">
        <div class="col-xl-5">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details">
                        <h5 class="f-w-600 mb-0">Orden Nº. {{order?.id}}</h5>
                        <hr>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Fecha de Pedido:</td>
                                        <td>{{order?.SaleOrderDate | date:'dd/MM/yyyy'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Método de Pago:</td>
                                        <td>
                                            <span *ngIf="order?.PaymentMethod === 'store'">Efectivo</span>
                                            <span *ngIf="order?.PaymentMethod === 'bank_account'">Transferencia</span>
                                            <span *ngIf="order?.PaymentMethod === 'card'">Tarjeta</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Subtotal:</td>
                                        <td>{{order?.SubtotalAmount | currency}} {{order?.CurrencyCode}}</td>
                                    </tr>
                                    <tr>
                                        <td>IVA:</td>
                                        <td>{{order?.TaxesAmount | currency}} {{order?.CurrencyCode}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total:</strong></td>
                                        <td><strong>{{order?.TotalAmount | currency}} {{order?.CurrencyCode}}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-7">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                        aria-labelledby="top-profile-tab">
                        <h5 class="f-w-600">Detalles del cliente</h5>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Nombre cliente:</td>
                                        <td>{{order?.Customer.firstName}} {{order?.Customer.lastName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Correo electrónico:</td>
                                        <td>{{order?.Customer.email}}</td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono:</td>
                                        <td>{{order?.Customer.phoneNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p>Cursos del pedido</p>
    <div class="card">
        <div class="card-body">
          <div id="batchDelete" class="">
              <div class="table-responsive">
                  <table
                  class=" table table-lg table-hover row-border hover">
                  <thead>
                      <tr>
                      <th>Cantidad</th>
                      <th>Producto</th>
                      <th>Precio unitario</th>
                      <th>Importe</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of order?.SaleOrderItem">
                      <td>{{item.SaleOrderItem.Quantity}}</td>
                      <td>{{item.Title}}</td>
                      <td>{{item.SaleOrderItem.UnitPrice | currency}} {{order?.CurrencyCode}}</td>
                      <td>{{item.SaleOrderItem.TotalAmount | currency}} {{order?.CurrencyCode}}</td>
                      </tr>
                  </tbody>
                  </table>
              </div>
          </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

