import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './users-routing.module';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ListAdminComponent } from './list-user-admin/list-admin.component';
import { ListTeacherComponent } from './list-user-teachers/list-teacher.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserDetailsComponent } from './user-detail/user-detail.component';
import { OrdersUserComponent } from '../sales/orders/order-user/order-user.component';
import { CoursesOrderComponent } from '../sales/orders/courses-order/course-order.component';

import { DataTablesModule } from 'angular-datatables';
import { MedicalHistoryComponent } from './collaborator-medical-history/medicalHistory.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
/* import { QRCodeModule } from 'angularx-qrcode' */

@NgModule({
  declarations: [
    ListUserComponent,
    CreateUserComponent,
    UpdateUserComponent,
    ListAdminComponent,
    ListTeacherComponent,
    UserDetailsComponent,
    OrdersUserComponent,
    CoursesOrderComponent,
    MedicalHistoryComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    DataTablesModule,
    Ng2SmartTableModule, 
    ReactiveFormsModule,
    FormsModule,
    UsersRoutingModule,
    MatTableModule,
    MatSortModule 
  ],
})
export class UsersModule { }
