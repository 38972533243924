import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WikiService } from 'src/app/shared/service/wiki.service';
import { ProductsService } from 'src/app/shared/service/products.service';
import { environment } from 'src/environments/environment';
import { CourseModel } from '../../products/product.models';
import { WikiModel } from '../wiki.models';
import Swal from 'sweetalert2';

const BASE_URL: string = environment.API_URL;
@Component({
  selector: 'app-add-wiki',
  templateUrl: './add-wiki.component.html',
  styleUrls: ['./add-wiki.component.scss']
})
export class AddWikiComponent implements OnInit {
  public wikiForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean = false;
  public productId: number;
  public id: number;

  public subtopics = [];
  public subtopicName;
  public footer: string = null;

  public ImgfileName = '';
  public viewLoaderImg = false;
  public responseImgFile: string = '';
  public previewImg: any;
  public footer1: string = '';

  public categories : any;
  public imageUrl:any;
  public requiredFileType: string;

  public image = [];
  public images: any;
  public imagesresult: string;
  public showImages: boolean;
  public array: any;
  public aditionalImages = [];

  public counter: number = 1;
  public url = [{
    img: "assets/images/photo.png",
  }
  ]
  private subs: Array<Subscription> = [];
  subtopicForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private wikiService: WikiService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private productService: ProductsService
  ) {
    

    this.wikiForm = this.fb.group({
      Topic: ['', [Validators.required]],
      Type: ['', [Validators.required]],
    });

    this.subtopicForm = this.fb.group({
      title: [''],
      content: [''],
      footerImg: ['']
    });

    this.productService
    .getCategories()
    .subscribe(data => {
      this.categories = data;
    })

  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'Montserrat',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'montserrat', name: 'Montserrat' }
    ],
    sanitize: true,
    toolbarHiddenButtons: [
      ['insertImage',
        'insertVideo']
       ]
  };

  onImgSelected(event, num) {
   
    const file: File = event.target.files[0];
    this.ImgfileName = file.name;

    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      this.viewLoaderImg = true;
    setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('profileImg', file);
       

          this.subs.push(
            this.wikiService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.ImageUrl !== null) {
                  if (this.responseImgFile == '') {
                    this.responseImgFile = resolve.ImageUrl;
                    this.viewLoaderImg = false;
                    this.viewBase64(file).then(image => {
                      this.previewImg = image;
                    })
                  }
                }

              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  deleteSubTopic(index: any){
    this.subtopics = this.subtopics.splice(index, 1);
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  });

  addSubtopic(){
    let inputName = this.subtopicForm.get("title").value;
    let inputContent = this.subtopicForm.get("content").value;
    let footerImg = this.subtopicForm.get('footerImg').value;
    if( !inputName || !inputContent ){
      this.showErrorMessage("Error", "Debes agregar titulo y contenido");
      return;
    }
    if( !this.responseImgFile || !footerImg ){
      this.showErrorMessage("Error", "Debes agregar imagen y pie de foto");
      return;
    }
    this.subtopics.push({
      title: inputName,
      content: inputContent,
      image: {image: this.responseImgFile, footer: footerImg}
    });
    this.subtopicForm.get('title').setValue('');
    this.subtopicForm.get('content').setValue('');
    this.subtopicForm.get('footerImg').setValue('');
    this.responseImgFile = '';
    this.ImgfileName = '';

  }

  ngOnInit() {
   }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.wikiForm.controls
  }

  private setValues(product: any): void {
    
    const values = this.wikiService.mapRequiredValues(product);
    this.id = values.id;
    this.imageUrl = values.ImageUrl;
 const { controls } = this.wikiForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.wikiForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildNew(): any {
    let user = JSON.parse(localStorage['currentUser']);
    let subtopics = this.subtopics.map( (element: any) => {
      return {
        SubTopic: element.title,
        Changes: element.content,
        ImageURL: JSON.stringify(element.image),
        wikiuserId: user.id
      }
    });
    return {
      Topic: this.wikiForm.controls['Topic'].value,
      Category: this.wikiForm.controls['Type'].value,
      subtopics: subtopics
    };
  }

  private buildNewUpdated(): any {
    }

  onSubmit() {
    this.submitted = true;

    if (this.wikiForm.invalid) {
      return;
    }

    if( this.subtopics.length == 0 ){
      this.showErrorMessage("Error", "Debes agregar al menos un subtema");
    }

    if (this.onEdit) {
      this.updateNew();
    } else {
      this.createNew();
    }
  }

  createNew() {

    const news: WikiModel = this.buildNew();
    this.isLoading = true;
    this.subs.push(
      this.wikiService.createWiki(news)
        .pipe(
          map(() => {
            this.handleCourseCreation();
            setTimeout(() => {
              this.router.navigateByUrl('wiki');
            }, 1800);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  updateNew() {
    const news: WikiModel = this.buildNewUpdated();
    this.isLoading = true;
    }

  private handleCourseCreation() {
    /* const toastOptions: ToastOptions = {
      title: 'Wiki',
      msg: 'Tema creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions); */
  }

  private handleCourseEdition() {
   /*  const toastOptions: ToastOptions = {
      title: 'Curso',
      msg: 'Curso actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions); */
  }

  private handleError(error: any) {
  /*   const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear la wiki',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);
*/
    return []; 
  }

  private showErrorMessage(title, msg) {
   /*  const toastOptions: ToastOptions = {
      title: title,
      msg: msg,
      showClose: true,
      timeout: 1800
    };
    this.isLoading = false;
    this.toastyService.error(toastOptions); */
  }

  private NoTypeImg() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions); */
  }

  private NoTypeFile() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un archivo de tipo pdf',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions); */
  }

}
