<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Nº de orden</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Total</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders">
                        <td>{{order.id}}</td>
                        <td>{{order.SaleOrderDate | date:'dd/MM/yyyy'}}</td>
                        <td>{{order.Customer.firstName}} {{order.Customer.lastName}}</td>
                        <td>{{order.TotalAmount | currency}} {{order.CurrencyCode}}</td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['show', order.id]"
                                title="Ver Orden">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="count" [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->