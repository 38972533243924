import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '@ks89/angular-modal-gallery';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NewsService } from 'src/app/shared/service/news.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
  providers: [NgbRatingConfig]
})
export class NewsDetailComponent implements OnInit {
  public closeResult: string;

  private subs: Array<Subscription> = [];
  public news: any;

  public dtTrigger: Subject<any> = new Subject()

  constructor(
    private newsService: NewsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    config: NgbRatingConfig,) {
    config.max = 5;
    config.readonly = false;
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.newsService.getNew(id)),
        catchError(error => {
          this.router.navigate(['/news']);
          return throwError(error);
        })
      ).subscribe(news => {
        this.news = news;
        this.dtTrigger.next();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
