import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { UserModel } from '../auth.models';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { NotificationType } from '../../interfaces/notification.enums';
import { ClientRoles } from '../../services/auth/auth.models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedLogin = false;
  public fieldTextType?: boolean = false;
  submittedError: boolean = false;
  messageError: string = '';
  submittedCredentials: boolean = false;
  messageCredentials: string = '';
  username: string = '';
  password: string = '';
  private subs: Array<Subscription> = [];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
  ) {
  }

  owlcarousel = [
    {
      title: "Hub de seguridad",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Hub de seguridad",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Hub de seguridad",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required]
    })

    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      businessname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
      confirmedPassword: ['', [Validators.required]]
    })

    console.log(this.username)
    console.log(this.password)
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.loginForm.controls;
  }

  private validatePasswords(): void {
    const password: string = this.registerForm.get('password').value;
    const confirmPassword: string = this.registerForm.get('confirmedPassword').value;

    if (password !== confirmPassword) {
      this.registerForm.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  private buildUser(): UserModel {
    return {
      firstName: this.registerForm.controls['firstName'].value,
      lastName: this.registerForm.controls['lastName'].value,
      businessname: this.registerForm.controls['businessname'].value,
      password: this.registerForm.controls['password'].value,
      email: this.registerForm.controls['email'].value
    }
  }

  private loginUser(): UserModel {
    return {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value
    }
  }

  public toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  private handleError(error: any) {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear el nuevo establecimiento',
      showClose: true,
      timeout: 2000
    };
    this.isLoading = false; */
    /*  this.toastyService.error(toastOptions); */

    return [];
  }

  private handleUserCreation() {
    /*   const toastOptions: ToastOptions = {
        title: 'Usuario',
        msg: 'Usuario creado correctamente',
        showClose: true,
        timeout: 1700
      }; */

    /* this.toastyService.success(toastOptions); */
  }

  onSubmit() {


    if (this.loginForm.controls['email'].value === '' || this.loginForm.controls['password'].value === '') {
      this.submittedLogin = true;
    }

    if (this.loginForm.invalid) {
      this.addToast('Debes llenar todos los datos', 'error-snackbar')
      return;
    }

    const user: UserModel = this.loginUser();
    this.isLoading = true;
    this.submittedLogin = false;
    this.subs.push(
      this.authService.login(user)
        .pipe(
          map((data: any) => {
            if (data.Message) {
              /* if(data.Message == "Esta cuenta no se encuentra activa"){
                this.submittedError = true;
                this.messageError = 'Su cuenta se encuentra suspendida, favor de contactar al administrador'
               
              }  */
              this.sendNotification('Error al acceder', data?.Message, NotificationType.error);
              this.isLoading = false;
              return;
            }/* 
            localStorage.setItem('user', JSON.stringify(data));
            this.authService.currentUserValue; */
            if (data.roles === 'admin') {
              this.router.navigateByUrl("/empresa");
              return;
            }
            if (data.roles == 'business') {
              this.router.navigateByUrl("/colaboradores");
            }

          }),
          catchError(err => {
            this.isLoading = false;
            this.submittedLogin = true;
            this.sendNotification('Error al acceder', err.error?.message, NotificationType.error);
            return throwError(err);
          })
        ).subscribe()
    );
  }

  registerUser() {
    this.submitted = true;
    this.validatePasswords();

    if (this.registerForm.invalid) {
      return;
    }
    const user: UserModel = this.buildUser();
    this.isLoading = true;
    this.subs.push(
      this.authService.signUp(user)
        .pipe(
          map(() => {
            this.handleUserCreation();
            setTimeout(() => {
              window.location.reload();
            }, 1800);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {

    if (message == 'Contraseña incorrecta.') {
      this.submittedError = true;
      this.messageError = 'Credenciales invalidas';
    } else {
      this.submittedError = true;
      this.messageError = message;
    }

    setTimeout(() => {
      this.submittedError = false;
      this.messageError = '';
    }, 2500)
  }

  addToast(message: any, className: string) {
    this.zone.run(() => {
      this._snackBar.open(message, null, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [className],
      });
    });

  }

}
