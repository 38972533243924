import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from '../../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProspectusComponent } from './prospectus/prospectus.component';
import { PolicityPrivacityComponent } from './policityPrivacity/policityPrivacity.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProspectusComponent,
    PolicityPrivacityComponent,
    ConfirmAccountComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    SharedModule,
    NgbToastModule,
    NgSelectModule,
    NgbToastModule,
    MatSnackBarModule,
  ],
})
export class AuthModule { }
