import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { AddWikiComponent } from './add-wiki/add-wiki.component';
import { WikiDetailComponent } from './wiki-detail/wiki-detail.component';
import { WikiComponent } from './wiki-list/wiki-list.component';

const routes: Routes = [
    {
      path: '',
      children: [
        {
          path: '',
          component: WikiComponent,
          data: {
            title: "Wiki",
            breadcrumb: "Wiki"
          }
        },
        {
          path: 'add-wiki',
          component: AddWikiComponent,
          data: {
            title: "Agregar Wiki",
            breadcrumb: "Agregar Wiki"
          }
        },
        {
          path: 'edit/:id',
          component: AddWikiComponent,
          data: {
            title: "Editar Wiki",
            breadcrumb: "Editar Wiki"
          }
        },
        {
          path: 'show/:id',
          component: WikiDetailComponent,
          data: {
            title: "Ver Wiki",
            breadcrumb: "Ver Wiki"
          }
        }
      ]
    }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class WikiRoutingModule { }