<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12 col-sm-6 centertb">
            <p class="no-mb">Total de wikis: <strong>{{wikis.length}}</strong></p>
        </div>
        <div class="col-xs-12 col-sm-6 text-right">
            <div class="action">
                <a class="btn btn-red btn-sm"
                  [routerLink]="['add-wiki']">
                  <i class="ti-plus"></i>
                  <span>Agregar Wiki</span>
                </a>
            </div>
        </div>
    </div>
    <div class="row products-admin ratio_asos">
        <div class="col-xl-4 col-sm-6" *ngFor="let wiki of wikis">
            <br>
            <div class="card">
                <div class="card-body product-box">
                    <br>
                    <div class="product-detail">
                        <h6>{{wiki.Topic}}</h6>
                        <br>
                        <br>
                        <hr>
                        <table>
                            <tbody>
                              <tr>
                                    <td><i class="fas fa-calendar-alt"></i></td>
                                    <td><i class="fa fa-copyright"></i></td>
                                    <td>{{wiki.created_at}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-eye-slash"></i></td>
                                    <td><span [ngClass]="{'show': wiki.Active === true, 'hide': wiki.Active === false}">{{wiki.Active === true ? 'Publicado' : 'Oculto'}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 fright">
                            <p>
                                <button type="button" class="btn btn-primary mr-1 mat-btn" (click)="wiki.isCollapsed = !wiki.isCollapsed"
                                    [attr.aria-expanded]="wiki.isCollapsed">
                                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                </button>
                            </p>
                            <div [ngbCollapse]="!wiki.isCollapsed">
                                <div class="product-hover">
                                    <ul class="">
                                        <li>
                                            <button [routerLink]="['show', wiki.id]" class="btn bt-action" type="button" data-original-title="">Ver wiki</button>
                                        </li>
                                        <!-- <li>
                                            <button [routerLink]="['edit', wiki.id]" class="btn bt-action" type="button" data-original-title="">Editar wiki</button>
                                        </li> -->
                                        <li>
                                            <button (click)="setModalContent(content, wiki.id, wiki.Active)" class="btn bt-action" type="button" data-original-title="">{{wiki.Active === true ? 'Ocultar wiki' : 'Mostrar wiki'}}</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">{{active === true ? 'Ocultar wiki' : 'Publicar wiki'}}</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">{{active === true ? 'Se ocultarán todos los datos de la wiki.' : 'Se publicarán todos los datos de la wiki.'}}</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>{{active === true ? 'Wiki publicada correctamente' : 'Wiki mostrada correctamente'}}</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger" [ngClass]="{'btn-success': active === false, 'btn-danger': active === true}"
        (click)="deleteCourse()">{{active === true ? 'Ocultar' : 'Publicar'}}</button>
    </div>
  </ng-container>
</ng-template>
