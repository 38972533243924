import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserModel, AuthModel, UserCreateModel } from '../../auth/auth.models';
import { NotificationType } from '../../interfaces/notification.enums';
import { UsersService } from '../../services/users/users.service';

@Component({
    selector: 'app-add-enterprise',
    templateUrl: './add-enterprise.component.html',
    styleUrls: ['./add-enterprise.component.scss']
  })
  export class AddEnterpriseComponent implements OnInit, OnDestroy {

    public values: any;
    public form: FormGroup;
    public submitted: boolean = false;
    public isLoading = false;
    public items = [];
    public selectedOption: any;
    public showForm: boolean = true;
    public showRegisterThanks: boolean = false;
    passwordMatch: boolean = false;
    personalDetails!: FormGroup;
    listEnterprise: any;
    emailMatch: boolean = false;
    submittedSuccess: boolean = false;
    messageSuccess: string = '';
    orderText: string = 'firstName-asc';
    private subs: Array<Subscription> = [];

    constructor(
        private fb: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        private usersService: UsersService,
    ) {
        this.items = [
            { name: 'Pertenece a', value: '' },
            { name: 'CRO', value: 'CRO' },
            { name: 'Farmacéutica', value: 'Farmacéutica' },
            { name: 'Centro de Investigación', value: 'Centro de Investigación' }
        ]
        this.selectedOption = this.items[0].name;

        this.usersService.getEnterprise(9999, 0, 'business', this.orderText).subscribe((data: any) => {
            this.listEnterprise = data.List;
          })
    }

    ngOnInit(): void {
        this.personalDetails = this.fb.group({
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            PhoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), this.validateEmail.bind(this)]],
            BusinessName: ['', Validators.required],
            Colabs: ['', Validators.required],
            Password: ['', [Validators.required, Validators.minLength(8)]],
            PasswordConfirm: ['', [Validators.required, Validators.minLength(8), this.validatePasswords.bind(this)]],
        });

    }

    ngOnDestroy(): void {
        let sub: Subscription;
        while (sub = this.subs.shift()) {
            sub.unsubscribe();
        }
    }

    public get formState() {
        return this.personalDetails.controls;
    }

    public onSubmit(): void {
        this.submitted = true;
        this.validatePasswords();

        if (this.personalDetails.invalid) {
            return;
        }

        this.createUser();
        this.onClickRedirectLogin();
    }

    private createUser() {
        const user: UserCreateModel = this.buildUser();
        this.isLoading = true;
        this.subs.push(
            this.authService.createUserPerson(user)
                .pipe(
                    map(() => {
                        this.handleUserCreation();
                        this.showForm = false;
                        this.submitted = false;
                    }),
                    catchError(error => this.handleError(error))
                )
                .subscribe()
        );

    }

 

     validatePasswords(){
        if (this.personalDetails && this.personalDetails.controls && this.personalDetails.controls['Password'] && this.personalDetails.controls['PasswordConfirm']) {
            const password: string = this.personalDetails.controls['Password'].value;
            const PasswordConfirm : string = this.personalDetails.controls['PasswordConfirm'].value;
            if (password !== PasswordConfirm) {
                this.passwordMatch = true;
            } else if (password === PasswordConfirm){
                this.passwordMatch = false;
            };
          }
    }

    validateEmail() {
        
        if (Array.isArray(this.listEnterprise) && this.personalDetails && this.personalDetails.controls && this.personalDetails.controls['Email']) {
           const email: string = this.personalDetails.controls['Email'].value;
           let emailMatch = false;
           for (let i = 0; i < this.listEnterprise.length; i++) {
               const item = this.listEnterprise[i];
               if (item.email === email) {
                   emailMatch = true;
                   break;
               }
           }
           this.emailMatch = emailMatch;
       } 
   }


    private buildUser(): UserCreateModel {
        return {
            firstName: this.personalDetails.controls['FirstName'].value,
            lastName: this.personalDetails.controls['LastName'].value,
            email: this.personalDetails.controls['Email'].value,
            password: this.personalDetails.controls['Password'].value,
            phoneNumber: this.personalDetails.controls['PhoneNumber'].value,
            businessname: this.personalDetails.controls['BusinessName'].value,
            collaborators: this.personalDetails.controls['Colabs'].value,
            roles: 'business'
        }
    }

    public onClickRedirectLogin(): void {
        this.submittedSuccess = false;
        setTimeout(() => {
            this.router.navigateByUrl('/empresa');
        }, 5000)
       
    }

    private handleError(error: any) {
       /*  this.isLoading = false;
        const toastOptions: ToastOptions = {
            title: 'Error',
            msg: error.error.message,
            showClose: true,
            timeout: 2000
        }; */
        /* this.toastyService.error(toastOptions); */
        return [];
    }

    private handleUserCreation() {
        this.isLoading = false;
            this.submittedSuccess = true;
            this.messageSuccess = 'Empresa creada correctamente'
    }

    private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {
        /* const toastOptions: ToastOptions = {
            title,
            msg: message,
            showClose: true,
            timeout: 3500,
            theme: 'bootstrap'
        };

        this.toastyService[type](toastOptions);
    } */
  }
}