import { Component, OnDestroy, OnInit } from "@angular/core";
import { UsersService } from "../../../services/users/users.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-historyClinic',
  templateUrl: './historyClinic.component.html',
  styleUrls: ['./historyClinic.component.scss']
})
export class HistoryClinicComponent implements OnInit {

  step: number = 0;
  formPatologicas!: FormGroup;
  formNoPatologicas!: FormGroup;
  formFamiliares!: FormGroup;
  arrayFamiliares: any;
  arrayUser: any;
  stages: any;
  showSelect: boolean = false;
  arrayAnwsers: any;
  arrayAnwser: any[] = [];
  arrayFormData: any;
  arrId: any;
  public optionSelected: string[] = [];
  public optionSelected2: string[] = [];
  arrayAnwsersExtremidades: any[] = [];
  arrayFormDataExtremidades: any;
  comments: string;
  submittedSuccessPatologica: boolean = false;
  messageSuccessPatologica: string = '';
  submittedSuccessNoPatologica: boolean = false;
  messageSuccessNoPatologica: string = '';
  submittedSuccessFamiliares: boolean = false;
  messageSuccessFamiliares: string = '';
  diabeticsBoolean: any;
  cardiologistsBoolean: any;
  neoplasticsBoolean: boolean = false;
  venerealBoolean: boolean = false;
  phymicsBoolean: boolean = false;
  psychiatricBoolean: boolean = false;
  epilepticsBoolean: boolean = false;
  allergicsFamBoolean: boolean = false;

  //////
  diabeticBoolean: boolean = false;
  cardiologistBoolean: any;
  hypertensiveBoolean: boolean = false;
  obesityBoolean: boolean = false;
  epilepsyBoolean: boolean = false;
  surgicalBoolean: boolean = false;
  venerealDiseasesBoolean: boolean = false;
  traumaticBoolean: boolean = false;
  allergicBoolean: boolean = false;

  /** 
  * * Array de lista de enfermedades
  */
  listDataPatologicas: any;
  listDataNoPatologicas: any;
  listDataFamiliares: any;
  /** 
  * * Array de lista de enfermedades
  */
  /** 
   * * Array de lista Preguntas
   */
  listAnwserPatologicas: any;
  listAnwserNoPatologicas: any;
  listAnwserFamiliares: any;
  /** 
   * *Lista de enfermedades patologicas
   */
  diabetic: any;
  cardiologist: any;
  hypertensive: any;
  obesity: any;
  epilepsy: any;
  surgical: any;
  venerealDiseases: any;
  traumatic: any;
  allergic: any;
  /** 
   * *Lista de enfermedades
   */
  formPatologia: any = {
    diabetic: '',
    cardiologist: '',
    hypertensive: '',
    obesity: '',
    epilepsy: '',
    surgical: '',
    venerealDiseases: '',
    traumatic: '',
    allergic: '',
  }


  /** 
  * *Variables para obtener los datos del formulario patologicas
  */
  diabeticChosen: string = 'Selecciona una opción';
  cardiologistChosen: string = 'Selecciona una opción';
  hypertensiveChosen: string = 'Selecciona una opción';
  obesityChosen: string = 'Selecciona una opción';
  epilepsyChosen: string = 'Selecciona una opción';
  surgicalChosen: string = 'Selecciona una opción';
  venerealDiseasesChosen: string = 'Selecciona una opción';
  traumaticChosen: string = 'Selecciona una opción';
  allergicChosen: string = 'Selecciona una opción';
  /** 
  * *Variables para obtener los datos del formulario patologicas
  */
  /** 
  * *Lista de enfermedades no patologicas
  */
  alcoholism: any;
  smoking: any;
  drugAddiction: any;
  physicalActivity: any;
  healthySleep: any;
  room: any;
  feeding: any;
  grooming: any;
  /** 
   * *Lista de enfermedades
   */
  /** 
  * *Variables para obtener los datos del formulario no patologicas
  */
  alcoholismChosen: string = 'Selecciona una opción';
  smokingChosen: string = 'Selecciona una opción';
  drugAddictionChosen: string = 'Selecciona una opción';
  physicalActivityChosen: string = 'Selecciona una opción';
  healthySleepChosen: string = 'Selecciona una opción';
  roomChosen: string = 'Selecciona una opción';
  feedingChosen: string = 'Selecciona una opción';
  groomingChosen: string = 'Selecciona una opción';
  /** 
  * *Variables para obtener los datos del formulario no patologicas
  */
  /** 
     * *Variables para obtener los datos del formulario no patologicas
     */
  alcoholismBoolean: any;
  smokingBoolean: any;
  drugAddictionBoolean: any;
  physicalActivityBoolean: any;
  healthySleepBoolean: any;
  roomBoolean: any;
  feedingBoolean: any;
  groomingBoolean: any;
  /** 
  * *Variables para obtener los datos del formulario no patologicas
  */


  /** 
* *Lista de enfermedades Antecedentes Heredo-Familiares
*/
  diabetics: any;
  cardiologists: any;
  neoplastics: any;
  venereal: any;
  phymics: any;
  psychiatric: any;
  epileptics: any;
  allergicsFam: any;
  /** 
   * *Lista de enfermedades
   */
  /** 
  * *Variables para obtener los datos del formulario Antecedentes Heredo-Familiares
  */
  diabeticsChosen: any;
  cardiologistsChosen: any;
  neoplasticsChosen: string = 'Selecciona una opción';
  venerealChosen: string = 'Selecciona una opción';
  phymicsChosen: string = 'Selecciona una opción';
  psychiatricChosen: string = 'Selecciona una opción';
  epilepticsChosen: string = 'Selecciona una opción';
  allergicsFamChosen: string = 'Selecciona una opción';
  /** 
  * *Variables para obtener los datos del formulario Antecedentes Heredo-Familiares
  */
  userId: any;
  currentUser: any;
  orderText: string = 'firstName-asc';
  public limit = 10;
  public offset = 0;
  arrayDataUser: any;
  arrayData: any;
  userCiviledo: string;
  placeholder: string = 'Seleccione una opción'
  quizResponse: any;
  constructor(
    private _usersService: UsersService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {



    /** 
        ** Exploracion fisica
        */
    this._usersService.getExplorationPhysic(1).subscribe((data) => {
      this.stages = data.stages;
    })





    /** 
        ** Exploracion fisica
        */

    /** 
    ** Antecedentes personales patologicos
    */
    this._usersService.getQuiz(2).subscribe((answers) => {
      /** 
  * *Obtengo la data
  */
      this.listDataPatologicas = answers;
      /** 
 * *Obtengo la lista de enfermedades
 */
      this.listAnwserPatologicas = answers.questions;
      /** 
 * * Obtengo las una por una enfermedades
 */
      this.diabetic = this.listAnwserPatologicas[0];
      this.cardiologist = this.listAnwserPatologicas[1];
      this.hypertensive = this.listAnwserPatologicas[2];
      this.obesity = this.listAnwserPatologicas[3];
      this.epilepsy = this.listAnwserPatologicas[4];
      this.surgical = this.listAnwserPatologicas[5];
      this.venerealDiseases = this.listAnwserPatologicas[6];
      this.traumatic = this.listAnwserPatologicas[7];
      this.allergic = this.listAnwserPatologicas[8];
      /** 
  * * Obtengo las una por una enfermedades
  */

    })

    /** 
    ** Antecedentes Heredo-Familiares
    */
    this._usersService.getQuiz(1).subscribe((answers) => {
      /** 
       * *Obtengo la data
       */
      this.listDataFamiliares = answers;

      /** 
   * *Obtengo la lista de enfermedades
   */
      this.listAnwserFamiliares = answers.questions;

      /** 
         * * Obtengo las una por una enfermedades
         */
      this.diabetics = this.listAnwserFamiliares[0];
      this.cardiologists = this.listAnwserFamiliares[1];
      this.neoplastics = this.listAnwserFamiliares[2];
      this.psychiatric = this.listAnwserFamiliares[3];
      this.epileptics = this.listAnwserFamiliares[4];
      this.venereal = this.listAnwserFamiliares[5];
      this.phymics = this.listAnwserFamiliares[6];
      this.allergicsFam = this.listAnwserFamiliares[7];
      /** 
   * * Obtengo las una por una enfermedades
   */



    })

    this._usersService.getQuiz(3).subscribe((answers) => {
      /** 
     * *Obtengo la data
     */
      this.listDataNoPatologicas = answers;
      /** 
   * *Obtengo la lista de enfermedades
   */
      this.listAnwserNoPatologicas = answers.questions;

      /** 
 * * Obtengo las una por una enfermedades
 */
      this.alcoholism = this.listAnwserNoPatologicas[0];
      this.smoking = this.listAnwserNoPatologicas[1];
      this.drugAddiction = this.listAnwserNoPatologicas[2];
      this.physicalActivity = this.listAnwserNoPatologicas[3];
      this.healthySleep = this.listAnwserNoPatologicas[4];
      this.room = this.listAnwserNoPatologicas[5];
      this.feeding = this.listAnwserNoPatologicas[6];
      this.grooming = this.listAnwserNoPatologicas[7];
      /** 
   * * Obtengo las una por una enfermedades
   */

    })


    this.formPatologicas = this.fb.group({
      diabeticChosen: new FormControl('', [Validators.required]),
      cardiologistChosen: new FormControl('', [Validators.required]),
      hypertensiveChosen: new FormControl('', [Validators.required]),
      obesityChosen: new FormControl('', [Validators.required]),
      epilepsyChosen: new FormControl('', [Validators.required]),
      surgicalChosen: new FormControl('', [Validators.required]),
      venerealDiseasesChosen: new FormControl('', [Validators.required]),
      traumaticChosen: new FormControl('', [Validators.required]),
      allergicChosen: new FormControl('', [Validators.required]),
    });

    this.formNoPatologicas = this.fb.group({
      alcoholismChosen: new FormControl('', [Validators.required]),
      smokingChosen: new FormControl('', [Validators.required]),
      drugAddictionChosen: new FormControl('', [Validators.required]),
      physicalActivityChosen: new FormControl('', [Validators.required]),
      healthySleepChosen: new FormControl('', [Validators.required]),
      roomChosen: new FormControl('', [Validators.required]),
      feedingChosen: new FormControl('', [Validators.required]),
      groomingChosen: new FormControl('', [Validators.required]),
    });

    this.formFamiliares = this.fb.group({
      diabeticsChosen: new FormControl('', [Validators.required]),
      cardiologistsChosen: new FormControl('', [Validators.required]),
      neoplasticsChosen: new FormControl('', [Validators.required]),
      psychiatricChosen: new FormControl('', [Validators.required]),
      epilepticsChosen: new FormControl('', [Validators.required]),
      venerealChosen: new FormControl('', [Validators.required]),
      phymicsChosen: new FormControl('', [Validators.required]),
      allergicsFamChosen: new FormControl('', [Validators.required]),
    });

    this._usersService.getCatalogFamiliar().subscribe(data => {
      this.arrayFamiliares = data;
    })

    this.loadData();
  }


  loadData() {
    this.userId = this._usersService.getId();

    this.currentUser = this._usersService.getidUser()
    this._usersService.getUser(this.userId!).subscribe((data: any) => {
      this.arrayUser = [data];

      this.userCiviledo = this.arrayUser[0].gender;
      const userCiviledoFromLocalStorage = localStorage.getItem('userCiviledo');

      if (userCiviledoFromLocalStorage !== this.userCiviledo) {
        localStorage.setItem('userCiviledo', this.userCiviledo);
      }

      localStorage.setItem('userData', JSON.stringify(this.arrayUser));
    })

    const userDataString = localStorage.getItem('userData');
    this.arrayUser = JSON.parse(userDataString);


    /* this._usersService.getQuizsByUser(this.currentUserId).subscribe((data: any) => { */
    /*   this.arrayDataUser = data; */
    const arrData = [1, 2, 3]
    const observables = arrData.map((element: any) => {
      /*   return this._usersService.getAnswersByQuiz(element?.id); */
      return this._usersService.getQuiz(element)
    });

    forkJoin(observables).subscribe((results: any) => {
      this.arrayData = [];
      results.forEach((data: any) => {
        this.arrayData.push(data);
      });

      console.log(this.arrayData);

      /*   this.arrayData = this.arrayData.filter(item => !this.quizResponse?.some(dataItem => dataItem.quiz.Title === item.Title));             */
    });
    /*  }); */

    this._usersService.getQuiz(4).subscribe((answers) => {
    })

  }




  ngOnInit(): void {
    /* const userDataString = localStorage.getItem('userData') */
    console.log(this.arrayUser[0].id)
    this._usersService.getQuizsByUser(this.arrayUser[0].id).subscribe(data => {
      this.quizResponse = data;
      console.log(data);
    })
  }




  /** 
    * * Funcion para sumar step
    */
  sumSteps() {
    this.step = this.step + 1;
  }

  /** 
* * Funcion para sumar step
*/

  /** 
* * Funcion para restar step
*/
  restSteps() {
    this.step = this.step - 1;
  }

  /** 
* * Funcion para restar step
*/



  capturar(event, answer, item, arr) {
    this.arrId = arr.id;
    console.log(this.arrId);
    console.log(answer);
    console.log(item);
    this.diabeticsChosen = event;
    if (this.diabeticsChosen !== undefined && this.diabeticsChosen !== null) {
      this.updateDiabeticsChosen(true, answer, item);
    }
  }

  updateDiabeticsChosen(value: boolean, answer, item) {
    console.log(answer);
    console.log(item);
    this.showSelect = value;
    let diabeticsTrue;
    /*  const element = answer.answers[index]; */
    if (this.showSelect.toString() == 'Sí') {
      this.diabeticsBoolean = 'Sí'
      diabeticsTrue = `${this.diabeticsChosen}`;
    } else if (this.showSelect.toString() == 'No') {
      this.diabeticsBoolean = 'No';
      diabeticsTrue = this.diabeticsBoolean;
    } else {
      diabeticsTrue = `${this.diabeticsChosen}`;
    }



    let filteredAnswerId;

    if (answer.Answer === 'Sí') {
      filteredAnswerId = answer.answers.find(answer => answer.Answer === 'Sí')?.id;
    } else if (answer.Answer !== 'Sí') {
      filteredAnswerId = answer.answers.find(answer => answer.Answer === 'No')?.id;
    }


    const exists = this.arrayAnwser.some((item: any) => {
      return item.id === answer.id;
    });

    if (!exists && this.diabeticsChosen !== undefined) {
      const formData = {
        QuestionId: answer.id,
        AnswerId: filteredAnswerId,
        Answers: diabeticsTrue,
      }
      this.arrayAnwser.push(formData);
    }

    console.log(this.comments)
    answer.Answer = value
    const validId = this.arrayData.some(quiz => quiz.id === this.arrId);
    if (validId) {
      this.arrayFormData = {
        UserId: this.arrayUser[0].id,
        QuizId: this.arrId,
        answers: this.arrayAnwser,
        Comments: this.comments
      };
    }

    console.log(this.arrayFormData)
  }
  capturarExtremidades(event, answer, item) {
    this.cardiologistsChosen = event;
    console.log(event)
    console.log(answer)
    console.log(item)
    if (this.cardiologistsChosen !== undefined && this.cardiologistsChosen !== null) {
      this.updateExtremidades(true, answer, item);
    }
  }


  updateExtremidades(value: boolean, answer, item) {
    this.showSelect = value;
    console.log(answer);
    console.log(value);
    console.log(item)
    let diabeticsTrue;
    /*  const element = answer.answers[index]; */
    if (this.showSelect.toString() == 'Sí') {
      this.cardiologistsBoolean = 'Sí'
      diabeticsTrue = `${this.cardiologistsBoolean} - ${this.cardiologistsChosen}`;
    } else if (this.showSelect.toString() == 'No') {
      this.cardiologistsBoolean = 'No';
      diabeticsTrue = this.cardiologistsBoolean;
    } else if (this.showSelect.toString() == 'Normal') {
      this.cardiologistsBoolean = 'Normal';
      diabeticsTrue = this.cardiologistsBoolean;
    } else if (this.showSelect.toString() == 'Anormal') {
      this.cardiologistsBoolean = 'Anormal';
      diabeticsTrue = `${this.cardiologistsBoolean} - ${this.cardiologistsChosen}`;
    } else {
      diabeticsTrue = `${this.cardiologistsChosen}`;
    }


    if (answer?.id) {
      const exists = this.arrayAnwsersExtremidades.some((item: any) => {
        return item?.id === answer.id;
      });
      if (!exists || typeof this.cardiologistsChosen !== undefined) {
        const formData = {
          QuestionId: item?.id,
          AnswerId: answer?.id,
          Answers: diabeticsTrue,
          Comments: this.comments
        }
        this.arrayAnwsersExtremidades.push(formData);
      }
    } else if (item?.id) {
      const formData = {
        QuestionId: item?.id,
        Answers: diabeticsTrue
      }
      this.arrayAnwsersExtremidades.push(formData);
    }

    console.log(this.arrayAnwsersExtremidades)
    const quizId = answer.id;
    answer.response = value
    console.log(this.arrayAnwsersExtremidades)
    this.arrayFormDataExtremidades = {
      UserId: this.arrayUser[0].id,
      QuizId: quizId,
      answers: this.arrayAnwsersExtremidades
    };


    console.log(this.arrayFormDataExtremidades);
  }

  updateExtremidadesV2(value: boolean, answer, item) {
    answer.response = value
  }
  /** 
* * Funcion para enviar  formulario familiares
*/
  submitFormFamilia() {
    this._usersService.addHistoryClinic(this.arrayFormData).subscribe(data => {
      this.submittedSuccessFamiliares = true;
      this.messageSuccessFamiliares = 'Se guardaron correctamente las respuestas.';
      setTimeout(() => {
        this.submittedSuccessFamiliares = false;
        this.messageSuccessFamiliares = '';
        this.arrayAnwser = [];
        this.diabeticsBoolean = false;
        this.diabeticsChosen = '';
        this.optionSelected = [];
        this.comments = '';
      }, 2500)
      this.loadData();
      /*  this.router.navigate([`/empresa/detalle/${this.userId}`]) */
    })

  }


  submitFormExtremidades() {

    console.log(this.arrayFormDataExtremidades)
    this._usersService.addHistoryClinicExtremidades(this.arrayFormDataExtremidades).subscribe(data => {
      this.submittedSuccessFamiliares = true;
      this.messageSuccessFamiliares = 'Se guardaron correctamente las respuestas.';
      /*   setTimeout(() => {
          this.submittedSuccessFamiliares = false;
        this.messageSuccessFamiliares = '';
        },2500)
        this.router.navigate([`/empresa/detalle/${this.userId}`]) */
    })
  }

  /** 
* * Funcion para enviar formulario familiares
*/
}