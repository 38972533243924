<div class="page-wrapper d-flex justify-content-center col-lg-12 h-auto">
    <div class="authentication-box paddd">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-12">
                    <div class="card tab2-card background-login mb-5">
                        <div class="card-body">
                            <div class="text-center mt-1 mb-2">
                                <img src="../../../../assets/images/logo.png" style="width: 50%;" alt="">
                            </div>
                            <div class="text-center mb-4">
                                <p class="text-secondary">Bienvenido a Metramex<br>
                                    Ingresa tus historial clínico
                                </p>
                            </div>
                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                aria-labelledby="account-tab">
                                <form 
                                    class="form-horizontal auth-form" novalidate="">
                                    <div class="d-flex justify-content-between col-xs-12">
                                        <div class="d-flex flex-column col-lg-6">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="form-group">
                                                        <input type="text"
                                                            class="form-control" placeholder="Nombre"
                                                           >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="form-group">
                                                        <input type="text"
                                                            class="form-control" placeholder="Apellido paterno"
                                                            >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="form-group">
                                                        <input type="text"
                                                            class="form-control" placeholder="Apellido materno"
                                                            >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="form-group">
                                                        <input  type="number"
                                                            class="form-control" placeholder="Puesto"
                                                            >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column col-lg-6">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="form-group">
                                                        <input  type="date"
                                                            class="form-control" placeholder="Fecha de nacimiento"
                                                            >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="form-group">
                                                        <input  type="text"
                                                            class="form-control" placeholder="Numero de colaboradores"
                                                           >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="form-group">
                                                        <select name="sexo" class="form-control">
                                                            <option value="" selected>Selecciona una opción</option>
                                                            <option value="masculino">Masculino</option>
                                                            <option value="femenino">Femenino</option>
                                                            <option value="otro">Otro</option>
                                                          </select>
                                                        <!-- <input type="text"
                                                            class="form-control" placeholder="Sexo"
                                                            > -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="form-group">
                                                        <select name="Civil" class="form-control">
                                                            <option value="" selected>Selecciona una opción</option>
                                                            <option value="masculino">Casado/a</option>
                                                            <option value="femenino">Soltero/a</option>
                                                            <option value="femenino">Separado/a</option>
                                                            <option value="femenino">Viudo/a</option>
                                                          </select>
                                                      <!--   <input  type="text"
                                                            class="form-control" placeholder="Estado civil"
                                                            > -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <button type="submit" class="btn btn-primary" >
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </button>
                                </div>
                            </div> -->
                             <div class="row">
                                <div class=" d-flex justify-content-center col-xs-12 col-sm-12">
                                    <button type="submit" class="btn btn-primary" >
                                       Enviar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                     <div class="row">
                        <small class="pl-3"><a [routerLink]="['/users/teachers']">Volver</a></small>
                    </div> 
                   <!--  <br/>
                    <small>Al registrarte estás aceptando nuestros
                        <a href="">Términos y Política de Privacidad</a>
                    </small> -->
                </div>
               <!--  <div class="col-sm-6 col-xs-12 animate__animated animate__fadeIn">
                    <img src="../../../../assets/images/finish-register.png" alt="" class="mb-3 border-radius-cards">
                    <img src="../../../../assets/images/logo.png" style="width: 90%;" alt="">
                    <h4>Gracias por registrarse en Metramex, nos podremos en contacto con usted para brindarle más
                        información</h4>
                </div>
                <div class="col-md-3">
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- <ng2-toasty></ng2-toasty> -->