import { RouterModule, Routes } from "@angular/router";
import { ListEnterpriseComponent } from "./list-enterprise/list-enterprise.component";
import { NgModule } from "@angular/core";
import { AddEnterpriseComponent } from "./add-enterprise/add-enterprise.component";
import { EditEnterpriseComponent } from "./edit-enterprise/edit-enterprise.component";
import { DetailEnterpriseComponent } from "./detail-enterprise/detail-enterprise.component";
import { AddCollaboratorComponent } from "../allCollaborators/addColaborador/addCollaborator.component";
import { DetailCollaboratorComponent } from "../allCollaborators/detailColaborador/detail-colaborador.component";
import { DetailHistoryClinicComponent } from "./historyClinic/detail-history-clinic/detail-history-clinic.component";
import { HistoryClinicComponent } from "./historyClinic/add-history-clinic/historyClinic.component";
import { PhysicalExaminationComponent } from "./historyClinic/physicalExamination/physicalExamination.component";
import { EditCollaboratorComponent } from "./collaborator/edit-collaborator/edit-collaborator.component";
import { ListComponent } from "./historyClinic/list/list.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListEnterpriseComponent,
        data: {
          title: "Listado de empresa",
          breadcrumb: "Listado de empresa"
        }
      },
      {
        path: 'agregar',
        component: EditEnterpriseComponent,
        data: {
          title: "Agregar empresa",
          breadcrumb: "Agregar empresa"
        }
      },
      {
        path: 'editar/:id',
        component: EditEnterpriseComponent,
        data: {
          title: "Editar empresa",
          breadcrumb: "Editar empresa"
        }
      },
      {
        path: 'detalle/:id',
        component: DetailEnterpriseComponent,
        data: {
          title: "Detalle de empresa",
          breadcrumb: "Detalle de empresa"
        }
      },
      {
        path: 'detalle/:id/add-colaborador',
        component: AddCollaboratorComponent,
        data: {
          title: "Agregar Colaborador",
          breadcrumb: "Agregar Colaborador"
        }
      },
      {
        path: 'detalle/:id/edit-collaborator/:idUser',
        component: AddCollaboratorComponent,
        data: {
          title: "Agregar Colaborador",
          breadcrumb: "Agregar Colaborador"
        }
      },
      {
        path: 'add-colaborador',
        component: AddCollaboratorComponent,
        data: {
          title: "Agregar Colaborador",
          breadcrumb: "Agregar Colaborador"
        }
      },
      {
        path: 'edit-colaborador/:id',
        component: EditCollaboratorComponent,
        data: {
          title: "Editar Colaborador",
          breadcrumb: "Editar Colaborador"
        }
      },
      {
        path: 'detail-colaborador/:id',
        component: DetailCollaboratorComponent,
        data: {
          title: "Colaborador",
          breadcrumb: "Colaborador"
        }
      },
      {
        path: 'history-clinic',
        component: HistoryClinicComponent,
        data: {
          title: "Historia clinico",
          breadcrumb: "Historia clinico"
        }
      },
      {
        path: 'history-clinic/:id',
        component: ListComponent,
        data: {
          title: "Detalle de historial clinico",
          breadcrumb: "Detalle de historial clinico"
        }
      },
      {
        path: 'detail-historyClinic/:id',
        component: DetailHistoryClinicComponent,
        data: {
          title: "Detalle de historial clinico",
          breadcrumb: "Detalle de historial clinico"
        }
      },
      {
        path: 'physical-examination',
        component: PhysicalExaminationComponent,
        data: {
          title: "Exploracion fisica",
          breadcrumb: "Exploracion fisica"
        }
      },

    ]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnterpriseRoutingModule { }
