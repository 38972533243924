import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CollaboratorsComponent } from './collaborators.component';
import { AddCollaboratorComponent } from './addColaborador/addCollaborator.component';
import { EditCollaboratorComponent } from './editColaborador/edit-collaborator.component';
import { DetailCollaboratorComponent } from './detailColaborador/detail-colaborador.component';
import { DetailHistoryClinicComponent } from '../enterprise/historyClinic/detail-history-clinic/detail-history-clinic.component';
import { HistoryClinicComponent } from '../enterprise/historyClinic/add-history-clinic/historyClinic.component';
import { PhysicalExaminationComponent } from '../enterprise/historyClinic/physicalExamination/physicalExamination.component';
import { ListComponent } from '../enterprise/historyClinic/list/list.component';

const routes: Routes = [
  {
    path: '',
    component: CollaboratorsComponent,
    data: {
      title: 'Listado de colaboradores',
      breadcrumb: 'Listado de colaboradores',
    },
  },
  {
    path: 'agregar-colaborador',
    component: AddCollaboratorComponent,
    data: {
      title: 'Agregar colaborador',
      breadcrumb: 'Agregar colaborador',
    },
  },
  {
    path: 'editar/:idUser',
    component: AddCollaboratorComponent,
    data: {
      title: 'Editar colaborador',
      breadcrumb: 'Editar colaborador',
    },
  },
  {
    path: 'detalle/:id',
    component: DetailCollaboratorComponent,
    data: {
      title: 'Detalle de colaborador',
      breadcrumb: 'Detalle de colaborador',
    },
  },
  {
    path: 'detail-historyClinic/:id',
    component: DetailHistoryClinicComponent,
    data: {
      title: 'Detalle de historial clinico',
      breadcrumb: 'Detalle de historial clinico',
    },
  },
  {
    path: 'history-clinic/:id',
    component: ListComponent,
    data: {
      title: 'Agregar historial clínico',
      breadcrumb: 'Agregar historial clínico',
    },
  },
  {
    path: 'history-clinic/:id/new-history',
    component: DetailHistoryClinicComponent,
    data: {
      title: 'Agregar historial clínico',
      breadcrumb: 'Agregar historial clínico',
    },
  },
  {
    path: 'history-clinic/:id/details/:historyId',
    component: DetailHistoryClinicComponent,
    data: {
      title: 'Agregar historial clínico',
      breadcrumb: 'Agregar historial clínico',
    },
  },
  {
    path: 'physical-examination',
    component: PhysicalExaminationComponent,
    data: {
      title: 'Exploracion fisica',
      breadcrumb: 'Exploracion fisica',
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CollaboratorsRoutingModule { }
