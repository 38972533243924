import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../services/users/users.service';

@Component({
  selector: 'app-edit-collaborator',
  templateUrl: './edit-collaborator.component.html',
  styleUrls: ['./edit-collaborator.component.scss'],
})
export class EditCollaboratorComponent implements OnInit, OnDestroy {
  
  
    public accountForm!: FormGroup;
    currentUserId: any;
    collaborator: any;
    firstName: string;
    lastName: string;
    businessname: string;
    phoneNumber: string;
    email: string;
    job: string;
    birthday: string;
    gender: string;
    civiledo: string;
    collaborators: any;
    civiledoSelected: string;
    genderSelected: string;
    isLoading: boolean = false;
    public submittedSuccess: boolean = false;
    messageSuccess: string = '';
    enterprises: any;
    enterprisesId: any;
  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ){
    this.route.params.subscribe(params => {
      this.currentUserId = params['id'];
    });

    this.enterprisesId = this.usersService.getId();
    this.usersService.getEnterpriseId(this.enterprisesId).subscribe((data: any) => {
      this.enterprises = [data];
    })

  }
  
    ngOnInit(): void {
      this.accountForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        job : ['', Validators.required],
        birthday: ['', Validators.required],
        gender : [''],
        civiledo: ['']
    });

      this.usersService.getUser(this.currentUserId).subscribe((data: any) => {
        this.collaborator = data;
        this.firstName = data['firstName'];
        this.lastName = data['lastName'];
        this.job = data['Job'];
        this.phoneNumber = data['phoneNumber'];
        this.email = data['email'];
        this.birthday = data['birthday'];
        this.gender = data['gender'];
        this.civiledo = data['civiledo'];
        this.civiledoSelected = this.civiledo;
        this.genderSelected = this.gender;
        this.accountForm.patchValue({
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          email: this.email,
          job: this.job,
          birthday: this.birthday,
          gender: this.gender,
        civiledo: this.civiledo
        });
      })
    }

    onSelectChangeGender(event: string) {
      this.genderSelected = event;
    }

    onSelectChangeCiviledo(event: string) {
      this.civiledoSelected = event;
    }

    public onSubmit(): void {
      if (this.accountForm.invalid) {
         return;
     } 
     const enterprise = {
         firstName: this.accountForm.controls['firstName'].value || this.firstName,
         lastName: this.accountForm.controls['lastName'].value || this.lastName,
         email: this.accountForm.controls['email'].value  || this.email,
         civiledo: this.civiledoSelected || this.civiledo,
         birthday: this.accountForm.controls['birthday'].value  || this.birthday,
         gender: this.genderSelected  || this.gender,
         Job: this.accountForm.controls['job'].value  || this.job,
         phoneNumber: this.accountForm.controls['phoneNumber'].value  || this.phoneNumber,
         displayName: this.accountForm.controls['firstName'].value + ' ' + this.accountForm.controls['lastName'].value || this.firstName + " " +  this.lastName,
         roles: 'user'
     }
     this.isLoading = true;
         this.usersService.updateUser(this.currentUserId, enterprise).subscribe(data => {
          this.submittedSuccess = true
          this.messageSuccess = 'Colaborador actualizado correctamente'
             })
              
        setTimeout(() => {
                 this.onClickRedirectLogin()
             }, 2500) 
 }

 public onClickRedirectLogin(): void {
  this.router.navigate([`empresa/detalle/${this.enterprisesId}`])
}

  ngOnDestroy(): void {}
}
