<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row mb-15">
        <div class="col-xs-12 col-sm-6"></div>
        <div class="col-xs-12 col-sm-6 text-right">
            <div class="action" *ngIf="!disableButton">
                <a class="btn btn-red btn-sm"
                  [routerLink]="['program']">
                  <i class="ti-plus"></i>
                  <span>Agregar Módulo</span>
                </a>
            </div>
            <div class="action" *ngIf="disableButton">
                <button class="btn btn-red btn-sm" disabled
                  [routerLink]="['program']">
                  <i class="ti-plus"></i>
                  <span>Agregar Módulo</span>
                </button>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <h4>{{programs?.Title}} - {{programs?.SKU}}</h4>
            <br>
            <p class="lineh">{{programs?.Description}}</p>
        </div>
    </div>
    <div *ngIf="percentage?.remaining <= 0">
        <div class="card border-card">
            <div class="card-body">
                <div>
                    <p class="center"><strong>Ya no se pueden agregar más módulos, el porcentaje está completo</strong> </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="programs?.SP.length == 0">
        <div class="card">
            <div class="card-body">
                <div>
                    <p class="center">Aún no se han agregado módulos al curso <strong>{{programs?.Title}}</strong></p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="programs?.SP.length > 0" class="row products-admin ratio_asos">
        <div class="col-xl-4 col-sm-6" *ngFor="let program of programs?.SP">
            <br>
            <div class="card">
                <div class="card-body product-box">
                    <div class="product-detail">
                        <h4 class="title">{{program.Name}}</h4>
                        <br>
                        <p>Duración: {{program.courseDuration}} horas</p>
                        <p>Total de temas: {{program.numberTopics}}</p>
                        <hr>
                        <div class="col-xs-12 fright flex">
                            <p>
                                <button type="button" [routerLink]="['edit', program.id]" class="btn btn-primary mr-1 mat-btn"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                            </p>
                            <p>
                                <button type="button" [routerLink]="['content', program.id]" class="btn btn-primary mr-1 mat-btn"><i class="fa fa-eye" aria-hidden="true"></i></button>
                            </p>
                            <p>
                                <button (click)="setModalContent(content, program.id)" type="button" class="btn btn-secondary mr-1 mat-btn"><i class="fa fa-trash"></i></button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Eliminar Módulo</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del módulo.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Módulo eliminado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteProgram()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>
