<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="row product-adding">
                <div class="col-xl-12">
                    <div class="card bordertop">
                        <div class="add-product-form">
                            <div class="row">
                                <div class="col-xs-12 col-sm-4">
                                    <div class="form-group">
                                        <label class="label">Curso del módulo</label>
                                        <p><strong>{{program?.products.Title}}</strong></p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-4">
                                    <div class="form-group">
                                        <label class="label">Maestro del curso</label>
                                        <p><strong>{{program?.teacher.firstName}} {{program?.teacher.lastName}}</strong></p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-4">
                                    <div class="form-group">
                                        <label class="label">Porcentaje</label>
                                        <p><strong>{{program?.Percentage}} %</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-4">
                                    <div class="form-group">
                                        <label class="label">Nombre del módulo</label>
                                        <p><strong>{{program?.Name}}</strong></p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-4">
                                    <div class="form-group">
                                        <label class="label">Número de unidades</label>
                                        <p><strong>{{program?.numberTopics}}</strong></p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-4">
                                    <div class="form-group">
                                        <label class="label">Horas del módulo</label>
                                        <p><strong>{{program?.courseDuration}}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card bordertop-blue">
                        <div class="add-product-form">
                            <div class="row">
                                <div class="col-xl-6 col-sm-4 col-xs-12">
                                    <div *ngIf="previewVideo" class="box-input-file">
                                        <img class="imgUpload" alt="" [src]="previewVideo">
                                    </div>
                                    <img *ngIf="viewLoaderVideo" src="./assets/images/loading-9.gif" alt="" style="width: 30%;">
                                    <input type="file" class="file-input" [accept]="requiredFileType" (change)="onVideoSelected($event)" #fileUploadVideo>
                                    <div class="file-upload">
                                    {{fileNameVideo || "Seleccionar video...  "}}
                                        <button class="btn btn-primary mat-btn" (click)="fileUploadVideo.click()">
                                            <i class="fa fa-video-camera" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-sm-8 col-xs-12">
                                    <div *ngIf="program?.VideoUrl">
                                        <div class="file-upload">
                                            Ya cuentas con un video cargado <a [href]="program?.VideoUrl" target="_blank"><img class="resize" src="./assets/images/reproductor-de-video.png" alt=""></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div>
                        <div class="card borderleft" *ngFor="let topic of topics; let i = index">
                            <div class="col-xs-12">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <h4>Tema Nº {{i+1}}</h4>
                                            <p>Nombre del tema: <strong>{{topic.Title}}</strong></p>
                                            <!-- <p>Duración del tema: <strong>{{topic.moduleDuration}} horas</strong></p> -->
                                        </div>
                                       </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

