import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserModel, AuthModel, UserCreateModel } from '../auth.models';
import { NotificationType } from '../../interfaces/notification.enums';


@Component({
    selector: 'app-prospectus',
    templateUrl: './prospectus.component.html',
    styleUrls: ['./prospectus.component.scss']
})

export class ProspectusComponent implements OnInit, OnDestroy {

    public values: any;
    public form: FormGroup;
    public submitted: boolean = false;
    public isLoading = false;
    public items = [];
    public selectedOption: any;
    public showForm: boolean = true;
    public showRegisterThanks: boolean = false;

    personalDetails!: FormGroup;
    private subs: Array<Subscription> = [];

    constructor(
        private fb: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
    ) {
        this.items = [
            { name: 'Pertenece a', value: '' },
            { name: 'CRO', value: 'CRO' },
            { name: 'Farmacéutica', value: 'Farmacéutica' },
            { name: 'Centro de Investigación', value: 'Centro de Investigación' }
        ]
        this.selectedOption = this.items[0].name;
    }

    ngOnInit(): void {
        this.personalDetails = this.fb.group({
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            PhoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            BusinessName: ['', Validators.required],
            Colabs: ['', Validators.required],
            Password: ['', Validators.required],
            ConfirmedPassword: ['', [Validators.required]]
        });
    }

    ngOnDestroy(): void {
        let sub: Subscription;
        while (sub = this.subs.shift()) {
            sub.unsubscribe();
        }
    }

    public get formState() {
        return this.personalDetails.controls;
    }

    public onSubmit(): void {
        this.submitted = true;
        this.validatePasswords();

        if (this.personalDetails.invalid) {
            return;
        }

        this.createUser();
    }

    private createUser() {
        const user: UserCreateModel = this.buildUser();
        this.isLoading = true;
        this.subs.push(
            this.authService.createUserPerson(user)
                .pipe(
                    map(() => {
                        this.handleUserCreation();
                        this.showForm = false;
                        this.submitted = false;
                        this.showRegisterThanks = true;
                    }),
                    catchError(error => this.handleError(error))
                )
                .subscribe()
        );

    }

    public authLogin() {
        const user: UserModel = this.loginUser();
        this.isLoading = true;
        this.subs.push(
            this.authService.login(user)
                .pipe(
                    map((data: any) => {
                        if (data.Message) {
                            this.sendNotification('Error al acceder', data?.Message, NotificationType.error);
                            this.isLoading = false;
                            return;
                        }
                        if (data.roles) {
                            this.router.navigateByUrl("/rallys");
                        }
                    }),
                    catchError(err => {
                        this.isLoading = false;
                        this.sendNotification('Error al acceder', err.error?.message, NotificationType.error);
                        return throwError(err);
                    })
                ).subscribe()
        );
    }

    private loginUser(): UserModel {
        return {
            email: this.personalDetails.controls['Email'].value,
            password: this.personalDetails.controls['Password'].value,
        }
    }

    private validatePasswords(): void {
        const password: string = this.personalDetails.get('Password').value;
        const confirmPassword: string = this.personalDetails.get('ConfirmedPassword').value;

        if (password !== confirmPassword) {
            this.personalDetails.controls['ConfirmedPassword'].setErrors({ incorrect: true });
        };
    }

    private buildUser(): UserCreateModel {
        return {
            firstName: this.personalDetails.controls['FirstName'].value,
            lastName: this.personalDetails.controls['LastName'].value,
            email: this.personalDetails.controls['Email'].value,
            password: this.personalDetails.controls['Password'].value,
            phoneNumber: this.personalDetails.controls['PhoneNumber'].value,
            businessname: this.personalDetails.controls['BusinessName'].value,
            collaborators: this.personalDetails.controls['Colabs'].value,
            roles: 'business'
        }
    }

    public onClickRedirectLogin(): void {
        this.router.navigateByUrl('/auth/login');
    }

    private handleError(error: any) {
        /* this.isLoading = false;
        const toastOptions: ToastOptions = {
            title: 'Error',
            msg: error.error.message,
            showClose: true,
            timeout: 2000
        }; */
        /* this.toastyService.error(toastOptions); */
        return [];
    }

    private handleUserCreation() {
        this.isLoading = false;
        /* const toastOptions: ToastOptions = {
            title: 'Registro',
            msg: 'Usuario creado correctamente',
            showClose: true,
            timeout: 1700
        }; */
       /*   */
    }

    private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {
        /* const toastOptions: ToastOptions = {
            title,
            msg: message,
            showClose: true,
            timeout: 3500,
            theme: 'bootstrap'
        };

        this.toastyService[type](toastOptions); */
    }
}
