import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject, throwError } from 'rxjs';
import { UserModel } from '../../auth/auth.models';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UsersService } from '../../services/users/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  public accountForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public quizId: number;
  public quizResult: any;

  private subs: Array<Subscription> = [];
  public user: any;
  public quizs: any;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic',
    size: 'lg',
    centered: true,
    backdrop: 'static'
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private usersService: UsersService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order:[[1, 'desc']]
    };

    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.authService.getUser(id)),
        catchError(error => {
          this.router.navigate(['/users']);
          return throwError(error);
        })
      ).subscribe(user => {
        this.user = user;
        if (this.user.roles === 'user'){
          this.subs.push(
            this.activatedRoute.paramMap.pipe(
              map(params => params.get('id')),
              switchMap(id => this.usersService.getQuizsByUser(+id)),
              catchError(error => {
                this.router.navigate(['/users']);
                return throwError(error);
              })
            ).subscribe(quizs => {
              this.quizs = quizs;
              this.dtTrigger.next();
            })
          );
        }else{
          return;
        }
      })
    );
}

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
  }

  setModalContent(content: any, quizId: number): void {
    this.subs.push(
      this.usersService
          .getAnswersByQuiz(quizId)
          .pipe(
              catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
              this.quizResult = data;
          })
  );
    this.quizId = quizId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

}
