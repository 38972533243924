import { NgModule } from "@angular/core";
import { CollaboratorsRoutingModule } from "./collaborators-routing.module";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { DataTablesModule } from "angular-datatables";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { CollaboratorsComponent } from "./collaborators.component";
import { AddCollaboratorComponent } from "./addColaborador/addCollaborator.component";
import { EditCollaboratorComponent } from "./editColaborador/edit-collaborator.component";
import { DetailCollaboratorComponent } from "./detailColaborador/detail-colaborador.component";
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';


@NgModule({
    declarations: [
      CollaboratorsComponent,
      AddCollaboratorComponent,
      EditCollaboratorComponent,
      DetailCollaboratorComponent
    ],
    imports: [
      CollaboratorsRoutingModule,
      CommonModule,
      RouterModule,
      ReactiveFormsModule,
      FormsModule,
      SharedModule,
      DataTablesModule,
      NgbModule,
      Ng2SmartTableModule, 
      MatTableModule,
      MatSortModule 
      
    ],
  })
  export class CollaboratorsModule { }
  