import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/shared/utils/headers.functions';
import * as moment from 'moment-timezone';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  
  constructor(
    private http: HttpClient
  ) { }

  public getSales(limit:number, offset:number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/sales?status=&limit=${limit}&offset=${offset}`);
  }

  public getSale(thisSaleId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/sales/${thisSaleId}`);
  }

  public getSalesByCustomer(id: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/sales-customer/${id}`);
  }

  public getCoursesByOrder(id: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/admin-sales-items/${id}`);
  }

  public getSubscriptions(orderId: number, productId: number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/courses/places?SaleOrderId=${orderId}&ProductId=${productId}`);
    
  }

  public getPayments(offset:number, limit:number, paymentMethod:string): Observable<any> {
    const endDate = moment().tz('America/Mexico_City').format('YYYY-MM-DD');
    return this.http.get<any>(`${BASE_URL}/payments?offset=${offset}&limit=${limit}&paymentStatus=&paymentMethod=${paymentMethod}&StartDate=2021-01-01&EndDate=${endDate}`);
  }

  public getPayment(thisPaymentId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/payments/${thisPaymentId}`);
  }

  getPaymentsFiltered(offset: number, limit: number, initialDate: string, endDate: string) {
    return this.http.get<any>(`${BASE_URL}/payments?offset=${offset}&limit=${limit}&paymentStatus=&paymentMethod=&StartDate=${initialDate}&EndDate=${endDate}`);
  }

  getPaymentsFilteredAll(offset: number, limit: number, paymentMethod:string, initialDate: string, endDate: string) {
    return this.http.get<any>(`${BASE_URL}/payments?offset=${offset}&limit=${limit}&paymentStatus=&paymentMethod=${paymentMethod}&StartDate=${initialDate}&EndDate=${endDate}`);
  }

}
