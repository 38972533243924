<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
            <div class="col-xl-4">
                <section>
                    <img *ngIf="news?.ImageUrl" [src]="[news?.ImageUrl]" class="img-fluid blur-up lazyload bg-img" alt="">
                    <img *ngIf="!news?.ImageUrl" src="../assets/images/no-found.jpeg" class="img-fluid blur-up lazyload bg-img" alt="No image found">
                    <p *ngIf="!news?.ImageUrl" class="center">No se ha encontrado la imagen</p>
                </section>
            </div>
            <div class="col-xl-8">
                <div class="product-page-details product-right mb-0">
                    <h2>{{news?.Title}}</h2>
                    <hr>
                    <h6 class="product-title">Descripción corta</h6>
                    <p class="mb-0" [innerHTML]="news?.ShortDescription"></p>
                    <hr>
                    <h6 class="product-title">Cuerpo de la noticia</h6>
                    <p class="mb-0" [innerHTML]="news?.MainBody"></p>
                    <hr>
                    <table>
                        <tbody>
                            <tr>
                                <td><i class="fa fa-id-card-o"></i></td>
                                <td>{{news?.Type}}</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-user"></i></td>
                                <td>{{news?.Author}}</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-copyright"></i></td>
                                <td>{{news?.Source}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>