import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { CourseModel, FeatureModel, QuizModel } from '../../components/products/product.models';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../utils/headers.functions';

const BASE_URL: string = environment.API_URL;


@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  public uploadImg(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/uploadimg`, formData, { headers });
  }

  setCoursePreview(uuid: string, id: number) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/masteracc/${uuid}`, {productid: id}, { headers });
  }

  validateActivation(productId:any):Observable<any>{
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/validate-products/${productId}`, { headers });
  }

  public uploadFile(formData: any, productId: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/saveDocument/${productId}`, formData, { headers });
  }

  public uploadFileWithoutID(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/uploadarchive`, formData, { headers });
  }



  public createFeature(feature: FeatureModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/catalogs`, feature, { headers });
  }

  public updateFeature(id: number, feature: any): Observable<any> {
    const buildFeature = this.editFeature(feature);
    const updatedFeature = this.addBackendStructureFeature(buildFeature);
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/catalog/${id}`, updatedFeature, {headers});
  }

  public getFeatures(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Feature'}`, {headers});
  }

  public deleteFeature(id: number): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/catalog/${id}`);
  }

  

  public getCatalogs(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs`, {headers});
  }

  public getValuestoCatalog(Catalog: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${Catalog}`);
  }

  public getValue(id: string | number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/catalog/${id}`);
  }

  public getCatalogsAlias(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-alias`, {headers});
  }

  public getSpecifications(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Specification'}`, {headers});
  }

  public getFamilies(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Family'}`, {headers});
  }

  public getCategories(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Category'}`, {headers});
  }

  public getSubCategories(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Subcategory'}`, {headers});
  }

  public getCourses(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/products?Family=${'Course'}`);
  }

  public getCapacitations(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/products?Family=${'Capacitation'}`);
  }

  public getWebinars(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/products?Family=${'Webinar'}`);
  }
  public getEvents(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/products?Family=${'Event'}`);
  }

  public getProduct(productId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/products/${productId}`);
  }

  public getProductsFiltered(family: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/products-filter?family=${family}`);
  }

  public createCourse(product: CourseModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/products`, product, { headers });
  }

  public updateCourse(id: number, product: CourseModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/products/${id}`, product, {headers});
  }

  public deleteProduct(id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<Observable<any>>(`${BASE_URL}/products/${id}`, { headers });
  }

  public mapRequiredValues(product: any): any {
    //console.log(product);
    const productInfo: any = {};

    {
      productInfo.id = product.id; 
      productInfo.Category = product.Category;
      productInfo.SKU = product.SKU;
      productInfo.Title = product.Title;
      productInfo.Description = product.Description;
      productInfo.UnitPrice = product.UnitPrice;
      productInfo.CurrencyCode = product.CurrencyCode;
      productInfo.ImgFileUrl = product.ImgFileUrl;
      productInfo.CourseModel = product.CourseModel;
      productInfo.Objective = product.Objective;
      productInfo.Introduction = product.Introduction;
      productInfo.Level = product.Level;
      productInfo.TotalHours = product.TotalHours;
      productInfo.Language = product.Language;
      productInfo.LanguageSubtitles = product.LanguageSubtitles;
      productInfo.AccessType = product.AccessType;
      productInfo.TargetMarket = product.TargetMarket;
      productInfo.FileUrl = product.FileUrl;
      productInfo.DiscountPrice = product.DiscountPrice;
      productInfo.StartDate = product.StartDate;
      productInfo.Teacher = product.Teacher;
      productInfo.Percentage = product.Percentage;
      productInfo.Name = product.Name;
      productInfo.numberTopics = product.numberTopics;
      productInfo.courseDuration = product.courseDuration;
  }

    return productInfo;
  }

  private editProduct(product: any): any {
    // console.log(product);
    const editProduct = {
      Title: product.Title,
      Family: product.Family,
      Category: product.Category,
      SubCategory: product.SubCategory,
      Brand: product.Brand,
      SKU: product.SKU,
      Model: product.Model,
      Description: product.Description,
      UnitPrice: product.UnitPrice,
      DiscountPrice: product.DiscountPrice,
      CurrencyCode: product.CurrencyCode,
    };
    return editProduct;
  }

  private addBackendStructure(product: any) {
    return {
      Title: product.Title,
      Family: product.Family,
      Category: product.Category,
      SubCategory: product.SubCategory,
      Brand: product.Brand,
      SKU: product.SKU,
      Model: product.Model,
      Description: product.Description,
      UnitPrice: product.UnitPrice,
      DiscountPrice: product.DiscountPrice,
      CurrencyCode: product.CurrencyCode,
      ProductInfo: product.ProductInfo
    };
  }

  private editFeature(feature: any): any {
    const editFeature = {
      CatalogName: feature.CatalogName,
      ValueAlpha: feature.ValueAlpha,
    };
    return editFeature;
  }

  private addBackendStructureFeature(feature: any) {
    return {
      CatalogName: feature.CatalogName,
      ValueAlpha: feature.ValueAlpha
    };
  }
}
