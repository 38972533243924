<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
            <div class="col-xl-4">
                <section>
                    <img *ngIf="product?.ImgFileUrl" [src]="[product?.ImgFileUrl]" class="img-fluid blur-up lazyload bg-img" alt="">
                    <img *ngIf="!product?.ImgFileUrl" src="../assets/images/no-found.jpeg" class="img-fluid blur-up lazyload bg-img" alt="No image found">
                    <p *ngIf="!product?.ImgFileUrl" class="center">No se ha encontrado la imagen</p>
                </section>
            </div>
            <div class="col-xl-8">
                <div class="product-page-details product-right mb-0">
                    <h2>{{product?.Title}}</h2>
                    <h4>{{product?.SKU}}</h4>
               <div class="product-price digits mt-2">
                        <h3>${{product?.DiscountPrice == null ? product?.UnitPrice : product?.DiscountPrice}} {{product?.CurrencyCode}} <del *ngIf="product?.DiscountPrice !== null">${{product?.UnitPrice}} {{product?.CurrencyCode}}</del></h3>
                    </div>
                    <p>Fecha de inicio: {{product?.StartDate | date:'dd/MM/yyyy'}}</p>
                    <p>Fecha de finalización: {{product?.StartDate | date:'dd/MM/yyyy'}}</p>
                    <hr>
                    <h6 class="product-title">Descripción</h6>
                    <p class="mb-0">{{product?.Description}}</p>
                    <hr>
                    <h6 class="product-title">Introducción</h6>
                    <p class="mb-0">{{product?.Introduction}}</p>
                    <hr>
                    <h6 class="product-title">Objetivo</h6>
                    <p class="mb-0">{{product?.Objective}}</p>
                    <hr>
                    <h6 class="product-title">Dirigido a</h6>
                    <p class="mb-0">{{product?.TargetMarket}}</p>
                   <hr>
                    <table>
                        <tbody>
                            <tr>
                                <td><i class="fa fa-university"></i></td>
                                <td>{{product?.Level}}</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-globe"></i></td>
                                <td>{{product?.AccessType}}</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-language"></i></td>
                                <td>{{product?.Language}}</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-clock-o"></i></td>
                                <td>{{product?.TotalHours}} horas</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
            </div>
        </div>
    </div>
</div>
