import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UsersService } from '../../services/users/users.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-list-admin',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.scss']
})
export class ListAdminComponent implements OnInit, OnDestroy {
  public user_list = []
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public userId: string;
  public count: number = 0;
  public page = 1;
  public limit = 10;
  public offset = 0;
  public deleteFilters: boolean = false;
  public adminFilter: string;
  dataSource: any;
  orderColumn = 'firstName';
  orderType = "asc";
  pageIndex = 0;
  orderText: string = 'firstName-asc';
  displayedColumns: string[] = [];
  constructor(
    private usersService: UsersService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {  
    this.loadData();
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthChange: false,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
    };

    this.displayedColumns = [
      'firstName',
      'email',
      'phoneNumber',
      'active',
      'actions',
      ]; 
  }

  @ViewChild(MatSort) sort!: MatSort;

  announceSortChange(sortState: Sort) {
    console.log(sortState)
    this.orderText = `${sortState.active}-${sortState.direction}`;
    this.pageIndex = this.page;
    this.orderColumn = sortState.active;
    this.orderType = sortState.direction;
    this.loadData();
  }


  loadData(){
    this.subs.push(
      this.usersService
        .getAdmins(this.limit, this.offset, this.orderText)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.user_list = data.List;
          console.log(data);
          this.dataSource = new MatTableDataSource(data.List)
          this.count = data.Count;
          this.dtTrigger.next();
        }) 
    );
  }

  getUsers(){
    this.subs.push(
      this.usersService
        .getAdmins(this.limit, this.offset, this.orderText)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.user_list = data.List;
          this.dataSource = new MatTableDataSource(data.List)
        })
    );
    this.deleteFilters = false;
    this.adminFilter = '';
  }

  filter(){
    let filter: HTMLInputElement = document.getElementById("filterText") as HTMLInputElement;
    if( filter.value != '' ){
      this.subs.push(
        this.usersService
          .getAdminsFiltered(filter.value)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.user_list = data.List;
            this.dataSource = new MatTableDataSource(data.List)
          })
      );
      this.deleteFilters = true;
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
   

  }

  public activeUser() {
    this.subs.push(
      this.usersService.updateUser(parseInt(this.userId), { active: true })
        .subscribe(resolve => {
         this.deleteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              this.deleteIsSuccess = false;
              this.loadData();
            }, 1800);
        })
    );
  }
  
  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteUser() {
    this.subs.push(
      this.usersService.deleteUser(this.userId)
        .subscribe(resolve => {
          if (resolve.message == 'Usuario ha sido dado de baja') {
            this.deleteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              this.deleteIsSuccess = false;
              this.loadData();
            }, 1800);
          }
        })
    );
  }
 
public loadPage(page: number) {
    this.page = page;
    this.subs.push(
      this.usersService
        .getAdmins(this.limit, page - 1, this.orderText)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.user_list = data.List;
          this.dataSource = new MatTableDataSource(data.List)
        })
    );
  } 

  
 

}

