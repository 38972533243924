<div class="page-wrapper">
    <div class="authentication-box paddd">
        <div class="container">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6" *ngIf="showForm">
                    <div class="card tab2-card background-login mb-5 ">
                        <div class="card-body">
                            <div class="text-center mt-1 mb-2">
                                <img src="../../../../assets/images/logo.png" style="width: 90%;" alt="">
                            </div>
                            <div class="text-center mb-4">
                                <p class="text-secondary">Bienvenido a Metramex<br>
                                    Ingresa tus datos para crear tu cuenta
                                </p>
                            </div>
                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                aria-labelledby="account-tab">
                                <form [formGroup]="personalDetails" class="form-horizontal auth-form">
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="col-xl-8 col-md-8">
                                                    <label class="">Nombre(s)</label>
                                                </div>
                                                <input formControlName="FirstName" type="text" class="form-control"
                                                    placeholder="Nombre" [ngClass]="{'error': personalDetails.controls['FirstName'].touched && personalDetails.controls['FirstName'].errors?.required
                                                    }" />
                                                <div *ngIf="personalDetails.controls['FirstName'].touched && personalDetails.controls['FirstName'].errors?.required"
                                                    class="text-danger">
                                                    El nombre es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="col-xl-8 col-md-8">
                                                    <label class="">Apellido(s)</label>
                                                </div>
                                                <input required formControlName="LastName" type="text"
                                                    class="form-control" placeholder="Apellido" [ngClass]="{'error': personalDetails.controls['LastName'].touched && personalDetails.controls['LastName'].errors?.required
                                                    }">
                                                <div *ngIf="personalDetails.controls['LastName'].touched && personalDetails.controls['LastName'].errors?.required"
                                                    class="text-danger">
                                                    El Apellido es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="col-xl-8 col-md-8">
                                                    <label class="">Empresa</label>
                                                </div>
                                                <input required formControlName="BusinessName" type="text"
                                                    class="form-control" placeholder="Nombre de la empresa" [ngClass]="{'error': personalDetails.controls['BusinessName'].touched && personalDetails.controls['BusinessName'].errors?.required
                                                    }">
                                                <div *ngIf="personalDetails.controls['BusinessName'].touched && personalDetails.controls['BusinessName'].errors?.required"
                                                    class="text-danger">
                                                    El nombre de la empresa es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="col-xl-8 col-md-8">
                                                    <label class="">Colaboradores</label>
                                                </div>
                                                <input required formControlName="Colabs" type="number"
                                                    class="form-control" placeholder="Número de colaboradores"
                                                    [ngClass]="{'error': personalDetails.controls['Colabs'].touched && personalDetails.controls['Colabs'].errors?.required
                                                    }">
                                                <div *ngIf="personalDetails.controls['Colabs'].touched && personalDetails.controls['Colabs'].errors?.required"
                                                    class="text-danger">
                                                    El numero de colaboradores es obligatorio.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="col-xl-8 col-md-8">
                                                    <label class="">Correo electrónico</label>
                                                </div>
                                                <input required formControlName="Email" type="text" class="form-control"
                                                    placeholder="Correo electrónico corporativo" [ngClass]="{'error': personalDetails.controls['Email'].touched && personalDetails.controls['Email'].errors?.required || personalDetails.controls['Email'].errors?.pattern || emailMatch
                                                    }">
                                                <div *ngIf="personalDetails.controls['Email'].touched && personalDetails.controls['Email'].errors?.required"
                                                    class="text-danger">
                                                    El email es obligatorio.
                                                </div>
                                                <div *ngIf="personalDetails.controls['Email'].touched && personalDetails.controls['Email'].errors?.pattern"
                                                    class="text-danger">
                                                    Por favor, introduce un correo electrónico válido.
                                                </div>
                                                <span *ngIf="personalDetails?.controls['Email'].touched && emailMatch"
                                                    class="text-danger">
                                                    Ya existe un email registrado.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="col-xl-8 col-md-8">
                                                    <label class="">Numero telefónico</label>
                                                </div>
                                                <input required formControlName="PhoneNumber" type="tel"
                                                    class="form-control" placeholder="Numero telefónico" maxlength="10"
                                                    [ngClass]="{'error': personalDetails.controls['PhoneNumber'].touched && personalDetails.controls['PhoneNumber'].errors?.required
                                                    }" appOnlyNumbers>
                                                <div *ngIf="personalDetails.controls['PhoneNumber'].touched && personalDetails.controls['PhoneNumber'].errors?.required"
                                                    class="text-danger">
                                                    El numero telefónico es obligatorio.
                                                </div>
                                                <div *ngIf="personalDetails.controls['PhoneNumber'].errors?.minlength && personalDetails.controls['PhoneNumber'].touched"
                                                    class="text-danger">
                                                    El número de teléfono debe tener al menos 10 caracteres.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="col-xl-8 col-md-8">
                                                    <label class="">Contraseña</label>
                                                </div>
                                                <input required formControlName="Password" type="password"
                                                    class="form-control" placeholder="Contraseña" [ngClass]="{'error': personalDetails.controls['Password'].touched && personalDetails.controls['Password'].errors?.required
                                                    }">
                                                <div *ngIf="personalDetails.controls['Password'].touched && personalDetails.controls['Password'].errors?.required"
                                                    class="text-danger">
                                                    La contraseña es obligatoria.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="col-xl-8 col-md-8">
                                                    <label class="">Confirmar contraseña</label>
                                                </div>
                                                <input required formControlName="ConfirmedPassword" type="password"
                                                    class="form-control" placeholder="Confirmar contraseña"
                                                    [ngClass]="{'error': personalDetails.controls['ConfirmedPassword'].touched && personalDetails.controls['ConfirmedPassword'].errors?.required || passwordMatch}">

                                                <div *ngIf="personalDetails.controls['ConfirmedPassword'].touched && passwordMatch"
                                                    class="text-danger">
                                                    Las contraseñas no coinciden.
                                                </div>
                                                <div *ngIf="personalDetails.controls['ConfirmedPassword'].touched && personalDetails.controls['ConfirmedPassword'].errors?.required"
                                                    class="text-danger">
                                                    Debes confirmar la contraseña.
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                            <ngb-toast *ngIf="submittedError" [class]="'ngb-toast bg-error'">
                                {{messageError}}
                            </ngb-toast>
                            <div class="row my-2" style="margin-left: 8px;">
                                <button type="button" class="checkbox-button" (click)="toggleCheck()"
                                    [class.checked]="isChecked">
                                    <span class="checkmark"></span>
                                </button>
                                <span class="pl-3">
                                    Al registrarte estás aceptando nuestros
                                    <br />
                                    <a class="terminoPrivacidad" (click)="toggleCheck();setModalContent(content)">
                                        Términos y política de privacidad
                                    </a></span>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-sm-8">
                                    <button type="submit" (click)="onSubmit()" class="button-font-size btn btn-primary"
                                        [disabled]="!isChecked1 && personalDetails.invalid">
                                        {{ isLoading ? 'Guardando' : 'Guardar'}}
                                        <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div class="row">
                        <span class="pl-3">¿Ya tienes una cuenta? <a [routerLink]="['/auth/login']">Inicia
                                sesión</a></span>
                    </div>
                    <!--  <br/>
                    <small>Al registrarte estás aceptando nuestros
                        <a href="">Términos y Política de Privacidad</a>
                    </small> -->
                </div>

                <div class="col-sm-6 col-xs-12 animate__animated animate__fadeIn" *ngIf="showRegisterThanks">
                    <!-- <img src="../../../../assets/images/finish-register.png" alt="" class="mb-3 border-radius-cards"> -->
                    <img src="../../../../assets/images/logo.png" style="width: 90%;" alt="">
                    <h4>
                        <strong>Gracias por registrarse</strong>
                        <br />
                        <br />
                        Hemos enviado un mensaje. Por favor
                        revise su bandeja de entrada y confirme
                        su cuenta de correo electrónico.
                    </h4>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content class="card">
    <div class="card-header">
        <h4 class="modal-title text-center w-100 text-uppercase" id="modal-title">Aviso de privacidad</h4>
    </div>
    <div class="modal-body card-body w-100 p-4">
        METRAMEX, Servicios Integrales en Salud, By FAICIC, conforme a la Ley Federal de Protección de Datos
        Personales en posesión de los particulares, pone a su disposición el siguiente aviso de privacidad. <br><br>
        <b>Responsable del Tratamiento de Datos Personales: </b>METRAMEX, con domicilio en: Av. 16 de septiembre
        #1165 Interior 15-A, Col. Ricardo Flores Magón, C.P. 91900, Veracruz, Ver.<br><br>
        <b>Correo electrónico:</b> contacto@metramex.com <br><br>
        <b>Finalidades del Tratamiento de Datos Personales:</b> Los datos recabados de cada paciente se utilizarán
        para:<br><br>
        <ul>
            <li>Certificados médicos laborales.</li>
            <li>Seguimiento de exámenes médicos.</li>
            <li>Capacitación en Seguridad Laboral.</li>
            <li>Campañas y promociones de salud.</li>
            <li>Realización de encuestas de calidad.</li>
            <li>Asistencia en salud y otras actividades relacionadas.</li>
        </ul>
        <br><br>
        <b>Datos Personales Recabados:</b> Nombre completo, RFC, dirección particular, número de celular, correo
        electrónico, estado civil, empresa donde labora, puesto que desempeña y antigüedad en el puesto.<br><br>
        <b>Datos Personales Sensibles: </b> Se consideran datos sensibles aquellos relacionados con resultados de
        estudios
        médicos, religión, enfermedades infectocontagiosas y toda información vinculada a la atención médica laboral.
        <br><br>
        <b>Derechos ARCO: </b>Usted tiene derecho a acceder, rectificar, cancelar u oponerse al uso de sus datos
        personales
        (derechos ARCO). Puede revocar su consentimiento en cualquier momento. Para ejercer estos derechos o revocar su
        consentimiento, comuníquese con el Departamento de Datos Personales a través de contacto@metramex.com. <br><br>
        <b>Responsable de Datos Personales</b><br><br>
        <ul>
            <li>Nombre: Gerencia Administrativa.
            </li>
            <li>
                Domicilio: Av. 16 de septiembre No.1165 Interior 15-A, Col. Ricardo Flores Magón, C.P. 91900 Veracruz,
                Ver.
            </li>
            <li>
                Correo electrónico: direcciongeneral@metramex.com coordinación@metramex.com
            </li>
        </ul><br>
        <b>Plazo de Respuesta:</b> Se atenderán las solicitudes de derechos ARCO en un plazo máximo de 20 días según el
        artículo 32 de la LFPPPP. <br><br>
        <b>Cambios en el Aviso de Privacidad: </b> En caso de cambios en nuestro modelo de negocio u otras razones, se
        informará sobre modificaciones al aviso de privacidad. <br><br>
        <b>CONSENTIMIENTO PARA EL TRATAMIENTO DE SUS DATOS PERSONALES</b><br><br>
        Consiento que mis datos personales sean tratados de conformidad con los términos y condiciones informados en el
        presente aviso de privacidad. <br><br>
        De conformidad con el artículo 8 de la Ley Federal de Protección de Datos Personales en Posesión de
        Particulares, se entenderá que el titular consiente tácitamente el tratamiento de sus datos, cuando habiéndose
        puesto a su
        disposición el aviso de privacidad, no manifieste oposición.

    </div>
    <div class="card-footer d-flex justify-content-end">
        <a type="button" class="btn btn-info" (click)="modalService.dismissAll();toggleButton()">Cerrar</a>
    </div>
</ng-template>