import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { ProspectusComponent } from './components/auth/prospectus/prospectus.component';
import { PolicityPrivacityComponent } from './components/auth/policityPrivacity/policityPrivacity.component';
import { ConfirmAccountComponent } from './components/auth/confirm-account/confirm-account.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/register',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: 'auth/register',
    component: SignUpComponent,
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'auth/reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth/prospecto',
    component: ProspectusComponent,
  },
  {
    path: 'auth/policity-privacity',
    component: PolicityPrivacityComponent,
  },
  {
    path: 'auth/confirm-account/:token',
    component: ConfirmAccountComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
