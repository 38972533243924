<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div>
                    <table id="table" *ngIf="collaboratorList"
                    class=" table table-responsive table-lg table-hover row-border hover w-100" style="display: '' !important;">
                    <thead>
                        <tr>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Puesto</th>
                        <th>Teléfono</th>
                        <th>Email</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let collaborator of collaboratorList">
                        <tr>
                        <td>{{collaborator}}</td>
                        <td>{{collaborator}}</td>
                        <td>{{collaborator}}</td>
                        <td>{{collaborator}}</td>
                        <td>{{collaborator}}</td>
                        <td class="actions-cell">
                            <span>
                              
                            <a 
                            [routerLink]="['/empresa/detalle', collaborator.id]" 
                                title="Ver colaborador">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span>
                            <span>
                              <a [routerLink]="['/empresa/editar', collaborator.id]"
                                  title="Editar colaborador">
                                  <i class="fa fa-edit"></i>
                              </a>
                            </span>
                            <span
                            title="Eliminar colaborador" (click)="setModalContent(content, '')">
                            <i class="fa fa-trash"></i>
                            </span>
                            <span
                            title="Activar colaborador">
                            <i class="fa fa-check"></i>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <table id="table" *ngIf="collaboratorList"
                    class=" table table-responsive table-lg table-hover row-border hover w-100">
                    <h2>La empresa aún no cuenta con colaboradores registrados
                    </h2>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div> 

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Eliminar Colaborador</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del colaborador.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Colaborador borrado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteUser()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>
