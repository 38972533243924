import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { UsersService } from '../services/users/users.service';
import { AuthenticationService } from '../services/auth/authentication.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-collaborators',
  templateUrl: './collaborators.component.html',
  styleUrls: ['./collaborators.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class CollaboratorsComponent implements OnInit, OnDestroy, AfterViewInit {

  displayedColumns: string[] = [];
  collaboratorsList: any[] = [];
  deleteFilters: boolean = false;
  colaboratorsFilter: string = '';
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  public count: number;
  public page = 1;
  public limit = 10;
  public offset = 0;
  public userId: string;
  private subs: Array<Subscription> = [];
  deleteCollaborators: any;
  submittedError: boolean = false;
  submittedSuccess: boolean = false;
  messageError: string = '';
  messageSuccess: string = '';
  collaboratorTotal: number;
  public deleteIsSuccess = false;
  user: string;
  userRoles: string;
  currentUserId: string;
  dataSource: any;
  orderColumn: string = '';
  orderType = "asc";
  pageIndex = 0;
  orderText: string = 'firstName-asc';
  businessname: string;
  filterData = '';
  constructor(
    private usersService: UsersService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
  ) {
    this.currentUserId = this.authService.currentUserValue.id;
    this.userRoles = this.authService.currentUserValue.roles;
    if (this.userRoles == 'admin') {
      this.orderColumn = 'businessname';
      this.displayedColumns = [
        'businessname',
        'firstName',
        'lastName',
        'birthday',
        'Job',
        'active',
        'actions',
      ];
    } else {
      this.orderColumn = 'firstName';
      this.displayedColumns = [
        'firstName',
        'lastName',
        'birthday',
        'Job',
        'email',
        'active',
        'actions',
      ];
    }
  }
  @ViewChild(MatSort) sort!: MatSort;

  obtenerId(id) {
    this.usersService.setidUser(id);
  }

  sendPhone(phone: number) {
    this.usersService.setPhone(phone);
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    if (this.dataSource && this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  ngOnDestroy(): void {
  }

  loadData() {
    if (this.userRoles == 'admin') {
      this.usersService.getCollaboratorsList2(this.limit, this.offset, this.orderText, this.filterData).subscribe((data: any) => {
        this.collaboratorsList = data.List;
        this.collaboratorTotal = data.Total;
        this.dataSource = new MatTableDataSource(data.List);
      })
    } else if (this.userRoles == 'business') {
      this.usersService.getListCollaborator(this.currentUserId, this.limit, this.offset, this.orderText).subscribe((data: any) => {
        this.collaboratorTotal = data.Total;
        this.collaboratorsList = data.List;
        this.dataSource = new MatTableDataSource(this.collaboratorsList);
      });
    }
  }

  announceSortChange(sortState: Sort) {
    this.orderText = `${sortState.active}-${sortState.direction}`;
    this.pageIndex = this.page;
    this.orderColumn = sortState.active;
    this.orderType = sortState.direction;
    this.loadData();
  }

  filterBusiness($event: any) {
    let filteredData = [];
    this.usersService.getCollaboratorsList(this.limit, this.offset, this.orderText).subscribe((data: any) => {
      for (let i = 0; i < data.List.length; i++) {
        if (data.List[i].person_id === this.currentUserId) {
          filteredData.push(data.List[i]);
        }
      }
      this.deleteFilters = true;
      this.collaboratorsList = filteredData.filter(item =>
        item.firstName.toLowerCase().includes($event.toLowerCase()) ||
        item.lastName.toLowerCase().includes($event.toLowerCase()) ||
        item.Job.toLowerCase().includes($event.toLowerCase()) ||
        item.Business.businessname.toLowerCase().includes($event.toLowerCase()) ||
        item.phoneNumber.includes($event) ||
        item.email.toLowerCase().includes($event.toLowerCase())
      );
    });
  }

  filter(event: any) {
    this.filterData = event;
    this.deleteFilters = true;
    this.loadData()
    /* this.usersService.getCollaboratorsList2(99999, this.offset, this.orderText, event).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(this.collaboratorsList);
    }); */
  }

  getCollaborators() {
    this.deleteFilters = false;
    this.filterData = '';
    this.loadData();
    this.colaboratorsFilter = ''
  }

  public loadPage(page: number) {
    this.page = page;
    this.subs.push(
      this.usersService
        .getCollaboratorsList(this.limit, page - 1, this.orderText)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.collaboratorsList = data.List;
          this.dataSource = new MatTableDataSource(data.List);
        })
    );
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this.usersService.getEnterpriseId(this.userId).subscribe((data: any) => {
      this.deleteCollaborators = [data.displayName];
    })
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public activeUser() {
    const user: any = this.collaboratorsList.filter((item) => item.id === this.userId);
    this.subs.push(
      this.usersService.putEnterpriseId(user[0].id, { active: true })
        .subscribe(resolve => {
          this.deleteIsSuccess = true;
          this.modalService.dismissAll();
          this.submittedSuccess = true;
          this.messageSuccess = 'Colaborador dado de alta correctamente'
          this.loadData();
          this.deleteIsSuccess = false;
          setTimeout(() => {
            this.submittedSuccess = false;
            this.messageSuccess = ''
          }, 2000);
        })
    );
  }

  public deleteUser() {
    const body = { active: false }
    this.usersService.editCollaborator(this.userId, body)
      .subscribe(resolve => {
        this.modalService.dismissAll();
        this.addToast('Colaborador eliminado correctamente', 'success-snackbar');
        this.loadData();
      });
  }

  addToast(message: any, className: string) {
    this.zone.run(() => {
      this._snackBar.open(message, null, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [className],
      });
    });
  }

}

