import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UsersService } from '../../services/users/users.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-enterprise',
  templateUrl: './list-enterprise.component.html',
  styleUrls: ['./list-enterprise.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class ListEnterpriseComponent implements OnInit, OnDestroy, AfterViewInit {
  public user_list = []
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  displayedColumns: string[] = [];
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public userId: string;
  public count: number;
  public page = 1;
  public limit = 5;
  public offset = 0;
  public deleteFilters: boolean = false;
  companyFilter = '';
  currentUser: any;
  enterprises: any[];
  enterprisesList: any[];
  filteredenterprises = [];
  deleteEnterprise: any;
  enterpriseId: any;
  public user: any;
  submittedError: boolean = false;
  submittedSuccess: boolean = false;
  messageError: string = '';
  messageSuccess: string = '';
  token: string = '';
  dataSource: any;
  orderColumn = 'businessname';
  orderType = "asc";
  pageIndex = 0;
  orderText: string = 'businessname-asc';
  searchText = '';

  constructor(
    private usersService: UsersService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private zone: NgZone,
    private _snackBar: MatSnackBar
  ) {

    this.enterpriseId = this.authService.currentUserValue.id;
    this.token = this.authService.currentUserValue.token;

    this.loadData();

    this.displayedColumns = [
      'businessname',
      'displayName',
      'email',
      'phoneNumber',
      'collaborators',
      'active',
      'actions',
    ];
  }

  @ViewChild(MatSort) sort!: MatSort;

  announceSortChange(sortState: Sort) {
    console.log(sortState)
    this.orderText = `${sortState.active}-${sortState.direction}`;
    this.pageIndex = this.page;
    this.orderColumn = sortState.active;
    this.orderType = sortState.direction;
    this.loadData();
  }

  loadData() {
    this.usersService.getEnterprise2(this.limit, this.offset, 'business', this.orderText, this.companyFilter).subscribe((data: any) => {
      this.enterprisesList = data.List;
      this.count = data.Count;
      this.dataSource = new MatTableDataSource(data.List)
    })
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      lengthChange: false,
      pageLength: 1000,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      retrieve: true
    };
  }

  ngAfterViewInit() {
    if (this.dataSource && this.sort) {
      this.dataSource.sort = this.sort;
    }
  }


  filter(event: any) {
    this.usersService.getEnterprise2(this.limit, 0, 'business', this.orderText, event).subscribe((data: any) => {
      this.deleteFilters = true;
      this.count = data.Count;
      this.dataSource = new MatTableDataSource(data.List);
    });
  }

  getEnterprise() {
    this.deleteFilters = false;
    this.companyFilter = ''
    this.usersService.getEnterprise(this.limit, this.offset, 'business', this.orderText).subscribe((data: any) => {
      this.enterprisesList = data.List;
      this.count = data.Count;
      this.dataSource = new MatTableDataSource(data.List)
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public selectQuizId(quizId): void {
    this.subs.push(
      this.usersService
        .getWinner(quizId)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.user_list = data;
        })
    );
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this.usersService.getEnterpriseId(this.userId).subscribe((data: any) => {
      this.deleteEnterprise = [data.businessname];
    });
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public loadPage(page: number) {
    console.log(page);
    this.offset = page - 1;
    this.loadData();
  }


  public deleteUser() {
    const user: any = this.enterprisesList.filter((item) => item.id === this.userId);
    this.subs.push(
      this.usersService.deleteUser(user[0].id)
        .subscribe(resolve => {
          if (resolve.message == 'Usuario ha sido dado de baja') {
            this.modalService.dismissAll();
            this.deleteIsSuccess = false;
            this.addToast('Empresa dada de baja correctamente', 'success-snackbar')
            this.loadData();
          }
        })
    );

  }

  public activeUser() {
    const user: any = this.enterprisesList.filter((item) => item.id === this.userId);
    this.subs.push(
      this.usersService.putEnterpriseId(user[0].id, { active: true })
        .subscribe(resolve => {
          this.deleteIsSuccess = true;
          this.modalService.dismissAll();
          this.addToast('Empresa dada de alta correctamente', 'success-snackbar')
          this.loadData();
          this.deleteIsSuccess = false;
        })
    );
  }

  addToast(message: any, className: string) {
    this.zone.run(() => {
      this._snackBar.open(message, null, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [className],
      });
    });
  }

}

