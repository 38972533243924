<div class="div-spinner" *ngIf="loading">
    <div class="container d-flex justify-content-center align-items-center h-100">
        <div class="spinner"></div>
    </div>
</div>
<div class="container-fluid">
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="mb-0">Generales</h4>
                </div>
                <div class="card-body">
                    <div>
                        <div class="row d-flex justify-content-center mb-2 position-relative">
                            <i class="fa fa-pencil text-info" id="editPencil" (click)="imgFile.click()"></i>
                            <input type="file" #imgFile style="display: none;" accept="image/jpg"
                                (change)="uploadImageFile($event)">
                            <img *ngIf="!collaborador?.imageFileUrl" width="100px"
                                src="../../../../../assets/images/user.png" alt="User Image">
                            <img *ngIf="collaborador?.imageFileUrl" width="100px" [src]="collaborador?.imageFileUrl"
                                alt="User Image">

                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Nombre(s)</span><br>
                                <b>{{ collaborador?.firstName }}</b>
                            </div>
                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Apellido(s)</span><br>
                                <b>{{ collaborador?.lastName }}</b>
                            </div>
                        </div>

                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Puesto</span><br>
                                <b>{{ collaborador?.position }}</b>
                            </div>
                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Género</span><br>
                                <b>{{ collaborador?.gender }}</b>
                            </div>
                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Fecha de nacimiento</span><br>
                                <b>{{ (collaborador?.dateOfBirth | date: 'dd/MM/YYYY') || '-' }}</b>
                            </div>
                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Número telefónico</span><br>
                                <b>{{ collaborador?.phone || '-' }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-9">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h4 *ngIf="!clickInputBoolean" class="mb-0">Detalle de historial clínico</h4>
                            <h4 *ngIf="clickInputBoolean" class="mb-0">Editar historial clínico</h4>
                        </div>
                        <div class="col-12 col-md-6 d-flex justify-content-end">
                            <a *ngIf="onSee" class="btn btn-red btn-sm mr-3" [disabled]="downloadFile"
                                (click)="generatePdf()">
                                Descargar
                                <i class="fa fa-spinner fa-spin lm-2" *ngIf="downloadFile"></i>
                            </a>
                            <a *ngIf=" onSee" class="btn btn-info btn-sm mr-3" (click)="generateExcel()">
                                Descargar respuestas
                            </a>
                            <a *ngIf="arrayDataUser?.length >= 17 && !onSee" class="btn btn-red btn-sm"
                                (click)="saveHistory()">
                                Guardar Historia
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="container-fluid">
                        <div class="row mb-3">
                            <div class="col-12 col-md-4 d-flex align-items-center">
                                <label for="historytype">Tipo de examen</label>
                            </div>
                            <div class="col-12 col-md-8">
                                <select name="historytype" [(ngModel)]="historyReason" [disabled]="onSee"
                                    [ngModelOptions]="{standalone: true}" class="form-control w-100" id="historytype">
                                    <option value="0" disabled selected>Especifica</option>
                                    <option value="Ingreso">Ingreso</option>
                                    <option value="Periódico">Periódico</option>
                                    <option value="Re-Valoración">Re-valoración post-incapacidad</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <ngb-tabset #tabset="ngbTabset" class="tab-coupon">
                        <ngb-tab id="tab{{q}}" *ngFor="let item of arrayData | slice: 0:3; let q = index;">
                            <ng-template ngbTabTitle>{{item?.Title}}</ng-template>
                            <ng-template ngbTabContent>
                                <div style="margin-top: 30px !important;">
                                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                                        aria-labelledby="account-tab"
                                        *ngFor="let question of item.questions; let i = index">
                                        <div class="form-group row mb-1">
                                            <div class="col-12 col-md-4 d-flex align-items-center">
                                                <b>{{question.Question}}</b>
                                            </div>
                                            <div class="col-12 col-md-8 d-flex align-items-center"
                                                *ngIf="question.Type === 'select'">
                                                <div class="col-12 mb-2">
                                                    <select name="" id="" id="type-select{{'-'+i+'-'+q}}"
                                                        class="form-control w-100" name="type-select{{ '-'+i+'-'+q}}"
                                                        (chage)="setResponse($event, q, i)">
                                                        <option *ngFor="let answer of question.answers; let a = index">
                                                            {{ answer.Answer }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-8 d-flex align-items-center"
                                                *ngIf="question.Type === 'other' || question.Type === 'radio'">
                                                <div class="row w-100 align-items-center">
                                                    <div class="col-12 mb-2"
                                                        [ngClass]="{ 'col-md-4': question?.Answer?.includes('Sí'), 'col-md-6': question?.Question.includes('Grupo') }">
                                                        <div class="float-left mr-2 d-flex align-items-center  input-container"
                                                            *ngFor="let answer of question.answers; let a = index">
                                                            <input [disabled]="onSee" type="radio"
                                                                id="type-{{ a+'-'+i+'-'+q}}"
                                                                name="type-{{ a+'-'+i+'-'+q}}"
                                                                [value]="answer.Answer+'-'+answer.id"
                                                                [checked]="question.Answer === answer.Answer || (answer.Answer === 'No' && !question.Answer)"
                                                                (click)="setResponse($event, q, i)" class="mr-2">
                                                            <label class="mb-0" for="type-{{ a+'-'+i+'-'+q}}">{{
                                                                answer.Answer
                                                                }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 px-0 my-2"
                                                        *ngIf="question?.Question?.includes('Grupo')">
                                                        <div class="float-left select-container my-0 px-0 w-100 mb-2">
                                                            <select [disabled]="onSee" class="form-control"
                                                                name="selectType" [(ngModel)]="question.Answerback"
                                                                (change)="toggleLabel('select-field-', q, i); setCorrect($event, q, i)">
                                                                <option value="" disabled selected>RH</option>
                                                                <option value="Positivo">Positivo</option>
                                                                <option value="Negativo">Negativo</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-8 px-0 my-2"
                                                        *ngIf="question?.Answer?.includes('Sí')">
                                                        <div *ngIf="item.Title.includes('Familiares')">
                                                            <div class="float-left mr-3 d-flex align-items-center"
                                                                *ngFor="let af of arrayFamiliares; let a = index">
                                                                <input [disabled]="onSee" type="checkbox"
                                                                    id="type-{{ i+'-'+j+'-'+q+'-'+a}}"
                                                                    name="type-{{ i+'-'+j+'-'+q+'-'+a}}" [value]="af"
                                                                    [checked]="question.Answerback.includes(af)"
                                                                    (click)="setResponseFamiliares($event, question)"
                                                                    class="mr-2">
                                                                <label class="mb-0"
                                                                    for="type-{{ i+'-'+j+'-'+q+'-'+a}}">{{
                                                                    af
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="float-left select-container my-0 px-0 w-100 mb-2"
                                                            *ngIf="question.Question === 'Alcoholismo' && question.Answer === 'Sí'">
                                                            <label for="label-{{q}}-{{i}}">Nivel de alcoholismo</label>
                                                            <select [disabled]="onSee" class="form-control"
                                                                (change)="setCorrect($event, q, i)" name="selectType"
                                                                [(ngModel)]="question.Answerback"
                                                                (change)="toggleLabel('select-field-', q, i)">
                                                                <option value="0" disabled selected>Especifica</option>
                                                                <option value="Social">Social</option>
                                                                <option value="Embriaguez">Embriaguez</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center input-container px-0"
                                                            *ngIf="question.Question === 'Tabaquismo' && question.Answer === 'Sí'">
                                                            <input [disabled]="onSee" [type]="question.Type"
                                                                id="label-{{q}}-{{i}}" placeholder="nnoon"
                                                                class="input-field" [(ngModel)]="question.Answerback">
                                                            <label class="input-label" style="left:15px;"
                                                                for="label-{{q}}-{{i}}">Número de
                                                                cigarros al
                                                                día</label>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center input-container px-0"
                                                            *ngIf="question.Question.includes('COVID') && question.Answer === 'Sí'">
                                                            <input [disabled]="onSee" type="text" id="label-{{q}}-{{i}}"
                                                                placeholder="nnoon" class="input-field"
                                                                [(ngModel)]="question.Answerback">
                                                            <label class="input-label" style="left:15px;"
                                                                for="label-{{q}}-{{i}}">Número de
                                                                dosis</label>
                                                        </div>
                                                        <div class="row"
                                                            *ngIf="question.Question.includes('Actividad') && question.Answer === 'Sí'">
                                                            <div
                                                                class="w-100 d-flex align-items-center input-container px-0">
                                                                <textarea [disabled]="onSee" [type]="question.Type"
                                                                    id="label-{{q}}-{{i}}" placeholder="nnoon"
                                                                    class="input-field"
                                                                    [(ngModel)]="question.Answerback"></textarea>
                                                                <label class="input-label" style="left:15px;"
                                                                    for="label-{{q}}-{{i}}">Actividad que
                                                                    realiza, frecuencia y duración</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center input-container px-0"
                                                            *ngIf="question.Question.includes('sueño')">
                                                            <input [disabled]="onSee" [type]="question.Type"
                                                                id="label-{{q}}-{{i}}" placeholder="nnoon"
                                                                class="input-field" [(ngModel)]="question.Answerback">
                                                            <label class="input-label" style="left:15px;"
                                                                for="label-{{q}}-{{i}}">Horas de
                                                                sueño</label>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center input-container px-0"
                                                            *ngIf="question.Question.includes('Hijos') && question.Answer === 'Sí'">
                                                            <input [disabled]="onSee" [type]="question.Type"
                                                                id="label-{{q}}-{{i}}" placeholder="nnoon"
                                                                class="input-field" [(ngModel)]="question.Answerback">
                                                            <label class="input-label" style="left:15px;"
                                                                for="label-{{q}}-{{i}}">¿Cuantos hijos?</label>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center input-container px-0"
                                                            *ngIf="item.id === 2">
                                                            <input [disabled]="onSee" [type]="question.Type"
                                                                id="label-{{q}}-{{i}}" placeholder="nnoon"
                                                                class="input-field" [(ngModel)]="question.Answerback">
                                                            <label class="input-label" style="left:15px;"
                                                                for="label-{{q}}-{{i}}">Descripción</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-8 d-flex align-items-center input-container mt-3"
                                                *ngIf="question.Type === 'text' ||  question.Type === 'date' || question.Type === 'number'">
                                                <input [disabled]="onSee" [type]="question.Type" placeholder="nnoon"
                                                    id="label-{{ q }}-{{i}}" class="input-field"
                                                    [(ngModel)]="question.Answer">
                                                <label class="input-label" for="label-{{ q }}-{{i}}">{{
                                                    question.Question.includes('sueño') ? 'Horas de sueño' :
                                                    'Especifica'}}</label>
                                            </div>
                                            <div class="col-12 col-md-8 d-flex align-items-center input-container mt-3"
                                                *ngIf="question.Type === 'textarea'">
                                                <textarea [disabled]="onSee" rows="5" class="input-field"
                                                    name="comments" [(ngModel)]="question.Answer" id="label-{{q}}-{{i}}"
                                                    [ngModelOptions]="{standalone: true}">Comentarios</textarea>
                                                <label class="input-label" for="label-{{q}}-{{i}}">Especifica</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row col-xl-12 pr-0 align-item-start" *ngIf="item.id !== 2">
                                        <div class="col-xl-4 col-md-4 pl-0">
                                            <b for="validationCustom1" class="">Comentarios</b>
                                        </div>
                                        <div class="col-xl-8 col-md-8 pr-0">
                                            <textarea [disabled]="onSee" rows="5"
                                                class="custom-rezise-form form-control" name="comments"
                                                [(ngModel)]="item.comments"
                                                [ngModelOptions]="{standalone: true}">Comentarios</textarea>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end row action pr-0 mx-0" *ngIf="!onSee">
                                        <button type="submit" (click)="submitFormFamilia(q)"
                                            class="btn btn-red btn-sm px-3">
                                            Siguiente
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab3">
                            <ng-template ngbTabTitle>Antecedentes Laborales</ng-template>
                            <ng-template ngbTabContent>
                                <div class="container">
                                    <div class="row py-3 d-flex justify-content-end" *ngIf="!onSee">
                                        <div class="col-4">
                                            <button class="btn btn-red w-100" (click)="openContentWork(contentWork)">
                                                Agregar antecedente
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row" [ngClass]="{'py-3': onSee}">
                                        <div class="col-12 col-md-3">
                                            <h5>Empresa</h5>
                                        </div>
                                        <div class="col-12 col-md-3">
                                            <h5>Antigüedad</h5>
                                        </div>
                                        <div class="col-12 col-md-3">
                                            <h5>Cargo/Puesto</h5>
                                        </div>
                                        <div class="col-12 col-md-3">
                                            <h5>EPP que utilizaba</h5>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="works.length === 0">
                                        <div class="col-12">

                                            <h5>No se han registrado antecedentes laborales</h5>
                                        </div>
                                    </div>
                                    <div class="row border-bottom py-2" *ngFor="let work of works; let i = index;">
                                        <div class="col-12 col-md-3 input-container">
                                            <span *ngIf="work.saved">{{ work.name }}</span>
                                        </div>
                                        <div class="col-12 col-md-3 input-container">
                                            <span *ngIf="work.saved">{{ work.position }}</span>
                                        </div>
                                        <div class="col-12 col-md-3 input-container">
                                            <span *ngIf="work.saved">{{ work.seniority }}</span>
                                        </div>
                                        <div class="col-12 col-md-3 input-container">
                                            <span *ngIf="work.saved">{{ work.epp }}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 col-12 mt-3 input-container">
                                        <input id="accidentes" class="input-field" type="text" placeholder="000"
                                            [(ngModel)]="collaborador.accidentes">
                                        <label class="input-label" for="accidentes">Accidentes de trabajos
                                            previos</label>
                                    </div>
                                    <div class="col-12 col-12 mt-3 input-container">
                                        <input id="enfermedades" class="input-field" type="text" placeholder="000"
                                            [(ngModel)]="collaborador.enfermedad">
                                        <label for="enfermedades" class="input-label">Enfermedad profesional</label>
                                    </div>
                                    <div class="col-12 col-12 mt-3 input-container">
                                        <input id="actividad" class="input-field" type="text" placeholder="000"
                                            [(ngModel)]="collaborador.actividad">
                                        <label for="actividad" class="input-label">Actividad actual</label>
                                    </div>
                                    <div class="col-12 col-12 mt-3 input-container">
                                        <input id="padecimiento" class="input-field" type="text" placeholder="000"
                                            [(ngModel)]="collaborador.padecimiento">
                                        <label for="padecimiento" class="input-label">Padecimiento actual</label>
                                    </div>

                                    <div class="d-flex justify-content-end row action pr-0 py-3 mx-0">
                                        <button type="submit" (click)="nextStep(4)" *ngIf="!onSee"
                                            class="btn btn-red btn-sm px-3">
                                            Siguiente
                                        </button>
                                    </div>

                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab4" *ngFor="let item of rallyData?.stages; let q = index">
                            <ng-template ngbTabTitle>{{item?.Description}}</ng-template>
                            <ng-template ngbTabContent>
                                <div class="container mt-3">
                                    <div class="row mb-2 border-bottom" *ngFor="let quiz of item.quizes; let j=index">
                                        <h5 class="container-fluid d-flex self-align-center">{{quiz?.Title}}</h5>
                                        <div *ngFor="let question of quiz.questions; let i = index"
                                            class="container-fluid">
                                            <div class="form-group row mb-2 mt-1">
                                                <div class="col-12 col-md-4 d-flex align-items-center">
                                                    <b class="">{{question.Question}}</b>
                                                </div>
                                                <div class="col-12 col-md-8 d-flex align-items-center input-container"
                                                    *ngIf="question.Type === 'other' || question.Type === 'radio'">
                                                    <div class="row w-100">
                                                        <div class="col-12 col-md-6 px-0 d-flex align-items-center">
                                                            <div class="float-left mr-3 d-flex align-items-center"
                                                                *ngFor="let answer of question.answers; let a = index">
                                                                <input [disabled]="onSee" type="radio"
                                                                    id="type-{{ i+'-'+j+'-'+q+'-'+a}}"
                                                                    name="type-{{ i+'-'+j+'-'+q+'-'+a}}"
                                                                    [value]="answer.Answer +' - '+answer.id"
                                                                    [checked]="question.Answer === answer.Answer || (answer.Answer === 'Normal' && !question.Answer) || (answer.Answer === 'No' && !question.Answer) || (answer.Answer === 'Completos' && !question.Answer) || (answer.Answer === 'Sin alteraciones' && !question.Answer)"
                                                                    (click)="setResponseRally($event, q, j, i)"
                                                                    class="mr-2">
                                                                <label class="mb-0"
                                                                    for="type-{{ i+'-'+j+'-'+q+'-'+a}}">{{
                                                                    answer.Answer
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 mb-1 col-md-6 px-0"
                                                            *ngIf="(question?.Answer?.includes('Sí') || question?.Answer?.includes('Anormal') || question?.Answer?.includes('Presentes') || question?.Answer?.includes('Incompletos') ) && !question?.Question.includes('anteojos')">
                                                            <div class="float-left w-100 input-container">
                                                                <input [disabled]="onSee" type="text"
                                                                    placeholder="nnoon" class="input-field"
                                                                    [(ngModel)]="question.Answerback">
                                                                <label class="input-label">{{
                                                                    question.Question.includes("Tatuajes") ||
                                                                    question.Question.includes("Piercing") ?
                                                                    '¿Cúantos y en que área del cuerpo?' :
                                                                    question.Question.includes("Sueño") ? 'Número de
                                                                    horas de sueño' :
                                                                    'Especifica'}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 d-flex align-items-center input-container mt-1"
                                                    *ngIf="question.Type === 'text' ||  question.Type === 'date' || question.Type === 'number'">
                                                    <input [disabled]="onSee" [type]="question.Type" placeholder="nnoon"
                                                        id="label-{{ q }}-{{i}}" class="input-field"
                                                        [(ngModel)]="question.Answer">
                                                    <label class="input-label"
                                                        for="label-{{ q }}-{{i}}">Especifica</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="float-left w-100 input-container mt-2">
                                            <textarea [disabled]="onSee" type="text" placeholder="nnoon"
                                                class="input-field" [(ngModel)]="quiz.comments"></textarea>
                                            <label class="input-label">Comentarios</label>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end row action pr-0 mx-0">
                                        <button type="submit" (click)="submitRally(q)" *ngIf="!onSee"
                                            class="btn btn-red btn-sm px-3">
                                            Siguiente
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab{{q+5}}" *ngFor="let item of arrayData | slice: 3:10; let q = index;">
                            <ng-template ngbTabTitle>{{item?.Title}}</ng-template>
                            <ng-template ngbTabContent>
                                <div style="margin-top: 30px !important;">
                                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                                        aria-labelledby="account-tab"
                                        *ngFor="let question of item.questions; let i = index">
                                        <div class="form-group row mb-1">
                                            <div class="col-12 col-md-4 d-flex align-items-center">
                                                <b>{{question.Question}}</b>
                                            </div>
                                            <div class="col-12 col-md-8 d-flex align-items-center"
                                                *ngIf="question.Type === 'other' || question.Type === 'radio'">
                                                <div class="row w-100 align-items-center">
                                                    <div class="col-12 my-2"
                                                        [ngClass]="{ 'col-md-5': question?.Answer?.includes('Sí') }">
                                                        <div class="float-left mr-2 d-flex align-items-center  input-container"
                                                            *ngFor="let answer of question.answers; let a = index">
                                                            <input [disabled]="onSee" type="radio"
                                                                id="type-{{ a+'-'+i+'-'+(q+5)}}"
                                                                name="type-{{ a+'-'+i+'-'+(q+5)}}"
                                                                [value]="answer.Answer+'-'+answer.id"
                                                                [checked]="question.Answer === answer.Answer || (answer.Answer === 'No' && !question.Answer)"
                                                                (click)="setResponse($event, (q+3), i)" class="mr-2">
                                                            <label class="mb-0" for="type-{{ a+'-'+i+'-'+(q+5)}}">{{
                                                                answer.Answer
                                                                }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex align-items-center input-container mt-3"
                                                        *ngIf="question.Type === 'other' && question.Question.includes('Antidoping')">
                                                        <input [disabled]="onSee" [type]="question.Type"
                                                            name="type-{{ i+'-'+j+'-'+(q+5)+'-'+a}}" placeholder="nnoon"
                                                            class="input-field" class="input-field"
                                                            [(ngModel)]="question.Answerback">
                                                        <label class="input-label"
                                                            for="type-{{ i+'-'+j+'-'+(q+5)+'-'+a}}">Especifica</label>
                                                    </div>
                                                    <div class="col-12 col-md-8 px-0 mb-2"
                                                        *ngIf="question?.Answer?.includes('Sí')">
                                                        <div *ngIf="item.Title.includes('Familiares')">
                                                            <div class="float-left mr-3 d-flex align-items-center"
                                                                *ngFor="let af of arrayFamiliares; let a = index">
                                                                <input [disabled]="onSee" type="checkbox"
                                                                    id="type-{{ i+'-'+j+'-'+(q+5)+'-'+a}}"
                                                                    name="type-{{ i+'-'+j+'-'+(q+5)+'-'+a}}"
                                                                    [value]="af"
                                                                    (click)="setResponseRally($event, q, j, i)"
                                                                    class="mr-2">
                                                                <label class="mb-0"
                                                                    for="type-{{ i+'-'+j+'-'+(q+5)+'-'+a}}">{{
                                                                    af
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="float-left select-container my-0 px-0 w-100 mb-2"
                                                            *ngIf="question.Question === 'Alcoholismo' && question.Answer === 'Sí'">
                                                            <select [disabled]="onSee" class="form-control"
                                                                (change)="setCorrect($event, (q+5), i)"
                                                                name="selectType" [(ngModel)]="question.AnswerSelected">
                                                                <option value="0" disabled selected>Especifica</option>
                                                                <option value="Social">Social</option>
                                                                <option value="Embriaguez">Embriaguez</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center input-container px-0"
                                                            *ngIf="question.Question === 'Tabaquismo' && question.Answer === 'Sí'">
                                                            <input [disabled]="onSee" [type]="question.Type"
                                                                placeholder="nnoon" class="input-field"
                                                                [(ngModel)]="question.Answerback">
                                                            <label class="input-label" style="left:15px;">Número de
                                                                cigarros al
                                                                día</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-8 d-flex align-items-center input-container mt-3"
                                                [ngClass]="{'col-md-8' : question.Type === 'text' || question.Type === 'number' || question.Type === 'date', 'col-md-2' : question.Type === 'more'}"
                                                *ngIf="question.Type !== 'other' && question.Type !== 'radio'">
                                                <input [disabled]="onSee || question.Question.includes('IMC')"
                                                    [type]="question.Type" placeholder="nnoon"
                                                    [value]="question.Question.includes('IMC') ? imc : ''"
                                                    name="type-{{ i+'-'+(q+5)}}" id="type-{{ i+'-'+(q+5)}}"
                                                    (keyup)="generateIMC(question)" class="input-field"
                                                    class="input-field" [(ngModel)]="question.Answer">
                                                <label class="input-label"
                                                    for="type-{{ i+'-'+(q+5)}}">Especifica</label>
                                            </div>

                                            <div class="col-12 col-md-6 px-0 mt-3" *ngIf="question.Type === 'more'">
                                                <div class="float-left w-100 input-container">
                                                    <input [disabled]="onSee" type="text" placeholder="nnoon"
                                                        class="input-field" [(ngModel)]="question.Answerback">
                                                    <label class="input-label">Interpretación</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row col-xl-12 pr-0 align-item-start">
                                        <div class="col-xl-4 col-md-4 pl-0">
                                            <b for="validationCustom1" class="">{{ item.Title.includes("gabinete") ?
                                                'Recomendaciones' : 'Comentarios' }}</b>
                                        </div>
                                        <div class="col-xl-8 col-md-8 pr-0">
                                            <textarea rows="5" class="custom-rezise-form form-control" name="comments"
                                                [(ngModel)]="item.comments" [disabled]="onSee"
                                                [ngModelOptions]="{standalone: true}">{{ item.Title.includes("gabinete") ? 'Recomendaciones' : 'Comentarios' }}</textarea>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row col-xl-12 pr-0 align-item-start"
                                        *ngIf="item.Title.includes('gabinete')">
                                        <div class="col-xl-4 col-md-4 pl-0">
                                            <b for="validationCustom1" class="">Opinión técnico médica</b>
                                        </div>
                                        <div class="col-xl-8 col-md-8 pr-0">
                                            <textarea rows="5" class="custom-rezise-form form-control" name="comments"
                                                [(ngModel)]="item.comments2" [disabled]="onSee"
                                                [ngModelOptions]="{standalone: true}">Opinión técnico médica</textarea>
                                        </div>
                                    </div> -->
                                    <div class="d-flex justify-content-end row action pr-0 mx-0">
                                        <button type="submit" (click)="submitFormFamilia(q+3)" *ngIf="!onSee"
                                            class="btn btn-red btn-sm px-3">
                                            Siguiente
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab8">
                            <ng-template ngbTabTitle>Expediente</ng-template>
                            <ng-template ngbTabContent>
                                <div class="container">
                                    <div *ngIf="!onSee" class="row py-3 d-flex justify-content-end">
                                        <div class="col-12 col-md-4">
                                            <button class="btn btn-red w-100" (click)="addStudy()">
                                                Nuevo estudio
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row" [ngClass]="{'py-3': onSee}">
                                        <div class="col-12 col-md-3">
                                            <h6>Nombre del estudio</h6>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <h6>Fecha del estudio</h6>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <h6>Archivo</h6>
                                        </div>
                                    </div>
                                    <div class="row border-bottom pt-3 pb-2"
                                        *ngFor="let study of studies; let i = index;">
                                        <div class="col-12 col-md-3 input-container">
                                            <input class="input-field" type="text" placeholder="000" id="study-{{ i }}"
                                                *ngIf="!study.saved" [(ngModel)]="study.name">
                                            <label class="input-label" for="study-{{ i }}"
                                                *ngIf="study.file === ''">Nombre del
                                                estudio</label>
                                            <span *ngIf="study.saved">{{ study.name }}</span>
                                        </div>
                                        <div class="col-12 col-md-4 input-container">
                                            <input id="input-field" class="input-field" type="date" placeholder="000"
                                                *ngIf="!study.saved" [(ngModel)]="study.date" id="date-{{ i }}"
                                                (focus)="changeDate('input-field')">
                                            <label class="input-label" for="study-{{ i }}"
                                                *ngIf="study.file === ''">Fecha del
                                                estudio</label>
                                            <span *ngIf="study.saved">{{ study.date | date: 'dd/MM/Y' }}</span>
                                        </div>
                                        <div class="col-12 col-md-4 input-container">
                                            <input type="file" style="display: none;"
                                                (change)="onFileSelected($event, i)" #uploadFile>
                                            <button *ngIf="!study.saved" class="btn btn-sm btn-info w-100"
                                                (click)="uploadFile.click()">
                                                {{ study.file === '' ? 'Cargar archivo' : 'Reemplazar archivo'}}
                                            </button>
                                            <span *ngIf="!study.saved">{{ study.fileName }}</span>
                                            <a *ngIf="study.saved" [href]="study.file" download="" target="_blank"
                                                class="btn btn-sm btn-success">
                                                <i class="fa fa-download"></i> Descargar
                                            </a>
                                            <a *ngIf="study.saved" class="btn btn-sm btn-danger ml-3"
                                                (click)="deleteStudy(i)">
                                                Eliminar
                                            </a>
                                        </div>
                                        <div class="col-12 col-md-1 input-container">
                                            <button (click)="saveStudy(i)" *ngIf="!study.saved"
                                                class="btn btn-sm btn-success">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col-12 d-flex justify-content-end">
                                            <button (click)="saveStudies()" class="btn btn-sm btn-info">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="display:none;">
    <div id="pdfId" style="width: 595px; height: 842px;">
        <div class="pdfId-container-user-date"
            style="width: 100%; position: relative; text-align:center; margin-top: 220px; margin-bottom: 10px; page-break-after: always;">
            <span style="font-size: 18px; margin-bottom: 0px; line-height: 22px;">{{collaborador?.firstName | uppercase
                }} {{collaborador?.lastName | uppercase }}</span> <br>
            <span style="font-size: 18px; margin-bottom: 0px; line-height: 22px;">{{
                collaborador?.business?.businessname }}</span>
        </div>
        <div class="pdfId-container-user-date"
            style="width: 100%; position: relative; height: 45px; margin-top: 40px; margin-bottom: 10px;">
            <div style="display: flex;flex-direction: column; position: absolute; right: 0%; text-align: right;">
                <span style="font-size: 10px; margin-bottom: 0px; line-height: 12px;">
                    <strong>EXAMEN MÉDICO PERIÓDICO.</strong>
                </span>
                <br>
                <span *ngIf='arrayDataUser'
                    style="font-size: 10px; margin-bottom: 0px; line-height: 12px;"><strong>{{arrayDataUser[0]?.created_at
                        | date: 'dd/MM/yyyy'}}</strong></span>
                <span *ngIf='!arrayDataUser'
                    style="font-size: 10px; margin-bottom: 0px; line-height: 12px;"><strong></strong></span>
            </div>
        </div>
        <div>
            <h3 style="font-size: 13px;"><strong>DATOS GENERALES</strong></h3>
        </div>
        <div class="pdfId-container-table">
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">NOMBRE:</th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px; text-align: left;font-size: 8px;">
                        {{collaborador?.firstName | uppercase }} {{collaborador?.lastName | uppercase }}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">EDAD:</th>
                    <td style=" border: 1px solid black; padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{age}} AÑOS
                    </td>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">GENERO</th>
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">{{
                        collaborador?.gender | uppercase }}</td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">FECHA DE
                        NACIMIENTO:</th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ collaborador?.dateOfBirth | date: 'dd/MM/Y'}}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">EMPRESA:
                    </th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ collaborador?.business?.businessname }}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">CARGO A
                        DESEMPEÑAR:</th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ collaborador?.position | uppercase }}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">TURNO:</th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ collaborador?.shift }}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">HORARIO:
                    </th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ collaborador?.schedule }}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">DIA DE
                        DESCANSO:
                    </th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ collaborador?.daysOff }}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">TELÉFONO:
                    </th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{collaborador?.phone}}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">DOMICILIO:
                    </th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ collaborador?.address }}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">ANTIGÜEDAD
                        LABORAL:</th>
                    <td colspan="3" style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ collaborador?.jobSeniority }}
                    </td>
                </tr>
            </table>
        </div>
        <div *ngFor="let item of arrayData | slice: 0:3" class="pdfId-container-antecedentes"
            style="margin-top: 15px; text-align: justify;">
            <b class="pdfId-container-antecedentes-title" style="font-size: 8px;">
                {{item.Title | uppercase }}
            </b>
            <br>
            <span *ngFor="let question of item.answers" style="font-size: 8px;line-height: 1.5; text-align:justify;">
                <span *ngIf="question.Answer && question.Answer.includes('Sí')">
                    {{question.Question }}: {{ question.Answer }}.
                </span>
            </span>
            <div>
                <span class="pdfId-container-antecedentes-subtitle"
                    style="font-size: 8px;line-height: 1.5; text-align:justify;">
                    Observaciones: {{item.comments | uppercase }}
                </span>
            </div>
        </div>
        <div class="pdfId-container-antecedentes" style="margin-top: 15px; text-align: justify;">
            <b class="pdfId-container-antecedentes-title" style="font-size: 8px;">
                Antecedentes laborales
            </b>
            <br>
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">Empresa</th>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">Puesto</th>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">Antigüedad
                    </th>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">EPP utilizado
                    </th>
                </tr>
                <tr *ngFor="let work of works">
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">{{work.name}}
                    </td>
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{work.position}}</td>
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{work.seniority}}</td>
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{ work.epp }}
                    </td>
                </tr>
            </table>
            <br>
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">Accidentes en
                        trabajo previos</th>
                </tr>
                <tr>
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{collaborador?.accidentes}}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">Enfermedad
                        Profesional</th>
                </tr>
                <tr>
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{collaborador?.enfermedad}}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">Actividad
                        Actual</th>
                </tr>
                <tr>
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{collaborador?.actividad}}
                    </td>
                </tr>
                <tr>
                    <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">Padecimiento
                        actual</th>
                </tr>
                <tr>
                    <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                        {{collaborador?.padecimiento}}
                    </td>
                </tr>
            </table>
        </div>
        <div class="pdfId-container-antecedentes" style="margin-top: 15px; text-align: justify;">
            <b class="pdfId-container-antecedentes-title" style="font-size: 8px;">
                Exploración física
            </b>
            <br>
            <div>
                <figure style="width: 200px; float:left;padding-right:20px; padding-bottom: 20px;"
                    *ngFor="let quizes of rallyData?.stages[0].quizes">
                    <table style="border-collapse: collapse;width:200px;"
                        *ngIf="!quizes.Description.includes('Agudeza Visual') && !quizes.Description.includes('Extremidades') && !quizes.Description.includes('Perianal')">
                        <tr>
                            <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">{{
                                quizes.Description.split(" - ")[1] }}</th>
                            <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ quizes.Description.includes('Piel') || quizes.Description.includes('Columna') ||
                                quizes.Description.includes('Genitourinario') ||
                                quizes.Description.includes('Sistema Vascular Perif') ||
                                quizes.Description.includes('Abdomen') ? 'Sí' : 'Normal' }}</th>
                            <th style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ quizes.Description.includes('Piel') || quizes.Description.includes('Columna') ||
                                quizes.Description.includes('Genitourinario') ||
                                quizes.Description.includes('Sistema Vascular Perif') ||
                                quizes.Description.includes('Abdomen')
                                ? 'No' : 'Anormal' }}</th>
                        </tr>
                        <tr *ngFor="let question of quizes.questions">
                            <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{question.Question}}</td>
                            <td colspan="2"
                                *ngIf="question.Question === 'Deformidades' || question.Question === 'Amígdalas'"
                                style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{question.Answer}}</td>
                            <td *ngIf="(question.Type === 'other' || question.Type==='radio') && (question.Question !== 'Deformidades' && question.Question !== 'Amígdalas' && !quizes.Description.includes('Perianal'))"
                                style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ question?.Answer === 'Sí' || question?.Answer === 'Normal' ? 'X'+' '+
                                (question?.Answerback || '') : ''
                                }}
                            </td>
                            <td *ngIf="(question.Type === 'other' || question.Type==='radio') && (question.Question !== 'Deformidades' && question.Question !== 'Amígdalas' && !quizes.Description.includes('Perianal'))"
                                style=" border: 1px solid black;padding: 2px 8px;text-align: center;font-size: 8px;">
                                {{ question?.Answer === 'No' || question?.Answer === 'Anormal' ?
                                'X'+' '+ (question?.Answerback || '') : '' }}
                            </td>
                            <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;"
                                colspan="2"
                                *ngIf="question.Type === 'text' || question.Type === 'more' || question.Type === 'number' && (question.Question !== 'Deformidades' && question.Question !== 'Amígdalas')">
                                {{ question?.Answer }}
                            </td>
                        </tr>
                    </table>
                    <table style=" border-collapse: collapse;width:200px;"
                        *ngIf="quizes.Description.includes('Agudeza Visual')">
                        <tr>
                            <th colspan="5"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ quizes.Description.split(" - ")[1] }}
                            </th>
                        </tr>
                        <tr>
                            <th style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                            </th>
                            <th style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                Cerca
                            </th>
                            <th colspan="2"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                Lejos
                            </th>
                            <th colspan="2"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                Corrección
                            </th>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                OD
                            </td>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ cercaAnswers ? cercaAnswers[0].Answer : '' }}
                            </td>
                            <td colspan="2"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ cercaAnswers ? cercaAnswers[1].Answer : '' }}
                            </td>
                            <td colspan="2"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ cercaAnswers ? cercaAnswers[2].Answer : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                OI
                            </td>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ lejosAnswers ? lejosAnswers[0].Answer : '' }}
                            </td>
                            <td colspan="2"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ lejosAnswers ? lejosAnswers[1].Answer : '' }}
                            </td>
                            <td colspan="2"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ lejosAnswers ? lejosAnswers[2].Answer : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{sentidoQuestion ? sentidoQuestion.Question : ''}}
                            </td>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                N
                            </td>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ sentidoQuestion ? sentidoQuestion.Answer === 'Normal' ? 'X' : '' : '' }}
                            </td>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                A
                            </td>
                            <td style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ sentidoQuestion ? sentidoQuestion.Answer === 'Anormal' ? 'X' : '' : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ visionQuestion ? visionQuestion.Question : '' }}: {{ visionQuestion ?
                                visionQuestion.Answer : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6"
                                style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ reflejosQuestion ? reflejosQuestion.Question : '' }}: {{ reflejosQuestion ?
                                reflejosQuestion.Answer : '' }}
                            </td>
                        </tr>
                    </table>
                    <table style="border-collapse: collapse;width:200px;"
                        *ngIf="quizes.Description.includes('Extremidades inferiores')">
                        <tr>
                            <th style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                Extremidades
                            </th>
                            <th style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                Superior
                            </th>
                            <th style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                Inferior
                            </th>
                        </tr>
                        <tr *ngFor="let quiz of extremidadesQuizes[0]?.questions; let i = index">
                            <th style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ quiz.Question }}
                            </th>
                            <th style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ quiz.Answer }}
                            </th>
                            <th style="border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{ extremidadesQuizes.length > 1 ? extremidadesQuizes[1].questions[i].Answer : ''}}
                            </th>
                        </tr>
                    </table>
                    <span style="margin-top: 20px;padding-top:20px;text-align: left;font-size: 8px;">
                        <b>Comentarios: </b>
                        {{ quizes.comments }}
                    </span>
                </figure>

                <div class="pdfId-container-table" style="margin-top: 20px;" *ngFor="let other of otherQuizes">
                    <table style="border-collapse: collapse; width: 100%;">
                        <tr>
                            <th colspan="3"
                                style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{other.Title}}</th>
                        </tr>
                        <tr>
                            <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                SOMATOMETRIA</td>
                            <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                Resultado</td>
                            <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                Interpretación</td>
                        </tr>
                        <tr *ngFor="let quiz of other.questions">
                            <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{quiz.Question}}</td>
                            <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{quiz.Answer}}</td>
                            <td style=" border: 1px solid black;padding: 2px 8px;text-align: left;font-size: 8px;">
                                {{quiz.Answerback}}</td>
                        </tr>
                    </table>
                </div>

                <div class="pdfId-container-table"
                    style="margin-top: 20px;padding-top:20px;text-align: left;font-size: 8px;">
                    <b>RECOMENDACIONES.</b>
                    <ol>
                        <li>MONITOREO DOMICILIARIO DE LA PRESIÓN ARTERIAL PARA DETECTAR CAMBIOS EN LOS PARÁMETROS EN LAS
                            CIFRAS DE TENSIÓN ARTERIAL
                            Y EVITAR RIESGO EN LA SALUD CARDIOVASCULAR.</li>
                        <li>CONSUMIR APROXIMADAMENTE ENTRE 2 Y 2.5 LITROS DE LIQUIDO AL DÍA (AGUA, INFUSIONES Y CALDOS
                            DESGRASADOS), LO RECOMENDABLE
                            ES BEBER TU PRIMER LITRO POR LA MAÑANA.</li>
                        <li>LIMITAR EL CONSUMO EXCESIVO DE BEBIDAS AZUCARADAS (REFRESCOS, JUGOS SABORIZADOS, BEBIDAS
                            ENERGIZANTES, DEBIDAS
                            DEPORTIVAS).</li>
                        <li>LIMITAR EL CONSUMO DE BEBIDAS ALCOHÓLICAS.</li>
                        <li>DIETA EQUILIBRADA EN CANTIDAD Y CALIDAD, INCORPORAR A DIARIO UNA COMBINACION DE ALIMENTOS DE
                            TODOS
                            LOS GRUPOS (VERDURAS,
                            FRUTAS, CEREALES, PRODUCTOS DE ORIGEN ANIMAL).</li>
                        <li>REDUCIR EL CONSUMO FRECUENTE DE ALIMENTOS CON ALTO CONTENIDO DE GRASAS TRANS Y SODIO.</li>
                        <li>LIMITAR EL CONSUMO DE SAL A 5 GRAMOS DIARIOS (EQUIVALENTES A POCO MENOS DE 1 CUCHARADITA).
                        </li>
                        <li>REALIZAR ACTIVIDAD FÍSICA DIARIA 30 MINUTOS AL DÍA MÍNIMO, 5 DÍAS A LA SEMANA, INICIA POR
                            PERIODOS
                            DE 5-10 MINUTOS E
                            INCREMENTA POCO A POCO EL TIEMPO.</li>
                        <li>TOMAR UN BREVE DESCANSO DE 5 MINUTOS POR CADA HORA DE TRABAJO, ESTIRA LAS ARTICULACIONES.
                        </li>
                        <li>NO CONSUMIR SUPLEMENTOS VITAMINICOS Y PROTEICOS SIN INDICACIÓN O AUTORIZACIÓN DE UN
                            PROFESIONAL DE
                            LA SALUD.</li>
                        <li>HACER SEGUIMIENTO ACTIVO PARA CONTROL DE SUS PADECIMIENTOS, EN CASO DE TENER ALGUNO.</li>
                        <li>CUMPLIR CON ESQUEMA DE VACUNACIÓN COMPLETO.</li>
                        <li>MANTENER UN BUEN HÁBITO DE DESCANSO REGULAR, DORMIR 8 HORAS POR LA NOCHE, EVITA EL CONSUMO
                            DE
                            BEBIDAS CON CAFEÍNA ANTES
                            Y EL USO DE DISPOSITIVOS ELECTRONICOS POR LO MENOS 1 HORA ANTES DE ACOSTARSE.</li>
                        <li>PROMUEVE RELACIONES SOCIALES SATISFACTORIAS Y RESPETUOSAS CON TUS COMPAÑEROS.</li>
                        <li>UTILIZAR COMPLETO EL EQUIPO DE PROTECCIÓN PERSONAL CORRESPONDIENTE</li>
                        <li>REALIZAR ANTIGENO PROSTATICO, UNA VEZ AL AÑO</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentWork>
    <div class="card mb-0">
        <div class="card-header py-3">
            <h4 class="mb-0">Nuevo trabajo</h4>
        </div>
        <div class="card-body pt-3 pb-1">
            <div class="row pt-2">
                <div class="col-12 col-12 input-container">
                    <input type="text" id="workName" placeholder="000" *ngIf="!workSelected.saved"
                        [(ngModel)]="workSelected.name" class="input-field">
                    <label class="input-label" for="workName" *ngIf="!workSelected.saved">Empresa</label>
                    <span *ngIf="workSelected.saved">{{ workSelected.name }}</span>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 mt-2">
                            <label for="">Antigüedad</label>
                        </div>
                        <div class="col-12 col-md-6 input-container mt-2">
                            <input id="workYear" class="input-field" type="text" placeholder="000"
                                *ngIf="!workSelected.saved" [(ngModel)]="workSelected.yearSeniority">
                            <label class="input-label" for="workYear" *ngIf="!workSelected.saved">Años</label>
                            <span *ngIf="workSelected.saved">{{ workSelected.monthSeniority }}</span>
                        </div>
                        <div class="col-12 col-md-6 mt-2 input-container">
                            <input id="workMonth" class="input-field" type="text" placeholder="000"
                                *ngIf="!workSelected.saved" [(ngModel)]="workSelected.monthSeniority">
                            <label class="input-label" for="workMonth" *ngIf="!workSelected.saved">Meses</label>
                            <span *ngIf="workSelected.saved">{{ workSelected.seniority }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-12 mt-3 input-container">
                    <input class="input-field" type="text" placeholder="000" *ngIf="!workSelected.saved"
                        id="workPosition" [(ngModel)]="workSelected.position">
                    <label class="input-label" for="workPosition" *ngIf="!workSelected.saved">Cargo o puesto</label>
                    <span *ngIf="workSelected.saved">{{ workSelected.position }}</span>
                </div>
                <div class="col-12 col-12 mt-3 input-container">
                    <input class="input-field" type="text" placeholder="000" *ngIf="!workSelected.saved" id="workEPP"
                        [(ngModel)]="workSelected.epp">
                    <label class="input-label" for="workEPP" *ngIf="!workSelected.saved">Equipo de protección personal
                        que
                        utilizaba</label>
                    <span *ngIf="workSelected.saved">{{ workSelected.epp }}</span>
                </div>
                <!-- <div class="col-12 col-12 mt-3 input-container">
                    <textarea id="input-field" class="input-field" placeholder="000" *ngIf="!workSelected.saved"
                        [(ngModel)]="workSelected.positionDescription"></textarea>
                    <label class="input-label" *ngIf="!workSelected.saved">Descripción del puesto</label>
                    <span *ngIf="workSelected.saved">{{ workSelected.position }}</span>
                </div> -->
                <!-- <div class="col-12 col-12 mt-3 input-container" *ngIf="!workSelected.saved">
                    <label for="">Factores de riesgo</label>
                    <div class="factores" *ngFor="let factor of arrayFactores; let q = index;">
                        <input type="checkbox" id="factor-{{ q }}" name="factor-{{ q }}" [value]="factor"
                            (click)="setResponseFactor($event, i)" class="mr-2">
                        <label class="mb-0" for="factor-{{ q }}">{{ factor
                            }}</label>
                    </div>
                </div> -->
                <!-- <div class="col-12 col-12 mt-3 input-container" *ngIf="!workSelected.saved">
                    <label for="">¿Haz experimentado un accidente en el trabajo?</label>
                    <div class="factores">
                        <input type="radio" id="accident1" name="accident" [value]="'Sí'"
                            [(ngModel)]="workSelected.accident" class="mr-2">
                        <label class="mb-0 mr-2" for="accident1">Sí</label>
                        <input type="radio" id="accident2" name="accident" [value]="'No'"
                            (click)="workSelected.accidentDestiption = ''" [(ngModel)]="workSelected.accident"
                            class="mx-2">
                        <label class="mb-0" for="accident2">No</label>
                    </div>
                </div>
                <div class="col-12 col-12 mt-3 input-container" *ngIf="workSelected.accident === 'Sí'">
                    <textarea id="input-field" class="input-field" placeholder="000" *ngIf="!workSelected.saved"
                        [(ngModel)]="workSelected.accidentDestiption"></textarea>
                    <label class="input-label" *ngIf="!workSelected.saved">Especifica</label>
                    <span *ngIf="workSelected.saved">{{ workSelected.position }}</span>
                </div>
                <div class="col-12 col-12 mt-3 input-container" *ngIf="workSelected.saved">
                    <span *ngFor="let ag of workSelected.agentes"> {{ ag }} </span>

                </div>

                <div class="col-12 col-12 mt-3 input-container">
                    <textarea id="input-field" class="input-field" placeholder="000" *ngIf="!workSelected.saved"
                        [(ngModel)]="workSelected.comments"></textarea>
                    <label class="input-label" *ngIf="!workSelected.saved">Comentarios</label>
                    <span *ngIf="workSelected.saved">{{ workSelected.position }}</span>
                </div> -->

            </div>
        </div>
        <div class="card-footer py-2 d-flex justify-content-end">
            <button type="submit" (click)="saveWork(i)" class="btn btn-red btn-sm px-3">
                Guardar
            </button>
        </div>
    </div>
</ng-template>