import { Component } from "@angular/core";

@Component({
    selector: 'app-policityPrivacity',
    templateUrl: './policityPrivacity.component.html',
    styleUrls: ['./policityPrivacity.component.scss']
})

export class PolicityPrivacityComponent{
    
}