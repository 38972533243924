import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../../services/auth/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-confirm-account',
    templateUrl: './confirm-account.component.html',
    styleUrls: ['./confirm-account.component.scss']
})

export class ConfirmAccountComponent{
    public loginForm: FormGroup;
    public registerForm: FormGroup;
    public isLoading = false;
    public submitted = false;
    public submittedLogin = false;
    public fieldTextType?: boolean = false;
    token: string = "";
 
  
    constructor(
      private formBuilder: FormBuilder,
      private authService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
    console.log(this.token);
    this.authService.activateUser(this.token, this.token).subscribe(data => {
        console.log(data);
    })
  }
  
   
  
    ngOnInit(): void {
        /* setTimeout(() => {
            this.router.navigate(['/auth/login']);
        }, 3000) */
        
    }
  
   
  
  
  
   
}