<div class="page-wrapper">
    <div class="authentication-box paddd">
        <div class="container">
            <div class="row mt-4 mb-3">
                <div class="col-md-3"></div>
                <div class="col-md-6 p-0">
                    <div class="card tab2-card">
                        <div class="card-body margin-calc">
                            <div class="text-center mt-1 mb-2">
                                <img src="../../../../assets/images/logo.png" style="width: 90%;" alt="">
                            </div>
                            <div>
                               <p class="text-info">Tu cuenta ha sido confirmada con éxito</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>