import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProgramsService } from 'src/app/shared/service/programs.service';

@Component({
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, OnDestroy {
  public programs = [];

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public submitted = false;

  public quizId: number;
  public form: FormGroup;
  public page = 1;
  public limit = 10;
  public offset = 0;

  constructor(
    private fb: FormBuilder,
    private programsService: ProgramsService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order:[[0, 'asc']]
    };

    this.subs.push(
      this.programsService
        .getPrograms(this.limit, this.offset)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.programs = data;
          this.dtTrigger.next();
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public setModalContent(content: any, orderId: number): void {
    this.quizId = orderId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public get formState() {
    return this.form.controls;
  }

  public loadPage(page: number) {
    this.subs.push(
      this.programsService
        .getPrograms(this.limit, page-1)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.programs = data;
        })
    );
  }

}
