import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

	constructor(private elem: ElementRef, private control: NgControl) { }
	@HostListener('input')
	onChange() {
		let value = this.elem.nativeElement.value
			.replace(/[^\d]/g, '')
		this.elem.nativeElement.value = value;
		this.control.control.setValue(value);
	}
}
