import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/shared/utils/headers.functions';
import * as moment from 'moment-timezone';
import { ProgramModel } from 'src/app/components/programs/program.models';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {

  constructor(
    private http: HttpClient
  ) { }

  public getPrograms(limit:number, offset:number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/studyprogram?limit=${limit}&offset=${offset}`);
  }

  public getProgram(thisProgramId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/studyprogram/${thisProgramId}`);
  }

  public getContent(topicId: number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/getcontent/${topicId}`);
  }

  public createProgram(program: ProgramModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/studyprogram`, program, { headers });
  }

  public updateProgram(id: any, program: ProgramModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/alterstudyprogram/${id}`, program, {headers});
  }

  public addTopics(id: any, topics: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/addTopics/${id}`, topics, {headers});
  }

  public updateTopic(id:any, topic:any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/altertopics/${id}`, topic, {headers});
  }

  public uploadVideo(formData: any, id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/upload-video/${id}`, formData, { headers });
  }

  public uploadFile(formData: any, id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/saveDocument/${id}`, formData, { headers });
  }

  public getProgramsByCourse(id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/studyprogramsofCourse/${id}`, { headers });
  }

  public getPercentage(programId:any):Observable<any>{
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/checkpercentaje/${programId}`, { headers });
  }

  public deleteProgram(id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<Observable<any>>(`${BASE_URL}/studyprogram/${id}`, { headers });
  }

  mapRequiredValues(product: any) {
    const productInfo: any = {};

    {
      productInfo.Active = product.Active; 
      productInfo.Name = product.Name;
      productInfo.OriginalName = product.OriginalName;
      productInfo.Percentage = product.Percentage;
      productInfo.VideoUrl = product.VideoUrl;
      productInfo.courseDuration = product.courseDuration;
      productInfo.id = product.id;
      productInfo.numberTopics = product.numberTopics;
      productInfo.products = product.products;
      productInfo.productsId = product.productsId;
      productInfo.teacher = product.teacher;
      productInfo.teacherId = product.teacherId;
      productInfo.topic = product.topic;
      productInfo.uuid = product.uuid;
  }

    return productInfo;
  }

}
