import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UsersService } from '../../services/users/users.service';
import { NgbModal, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { event } from 'jquery';

@Component({
  selector: 'app-datail-enterprise',
  templateUrl: './detail-enterprise.component.html',
  styleUrls: ['./detail-enterprise.component.scss']
})
export class DetailEnterpriseComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [];
  public values: any;
  public form: FormGroup;
  public submitted: boolean = false;
  public isLoading = false;
  public items = [];
  public selectedOption: any;
  public showForm: boolean = true;
  public showRegisterThanks: boolean = false;
  personalDetails!: FormGroup;
  private subs: Array<Subscription> = [];
  currentUserId: any;
  enterprises: any[];
  firstName: string;
  lastName: string;
  businessname: string;
  phoneNumber: string;
  email: string;
  collaborators: string;
  collaborator: any[];
  collaboratorList: any[];
  public deleteFilters: boolean = false;
  collaboratorFilter: any;
  perfilEnterprise: string;
  perfilColaborador: string;
  tabsBoolean: boolean = false;
  collaboratorTotal: number;
  public deleteIsSuccess = false;
  public userId: string;
  public count: number;
  public page = 1;
  public limit = 5;
  public offset = 0;
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  deleteCollaborators: any;
  buttonDisabled: boolean = false;
  submittedSuccess: boolean = false;
  messageSuccess: string = '';
  submittedError: boolean = false;
  messageError: string = '';
  dataSource: any;
  orderColumn = 'displayName';
  orderType = "asc";
  pageIndex = 0;
  orderText: string = 'id-asc';
  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private _usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.route.params.subscribe(params => {
      this.currentUserId = params['id'];
    });

    this._usersService.setId(this.currentUserId);

    this._usersService.getEnterpriseId(this.currentUserId).subscribe((data: any) => {
      this.enterprises = [data]
      this.firstName = data['firstName'];
      this.lastName = data['lastName'];
      this.businessname = data['businessname'];
      this._usersService.setBusinessname(this.businessname)
      this.phoneNumber = data['phoneNumber'];
      this.email = data['email'];
      this.collaborators = data['collaborators'];
    })

    this.displayedColumns = [
      'firstName',
      'lastName',
      'birthday',
      'Job',
      'phoneNumber',
      'actions',
    ];
  }

  ngOnInit(): void {
    this.loadData();
  }

  @ViewChild(MatSort) sort!: MatSort;

  announceSortChange(sortState: Sort) {
    this.orderText = `${sortState.active}-${sortState.direction}`;
    this.pageIndex = this.page;
    this.orderColumn = sortState.active;
    this.orderType = sortState.direction;
    this.loadData();
  }

  obtenerId(id) {
    this._usersService.setidUser(id)
  }


  loadData() {
    this._usersService.getListCollaborator3(this.currentUserId, this.limit, 0, this.orderText).subscribe((data: any) => {
      this.collaboratorList = data.List;
      this.collaboratorTotal = data.Total;
      console.log(data);
      if (Number(this.collaborators) == this.collaboratorTotal) {
        this.buttonDisabled = true;
      }
      this.dataSource = new MatTableDataSource(data.List)
    })
  }

  loadDataFilter() {
    this.deleteFilters = false;
    this.collaboratorFilter = '';
    this.loadData();
  }

  public loadPage(page: number) {
    this.page = page;
    this.subs.push(
      this._usersService
        .getListCollaborator(this.currentUserId, this.limit, page - 1, this.orderText)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.collaboratorList = data.List;
          this.dataSource = new MatTableDataSource(data.List);
        })
    );
  }


  toogleTabs() {
    this.tabsBoolean = !this.tabsBoolean;
  }

  toogleTabsTrue() {
    this.tabsBoolean = true;
  }

  ngOnDestroy(): void {
    let sub: Subscription;
    while (sub = this.subs.shift()) {
      sub.unsubscribe();
    }
  }

  public get formState() {
    return this.personalDetails.controls;
  }


  filter(event: any) {
    this.deleteFilters = true;
    console.log(this.collaboratorList)
    this.collaboratorList = this.collaboratorList.filter(item =>
      item?.displayName?.toLowerCase().includes(event.toLowerCase()) ||
      item?.firstName?.toLowerCase().includes(event.toLowerCase()) ||
      item?.lastName?.toLowerCase().includes(event.toLowerCase()) ||
      item?.Job?.toLowerCase().includes(event.toLowerCase()) ||
      item?.phoneNumber?.includes(event) ||
      item?.email?.toLowerCase().includes(event.toLowerCase())
    );
    console.log(this.collaboratorList)
    this.dataSource = new MatTableDataSource(this.collaboratorList);
  }

  public onClickRedirectLogin(): void {
    this.router.navigate(['/enterprise'])
  }

  private handleError(error: any) {
    /*  this.isLoading = false;
     const toastOptions: ToastOptions = {
         title: 'Error',
         msg: error.error.message,
         showClose: true,
         timeout: 2000
     }; */
    /* this.toastyService.error(toastOptions); */
    return [];
  }

  private handleUserCreation() {
    /* this.isLoading = false;
    const toastOptions: ToastOptions = {
        title: 'Registro',
        msg: 'Usuario creado correctamente',
        showClose: true,
        timeout: 1700
    }; */
    /*   */
  }

  private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {
    /*  const toastOptions: ToastOptions = {
         title,
         msg: message,
         showClose: true,
         timeout: 3500,
         theme: 'bootstrap'
     };

     this.toastyService[type](toastOptions); */
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this._usersService.getUser(this.userId).subscribe((data: any) => {
      this.deleteCollaborators = [data.businessname];
    })
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteUser() {
    const user: any = this.collaboratorList.filter((item) => item.id === this.userId);
    this.subs.push(
      this._usersService.deleteUser(user[0].id)
        .subscribe(resolve => {
          if (resolve.message == 'Usuario ha sido dado de baja') {
            this.modalService.dismissAll();
            this.deleteIsSuccess = false;
            this.submittedError = true;
            this.messageError = 'Colaborador dado de baja correctamente'
            this.loadData();
            setTimeout(() => {
              this.submittedError = false;
              this.messageError = ''
              this.loadData();
            }, 2000)
          }
        })
    );
  }

  public activeUser() {
    const user: any = this.collaboratorList.filter((item) => item.id === this.userId);
    this.subs.push(
      this._usersService.putEnterpriseId(user[0].id, { active: true })
        .subscribe(resolve => {
          this.deleteIsSuccess = true;
          this.submittedSuccess = true;
          this.messageSuccess = 'Colaborador dado de alta correctamente';
          this.modalService.dismissAll();
          this.loadData();
          this.deleteIsSuccess = false;
          setTimeout(() => {
            this.submittedSuccess = false;
            this.messageSuccess = ''
          }, 2000);
        })
    );
  }
}