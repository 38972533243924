<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Duración del curso</th>
                        <th>Total de temas</th>
                        <th>Maestro</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let program of programs">
                        <td>{{program.id}}</td>
                        <td>{{program.Name}}</td>
                        <td>{{program.courseDuration}} horas</td>
                        <td>{{program.numberTopics}}</td>
                        <td>{{program.teacher.firstName}} {{program.teacher.lastName}}</td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['show', program.id]"
                                title="Ver Programa">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span>
                            <span>
                            <a [routerLink]="['quiz', program.id]"
                                title="Añadir Cuesitonario">
                                <i class="fa fa-list-ul"></i>
                            </a>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="10" [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->