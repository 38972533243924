import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { WikiService } from 'src/app/shared/service/wiki.service';
import { ProductsService } from 'src/app/shared/service/products.service';
import * as moment from 'moment';

@Component({
  selector: 'app-wiki-list',
  templateUrl: './wiki-list.component.html',
  styleUrls: ['./wiki-list.component.scss']
})
export class WikiComponent implements OnInit {

  public wikis = []
  public isCollapsed = true;
  public wikiId: number;
  public active: boolean;
  public deleteIsSuccess = false;

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  constructor(
    private wikiService: WikiService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.wikiService
        .getListWiki()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          data = data.map((element: any) => {
            element.created_at = moment(element.created_at).format("DD-MM-YYYY");
            return element;
          })
          this.wikis = data;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setModalContent(content: any, wikiId: number, status: boolean): void {
    this.wikiId = wikiId;
    this.active = status;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteCourse() {
    this.subs.push(
      this.wikiService.deleteWiki(this.wikiId)
        .subscribe(resolve => {
          if (resolve.Message == 'Estatus cambiado') {
            this.deleteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
            }, 1000);
            setTimeout(() => {
              this.ngOnInit();
            }, 2000);
          }
          this.deleteIsSuccess = false;
        })
    );
  }
  
}
