import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProductsService } from 'src/app/shared/service/products.service';
import { QuizsService } from 'src/app/shared/service/quizs.service';
import { AnswerModel, QuestionModel, QuizModel } from '../../products/product.models';


@Component({
  selector: 'app-add-quiz',
  templateUrl: './add-quiz.component.html',
  styleUrls: ['./add-quiz.component.scss']
})
export class AddQuizComponent implements OnInit {
  public quizForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public programId: number;
  public questionId: number;
  public id: number;
  public urlGet = [];

  public typeQuestion: string = null;
  public valueQuestion: string = null;
  public valueAnswer: string = null;
  public valueCorrect: boolean = false;
  public questionsQuiz: Array<QuestionModel> = [];
  public answerQuestion: any = [];
  public answerResults: Array<AnswerModel> = [];
  public questions: any = [];
  public answers: any;
  public results: any;
  public count: number = 0;
  public countTrue: number;

  public counter: number = 1;
  public url = [{
    img: "assets/images/photo.png",
  },
  {
    img: "assets/images/photo.png",
  }
  ]
  private subs: Array<Subscription> = [];

  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  editingQuestion: boolean = false;
  editingAnswer: boolean = false;
  indexQuestion: any;
  indexAnswer: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private productService: ProductsService,
    private quizService: QuizsService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }


  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;
 
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
  }

  ngOnInit() {
    this.urlGet = window.location.pathname.split('/').slice(2);
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (!this.questionsQuiz.length){
          this.programId = data.params.id;
          this.onEdit = false;
            this.quizForm = this.fb.group({
              Title: ['', [Validators.required]],
              Description: ['', [Validators.required]]
            })
        }
        else if (data.params.id) {
          this.programId = data.params.id;
          this.onEdit = true;
          this.productService.getProduct(data.params.id)
            .subscribe(product => this.setValues(product));
            this.quizForm = this.fb.group({
              Title: ['', [Validators.required]],
              Description: ['', [Validators.required]]
            })
        }else {
          this.onEdit = false;
          this.quizForm = this.fb.group({
            Title: ['', [Validators.required]],
            Description: ['', [Validators.required]]
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.quizForm.controls
  }

  private setValues(product: any): void {
    const values = this.productService.mapRequiredValues(product);
    this.id = values.id;
    
    const { controls } = this.quizForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.quizForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildQuiz(): QuizModel {
    return {
        Title: this.quizForm.controls['Title'].value,
        Description: this.quizForm.controls['Description'].value,
        studyprogramId: Number(this.programId),
        Questions: this.questions,
        Answers: this.answerResults,
        NumQuestions: this.questions.length,
        courseevaluationId: this.programId
    }
  }

  private buildQuizUpdated(): QuizModel {
    return {
        Title: this.quizForm.controls['Title'].value,
        Description: this.quizForm.controls['Description'].value,
        studyprogramId: Number(this.programId),
        Questions: this.questions,
        Answers: this.answerResults,
        NumQuestions: this.questions.length,
        courseevaluationId: this.programId
    }
  }

  addQuestion(){
    if (this.typeQuestion === null || this.valueQuestion === null){
      this.EmptyInputsError();
      return;
    }
    if(this.answerQuestion.length === 0){
      this.AddAnswersError();
      return;
    }
    if(this.answerQuestion.length === 1){
      this.NoAnswersTwo();
      return;
    }
    else{
      this.countTrue = 0;
      for(let x=0; x<this.answerQuestion.length; x++){
        if(this.answerQuestion[x].Correct === true){
          this.questionsQuiz.push({Type: this.typeQuestion, Question: this.valueQuestion});
          this.typeQuestion = null;
          this.valueQuestion = null;
          this.answers = this.answerQuestion;
          this.answerResults.push(this.answers);
          this.questions = [ ...this.questionsQuiz];
          this.answerQuestion = [];
          this.countTrue = this.countTrue+1;
        }
      }
      this.count = 0;
    }
    if (this.countTrue === 0){
      this.NoTrueError();
      return;
    }
  }

  editQuestion(question, index){
    this.valueQuestion = question.Question;
    this.typeQuestion = question.Type;
    this.answerQuestion = this.answerResults[index];
    this.indexQuestion = index;
    this.count = 1;
    this.editingQuestion = true;
  }

  changeQuestion(){
    this.questions[this.indexQuestion].Question = this.valueQuestion;
    this.questions[this.indexQuestion].Type = this.typeQuestion; 
    this.answerResults[this.indexQuestion] = this.answerQuestion;
    this.editingQuestion = false;
    this.valueQuestion = "";
    this.typeQuestion = "";
    this.count = 0;
    this.answerQuestion = [];
  }

  editAnswer(answer, index){
    console.log(answer);
    let check = document.querySelector("#validationCustom08") as HTMLInputElement;
    this.valueAnswer = answer.Answer;
    this.valueCorrect = answer.Correct;
    this.count = answer.Correct ? 0 : this.count;
    this.indexAnswer = index;
    this.editingAnswer = true;
    console.log(this.count);
  }

  changeAnswer(){
    let corrects = this.answerQuestion.filter( q => q.Correct == true );
    if( this.count != 0 && this.valueCorrect == true ){
      this.NoCorrectMore();
      return;
    }
    this.answerQuestion[this.indexAnswer].Answer = this.valueAnswer;
    this.answerQuestion[this.indexAnswer].Correct = this.valueCorrect; 
    this.count  = this.valueCorrect && this.count == 0 ? 1 : 0 ;
    this.editingAnswer = false;
    this.valueAnswer = "";
    this.valueCorrect = false;
  }

  removeQuestion(question) {
    if( !this.editingQuestion ){
      this.count  = 0;
      const index: number = this.questionsQuiz.indexOf(question);
      this.questionsQuiz.splice(index, 1);
      this.questions = [ ...this.questionsQuiz];
      const indexOld = index;
      this.answerResults.splice(indexOld, 1);
    }else{
      this.AddFinalError();
      return;
    }
  }

  public setModalContent(content: any): void {
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  addAnswer(){
    console.log(this.count, this.typeQuestion, this.valueCorrect);
    if(this.valueAnswer === null || this.valueAnswer?.length === 0){
      this.EmptyInputsAnswerError();
      return;
    }

    if(this.count === 1 && this.valueCorrect === true){
      this.NoCorrectMore();
      return;
    }

    if(this.typeQuestion === 'Única' && this.valueCorrect === true){
      this.count = 1;
    }
    console.log(this.count);
    
      this.answerQuestion.push({Answer: this.valueAnswer, Correct: this.valueCorrect});
      this.valueAnswer = null;
      this.valueCorrect = false;

    /* if(this.typeQuestion === 'Múltiple'){
      this.answerQuestion.push({Answer: this.valueAnswer, Correct: this.valueCorrect});
      this.valueAnswer = null;
      this.valueCorrect = false;
      // this.results = [ ...this.answerQuestion];
    } */
  }

  removeAnswer(answer) {
    if( !this.editingAnswer ){
      const index: number = this.answerQuestion.indexOf(answer);
      this.count = 0;
      // this.answerQuestion.splice(index, 1);
      // this.results = [ ...this.answerQuestion];
      this.answerQuestion = this.answerQuestion.filter(item => item.Answer !== answer.Answer);
    }else{
      this.AddFinalError();
      return;
    }

  }

  onSubmit() {
    this.submitted = true;
    if(this.questions.length === 0){
      this.AddQuestionsError();
      return;
    }

    if (this.quizForm.invalid) {
        return;
    }

    if (this.onEdit) {
      this.updateQuiz();
    } else {
      this.createQuiz();
    }
  }

  createQuiz() {
    const quiz: QuizModel = this.buildQuiz();
    this.isLoading = true;
    this.subs.push(
        this.quizService.createQuiz(quiz)
        .pipe(
        map(() => {
            this.handleQuizCreation();
            setTimeout(() => {
            if (this.urlGet[0] === 'capacitations'){
              this.router.navigateByUrl('products/capacitations');
            }else {
              this.router.navigateByUrl('products/courses');
            }
            }, 1800);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  updateQuiz() {
    const quiz: QuizModel = this.buildQuizUpdated();
    this.isLoading = true;

    this.subs.push(
        this.quizService.updateQuiz(this.programId, quiz)
        .pipe(
        map(() => {
            this.handleQuizEdition();
            setTimeout(() => {
            this.router.navigateByUrl('products/courses');
            }, 1800);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

 

  private EmptyInputsError() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes llenar todos los campos para agregar una pregunta.',
      showClose: true,
      timeout: 2000
    };
    this.toastyService.warning(toastOptions); */
    return [];
  }

  private EmptyInputsAnswerError() {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes llenar todos los campos para agregar una respuesta.',
      showClose: true,
      timeout: 3000
    };
    this.toastyService.warning(toastOptions); */
    return [];
  }

  private NoCorrectMore() {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No puedes tener 2 respuestas correctas en una pregunta única.',
      showClose: true,
      timeout: 3000
    };
    this.toastyService.warning(toastOptions); */
    return [];
  }

  private NoAnswersTwo() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No puedes agregar la pregunta, necesitas mínimo 2 respuestas.',
      showClose: true,
      timeout: 3000
    };
    this.toastyService.warning(toastOptions); */
    return [];
  }

  private AddAnswersError() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes agregar respuestas en la pregunta actual.',
      showClose: true,
      timeout: 3000
    };
    this.toastyService.warning(toastOptions); */
    return [];
  }

  private NoTrueError() {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes agregar una respuesta correcta en la pregunta actual.',
      showClose: true,
      timeout: 3000
    };
    this.toastyService.warning(toastOptions); */
    return [];
  }

  private AddQuestionsError() {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes agregar preguntas en tu cuestionario.',
      showClose: true,
      timeout: 3000
    };
    this.toastyService.error(toastOptions); */
    return [];
  }

  private AddFinalError() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes terminar la edición para eliminar el elemento.',
      showClose: true,
      timeout: 3000
    };
    this.toastyService.error(toastOptions); */
    return [];
  }

  private handleQuizCreation() {
    /* const toastOptions: ToastOptions = {
      title: 'Cuestionario',
      msg: 'Cuestionario creado correctamente',
      showClose: true,
      timeout: 1700
    };
    this.toastyService.success(toastOptions); */
    return [];
  }

  private handleQuizEdition() {
    /* const toastOptions: ToastOptions = {
      title: 'Cuestionario',
      msg: 'Cuestionario creado correctamente',
      showClose: true,
      timeout: 1700
    };
    this.toastyService.success(toastOptions); */
    return [];
  }

  private handleError(error: any) {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear el cuestionario',
      showClose: true,
      timeout: 2000
    };
    this.isLoading = false;
    this.toastyService.error(toastOptions); */
    return [];
  }

}
