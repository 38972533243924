import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel, UserResult } from 'src/app/shared/models/user';
import { catchError, map, tap } from 'rxjs/operators';
let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' };
@Injectable({ providedIn: 'root' })

export class AccountService {
    private userSubject: BehaviorSubject<UserModel>;
    public user: Observable<UserModel>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): UserModel {
        return this.userSubject.value;
    }

    login(user, pwd) {
        return this.http.post<UserResult>(`/servicios.svc/LoginApp`, { user, pwd }, httpOptions);
    }

    logout() {
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/pages/login']);
    }

    register(user: UserModel) {
        // console.log(user);
        return this.http.post<any>(`/servicios.svc/Registrar`, user, httpOptions );
    }

    getAll() {
        return this.http.get<UserModel[]>(`/users`);
    }

    getById(id: string) {
        return this.http.get<UserModel>(`/users/${id}`);
    }

    update(id, params) {
        return this.http.put(`/users/${id}`, params)
            .pipe(map(x => {
               if (id == this.userValue) {
                  const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                     this.userSubject.next(user);
                }
                return x;
            }));
    }
}

    