<div class="container-fluid">
    <div class="row">
        <div class="d-flex custom-add-history-clinic-direction justify-content-between align-item-center w-100">
            <div class="card tab2-card custom-card-margin card-with-add-history-clinic">
                <div class="card-body tab2-card" style="padding-left: 15px !important; padding-right: 0px !important;">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab>
                            <ng-template ngbTabContent>
                                <h4 class="px-2">Generales</h4>
                                <hr />
                                <div class="tab-pane fade active show" id="account2" role="tabpanel"
                                    aria-labelledby="account2-tab" *ngFor="let user of arrayUser">
                                    <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                        <div class="d-flex flex-row">
                                            <i class="fa fa-briefcase"></i>
                                        </div>
                                        <div class=" px-1">
                                            <h4 class="custom-font-size-h4">
                                                <h5 class="custom-font-size-h5">Nombre(s)</h5>
                                                {{user.firstName}}
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                        <div class="d-flex flex-row">
                                            <i class="fa fa-user"></i>
                                        </div>
                                        <div class="">
                                            <h5 class="custom-font-size-h5">Apellido(s)</h5>
                                            <h4 class="custom-font-size-h4">
                                                {{user.lastName}}
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                        <div class="d-flex flex-row">
                                            <i class="fa fa-envelope"></i>
                                        </div>
                                        <div class="">
                                            <h5 class="custom-font-size-h5">Puesto</h5>
                                            <h4 class="custom-font-size-h4">
                                                {{user.Job}}
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                        <div class="d-flex flex-row">
                                            <i class="fa fa-phone"></i>
                                        </div>
                                        <div class="">
                                            <h5 class="custom-font-size-h5">Estado civil</h5>
                                            <h4 class="custom-font-size-h4">
                                                {{user.civiledo}}
                                            </h4>
                                        </div>
                                    </div>

                                    <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                        <div class="d-flex flex-row">
                                            <i class="fa fa-users"></i>
                                        </div>
                                        <div class="">
                                            <h5 class="custom-font-size-h5">Género</h5>
                                            <h4 class="custom-font-size-h4">
                                                {{user.gender}}
                                            </h4>
                                        </div>
                                    </div>

                                    <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                        <div class="d-flex flex-row">
                                            <i class="fa fa-users"></i>
                                        </div>
                                        <div class="">
                                            <h5 class="custom-font-size-h5">Fecha de nacimiento</h5>
                                            <h4 class="custom-font-size-h4">
                                                {{user.birthday}}
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                        <div class="d-flex flex-row">
                                            <i class="fa fa-users"></i>
                                        </div>
                                        <div class="">
                                            <h5 class="custom-font-size-h5">Correo electrónico</h5>
                                            <h4 class="custom-font-size-h4">
                                                {{user.email}}
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                        <div class="d-flex flex-row">
                                            <i class="fa fa-users"></i>
                                        </div>
                                        <div class="">
                                            <h5 class="custom-font-size-h5">Numero telefónico</h5>
                                            <h4 class="custom-font-size-h4">
                                                {{user.phoneNumber}}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
            <div class="card card-with-add-history-clinic-collaborators">
                <div class="card-body">
                    <h4 class="px-2 pb-3">Agregar historial clinico</h4>
                    <ngb-tabset>
                        <ngb-tab *ngFor="let item of arrayData">
                            <!-- {{listDataFamiliares?.Title}} *ngIf="step == 2" -->
                            <ng-template ngbTabTitle>{{item.Title}} {{ item.id }}</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form [formGroup]="formFamiliares" (ngSubmit)="submitFormFamilia()"
                                        class="needs-validation user-add" novalidate>
                                        <div class="form-group row"
                                            *ngFor="let questions of item.questions; let i = index">
                                            <div class="col-xl-4 col-md-4 custom-margin-add-history-clinic">
                                                <label for="validationCustom0" class="">{{questions.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div *ngIf="questions.Type == 'other'"
                                                        class="d-flex gap-custom justify-content-between col-lg-3 col-sm-3">
                                                        <div *ngFor="let answer of questions.answers; let c = index">
                                                            <input class="form-check-input" type="radio"
                                                                name="diabeticsBoolean-{{i}}-{{c}}"
                                                                value="{{answer.Answer}}"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (change)="updateDiabeticsChosen($event.target.value,questions,answer.id)">
                                                            <label class="form-check-label">
                                                                {{answer.Answer}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="questions.Type != 'other'"
                                                        class="d-flex gap-custom justify-content-between col-lg-6 col-sm-3">
                                                        <input class="form-control" type="{{questions.Type}}"
                                                            name="diabeticsBoolean-{{i}}-{{c}}"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updateDiabeticsChosen($event.target.value,questions, answer.id)">
                                                    </div>
                                                    <div class="mr-2 col-xl-8">
                                                        <select
                                                            *ngIf="questions.Answer == 'Sí' || questions.Answer == true"
                                                            class="select-option-font-size form-control"
                                                            aria-label="Default select example"
                                                            [(ngModel)]="optionSelected[i]" name="optionSelected-{{i}}"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="capturar(optionSelected[i], questions, questions.id, item)">
                                                            <option *ngFor="let item of arrayFamiliares" [value]="item">
                                                                {{item}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="form-group row col-xl-12 pl-0 align-item-start"
                                            *ngIf="item.id !== 2">
                                            {{ item.id }}
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom1" class="">Comentarios</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <textarea class="custom-rezise-form form-control" name="comments"
                                                    [(ngModel)]="comments"
                                                    [ngModelOptions]="{standalone: true}">Comentarios</textarea>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <ngb-toast *ngIf="submittedSuccessFamiliares"
                                                [class]="'ngb-toast bg-success'">
                                                {{messageSuccessFamiliares}}
                                            </ngb-toast>
                                        </div>
                                        <div class="d-flex justify-content-end form-group col-12 mb-0 text-right">
                                            <!--  <button type="submit" class="btn btn-red" (click)="restSteps()">
                                                Anterior
                                                <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                           </button> -->
                                            <button type="submit" class="btn btn-red">
                                                Guardar
                                                <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <!--  <ngb-tab *ngFor="let item of stages">
                            <ng-template ngbTabTitle>{{item.Description}}</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form [formGroup]="formFamiliares" (ngSubmit)="submitFormExtremidades()"
                                        class="needs-validation user-add" novalidate>
                                        <div class="form-group row" *ngFor="let title of item.quizes; let c = index">
                                            <div class="col-xl-12 col-md-4 custom-margin-add-history-clinic">
                                                <label for="validationCustom0" class="custom-exploracion-font-size--title">{{title.Title}}</label>
                                            </div>
                                            <div class="col-xl-12 col-md-8">
                                                <div class="d-flex flex-column align-items-center">
                                                                <div class="d-flex col-xl-12" *ngFor="let answer of title.questions; let i = index">
                                                                    <div class="col-xl-4">
                                                                    <h5 class="custom-exploracion-font-size--subtitle my-2">{{answer.Question}}</h5>
                                                                </div>
                                                                <div class="d-flex justify-content-between col-xl-6 my-1">
                                                                     <div *ngFor="let answers of answer.answers; let x = index">
                                                                          <div *ngIf="answer.Type == 'other' || answer.Type == 'radio'" class="d-flex justify-content-between col-xl-12">
                                                                            <input class="form-check-input" type="radio" name="cardiologistsBoolean-{{c}}-{{i}}-{{x}}" 
                                                                            value="{{answers.Answer}}" [ngModelOptions]="{standalone: true}"
                                                                            (change)="updateExtremidades($event.target.value, answers, answers.id)">
                                                                            <label class="form-check-label">
                                                                                {{answers.Answer}}
                                                                            </label>
                                                                            <div class="d-flex col-xl-12 my-1" style="width: 200px;" *ngIf="answers.response === 'Anormal' || answers.response === 'Sí'" >
                                                                                <input class="form-control" type="text" name="optionSelected-{{c}}-{{i}}-{{x}}" [(ngModel)]="optionSelected[i]"  (change)="capturarExtremidades(optionSelected[i],answers, answer)" [ngModelOptions]="{standalone: true}">
                                                                                
                                                                            </div>
                                                                          </div>
                                                                </div> 
                                                                <div class="d-flex col-xl-12 my-1" *ngIf="answer.Type == 'text'">
                                                                    <input class="form-control" type="text" name="optionSelected2-{{c}}-{{i}}-{{x}}" [(ngModel)]="optionSelected2[i]"  (change)="capturarExtremidades(optionSelected2[i],answers, answer)" [ngModelOptions]="{standalone: true}">
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-end form-group col-12 mb-0 text-right" >
                                            <button type="submit" class="btn btn-red" (click)="restSteps()">
                                                Anterior
                                                <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                           </button>
                                           <div class="d-flex justify-content-center">
                                            <ngb-toast *ngIf="submittedSuccessFamiliares" [class]="'ngb-toast bg-success'">
                                                {{messageSuccessFamiliares}}
                                              </ngb-toast>
                                        </div>
                                            <button type="submit" class="btn btn-red">
                                                 Guardar
                                               <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab> -->
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>