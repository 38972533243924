import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { QuizsService } from 'src/app/shared/service/quizs.service';


@Component({
  selector: 'app-quiz-detail',
  templateUrl: './quiz-detail.component.html',
  styleUrls: ['./quiz-detail.component.scss']
})
export class QuizsDetailsComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  public quiz: any;

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject()

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private quizService: QuizsService,
  ) {
  }


  ngOnInit() {
    this.subs.push(
        this.activatedRoute.paramMap.pipe(
          map(params => params.get('id')),
          switchMap(id => this.quizService.getQuiz(id)),
          catchError(error => {
            this.router.navigate(['/quizs']);
            return throwError(error);
          })
        ).subscribe(quiz => {
          this.quiz = quiz;
          this.dtTrigger.next();
        })
      );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
  }

}
