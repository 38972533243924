import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProductsService } from 'src/app/shared/service/products.service';
import { ProgramsService } from 'src/app/shared/service/programs.service';
import { NumberLiteralType } from 'typescript';
import { UsersService } from '../../services/users/users.service';
import { ProgramModel, TopicsModel } from '../program.models';



@Component({
  selector: 'app-add-program',
  templateUrl: './add-program.component.html',
  styleUrls: ['./add-program.component.scss']
})
export class AddProgramComponent implements OnInit {
  public programForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public productId: number;
  public programId: any;
  public questionId: number;
  public id: number;
  public teachers = [];


  public valueTitle: string = '';
  public valueDuration: string;
  public valueCorrect: boolean = false;
  public topicsProgram: Array<TopicsModel> = [];
  public topics = [];
  public topicsFalse = [];
  public totalTopics: number = 1;

  public program: any;
  public requiredFileType: string;
  public viewLoaderVideo = false;
  public previewVideo: any;
  public responseVideo: string = '';
  public fileNameVideo = '';
  public videoUploaded = false;
  public counter: number = 1;
  public url = [{
    img: "assets/images/photo.png",
  },
  {
    img: "assets/images/photo.png",
  }
  ]
  private subs: Array<Subscription> = [];

  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  onEditTopic: boolean;
  index: any;
  percent: any;
  prevPercent: any;
  course: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private productService: ProductsService,
    private usersService: UsersService,
    private programsService: ProgramsService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        this.productId = data.params.id;
        this.usersService
          .getTeachers(500, 0)
          .subscribe(data => {
            this.teachers = data.List.filter(item => item.active);
          }),
          this.programsService.getPercentage(this.productId).subscribe((data:any)=>{
            this.percent = data;
          })

          this.productService.getProduct(this.productId.toString()).subscribe(data => {
            this.course = data;
          })
        // if (!this.topicsProgram.length){
        //   this.productId = data.params.id;
        //   this.onEdit = false;
        //     this.programForm = this.fb.group({
        //       Name: ['', [Validators.required]],
        //       numberTopics: ['', [Validators.required]],
        //       courseDuration: ['', [Validators.required]],
        //       Teacher: ['', [Validators.required]],
        //       Percentage: ['', [Validators.required]]
        //     })
        // }
        
        if (data.params.programId) {
          this.programId = data.params.programId;
          this.onEdit = true;
          this.programsService.getProgram(data.params.programId)
            .subscribe(product => this.setValues(product));
          this.programForm = this.fb.group({
            Name: ['', [Validators.required]],
            numberTopics: ['', [Validators.required,Validators.min(0)]],
            courseDuration: ['', [Validators.required,Validators.min(0)]],
            Teacher: ['', [Validators.required]],
            Percentage: ['', [Validators.required,Validators.min(0)]]
          })
          this.subs.push(
            this.activatedRoute.paramMap.pipe(
              map(params => params.get('programId')),
              switchMap(id => this.programsService.getProgram(id)),
              catchError(error => {
                return throwError(error);
              })
            ).subscribe(program => {
              this.program = program;
            })
          );
        } else {
          this.onEdit = false;
          this.programForm = this.fb.group({
            Name: ['', [Validators.required]],
            numberTopics: ['', [Validators.required, Validators.min(0)]],
            courseDuration: ['', [Validators.required,Validators.min(0)]],
            Teacher: ['', [Validators.required]],
            Percentage: ['', [Validators.required, Validators.min(0)]]
          });
        }


      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.programForm.controls
  }

  onVideoSelected(event) {
    this.videoUploaded = false;
    const file:File = event.target.files[0];

    if(file.type == 'video/mp4' || file.type == 'video/mov' || file.type == 'video/wmv' || file.type == 'video/avi' || file.type == 'video/flv'){
      this.viewLoaderVideo = true;
      this.fileNameVideo = 'Cargando video...';
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('videoupload' , file);
          formData.append('name' , file.name);
  
          this.subs.push(
            this.programsService.uploadVideo(formData, this.programId)
              .subscribe(resolve => {
                if (resolve.Responses== 'Este archivo se esta renderizando en Vimeo') {
                  this.responseVideo = resolve.FileUrl;
                  this.viewLoaderVideo = false;
                  this.videoUploaded = true;
                  this.previewVideo = "assets/images/check.png";
                  this.fileNameVideo = 'Video cargado correctamente';
                  // setTimeout(() => {
                  //   this.router.navigateByUrl('/products/courses/view-programs/' + this.productId);
                  // }, 1500);
                }
              })
          );
        }
      }, 1800);
    }else{
      this.NoTypeVideo()
    }
  }

  private setValues(product: any): void {
    const values = this.productService.mapRequiredValues(product);
    this.id = values.id;
    const { controls } = this.programForm;

    this.topicsProgram = product.topic.filter(item => item.Status === true);
    this.topics = [...this.topicsProgram];
    this.totalTopics = product.numberTopics;
    let teacher = this.teachers.find(item => item.firstName === product.teacher.firstName)

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.programForm.controls[value].setValue(values[value]);
      }
    }
    this.programForm.controls['Teacher'].setValue(teacher.id);
    this.programForm.controls['Percentage'].setValue(product.Percentage);
    this.prevPercent = product.Percentage;
    this.programForm.controls['Name'].setValue(product.Name);
    this.programForm.controls['numberTopics'].setValue(product.numberTopics);
    this.programForm.controls['courseDuration'].setValue(product.courseDuration);
    /* if ((product.courseDuration).toString().length < 2) {
      this.programForm.controls['courseDuration'].setValue('0' + (product.courseDuration).toString() + ':00')
    } else {
      this.programForm.controls['courseDuration'].setValue((product.courseDuration).toString() + ':00')
    } */

  }


  private buildProgram(): ProgramModel {
    return {
      Name: this.programForm.controls['Name'].value,
      numberTopics: this.programForm.controls['numberTopics'].value,
      courseDuration: this.programForm.controls['courseDuration'].value,
      teacherId: this.programForm.controls['Teacher'].value,
      Percentage: this.programForm.controls['Percentage'].value,
      productsId: Number(this.productId),
      Topics: this.topics,
    }
  }

  private buildProgramUpdated(): any {
    return {
      Name: this.programForm.controls['Name'].value,
      courseDuration: this.programForm.controls['courseDuration'].value,
      teacherId: this.programForm.controls['Teacher'].value,
      Percentage: this.programForm.controls['Percentage'].value,
      numberTopics: this.programForm.controls['numberTopics'].value,
      productsId: Number(this.productId)
    }
  }

  editTopic(topic, i){
    this.onEditTopic = true;
    this.index = i+1;
    this.valueTitle = topic.Title;
  }

  editingTopic(){
    if( this.valueTitle != "" ){
      this.topicsProgram[this.index-1].Title = this.valueTitle;
      this.onEditTopic = false;
    }else{
      this.EmptyInputsError();
    }
  }

  addTopic() {
    if (this.valueTitle === null || this.valueTitle === "") {
      this.EmptyInputsError();
    }
    else {
      this.totalTopics = this.programForm.controls['numberTopics'].value;
      if (this.totalTopics > this.topics.length) {
        this.topicsProgram.push({ Title: this.valueTitle, moduleDuration: null, Status: true });
        this.valueTitle = null;
        this.valueDuration = null;
        this.topics = [...this.topicsProgram];
      } else {
        this.NoMoreTopics();
      }
    }
  }

  removeTopic(topic) {
    const index: number = this.topicsProgram.indexOf(topic);
    this.topicsProgram[index].Status = false;
    this.topicsFalse.push(this.topicsProgram[index]);
    this.topicsProgram = this.topicsProgram.filter(item => item.Status === true);
    this.topics = [...this.topicsProgram];
  }

  public setModalContent(content: any): void {
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }


  onSubmit() {
    this.submitted = true;

    if (this.topics.length !== this.totalTopics) {
      this.AddTopicsError();
      return;
    }

    if(this.programForm.controls['courseDuration'].value > this.course.TotalHours){
      this.handleError('El total de horas del módulo es mayor al total de horas del curso');
      return;
    }

    if(!this.videoUploaded){
      this.handleError('Debes agregar un video en tu módulo')
      return;
    }

    if (this.programForm.invalid) {
      return;
    }

    if (this.onEdit) {
      if(this.programForm.controls['Percentage'].value > this.prevPercent){
        this.PercentError();
        return
      }
      this.updateProgram();
    } else {
      if(this.programForm.controls['Percentage'].value > this.percent.remaining){
        this.PercentError();
        return
      }
      this.createProgram();
    }
  }

  createProgram() {
    const program: ProgramModel = this.buildProgram();
    this.isLoading = true;

    this.subs.push(
      this.programsService.createProgram(program)
        .pipe(
          map(() => {
            this.handleProgramCreation();
            setTimeout(() => {
              this.router.navigateByUrl('/products/courses/view-programs/' + this.productId);
            }, 1800);
          }),
          catchError(error => this.handleError('No se pudo crear el módulo'))
        )
        .subscribe()
    );
  }

  updateProgram() {
    const program: any = this.buildProgramUpdated();

    let filtTopics = this.topics.filter(item => !item.id);
    let updTopics = this.topics.filter(item => item.id);
    updTopics.push(...this.topicsFalse);

    const topicAdd = {
      numberTopics: this.totalTopics,
      Topics: filtTopics
    }

    this.isLoading = true;
    this.subs.push(
      this.programsService.updateProgram(this.programId, program)
        .pipe(
          map(() => {
            if (filtTopics.length > 0) {
              this.programsService.addTopics(this.programId, topicAdd).subscribe();
            }

            updTopics.forEach(topic => {
              this.programsService.updateTopic(topic.id,topic).subscribe();
            })
            
            this.handleProgramEdition();
            setTimeout(() => {
              this.router.navigateByUrl('/products/courses/view-programs/' + this.productId);
            }, 1800);
          }),
          catchError(error => this.handleError('No se pudo crear el módulo'))
        )
        .subscribe()
    );
  }



  private EmptyInputsError() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes llenar todos los campos para agregar un tema.',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.warning(toastOptions); */

    return [];
  }

  private PercentError() {
    if(this.onEdit){
      /* const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'El porcentaje del módulo es mayor al porcentaje anterior',
        showClose: true,
        timeout: 2000
      };
  
      this.toastyService.warning(toastOptions); */
    }else{
      /* const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'El porcentaje del módulo es mayor al restante('+this.percent.remaining+'%)',
        showClose: true,
        timeout: 2000
      };
  
      this.toastyService.warning(toastOptions); */
    }

    return [];
  }

  private AddTopicsError() {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes agregar temas en tu módulo.',
      showClose: true,
      timeout: 3000
    };

    this.toastyService.error(toastOptions); */

    return [];
  }

  private NoMoreTopics() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No puedes agregar más temas de los establecidos en el módulo.',
      showClose: true,
      timeout: 3500
    };

    this.toastyService.error(toastOptions); */

    return [];
  }

  private handleProgramCreation() {
    /* const toastOptions: ToastOptions = {
      title: 'Módulo',
      msg: 'Módulo creado correctamente.',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions); */
  }

  private handleProgramEdition() {
   /*  const toastOptions: ToastOptions = {
      title: 'Módulo',
      msg: 'Módulo actualizado correctamente',
      showClose: true,
      timeout: 1700
    };
    this.toastyService.success(toastOptions); */
  }

  private handleError(error: any) {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: error,
      showClose: true,
      timeout: 2000
    };
    this.isLoading = false;
    this.toastyService.error(toastOptions); */

    return [];
  }

  private NoTypeVideo() {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un video en los siguientes formatos mov, wmv, avi, flv o mp4',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions); */
  }

}
