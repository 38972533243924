import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { UsersService } from '../../../services/users/users.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-collaborator',
  templateUrl: './list-collaborator.component.html',
  styleUrls: ['./list-collaborator.component.scss']
})
export class ListCollaboratorComponent implements OnInit, OnDestroy {
  public user_list = []
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public userId: string;
  public count: number;
  public page = 1;
  public limit = 10;
  public offset = 0;
  public deleteFilters: boolean = false;
  collaboratorFilter:any; 
  currentUser: any;
  enterprises: any[];
  enterprisesList: any[];
  collaborator: any[];
  collaboratorList: any[];
  businessname: string;
  constructor(
    private usersService: UsersService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {  
    
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
   const enterpriseId =   Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.businessname = this.usersService.getBusinessname();
    this.usersService.getEnterpriseId(this.currentUser.id).subscribe((data: any) => {
      this.collaborator = [data];
    })
    this.usersService.getListCollaborator( enterpriseId, 5, this.offset, 'firstName-asc').subscribe((data: any) => {
      this.collaboratorList = data.List;
    })

    
    let boolean = this.usersService.getfindColaborator()
      boolean = true; 
    this.usersService.setfindColaborator(boolean);
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      lengthChange: false,
      pageLength: 1000,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      retrieve: true
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public selectQuizId(quizId): void {
    this.subs.push(
      this.usersService
        .getWinner(quizId)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.user_list = data;
        })
    );
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public loadPage(page: number) {
    this.page = page;
    this.subs.push(
      this.usersService
        .getBusiness('business',this.limit, page-1)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.user_list = data.List;
          this.count = data.Count;
        })
    );
  }

}

