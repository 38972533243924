import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserModel, AuthModel, UserCreateModel } from '../../auth/auth.models';
import { NotificationType } from '../../interfaces/notification.enums';
import { UsersService } from '../../services/users/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BusinessData } from 'src/app/shared/interfaces/data.interface';

@Component({
    selector: 'app-edit-enterprise',
    templateUrl: './edit-enterprise.component.html',
    styleUrls: ['./edit-enterprise.component.scss']
})
export class EditEnterpriseComponent implements OnInit, OnDestroy {

    public values: any;
    public accountForm!: FormGroup;
    public submitted: boolean = false;
    public isLoading = false;
    public items = [];
    public selectedOption: any;
    public showForm: boolean = true;
    public showRegisterThanks: boolean = false;
    personalDetails!: FormGroup;
    private subs: Array<Subscription> = [];
    currentUserId: any;
    enterprises: any[];
    firstName: string;
    lastName: string;
    businessname: string;
    phoneNumber: string;
    email: string;
    collaborators: string;
    listEnterprise: any;
    emailMatch: boolean = false;
    public submittedSuccess: boolean = false;
    messageSuccess: string = '';
    orderText: string = 'firstName-asc';
    public rol: string;
    onEdit = false;

    constructor(
        private fb: FormBuilder,
        private authService: AuthenticationService,
        private zone: NgZone,
        private _snackBar: MatSnackBar,
        private usersService: UsersService,
        private router: Router,
        private route: ActivatedRoute
    ) {

        this.currentUserId = this.route.snapshot.paramMap.get('id');
        this.onEdit = this.currentUserId !== null ? true : false;
        console.log(this.onEdit, this.currentUserId);
        this.usersService.getEnterprise(9999, 0, 'business', this.orderText).subscribe((data: any) => {
            this.listEnterprise = data.List;
        })

    }

    ngOnInit(): void {

        if (this.onEdit) {
            this.createEditForm();
            this.usersService.getEnterpriseId(this.currentUserId).subscribe((data: any) => {
                this.enterprises = [data]
                this.firstName = data['firstName'];
                this.lastName = data['lastName'];
                this.businessname = data['businessname'];
                this.phoneNumber = data['phoneNumber'];
                this.email = data['email'];
                this.collaborators = data['collaborators'];

                this.accountForm.patchValue({
                    FirstName: this.firstName,
                    LastName: this.lastName,
                    PhoneNumber: this.phoneNumber,
                    Email: this.email,
                    BusinessName: this.businessname,
                    Colabs: this.collaborators
                });
            });
        } else {
            this.createNewForm();
        }

    }
    createEditForm() {
        this.accountForm = this.fb.group({
            FirstName: ['', Validators.required],
            LastName: [''],
            PhoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), this.validateEmail.bind(this)]],
            BusinessName: [''],
            Colabs: ['', Validators.required]
        });
    }

    createNewForm() {
        this.accountForm = this.fb.group({
            FirstName: ['', Validators.required],
            LastName: [''],
            PhoneNumber: ['', [Validators.required]],
            Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), this.validateEmail.bind(this)]],
            BusinessName: [''],
            Colabs: ['', Validators.required],
            Password: ['', Validators.required],
            newPassword: ['', Validators.required]
        });
    }

    validateEmail() {
        if (Array.isArray(this.listEnterprise) && this.accountForm && this.accountForm.controls && this.accountForm.controls['Email']) {
            const email: string = this.accountForm.controls['Email'].value;
            let emailMatch = false;
            for (let i = 0; i < this.listEnterprise.length; i++) {
                const item = this.listEnterprise[i];
                if (this.accountForm.controls['Email'].value == this.email) {
                    this.emailMatch = false;
                    break;
                }
                if (item.email === email) {
                    emailMatch = true;
                    break;
                }
            }
            this.emailMatch = emailMatch;
        }
    }

    ngOnDestroy(): void {
        let sub: Subscription;
        while (sub = this.subs.shift()) {
            sub.unsubscribe();
        }
    }

    public get formState() {
        return this.accountForm.controls;
    }

    limit10(event) {
        console.log(event.target.value);
        event.target.value = event.target.value.slice(0, 10)
        //this.accountForm.controls['PhoneNumber'].setValue();
    }

    public onSubmit(): void {
        this.isLoading = true;
        if (this.accountForm.invalid) {
            this.isLoading = false;
            this.addToast('Debes llenar los datos requeridos', 'error-snackbar');
            return;
        }
        const enterprise: BusinessData = {
            firstName: this.accountForm.controls['FirstName'].value || this.firstName,
            lastName: this.accountForm.controls['LastName'].value || this.lastName,
            email: this.accountForm.controls['Email'].value || this.email,
            phoneNumber: this.accountForm.controls['PhoneNumber'].value || this.phoneNumber,
            businessname: this.accountForm.controls['BusinessName'].value || this.businessname,
            collaborators: this.accountForm.controls['Colabs'].value || this.collaborators,
            displayName: this.accountForm.controls['FirstName'].value + ' ' + this.accountForm.controls['LastName'].value || this.firstName + ' ' + this.lastName
        }

        if (this.onEdit) {
            this.usersService.putEnterpriseId(this.currentUserId, enterprise).subscribe(data => {
                this.submittedSuccess = true
                this.addToast('Empresa modificada correctamente', 'success-snackbar');
            })
        } else {
            enterprise.password = this.accountForm.controls['Password'].value,
                enterprise.roles = 'business';
            this.authService.createUserPerson(enterprise).subscribe(data => {
                this.submittedSuccess = true
                this.addToast('Empresa creada correctamente', 'success-snackbar');
            })
        }


        setTimeout(() => {
            this.onClickRedirectLogin()
        }, 3500)
    }



    createUser() {
        const user = {
            firstName: this.accountForm.controls['FirstName'].value || this.firstName,
            lastName: this.accountForm.controls['LastName'].value || this.lastName,
            email: this.accountForm.controls['Email'].value || this.email,
            phoneNumber: this.accountForm.controls['PhoneNumber'].value || this.phoneNumber,
            businessname: this.accountForm.controls['BusinessName'].value || this.businessname,
            collaborators: this.accountForm.controls['Colabs'].value || this.collaborators,
            roles: 'business'
        }
        this.isLoading = true;
        this.usersService.putEnterpriseId(this.currentUserId, user).subscribe(data => {

        })
    }


    buildUser() {
        return {
            firstName: this.accountForm.controls['FirstName'].value,
            lastName: this.accountForm.controls['LastName'].value,
            email: this.accountForm.controls['Email'].value,
            password: this.accountForm.controls['Password'].value,
            phoneNumber: this.accountForm.controls['PhoneNumber'].value,
            businessname: this.accountForm.controls['BusinessName'].value,
            collaborators: this.accountForm.controls['Colabs'].value,
            roles: 'business'
        }

    }

    public onClickRedirectLogin(): void {
        this.router.navigate(['/empresa'])
    }

    addToast(message: any, className: string) {
        this.zone.run(() => {
            this._snackBar.open(message, null, {
                duration: 5000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: [className],
            });
        });
    }

}