import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/shared/utils/headers.functions';
import * as moment from 'moment-timezone';
import { QuizModel } from 'src/app/components/products/product.models';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class QuizsService {
  constructor(
    private http: HttpClient
  ) { }

  public getQuizs(limit:number, offset:number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/evaluation?limit=${limit}&offset=${offset}`);
  }

  public getQuiz(thisQuizId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/evaluation/${thisQuizId}`);
  }

  public createQuiz(quiz: QuizModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/evaluation`, quiz, { headers });
  }

  public updateQuiz(id: number, quiz: QuizModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/quizs/${id}`, quiz, {headers});
  }


  public getEvaluations(limit:number, offset:number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/getuserevaluation?limit=${limit}&offset=${offset}`);
  }

  public getEvaluation(thisEvaluationId: string, trie: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/examsavedbyId/${thisEvaluationId}?number=${trie}`);
  }

}
