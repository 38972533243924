import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../services/users/users.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-collaborator',
  templateUrl: './edit-collaborator.component.html',
  styleUrls: ['./edit-collaborator.component.scss'],
})
export class EditCollaboratorComponent implements OnInit, OnDestroy {
  
  public values: any;
  public accountForm!: FormGroup;
  public submitted: boolean = false;
  public isLoading = false;
  public items = [];
  public selectedOption: any;
  public showForm: boolean = true;
  public showRegisterThanks: boolean = false;
  personalDetails!: FormGroup;
  private subs: Array<Subscription> = [];
  currentUserId: any;
  enterprises: any[];
  firstName: string;
  lastName : string;
  businessname : string;
job: string;
  email : string;
  birthday: string;
  civiledo: string;
  gender: string;
  collaborators: string;
  phoneNumber: string;
  listEnterprise: any;
  emailMatch: boolean = false;
  firstNameBoolean: boolean = false;
  collaborator: any;
  civiledoSelected: string;
genderSelected: string;
public submittedSuccess: boolean = false;
messageSuccess: string = '';
 constructor( 
  private fb: FormBuilder,
  private authService: AuthenticationService,
  private usersService: UsersService,
  private router: Router,
  private route: ActivatedRoute
  ){
      this.route.params.subscribe(params => {
          this.currentUserId = params['id'];
        });


  }

  ngOnInit(): void {
    this.accountForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        job : ['', Validators.required],
        birthday: ['', Validators.required],
        gender : [''],
        civiledo: ['']
    });

      this.usersService.getUser(this.currentUserId).subscribe((data: any) => {
        this.collaborator = data;
        this.firstName = data['firstName'];
        this.lastName = data['lastName'];
        this.job = data['Job'];
        this.phoneNumber = data['phoneNumber'];
        this.email = data['email'];
        this.birthday = data['birthday'];
        this.gender = data['gender'];
        this.civiledo = data['civiledo'];
        this.civiledoSelected = this.civiledo;
        this.genderSelected = this.gender;
        this.accountForm.patchValue({
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          email: this.email,
          job: this.job,
          birthday: this.birthday,
          gender: this.gender,
        civiledo: this.civiledo
        });
      })
  }



  onSelectChangeGender(event: string) {
    this.genderSelected = event;
  }

  onSelectChangeCiviledo(event: string) {
    this.civiledoSelected = event;
  }


  validateEmail() {
       if (Array.isArray(this.listEnterprise) && this.accountForm && this.accountForm.controls && this.accountForm.controls['Email']) {
          const email: string = this.accountForm.controls['Email'].value;
          let emailMatch = false;
          for (let i = 0; i < this.listEnterprise.length; i++) {
              const item = this.listEnterprise[i];
              if(this.accountForm.controls['Email'].value == this.email){
                  this.emailMatch = false;
                  break;
              }
              if (item.email === email) {
                  emailMatch = true;
                  break;
              }
          }
          this.emailMatch = emailMatch;
      } 
  }


  ngOnDestroy(): void {
      let sub: Subscription;
      while (sub = this.subs.shift()) {
          sub.unsubscribe();
      }
  }

  public get formState() {
      return this.accountForm.controls;
  }

  public onSubmit(): void {
       if (this.accountForm.invalid) {
          return;
      } 
      const enterprise = {
        firstName: this.accountForm.controls['firstName'].value || this.firstName,
        lastName: this.accountForm.controls['lastName'].value || this.lastName,
        email: this.accountForm.controls['email'].value  || this.email,
        civiledo: this.civiledoSelected || this.civiledo,
        birthday: this.accountForm.controls['birthday'].value  || this.birthday,
        gender: this.genderSelected  || this.gender,
        Job: this.accountForm.controls['job'].value  || this.job,
        phoneNumber: this.accountForm.controls['phoneNumber'].value  || this.phoneNumber,
        roles: 'user'
    }
      this.isLoading = true;
      this.usersService.updateUser(this.currentUserId, enterprise).subscribe(data => {
        this.submittedSuccess = true
        this.messageSuccess = 'Colaborador editado correctamente'
           })
            
        setTimeout(() => {
               this.onClickRedirectLogin()
           }, 2500)
  }



  createUser() {
      const user = {
        FirstName: this.accountForm.controls['FirstName'].value || this.firstName,
        LastName: this.accountForm.controls['LastName'].value || this.lastName,
        Email: this.accountForm.controls['Email'].value  || this.email,
        civiledo: this.accountForm.controls['civiledo'].value  || this.civiledo,
        Birthday: this.accountForm.controls['Birthday'].value  || this.birthday,
        gender: this.accountForm.controls['gender'].value  || this.gender,
        Job: this.accountForm.controls['Job'].value  || this.job,
        phoneNumber: this.accountForm.controls['phoneNumber'].value  || this.phoneNumber,
        roles: 'user'
      }
      this.isLoading = true;
          this.usersService.putEnterpriseId(this.currentUserId, user).subscribe(data => {
              
              })
  }


  buildUser() {
      return {
            FirstName: this.accountForm.controls['FirstName'].value,
            LastName: this.accountForm.controls['LastName'].value,
            Email: this.accountForm.controls['Email'].value,
            civiledo: this.accountForm.controls['civiledo'].value,
            Birthday: this.accountForm.controls['Birthday'].value,
            gender: this.accountForm.controls['gender'].value,
            Job: this.accountForm.controls['Job'].value,
            phoneNumber: this.accountForm.controls['phoneNumber'].value,
            roles: 'user'
  }
}

  public onClickRedirectLogin(): void {
      this.router.navigate(['/colaboradores'])
  }

 

 /*  private handleUserCreation() {
      this.isLoading = false;
      const toastOptions: ToastOptions = {
          title: 'Registro',
          msg: 'Usuario creado correctamente',
          showClose: true,
          timeout: 1700
      };
  } */

}
