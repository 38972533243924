<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
           
            <div class="col-12">
                <div class="product-page-details product-right mb-0">
                    <h2>{{ news?.Topic }}</h2>
                    <hr>
                    <h6 class="product-title">Categoría</h6>
                    <p class="mb-0">{{ news?.Category }}</p>
                    <hr>
                    <h6 class="product-title">Publicado el</h6>
                    <p class="mb-0">{{news?.created_at}}</p>
                    <hr>
                    <h2>{{news?.Topic}}</h2>
                    <hr>
                    <h6 class="product-title">Categoría</h6>
                    <p class="mb-0">{{news?.Category}}</p>
                    <hr>
                    <h6 class="product-title">Subtemas</h6>
                    <hr>
                    <table>
                        <tbody>
                            <tr *ngFor="let sub of news?.Subtitles" class="py-4">
                                <td width="15%">{{sub?.SubTopic}}</td>
                                <td width="70%" [innerHTML]="sub?.WC.Changes"></td>
                                <td width="15%">
                                    <img width="100px" [src]="sub?.ImageURL.image" alt="{{sub?.SubTopic}}">
                                    <p>{{ sub?.ImageURL.footer }}</p>
                                </td>
                            </tr>
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11" *ngFor="let subtitle of news?.Subtitles">
                <table class="table">
                    <tbody>
                        <tr class="border-bottom">
                            <td style="width: 80%;">
                                <h3> {{ subtitle?.SubTopic }} </h3>
                            </td>
                            <td>
                                <img [src]="subtitle?.ImageURL.image" width="150px  " alt="{{ subtitle?.SubTopic }}">
                            </td>
                        </tr>
                        <tr *ngFor="let content of subtitle?.WC">
                            <td [innerHTML]="content.Changes"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
