import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProductsService } from 'src/app/shared/service/products.service';
import { WebinarModel } from '../../product.models';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { DomSanitizer } from '@angular/platform-browser';
import { UsersService } from 'src/app/components/services/users/users.service';
import * as moment from 'moment';

@Component({
  selector: 'app-digital-add',
  templateUrl: './digital-add.component.html',
  styleUrls: ['./digital-add.component.scss']
})
export class DigitalAddComponent implements OnInit {
  public productForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public productId: number;
  public id: number;

  public requiredFileType: string;
  public ImgfileName = '';
  public responseImgFile: string = '';
  public responseFile: string;
  public previewImg: any;
  public viewLoaderImg = false;
  public teachers: any;
  public categories: any;
  public subcategories: any;
  dropdownSettings = {};
  subcategoryList = [];
  subcategoriesList = [];
  subcategoriesresult: any;
  public counter: number = 1;
  public url = [{
    img: "assets/images/photo.png",
  }
  ]
  private subs: Array<Subscription> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private productService: ProductsService,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private sanitizer: DomSanitizer,
  ) {
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  onImgSelected(event) {
    const file: File = event.target.files[0];

    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      this.viewLoaderImg = true;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('profileImg', file);

          this.subs.push(
            this.productService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseImgFile = resolve.ImageUrl;
                  this.viewLoaderImg = false;
                  this.viewBase64(file).then(image => {
                    this.previewImg = image;
                  })
                  this.ImgfileName = file.name;
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })


  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'ValueAlpha',

      unSelectAllText: 'Quitar todos',
      enableCheckAll: false,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar',
      noDataAvailablePlaceholderText: 'Buscando datos'
    };
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        this.usersService
          .getTeachers(500, 0)
          .subscribe(data => {
            this.teachers = data.List.filter(item => item.active);
          })

        if (data.params.id) {
          this.productId = data.params.id;
          this.onEdit = true;
          this.productService.getProduct(data.params.id)
            .subscribe(product => this.setValues(product));
          this.productForm = this.fb.group({
            AccessType: ['', [Validators.required]],
            Category: ['', [Validators.required]],
            CourseModel: ['', [Validators.required]],
            CurrencyCode: ['', [Validators.required]],
            Description: ['', [Validators.required]],
            //Family: ['', Validators.required],
            Introduction: ['', Validators.required],
            Language: ['', Validators.required],
            LanguageSubtitles: ['', Validators.required],
            Level: ['', Validators.required],
            Objective: ['', Validators.required],
            SKU: ['', Validators.required],
            SubCategory: [''],
            TargetMarket: ['', Validators.required],
            Title: ['', Validators.required],
            TotalHours: ['', [Validators.required,Validators.min(0)]],
            StartDate: ['', Validators.required],
            EndDate: ['', Validators.required],
            UnitPrice: ['', [Validators.required, Validators.min(0)]],
            Teacher: ['', [Validators.required]],

          })
        } else {
          this.onEdit = false;
          this.productForm = this.fb.group({
            AccessType: ['', [Validators.required]],
            Category: ['', [Validators.required]],
            CourseModel: ['', [Validators.required]],
            CurrencyCode: ['', [Validators.required]],
            Description: ['', [Validators.required]],
            Introduction: ['', Validators.required],
            Language: ['', Validators.required],
            LanguageSubtitles: ['', Validators.required],
            Level: ['', Validators.required],
            Objective: ['', Validators.required],
            SKU: ['', Validators.required],
            SubCategory: [''],
            TargetMarket: ['', Validators.required],
            Title: ['', Validators.required],
            TotalHours: ['', [Validators.required, Validators.min(0)]],
            StartDate: ['', Validators.required],
            EndDate: ['', Validators.required],
            UnitPrice: ['', [Validators.required, Validators.min(0)]],
            Teacher: ['', [Validators.required]],
          })
        }
      })
    );
    this.productService
      .getCategories()
      .subscribe(data => {
        this.categories = data;
      })
    this.productService
      .getSubCategories()
      .subscribe(data => {
        this.subcategoryList = data;
      })
  }

  selectSubCategory(item: any) {
    this.subcategoriesList.push(item.ValueAlpha);
    const subList = [...this.subcategoriesList];
    this.subcategoriesresult = subList;
  }
  deselectSubCategory(item: any) {
    this.subcategoriesList = this.subcategoriesList.filter(subcategory => subcategory !== item.ValueAlpha);
    const subList = [...this.subcategoriesList];
    this.subcategoriesresult = subList;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.productForm.controls
  }

  private setValues(product: any): void {

    product.StartDate = moment(product.StartDate).format("YYYY-MM-DDTHH:mm");
    product.EndDate = moment(product.EndDate).format("YYYY-MM-DDTHH:mm");
    product.Teacher = product.user_id;

    const values = this.productService.mapRequiredValues(product);
    this.id = values.id;
    const { controls } = this.productForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.productForm.controls[value].setValue(values[value]);
      }
    }

    this.previewImg = { base: product.ImgFileUrl };

  }

  private buildCourse(): WebinarModel {
    return {
      AccessType: this.productForm.controls['AccessType'].value,
      Category: this.productForm.controls['Category'].value,
      CourseModel: this.productForm.controls['CourseModel'].value,
      CurrencyCode: this.productForm.controls['CurrencyCode'].value,
      Description: this.productForm.controls['Description'].value,
      Family: 'Webinar',
      Introduction: this.productForm.controls['Introduction'].value,
      Language: this.productForm.controls['Language'].value,
      LanguageSubtitles: this.productForm.controls['LanguageSubtitles'].value,
      Level: this.productForm.controls['Level'].value,
      Objective: this.productForm.controls['Objective'].value,
      SKU: this.productForm.controls['SKU'].value,
      SubCategory: JSON.stringify(this.subcategoriesresult),
      TargetMarket: this.productForm.controls['TargetMarket'].value,
      Title: this.productForm.controls['Title'].value,
      TotalHours: this.productForm.controls['TotalHours'].value,
      UnitMeasureCode: 'Webinar',
      UnitPrice: this.productForm.controls['UnitPrice'].value,
      StartDate: this.productForm.controls['StartDate'].value,
      EndDate: this.productForm.controls['EndDate'].value,
      ImgFileUrl: this.responseImgFile,
      user_id: this.productForm.controls['Teacher'].value,
      Active: false
    }
  }

  private buildCourseUpdated(): WebinarModel {
    return {
      id: this.id,
      AccessType: this.productForm.controls['AccessType'].value,
      Category: this.productForm.controls['Category'].value,
      CourseModel: this.productForm.controls['CourseModel'].value,
      CurrencyCode: this.productForm.controls['CurrencyCode'].value,
      Description: this.productForm.controls['Description'].value,
      Family: 'Webinar',
      Introduction: this.productForm.controls['Introduction'].value,
      Language: this.productForm.controls['Language'].value,
      LanguageSubtitles: this.productForm.controls['LanguageSubtitles'].value,
      Level: this.productForm.controls['Level'].value,
      Objective: this.productForm.controls['Objective'].value,
      SKU: this.productForm.controls['SKU'].value,
      SubCategory: JSON.stringify(this.subcategoriesresult),
      TargetMarket: this.productForm.controls['TargetMarket'].value,
      Title: this.productForm.controls['Title'].value,
      TotalHours: this.productForm.controls['TotalHours'].value,
      UnitMeasureCode: 'Webinar',
      UnitPrice: this.productForm.controls['UnitPrice'].value,
      StartDate: this.productForm.controls['StartDate'].value,
      EndDate: this.productForm.controls['EndDate'].value,
      user_id: this.productForm.controls['Teacher'].value,
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.productForm.invalid) {
      return;
    }

    if(this.responseImgFile === ''){
      this.NoImg();
      return;
    }

    if(Date.parse(this.productForm.controls['StartDate'].value) > Date.parse(this.productForm.controls['EndDate'].value)){
      this.handleDateError();
      return;
    }

    if (this.onEdit) {
      this.updateCourse();
    } else {
      this.createCourse();
    }
  }

  createCourse() {
    const course: WebinarModel = this.buildCourse();
    this.isLoading = true;

    this.subs.push(
      this.productService.createCourse(course)
        .pipe(
          map(() => {
            this.handleWebinarCreation();
            setTimeout(() => {
              this.router.navigateByUrl('products/webinars');
            }, 1800);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  updateCourse() {
    const course: WebinarModel = this.buildCourseUpdated();
    this.isLoading = true;

    this.subs.push(
      this.productService.updateCourse(this.productId, course)
        .pipe(
          map(() => {
            this.handleWebinarEdition();
            setTimeout(() => {
              this.router.navigateByUrl('products/courses');
            }, 1800);
          }),
          catchError(error => this.handleErrorEdit(error))
        )
        .subscribe()
    );
  }

  private handleWebinarCreation() {
    /* const toastOptions: ToastOptions = {
      title: 'Webinar',
      msg: 'Webinar creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions); */
  }

  private handleWebinarEdition() {
    /* const toastOptions: ToastOptions = {
      title: 'Webinar',
      msg: 'Webinar actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions); */
  }

  private handleError(error: any) {
    if (error?.error.errors[0].message === "SKU must be unique") {
      /* const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'SKU repetido',
        showClose: true,
        timeout: 2000
      };
      this.isLoading = false;
      this.toastyService.error(toastOptions); */

    } else {
      /* const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'No se pudo crear el producto',
        showClose: true,
        timeout: 2000
      };
      this.isLoading = false;
      this.toastyService.error(toastOptions); */
    }

    return [];
  }
  handleDateError(){
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'La fecha de finalización debe ser mayor a la fecha de inicio',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions); */
  }
  private NoImg() {
   /*  const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes cargar una imagen de portada',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions); */
  }

  private handleErrorEdit(error: any) {
    if (error?.error.errors[0].message === "SKU must be unique") {
      /* const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'SKU repetido',
        showClose: true,
        timeout: 2000
      };
      this.isLoading = false;
      this.toastyService.error(toastOptions); */

    } else {
     /*  const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'No se pudo editar el producto',
        showClose: true,
        timeout: 2000
      };
      this.isLoading = false;
      this.toastyService.error(toastOptions); */
    }

    return [];
  }

  private NoTypeImg() {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions); */
  }

}

