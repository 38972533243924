import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';

import { QuizsRoutingModule } from './quizs-routing.module';
import { QuizsComponent } from './quizs-list/quizs.component';
import { QuizsDetailsComponent } from './quiz-detail/quiz-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    QuizsComponent, QuizsDetailsComponent],
  imports: [
    CommonModule,
    DataTablesModule,
    QuizsRoutingModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    NgbModule
  ]
})
export class QuizsModule { }
