import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { AddNewsComponent } from './add-news/add-news.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsComponent } from './news-list/news-list.component';

const routes: Routes = [
    {
      path: '',
      children: [
        {
          path: '',
          component: NewsComponent,
          data: {
            title: "Noticias",
            breadcrumb: "Noticias"
          }
        },
        {
          path: 'add-news',
          component: AddNewsComponent,
          data: {
            title: "Agregar Noticia",
            breadcrumb: "Agregar Noticia"
          }
        },
        {
          path: 'edit/:id',
          component: AddNewsComponent,
          data: {
            title: "Editar Noticia",
            breadcrumb: "Editar Noticia"
          }
        },
        {
          path: 'show/:id',
          component: NewsDetailComponent,
          data: {
            title: "Ver Noticia",
            breadcrumb: "Ver Noticia"
          }
        }
      ]
    }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class NewsRoutingModule { }