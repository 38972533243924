import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType } from '../../interfaces/notification.enums';
import { PasswordProfileModel, UserModel } from '../../auth/auth.models';
import { UsersService } from '../../services/users/users.service';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formpassword: FormGroup;
  public submitted = false;
  public isLoading = false;
  public isLoadingPass = false;
  public submittedProfile = false;
  public onEdit = false;
  public userId: number;
  public disabled = true;
  public countOrders: number;
  public countCourses: number;

  private subs: Array<Subscription> = [];
  public user: any;
  public userImage: string;
  userRoles: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  businessname: string;
  collaborators: string;
  displayName: string
  userEmail: string;
  submittedMessage: boolean = false;
  message: string;
  submittedMessageProfile: boolean = false;
  messageProfile: string;
  submittedMessageProfileError: boolean = false;
  messageProfileError: string;
  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private authService: AuthenticationService,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
    private _usersService: UsersService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.userImage
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.userId = data.params.id;
          this.onEdit = true;
          this.authService.getUser(data.params.id)
            .subscribe(user => {
              this.setValues(user);
              this.setValuesPassword(user)
              this.user = user;
              this.userRoles = user.roles;
              this.userEmail = user.email;
              this.countOrders = 7
              this.countCourses = 12;
            })
        }
      })
    );
    this.buildUser();
    this.buildPassword();
  }

  async onFileChange2(event) {
    const file = event.target.files[0];
    const userId = this.activatedRoute.snapshot.paramMap.get('id')
    this.userImage = file.name;
    if (file) {
      const formData = new FormData();
      const filename = file.name.split(".");
      formData.append('file', file, filename.join(new Date().getTime() + '.'));
      formData.append('type', 'img');

      const resolve = await this._usersService.uploadFile(formData).toPromise();
      this.user.SignFileUrl = resolve.ImageUrl;
      const body = {
        SignFileUrl: resolve.ImageUrl
      }
      this._usersService.editUser(userId, body).subscribe((data: any) => {
        this.addToast('Imagen cargada exitosamente', 'success-snackbar');
      });
      this.ngOnInit();
    }
  }

  async onFileChange(event) {
    const file = event.target.files[0];
    const userId = this.activatedRoute.snapshot.paramMap.get('id')
    this.userImage = file.name;
    if (file) {
      const formData = new FormData();
      const filename = file.name.split(".");
      formData.append('file', file, filename.join(new Date().getTime() + '.'));
      formData.append('type', 'img');

      const resolve = await this._usersService.uploadFile(formData).toPromise();
      this.user.ImageFileUrl = resolve.ImageUrl;
      const body = {
        ImgFileUrl: resolve.ImageUrl
      }
      this._usersService.editUser(userId, body).subscribe((data: any) => {
        this.addToast('Imagen cargada exitosamente', 'success-snackbar');
      });
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private validatePasswords(): void {
    const password: string = this.formpassword.get('password').value;
    const confirmPassword: string = this.formpassword.get('confirmedPassword').value;

    if (password !== confirmPassword) {
      this.formpassword.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  public get formState(): any {
    return this.form.controls;
  }

  public get formStatePass(): any {
    return this.formpassword.controls;
  }

  public buildUser(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.pattern("[0-9]{10}")]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      businessname: ['', [Validators.required]],
      collaborators: ['', [Validators.required]],
    })
  }

  private buildUserUpdate(): UserModel {
    return {
      firstName: this.form.controls['firstName'].value ? this.form.controls['firstName'].value : this.firstName,
      lastName: this.form.controls['lastName'].value ? this.form.controls['lastName'].value : this.lastName,
      email: this.form.controls['email'].value ? this.form.controls['email'].value : this.email,
      phoneNumber: this.form.controls['phoneNumber'].value ? this.form.controls['phoneNumber'].value : this.phoneNumber,
      businessname: this.form.controls['businessname'].value ? this.form.controls['businessname'].value : this.businessname,
      collaborators: this.form.controls['collaborators'].value ? this.form.controls['collaborators'].value : this.collaborators,
      displayName: this.form.controls['firstName'].value + ' ' + this.form.controls['lastName'].value ? this.form.controls['firstName'].value + ' ' + this.form.controls['lastName'].value : this.displayName
    }
  }

  public buildPassword(): void {
    this.formpassword = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmedPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  updateUser() {
    if (this.form.invalid) {
      return;
    }
    const user: UserModel = this.buildUserUpdate();
    this.isLoading = true;
    user.image = this.userImage
    this.subs.push(
      this.userService.updateUser(this.userId, user)
        .pipe(
          map(() => {
            this.handleUserEdit();
            /* setTimeout(() => {
              window.location.reload();
            }, 1500); */
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  public onChangePassword(): void {
    this.submitted = true;
    this.validatePasswords();

    if (this.formpassword.invalid) {
      return;
    }
    const formData: PasswordProfileModel = {
      email: this.userEmail,
      password: this.formpassword.controls['password'].value
    }
    this.isLoadingPass = true;
    this.subs.push(
      this.authService.changePassword(formData)
        .pipe(catchError(err => {

          this.sendNotification('Error al cambiar la contraseña', err.error?.message, NotificationType.error);
          return throwError(err);
        }))
        .subscribe(() => this.sendNotification(null, 'Contraseña actualizada correctamente', NotificationType.success))
    );
    /* setTimeout(() => {
      window.location.reload();
    }, 1500); */
  }

  private setValues(user: any): void {
    const values = this.authService.mapRequiredValues(user);
    const { controls } = this.form;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.form.controls[value].setValue(values[value]);
      }
    }
  }

  private setValuesPassword(userpass: any): void {
    const values = this.authService.mapRequiredValuesForPassword(userpass);
    const { controls } = this.formpassword;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.formpassword.controls[value].setValue(values[value]);
      }
    }
  }

  private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {
    this.isLoadingPass = false;
    this.submittedMessage = true;
    this.message = message
    setTimeout(() => {
      this.submittedMessage = false;
      this.message = ''
      window.location.reload();
    }, 3000);
  }

  private handleUserEdit() {
    this.isLoading = false;
    this.submittedMessageProfile = true;
    this.messageProfile = 'Usuario actualizado correctamente'
    setTimeout(() => {
      this.submittedMessageProfile = false;
      this.messageProfile = ''
      window.location.reload();
    }, 3000);
  }

  private handleError(error: any) {
    this.isLoading = false;
    this.submittedMessageProfileError = true;
    this.messageProfileError = 'Ha sucedido un error intente nuevamente'
    setTimeout(() => {
      this.submittedMessageProfileError = false;
      this.messageProfileError = ''
      window.location.reload();
    }, 3000);
    return [];
  }

  addToast(message: any, className: string) {
    this.zone.run(() => {
      this._snackBar.open(message, null, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [className],
      });
    });
  }

}
