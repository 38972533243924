import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListCouponComponent } from './list-coupon/list-coupon.component';
import { CreateCouponComponent } from './create-coupon/create-coupon.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-coupons',
        component: ListCouponComponent,
        data: {
          title: "List Coupons",
          breadcrumb: "List Coupons"
        }
      },
      {
        path: 'mis-cursos',
        component: ListCouponComponent,
        data: {
          title: "Mis cursos",
          breadcrumb: "Mis cursos"
        }
      },
      {
        path: 'mis-certificaciones',
        component: ListCouponComponent,
        data: {
          title: "Mis certificaciones",
          breadcrumb: "Mis certificaciones"
        }
      },
      {
        path: 'mis-evaluaciones',
        component: ListCouponComponent,
        data: {
          title: "Mis evaluaciones",
          breadcrumb: "Mis evaluaciones"
        }
      },
      {
        path: 'empresariales',
        component: ListCouponComponent,
        data: {
          title: "Empresariales",
          breadcrumb: "Empresariales"
        }
      },
      {
        path: 'colaboradores',
        component: ListCouponComponent,
        data: {
          title: "Colaboradores",
          breadcrumb: "Colaboradores"
        }
      },
      {
        path: 'estudiantes',
        component: ListCouponComponent,
        data: {
          title: "Estudiantes",
          breadcrumb: "Estudiantes"
        }
      },
      {
        path: 'hogar',
        component: ListCouponComponent,
        data: {
          title: "Hogar",
          breadcrumb: "Hogar"
        }
      },
      {
        path: 'area-de-salud',
        component: ListCouponComponent,
        data: {
          title: "Área de salud'",
          breadcrumb: "Área de salud'"
        }
      },
      {
        path: 'brigadas',
        component: ListCouponComponent,
        data: {
          title: "Brigadas",
          breadcrumb: "Brigadas"
        }
      },
      {
        path: 'higiene',
        component: ListCouponComponent,
        data: {
          title: "Higiene",
          breadcrumb: "Higiene"
        }
      },
      {
        path: 'salud',
        component: ListCouponComponent,
        data: {
          title: "Salud",
          breadcrumb: "Salud"
        }
      },
      {
        path: 'informatica',
        component: ListCouponComponent,
        data: {
          title: "Informática",
          breadcrumb: "Informática"
        }
      },
      {
        path: 'create-coupons',
        component: CreateCouponComponent,
        data: {
          title: "Create Coupon",
          breadcrumb: "Create Coupons"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CouponsRoutingModule { }
