<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Cuenta">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm" (ngSubmit)="onSubmit()"
                                        class="needs-validation user-add" novalidate>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Nombre</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="firstName" id="validationCustom0"
                                                type="text" [ngClass]="{'error': submitted && formState?.firstName?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom1" class="">Apellido</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="lastName" id="validationCustom1"
                                                type="text" [ngClass]="{'error': submitted && formState?.lastName?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom2" class="">Celular</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="phoneNumber" id="validationCustom2"
                                                type="number" maxlength="10" [ngClass]="{'error': submitted && formState?.phoneNumber?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom3" class="">País</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="country" id="validationCustom3"
                                                type="text" [ngClass]="{'error': submitted && formState?.country?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom4" class="">Fecha de nacimiento</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" type="date" placeholder="yyyy-mm-dd" formControlName="birthday" id="validationCustom4">
                                            </div>
                                        </div>
                                        <hr>

                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom5" class="">Correo electrónico</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="email" id="validationCustom5"
                                                type="email" [ngClass]="{'error': submitted && formState?.email?.errors?.required || submitted && formState?.email?.errors?.pattern}">
                                                <span *ngIf="submitted && formState?.email?.errors" class="text-danger">
                                                    Ingresa un correo válido.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="onEdit == false">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom6" class="">Contraseña</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="password" id="validationCustom6" minlength="8"
                                                type="password" [ngClass]="{'error': submitted && formState?.password?.errors?.required || submitted && formState?.password?.errors}">
                                                <span *ngIf="submitted && formState?.password?.errors" class="text-danger">
                                                    La contraseña debe tener mínimo 8 caracteres.
                                                </span>
                                            </div>                                            
                                        </div>
                                        <div class="form-group row" *ngIf="onEdit == false">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom7" class="">Confirmar contraseña</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="confirmedPassword" id="validationCustom7" minlength="8"
                                                type="password" [ngClass]="{'error': submitted && formState?.confirmedPassword?.errors?.required || submitted && formState?.password?.errors}">
                                                <span *ngIf="submitted && formState?.confirmedPassword?.errors" class="text-danger">
                                                    Las contraseñas no coinciden.
                                                </span>
                                                <span *ngIf="submitted && formState?.confirmedPassword?.errors" class="text-danger">
                                                    La contraseña debe tener mínimo 8 caracteres.
                                                </span>
                                            </div>
                                        </div>    
                                        <div class="form-group col-12 mb-0 text-right" >
                                            <button class="btn btn-red"
                                                [disabled]="isLoading"  *ngIf="onEdit == false">
                                                {{ isLoading ? 'Creando cuenta' : 'Registrarse'}}
                                                <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                            </button>
                                            <button type="submit" class="btn btn-red" *ngIf="onEdit == true">
                                                {{ isLoading ? 'Editando cuenta' : 'Editar usuario'}}
                                                <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<!-- <ng2-toasty></ng2-toasty> -->