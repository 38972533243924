<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-3">  </div>
                <div class="col-md-6 p-0" *ngIf="!showRegisterThanks">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="../../../../assets/images/logo.png" style="width: 50%;" alt="">
                            </div>
                            <div class="pt-2 mb-4">
                                <small class="custom-font-size-forgot">Ingresa el correo electrónico que usaste para crear tu cuenta, te enviaremos un enlace a tu bandeja de entrada para que configures tu nueva contraseña.</small>
                            </div>
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="forgotPasswordForm" 
                                                (ngSubmit)="onSubmit()"
                                                class="form-horizontal auth-form" novalidate="">
                                                <div class="form-group">
                                                    <input required="" formControlName="email" type="email"
                                                        class="form-control" placeholder="Correo electrónico"
                                                        [ngClass]="{'error': submittedEmail && formState?.email?.errors?.required}">
                                                        <label class="errorRed"
                                                            *ngIf="submittedEmail && formState?.email?.errors?.pattern">
                                                            Ingresa un correo válido.
                                                        </label>
                                                </div>
                                                <button class="btn btn-primary"
                                                [disabled]="isLoading">
                                                {{ isLoading ? 'Enviando correo electrónico' : 'Recuperar contraseña'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                    <div class="row">
                        <small class="pl-3">¿Recordaste tu contraseña? <a [routerLink]="['/auth/login']">Inicia sesión</a></small>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-12 animate__animated animate__fadeIn" *ngIf="showRegisterThanks">
                    <!-- <img src="../../../../assets/images/finish-register.png" alt="" class="mb-3 border-radius-cards"> -->
                    <img src="../../../../assets/images/logo.png" style="width: 90%;" alt="">
                    <h4>
                        <br/>
                        <br/>
                        {{resolve.Message}}
                    </h4>
                </div>
                <div class="col-md-3">

                </div>
                
            </div>
            
        </div>
    </div>
</div>
