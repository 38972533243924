<div class="container-fluid">
    <div class="row">
        <div class="d-flex flex-wrap justify-content-between align-item-center w-100">
            <div class="card tab2-card custom-card-margin card-with-enterprise">
                <div class="card-body tab2-card" style="padding-left: 15px !important; padding-right: 0px !important;">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab > 
                            <ng-template ngbTabContent>
                                <h4 class="px-2">Generales</h4>
                                <hr/>
                                <div class="tab-pane fade active show"  id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-briefcase"></i>
                                            </div>
                                            <div class=" px-1">
                                                <h4 class="custom-font-size-h4">
                                                    <h5 class="custom-font-size-h5">Empresa</h5>
                                                    {{businessname}}
                                                    </h4>
                                            </div>
                                        </div>
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div  class="d-flex flex-row">
                                                <i class="fa fa-user"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Representante</h5>
                                                <h4 class="custom-font-size-h4">
                                                    {{firstName}} {{lastName}}
                                                    </h4>
                                            </div>
                                        </div>
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-envelope"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Correo electrónico</h5>
                                                <h4 class="custom-font-size-h4">
                                                    {{email}}
                                                    </h4>
                                            </div>
                                        </div>
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-phone"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Numero telefónico</h5>
                                                <h4 class="custom-font-size-h4">
                                                    {{phoneNumber}}
                                                    </h4>
                                            </div>
                                        </div>
                                       
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-users"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Total de colaboradores</h5>
                                                <h4 class="custom-font-size-h4">
                                                    {{collaborators}}
                                                    </h4>
                                            </div>
                                        </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
            <div class="card">
              </div>
            <div class="card tab2-card custom-card-margin card-with-collaborators">
                <h4 class="pl-3 pt-4">Detalle de colaborador</h4>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab> 
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show"  id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label class="">Nombre</label>
                                        </div>
                                        <div class=" col-xl-8 col-md-8">
                                            <input class="form-control"
                                            [(ngModel)]="firstName"
                                            id="validationCustom0"
                                            type="text">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label class="">Apellido</label>
                                        </div>
                                        <div class=" col-xl-8 col-md-8">
                                            <input class="form-control"
                                            [(ngModel)]="lastName"
                                            type="text">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label class="">Numero telefónico</label>
                                        </div>
                                        <div class=" col-xl-8 col-md-8">
                                            <input class="form-control" 
                                            [(ngModel)]="phoneNumber"
                                            type="number">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label for="validationCustom5" class="">Correo electrónico</label>
                                        </div>
                                        <div class=" col-xl-8 col-md-8">
                                            <input class="form-control"
                                            [(ngModel)]="email"
                                            type="email">
                                            
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label for="validationCustom2" class="">Empresa</label>
                                        </div>
                                        <div class=" col-xl-8 col-md-8">
                                            <input class="form-control" 
                                            [(ngModel)]="businessname"
                                            type="text">
                                        </div>
                                    </div>
                                    <!--<div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label class="">Colaboradores</label>
                                        </div>
                                      - <div class=" col-xl-8 col-md-8">
                                            <input class="form-control"
                                            [(ngModel)]="collaborators"
                                            type="number">
                                        </div> 
                                    </div>-->
                                    <!-- 
                                    <div class="form-group col-12 mb-0 text-right" >
                                        <button type="submit" class="btn btn-red">
                                             Editar usuario
                                           <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                        </button>
                                    </div> -->
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>