import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { SalesService } from 'src/app/shared/service/sales.service';


@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrdersDetailsComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  public order: any;
  public isCollapsed = true;
  public isCollapsedTran = true;

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject()

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private salesService: SalesService,
  ) {
  }


  ngOnInit() {
    this.subs.push(
        this.activatedRoute.paramMap.pipe(
          map(params => params.get('id')),
          switchMap(id => this.salesService.getSale(id)),
          catchError(error => {
            this.router.navigate(['/sales/orders']);
            return throwError(error);
          })
        ).subscribe(order => {
          this.order = order;
          this.dtTrigger.next();
        })
      );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
  }

}
