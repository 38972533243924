<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12 col-sm-6 centertb">
            <p class="no-mb">Total de webinars: <strong>{{totalProducts}}</strong></p>
        </div>
        <div class="col-xs-12 col-sm-6 text-right">
            <div class="action">
                <a class="btn btn-red btn-sm"
                  [routerLink]="['add-webinar']">
                  <i class="ti-plus"></i>
                  <span>Agregar Webinar</span>
                </a>
            </div>
        </div>
    </div>
    <div class="row products-admin ratio_asos">
        <div class="col-xl-4 col-sm-6" *ngFor="let product of product_list">
            <br>
            <div class="card">
                <div class="card-body product-box">
                    <div class="img-wrapper">
                        <div class="lable-block"><span class="lable3" *ngIf="product.tag == 'new'">new</span> <span
                                class="lable4" *ngIf="product.sale == 'on sale'">on sale</span></div>
                        <div class="front">
                            <a href="javascript:void(0)"><img [src]="[product.ImgFileUrl]"  *ngIf="product.ImgFileUrl !== null"
                                class="img-fluid blur-up nm lazyload bg-img img" alt=""></a>
                            <a href="javascript:void(0)"><img [src]="'../../../../../assets/images/no-image.png'" *ngIf="product.ImgFileUrl === null"
                                  class="img-fluid blur-up lazyload bg-img img" alt=""></a>
                        </div>
                    </div>
                    <div class="product-detail">
                        <h6>{{product.Title}}</h6>
                        <br>
                        <h4>{{product.UnitPrice}} {{product.CurrencyCode}} <del>{{product.UnitPrice}} {{product.CurrencyCode}}</del></h4>
                        <br>
                        <h5>{{product.SKU}}</h5>
                        <p>{{(product.Introduction.length>100)? (product.Introduction | slice:0:100)+'..':(product.Introduction)}}</p>
                        <hr>
                        <p>Fecha de inicio: {{product.StartDate | date:'dd/MM/yyyy'}}</p>
                        <p>Fecha de finalización: {{product.EndDate | date:'dd/MM/yyyy'}}</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td><i class="fa fa-university"></i></td>
                                    <td>{{product.Level}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-globe"></i></td>
                                    <td>{{product.AccessType}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-language"></i></td>
                                    <td>{{product.Language}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-clock-o"></i></td>
                                    <td>{{product.TotalHours}} horas</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-eye-slash"></i></td>
                                    <td><span [ngClass]="{'show': product.Active === true, 'hide': product.Active === false}">{{product.Active === true ? 'Publicado' : 'Oculto'}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 fright">
                            <p>
                            <button type="button" class="btn btn-primary mr-1 mat-btn" (click)="product.isCollapsed = !product.isCollapsed"
                                    [attr.aria-expanded]="product.isCollapsed">
                                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                            </button>
                            </p>
                            <div [ngbCollapse]="!product.isCollapsed">
                                <div class="product-hover">
                                    <ul class="">
                                        <li>
                                            <button [routerLink]="['show', product.id]" class="btn bt-action" type="button" data-original-title="">Ver webinar</button>
                                        </li>
                                        <li>
                                            <button [routerLink]="['edit', product.id]" class="btn bt-action" type="button" data-original-title="">Editar webinar</button>
                                        </li>
                                        <li>
                                            <button (click)="setModalContent(content, product.id, product.Active)" class="btn bt-action" type="button" data-original-title="">{{product.Active === true ? 'Ocultar webinar' : 'Publicar webinar'}}</button>
                                        </li>
                                        <li *ngIf="!product?.FileUrl">
                                            <button (click)="setModalContent(contentmodal, product.id, true)" class="btn bt-action" type="button" data-original-title="">Iniciar transmisión</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">{{active === true ? 'Ocultar webinar' : 'Publicar webinar'}}</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">{{active === true ? 'Se ocultarán todos los datos del webinar.' : 'Se publicarán todos los datos del webinar.'}}</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>{{active === true ? 'Webinar publicado correctamente' : 'Webinar publicado correctamente'}}</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button [disabled]="isLoading" type="button"
        class="btn btn-inverse btn-danger" [ngClass]="{'btn-success': active === false, 'btn-danger': active === true}"
        (click)="deleteWebinar()">{{active === true ? 'Ocultar' : 'Publicar'}}
        <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #contentmodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title text-center width-100" id="modal-title">
          URL de la transmisión
      </h4>
    </div>
    <div class="modal-body text-center">
      <ng-container>
        <p class="no-mrg">Ingresa la URL de la transmisión en vivo</p>
        <input type="text" class="form-control" #inputTransmition [(ngModel)]="transmitionUrl">
      </ng-container>
  </div>
    <ng-container>
      <div class="modal-footer">
        <a type="button"
          class="btn btn-outline-light"
          (click)="modal.close()">Cancelar</a>
        <button [disabled]="isLoading" type="button"
          class="btn btn-inverse btn-danger" [ngClass]="{'btn-success': active === false, 'btn-danger': active === true}"
          (click)="saveTransmition()">Iniciar
          <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
        </button>
      </div>
    </ng-container>
  </ng-template>
