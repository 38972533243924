<div class="page-wrapper">
    <div class="authentication-box paddd">
        <div class="container">
            <div class="row my-2 d-flex justify-content-center">
                <div class="col-md-6 p-0">
                    <div class="card tab2-card background-login login-height">
                        <div class="card-body align-items-center">
                            <div class="text-center mb-2">
                                <img src="../../../../assets/images/logo.png" style="width: 90%;" alt="">
                            </div>
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-horizontal auth-form"
                                novalidate="">
                                <div class="form-group mt-3">
                                    <label for="">Correo electrónico</label>
                                    <input required="" formControlName="email" type="email" class="form-control"
                                        placeholder="Correo electrónico" [(ngModel)]="username"
                                        [ngClass]="{'error': submittedLogin && loginForm.controls['email'].errors?.required}">
                                    <div *ngIf="submittedLogin && loginForm.controls['email'].errors?.required"
                                        class="text-danger">
                                        El email es obligatorio.
                                    </div>
                                    <div *ngIf="submittedLogin && loginForm.controls['email'].errors?.pattern"
                                        class="text-danger">
                                        Por favor, introduce un correo electrónico válido.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="">Contraseña</label>
                                    <input required="" formControlName="password"
                                        [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                        placeholder="Contraseña" [(ngModel)]="password"
                                        [ngClass]="{'error': submittedLogin && loginForm.controls['password'].errors?.required}">

                                    <span class="icons-eye" (click)="toggleFieldTextType()">
                                        <i *ngIf="fieldTextType" class="fa fa-eye" aria-hidden="true"></i>
                                        <i *ngIf="!fieldTextType" class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </span>
                                    <div *ngIf="submittedLogin && loginForm.controls['password'].errors?.required"
                                        class="text-danger">
                                        La contraseña es obligatoria.
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <ngb-toast *ngIf="submittedError" [class]="'ngb-toast bg-error'">
                                        {{messageError}}
                                    </ngb-toast>
                                    <ngb-toast *ngIf="submittedCredentials" [class]="'ngb-toast bg-error'">
                                        {{messageCredentials}}
                                    </ngb-toast>
                                </div>

                                <button type="submit" [disabled]="loginForm.invalid || username == '' && password == ''"
                                    class="button-font-size btn btn-primary" [disabled]="isLoading">
                                    {{ isLoading ? 'Cargando tu cuenta' : 'Ingresar'}}
                                    <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                </button>
                            </form>
                            <div class="row mt-3">
                                <small class="pl-3">¿Te olvidaste la contraseña? <a
                                        [routerLink]="['/auth/forgot-password']">Ingresa aquí</a></small>
                            </div>
                            <div class="row mt-3">
                                <small class="pl-3">¿No tienes cuenta? <a [routerLink]="['/auth/register']">Ingresa
                                        aquí</a></small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>