import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserModel, AuthModel, UserCreateModel } from '../../auth/auth.models';
import { NotificationType } from '../../interfaces/notification.enums';
import { UsersService } from '../../services/users/users.service';
import { NgbModal, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detail-colaborador',
  templateUrl: './detail-colaborador.component.html',
  styleUrls: ['./detail-colaborador.component.scss']
})
export class DetailCollaboratorComponent implements OnInit, OnDestroy {

  public values: any;
  public form: FormGroup;
  public submitted: boolean = false;
  public isLoading = false;
  public items = [];
  public selectedOption: any;
  public showForm: boolean = true;
  public showRegisterThanks: boolean = false;
  personalDetails!: FormGroup;
  private subs: Array<Subscription> = [];
  currentUserId: any;
  enterprises: any[];
  firstName: string;
  lastName: string;
  businessname: string;
  phoneNumber: string;
  email: string;
  collaborators: string;
  collaborator: any[];
  collaboratorList: any[];
  public deleteFilters: boolean = false;
  collaboratorFilter: any;
  perfilEnterprise: string;
  perfilColaborador: string;
  tabsBoolean: boolean = false;
  public deleteIsSuccess = false;
  public userId: string;
  public count: number;
  public page = 1;
  public limit = 10;
  public offset = 0;
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  deleteCollaborators: any;
  orderText: string = 'firstName-asc';
  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private _usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.route.params.subscribe(params => {
      this.currentUserId = params['id'];
    });


    this._usersService.setId(this.currentUserId);

    /* 
             this._usersService.getEnterpriseId(this.currentUserId.id).subscribe((data: any) => {
               this.collaborator = [data]
             }) */


    this._usersService.getEnterpriseId(this.currentUserId).subscribe((data: any) => {
      this.enterprises = [data]
      this.firstName = data['firstName'];
      this.lastName = data['lastName'];
      this.businessname = data['businessname'];

      this._usersService.setBusinessname(this.businessname)

      this.phoneNumber = data['phoneNumber'];

      this.email = data['email'];

      this.collaborators = data['collaborators'];
      console.log(this.enterprises)
    })


  }

  ngOnInit(): void {
    this.loadData();
  }

  obtenerId(id) {
    this._usersService.setidUser(id)
  }


  loadData() {
    this._usersService.getListCollaborator(this.currentUserId, 5, 0, this.orderText).subscribe((data: any) => {
      this.collaboratorList = data.List;
      console.log(this.collaboratorList);
    })
  }

  loadDataFilter() {
    this.deleteFilters = false;
    this.collaboratorFilter = '';
    this.loadData();
  }

  toogleTabs() {
    this.tabsBoolean = !this.tabsBoolean;
  }

  toogleTabsTrue() {
    this.tabsBoolean = true;
  }

  ngOnDestroy(): void {
    let sub: Subscription;
    while (sub = this.subs.shift()) {
      sub.unsubscribe();
    }
  }

  public get formState() {
    return this.personalDetails.controls;
  }


  filter($event: any) {
    this.deleteFilters = true;
    this.collaboratorList = this.collaboratorList.filter(item =>
      item.displayName.toLowerCase().includes($event.toLowerCase()) ||
      item.email.toLowerCase().includes($event.toLowerCase()) ||
      item.phoneNumber.toLowerCase().includes($event.toLowerCase())
    );
  }

  public onClickRedirectLogin(): void {
    this.router.navigate(['/enterprise'])
  }

  private handleError(error: any) {
    /* this.isLoading = false;
    const toastOptions: ToastOptions = {
        title: 'Error',
        msg: error.error.message,
        showClose: true,
        timeout: 2000
    }; */
    /* this.toastyService.error(toastOptions); */
    return [];
  }

  private handleUserCreation() {
    /*  this.isLoading = false;
     const toastOptions: ToastOptions = {
         title: 'Registro',
         msg: 'Usuario creado correctamente',
         showClose: true,
         timeout: 1700
     }; */
    /*   */
  }

  private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {
    /* const toastOptions: ToastOptions = {
        title,
        msg: message,
        showClose: true,
        timeout: 3500,
        theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions); */
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this._usersService.getUser(this.userId).subscribe((data: any) => {
      this.deleteCollaborators = [data.businessname];
    })
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteUser() {
    const user: any = this.collaboratorList.filter((item) => item.id === this.userId);
    this.subs.push(
      this._usersService.deleteUser(user[0].id)
        .subscribe(resolve => {
          if (resolve.message == 'Usuario ha sido dado de baja') {
            setTimeout(() => {
              this.modalService.dismissAll();
              this.deleteIsSuccess = false;
              this.loadData();
            }, 2000);
          }
        })
    );
  }

  public activeUser() {
    const user: any = this.collaboratorList.filter((item) => item.id === this.userId);
    this.subs.push(
      this._usersService.putEnterpriseId(user[0].id, { active: true })
        .subscribe(resolve => {
          this.deleteIsSuccess = true;
          setTimeout(() => {
            this.modalService.dismissAll();
            this.loadData();
            this.deleteIsSuccess = false;
          }, 2000);
        })
    );
  }
}