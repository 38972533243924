import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Productos"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Ventas"
    }
  },
  {
    path: 'quizs',
    loadChildren: () => import('../../components/quizs/quizs.module').then(m => m.QuizsModule),
    data: {
      breadcrumb: "Cuestionarios"
    }
  },
  {
    path: 'rallys',
    loadChildren: () => import('../../components/rallys/rallys.module').then(m => m.RallysModule),
    data: {
      breadcrumb: "Cuestionarios"
    }
  },
  {
    path: 'evaluations',
    loadChildren: () => import('../../components/evaluations/evaluations.module').then(m => m.EvaluationsModule),
    data: {
      breadcrumb: "Evaluaciones"
    }
  },
  {
    path: 'programs',
    loadChildren: () => import('../../components/programs/programs.module').then(m => m.ProgramsModule),
    data: {
      breadcrumb: "Módulos"
    }
  },
  {
    path: 'news',
    loadChildren: () => import('../../components/news/news.module').then(m => m.NewsModule),
    data: {
      breadcrumb: "Noticias"
    }
  },
  {
    path: 'wiki',
    loadChildren: () => import('../../components/wiki/wiki.module').then(m => m.WikiModule),
    data: {
      breadcrumb: "Wiki"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Menus"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Usuarios"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },
  {
    path: 'courses',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Cursos Impartidos"
    }
  },
  {
    path: 'students',
    loadChildren: () => import('../../components/students/students.module').then(m => m.StudentsModule),
    data: {
      breadcrumb: "Miembros"
    }
  },
  {
    path: 'empresa',
    loadChildren: () => import('../../../app/components/enterprise/enterprise.module').then(m => m.EnterpriseModule),
    data: {
      breadcrumb: "Empresa"
    }
  },
  {
    path: 'colaboradores',
    loadChildren: () => import('./../../components/allCollaborators/collaborators.module').then(m => m.CollaboratorsModule),
    data: {
      breadcrumb: "Colaboradores"
    }
  }
];