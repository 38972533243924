<div class="container-fluid">
    <div class="row">
        <div class="d-flex flex-wrap justify-content-between align-item-center w-100">
            <div class="card tab2-card custom-card-margin card-with-enterprise">
                <div class="card-body tab2-card" style="padding-left: 15px !important; padding-right: 0px !important;">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab > 
                            <ng-template ngbTabContent>
                                <h4 class="px-2">Generales</h4>
                                <hr/>
                                <div class="tab-pane fade active show"  id="account2" role="tabpanel"
                                    aria-labelledby="account2-tab">
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-briefcase"></i>
                                            </div>
                                            <div class=" px-1">
                                                <h4 class="custom-font-size-h4">
                                                    <h5 class="custom-font-size-h5">Nombre(s)</h5>
                                                    Matias
                                                    </h4>
                                            </div>
                                        </div>
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div  class="d-flex flex-row">
                                                <i class="fa fa-user"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Apellido(s)</h5>
                                                <h4 class="custom-font-size-h4">
                                                     Sabbadini
                                                    </h4>
                                            </div>
                                        </div>
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-envelope"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Puesto</h5>
                                                <h4 class="custom-font-size-h4">
                                                    Devs
                                                    </h4>
                                            </div>
                                        </div>
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-phone"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Estado civil</h5>
                                                <h4 class="custom-font-size-h4">
                                                    Casado
                                                    </h4>
                                            </div>
                                        </div>
                                       
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-users"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Género</h5>
                                                <h4 class="custom-font-size-h4">
                                                    masculino
                                                    </h4>
                                            </div>
                                        </div>
                                           
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-users"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Fecha de nacimiento</h5>
                                                <h4 class="custom-font-size-h4">
                                                    12/12/1989
                                                    </h4>
                                            </div>
                                        </div>
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-users"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Correo electrónico</h5>
                                                <h4 class="custom-font-size-h4">
                                                    matias@yopmail.com
                                                    </h4>
                                            </div>
                                        </div>
                                        <div class="row d-flex flex-row align-items-baseline col-xl-12 col-md-4 pb-1">
                                            <div class="d-flex flex-row">
                                                <i class="fa fa-users"></i>
                                            </div>
                                            <div class="">
                                                <h5 class="custom-font-size-h5">Numero telefónico</h5>
                                                <h4 class="custom-font-size-h4">
                                                    1234567890
                                                    </h4>
                                            </div>
                                        </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
            <div class="card card-with-collaborators">
                <div class="card-body">
                    <h4 class="px-2 pb-3">Exploracion fisica</h4>
                    <ngb-tabset>
                        <ngb-tab> <!-- {{listDataFamiliares?.Title}} *ngIf="step == 2" -->
                            <ng-template ngbTabTitle>Cabeza</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form
                                        class="needs-validation user-add" novalidate>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Deformidades</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="diabeticsBoolean">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="diabeticsBoolean">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <!-- <div class="mr-2 col-xl-8">
                                                        <select *ngIf="diabeticsBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="diabeticsChosen" name="diabeticsChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Craneo</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio" name="cardiologistsBoolean">
                                                        <label class="form-check-label">
                                                          Sí
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio" name="cardiologistsBoolean">
                                                        <label class="form-check-label">
                                                          No
                                                        </label>
                                                      </div>
                                                </div>
                                                <!-- <div class="mr-2 col-xl-8">
                                                    <select *ngIf="cardiologistsBoolean=== true" class="form-control" aria-label="Default select example" [(ngModel)]="cardiologistsChosen" name="cardiologistsChosen" [ngModelOptions]="{standalone: true}">
                                                        <option [value]='' selected>Selecciona una opción</option>
                                                        <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                      </select>
                                                </div> -->
                                            </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Cara</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio" name="neoplasticsBoolean">
                                                        <label class="form-check-label">
                                                          Sí
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio" name="neoplasticsBoolean">
                                                        <label class="form-check-label">
                                                          No
                                                        </label>
                                                      </div>
                                                </div>
                                               <!--  <div class="mr-2 col-xl-8">
                                                <select  *ngIf="neoplasticsBoolean=== true" class="form-control" aria-label="Default select example"  [(ngModel)]="neoplasticsChosen" name="neoplasticsChosen" [ngModelOptions]="{standalone: true}">
                                                    <option [value]='' selected>Selecciona una opción</option>
                                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                  </select>
                                                  </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Cuello</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio" name="venerealBoolean">
                                                        <label class="form-check-label">
                                                          Sí
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio" name="venerealBoolean">
                                                        <label class="form-check-label">
                                                          No
                                                        </label>
                                                      </div>
                                                </div>
                                                <!-- <div class="mr-2 col-xl-8">
                                                <select *ngIf="venerealBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="venerealChosen" name="venerealChosen" [ngModelOptions]="{standalone: true}">
                                                    <option [value]='' selected>Selecciona una opción</option>
                                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                  </select>
                                                </div> -->
                                            </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Piezas dentales</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex gap-custom justify-content-between col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          Sí
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          No
                                                        </label>
                                                      </div>
                                                </div>
                                                <!-- <div class="mr-2 col-xl-8">
                                                <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                                    <option [value]='' selected>Selecciona una opción</option>
                                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                  </select>
                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                      <!--   <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{allergicsFam?.Question}}</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex justify-content-between col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio" name="allergicsFamBoolean" [(ngModel)]="allergicsFamBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                        <label class="form-check-label">
                                                          Sí
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio" name="allergicsFamBoolean" [(ngModel)]="allergicsFamBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                        <label class="form-check-label">
                                                          No
                                                        </label>
                                                      </div>
                                                </div>
                                                <div class="mr-2 col-xl-8">
                                                <select *ngIf="allergicsFamBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="allergicsFamChosen" name="allergicsFamChosen" [ngModelOptions]="{standalone: true}">
                                                    <option [value]='' selected>Selecciona una opción</option>
                                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                  </select>
                                                </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="d-flex justify-content-end form-group col-12 mb-0 text-right" >
                                           <!--  <button type="submit" class="btn btn-red" (click)="restSteps()">
                                                Anterior
                                                <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                           </button> -->
                                          <!--  <div class="d-flex justify-content-center">
                                            <ngb-toast *ngIf="submittedSuccessFamiliares" [class]="'ngb-toast bg-success'">
                                                {{messageSuccessFamiliares}}
                                              </ngb-toast>
                                        </div> -->
                                            <button type="submit" class="btn btn-red">
                                                 Guardar
                                               <!-- <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab> <!-- "" *ngIf="step == 0" -->
                            <ng-template ngbTabTitle>Agudeza auditiva</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form
                                        class="needs-validation user-add" novalidate>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Oido derecho</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="diabeticBoolean">
                                                            <label class="form-check-label">
                                                              A
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="diabeticBoolean">
                                                            <label class="form-check-label">
                                                              N
                                                            </label>
                                                          </div>
                                                    </div>
                                                     <!--  <div class="mr-2 col-xl-8">
                                                        <select *ngIf="diabeticBoolean === true" class="select-option-font-size form-control" aria-label="Default select example">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Oido izquierdo</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="cardiologistBoolean">
                                                            <label class="form-check-label">
                                                              A
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="cardiologistBoolean">
                                                            <label class="form-check-label">
                                                              N
                                                            </label>
                                                          </div>
                                                    </div>
                                                   <!--    <div class="mr-2 col-xl-8">
                                                        <select *ngIf="cardiologistBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="cardiologistChosen" name="cardiologistChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                      <!--   <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{hypertensive?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="hypertensiveBoolean" [(ngModel)]="hypertensiveBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="hypertensiveBoolean" [(ngModel)]="hypertensiveBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="hypertensiveBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="hypertensiveChosen" name="hypertensiveChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{obesity?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="obesityBoolean" [(ngModel)]="obesityBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="obesityBoolean" [(ngModel)]="obesityBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="obesityBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="obesityChosen" name="obesityChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{epilepsy?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="epilepsyBoolean" [(ngModel)]="epilepsyBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="epilepsyBoolean" [(ngModel)]="epilepsyBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="epilepsyBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="epilepsyChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{surgical?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="surgicalBoolean" [(ngModel)]="surgicalBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="surgicalBoolean" [(ngModel)]="surgicalBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="surgicalBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="surgicalChosen" name="surgicalChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{venerealDiseases?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="venerealDiseasesBoolean" [(ngModel)]="venerealDiseasesBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="venerealDiseasesBoolean" [(ngModel)]="venerealDiseasesBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="venerealDiseasesBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="venerealDiseasesChosen" name="venerealDiseasesChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{traumatic?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="traumaticBoolean" [(ngModel)]="traumaticBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="traumaticBoolean" [(ngModel)]="traumaticBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="traumaticBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="traumaticChosen" name="traumaticChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{allergic?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="allergicBoolean" [(ngModel)]="allergicBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="allergicBoolean" [(ngModel)]="allergicBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="allergicBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="allergicChosen" name="allergicChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div> -->
                                       <!--  <div class="d-flex justify-content-center">
                                            <ngb-toast *ngIf="submittedSuccessPatologica" [class]="'ngb-toast bg-success'">
                                                {{messageSuccessPatologica}}
                                              </ngb-toast>
                                        </div> -->
                                        <div class="form-group col-12 mb-0 text-right" >
                                            <button type="submit" class="btn btn-red" type='submit'>
                                                Guardar
                                               <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>Columna</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form
                                        class="needs-validation user-add" novalidate>
                                            <div class="form-group row">
                                                <div class="col-xl-4 col-md-4">
                                                    <label for="validationCustom0" class="">Columna</label>
                                                </div>
                                                <div class="col-xl-8 col-md-8">
                                                    <div class="d-flex align-items-center">
                                                        <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                            <div>
                                                                <input class="form-check-input" type="radio" name="alcoholismBoolean">
                                                                <label class="form-check-label">
                                                                  A
                                                                </label>
                                                              </div>
                                                              <div>
                                                                <input check class="form-check-input" type="radio" name="alcoholismBoolean">
                                                                <label class="form-check-label">
                                                                  N
                                                                </label>
                                                              </div>
                                                        </div>
                                                         <!--  <div class="mr-2 col-xl-8">
                                                            <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                                <option [value]='' selected>Selecciona una opción</option>
                                                                <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                              </select>
                                                          </div> -->
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-4 col-md-4">
                                                    <label for="validationCustom0" class="">Escoliosis</label>
                                                </div>
                                                <div class="col-xl-8 col-md-8">
                                                    <div class="d-flex align-items-center">
                                                        <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                            <div>
                                                                <input class="form-check-input" type="radio" name="alcoholismBoolean">
                                                                <label class="form-check-label">
                                                                  Si
                                                                </label>
                                                              </div>
                                                              <div>
                                                                <input check class="form-check-input" type="radio" name="alcoholismBoolean">
                                                                <label class="form-check-label">
                                                                  No
                                                                </label>
                                                              </div>
                                                        </div>
                                                         <!--  <div class="mr-2 col-xl-8">
                                                            <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                                <option [value]='' selected>Selecciona una opción</option>
                                                                <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                              </select>
                                                          </div> -->
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-4 col-md-4">
                                                    <label for="validationCustom0" class="">Xifosis</label>
                                                </div>
                                                <div class="col-xl-8 col-md-8">
                                                    <div class="d-flex align-items-center">
                                                        <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                            <div>
                                                                <input class="form-check-input" type="radio" name="alcoholismBoolean">
                                                                <label class="form-check-label">
                                                                  Si
                                                                </label>
                                                              </div>
                                                              <div>
                                                                <input check class="form-check-input" type="radio" name="alcoholismBoolean">
                                                                <label class="form-check-label">
                                                                  No
                                                                </label>
                                                              </div>
                                                        </div>
                                                         <!--  <div class="mr-2 col-xl-8">
                                                            <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                                <option [value]='' selected>Selecciona una opción</option>
                                                                <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                              </select>
                                                          </div> -->
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-4 col-md-4">
                                                    <label for="validationCustom0" class="">Quiste pilonidal</label>
                                                </div>
                                                <div class="col-xl-8 col-md-8">
                                                    <div class="d-flex align-items-center">
                                                        <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                            <div>
                                                                <input class="form-check-input" type="radio" name="alcoholismBoolean">
                                                                <label class="form-check-label">
                                                                  Si
                                                                </label>
                                                              </div>
                                                              <div>
                                                                <input check class="form-check-input" type="radio" name="alcoholismBoolean">
                                                                <label class="form-check-label">
                                                                  No
                                                                </label>
                                                              </div>
                                                        </div>
                                                         <!--  <div class="mr-2 col-xl-8">
                                                            <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                                <option [value]='' selected>Selecciona una opción</option>
                                                                <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                              </select>
                                                          </div> -->
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                       <!--  <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{smoking?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="smokingBoolean" [(ngModel)]="smokingBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="smokingBoolean" [(ngModel)]="smokingBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="smokingBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="smokingChosen" name="smokingChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{drugAddiction?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="drugAddictionBoolean" [(ngModel)]="drugAddictionBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="drugAddictionBoolean" [(ngModel)]="drugAddictionBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="drugAddictionBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="drugAddictionChosen" name="drugAddictionChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{physicalActivity?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="physicalActivityBoolean" [(ngModel)]="physicalActivityBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="physicalActivityBoolean" [(ngModel)]="physicalActivityBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="physicalActivityBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="physicalActivityChosen" name="physicalActivityChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{healthySleep?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="healthySleepBoolean" [(ngModel)]="healthySleepBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="healthySleepBoolean" [(ngModel)]="healthySleepBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="healthySleepBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="healthySleepChosen" name="healthySleepChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{room?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="roomBoolean" [(ngModel)]="roomBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="roomBoolean" [(ngModel)]="roomBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="roomBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="roomChosen" name="roomChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{feeding?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="feedingBoolean" [(ngModel)]="feedingBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="feedingBoolean" [(ngModel)]="feedingBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="feedingBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="feedingChosen" name="feedingChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{grooming?.Question}}</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="groomingBoolean" [(ngModel)]="groomingBoolean" [value]="true" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              Sí
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="groomingBoolean" [(ngModel)]="groomingBoolean" [value]="false" [ngModelOptions]="{standalone: true}">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                      <div class="mr-2 col-xl-8">
                                                        <select *ngIf="groomingBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="groomingChosen" name="groomingChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </div> -->
                                        <!-- <div class="d-flex justify-content-center">
                                            <ngb-toast *ngIf="submittedSuccessNoPatologica" [class]="'ngb-toast bg-success'">
                                                {{messageSuccessNoPatologica}}
                                              </ngb-toast>
                                        </div> -->
                                        <div class="d-flex justify-content-end form-group col-12 mb-0 text-right">
                                            <!-- <button type="submit" class="btn btn-red" (click)="restSteps()">
                                                Anterior
                                              <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                           </button> -->
                                            <button type="submit" class="btn btn-red" type='submit'>
                                                Guardar
                                               <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab> <!-- {{listDataNoPatologicas?.Title}} *ngIf="step == 1" -->
                            <ng-template ngbTabTitle>Piel</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form
                                        class="needs-validation user-add" novalidate>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Cicatrices</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="alcoholismBoolean">
                                                            <label class="form-check-label">
                                                              Si
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="alcoholismBoolean">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                     <!--  <div class="mr-2 col-xl-8">
                                                        <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Nuevos</label>
                                            </div>
                                            <div class="col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-between gap-custom col-xl-2">
                                                        <div>
                                                            <input class="form-check-input" type="radio" name="alcoholismBoolean">
                                                            <label class="form-check-label">
                                                              Si
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <input check class="form-check-input" type="radio" name="alcoholismBoolean">
                                                            <label class="form-check-label">
                                                              No
                                                            </label>
                                                          </div>
                                                    </div>
                                                     <!--  <div class="mr-2 col-xl-8">
                                                        <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                       <!--  <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{smoking?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let smoking of smoking?.answers">
                                                    <input type="radio" 
                                                           name="smokingChosen" 
                                                           [id]="'smokingChosen' + smoking.id" 
                                                           [value]="smoking.id" 
                                                           [(ngModel)]="smokingChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'smokingChosen' + smoking.id">{{ smoking.Answer }}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                      <!--   <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{drugAddiction?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let drugAddiction of drugAddiction?.answers">
                                                    <input type="radio" 
                                                           name="drugAddictionChosen" 
                                                           [id]="'drugAddictionChosen' + drugAddiction.id" 
                                                           [value]="drugAddiction.id" 
                                                           [(ngModel)]="drugAddictionChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'drugAddictionChosen' + drugAddiction.id">{{ drugAddiction.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{physicalActivity?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let physicalActivity of physicalActivity?.answers">
                                                    <input type="radio" 
                                                           name="physicalActivityChosen" 
                                                           [id]="'physicalActivityChosen' + physicalActivity.id" 
                                                           [value]="physicalActivity.id" 
                                                           [(ngModel)]="physicalActivityChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'physicalActivityChosen' + physicalActivity.id">{{ physicalActivity.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{healthySleep?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let healthySleep of healthySleep?.answers">
                                                    <input type="radio" 
                                                           name="healthySleepChosen" 
                                                           [id]="'healthySleepChosen' + healthySleep.id" 
                                                           [value]="healthySleep.id" 
                                                           [(ngModel)]="healthySleepChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'healthySleepChosen' + healthySleep.id">{{ healthySleep.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{room?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let room of room?.answers">
                                                    <input type="radio" 
                                                           name="roomChosen" 
                                                           [id]="'roomChosen' + room.id" 
                                                           [value]="room.id" 
                                                           [(ngModel)]="roomChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'roomChosen' + room.id">{{ room.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{feeding?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let feeding of feeding?.answers">
                                                    <input type="radio" 
                                                           name="feedingChosen" 
                                                           [id]="'feedingChosen' + feeding.id" 
                                                           [value]="feeding.id" 
                                                           [(ngModel)]="feedingChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'feedingChosen' + feeding.id">{{ feeding.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{grooming?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let grooming of grooming?.answers">
                                                    <input type="radio" 
                                                           name="groomingChosen" 
                                                           [id]="'groomingChosen' + grooming.id" 
                                                           [value]="grooming.id" 
                                                           [(ngModel)]="groomingChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'groomingChosen' + grooming.id">{{ grooming.Answer }}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                       <!--  <div class="d-flex justify-content-center">
                                            <ngb-toast *ngIf="submittedSuccessNoPatologica" [class]="'ngb-toast bg-success'">
                                                {{messageSuccessNoPatologica}}
                                              </ngb-toast>
                                        </div> -->
                                        <div class="d-flex justify-content-end form-group col-12 mb-0 text-right">
                                            <!-- <button type="submit" class="btn btn-red" (click)="restSteps()">
                                                Anterior
                                              <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                           </button> -->
                                            <button type="submit" class="btn btn-red" type='submit'>
                                                Guardar
                                               <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab> <!-- {{listDataNoPatologicas?.Title}} *ngIf="step == 1" -->
                            <ng-template ngbTabTitle>Agudeza visual</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-panel fade show mt-3" role="tabpanel">
                                    <form
                                        class="needs-validation user-add" novalidate>
                                        <div class="form-group row my-2">
                                            <!-- <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Cicatrices</label>
                                            </div> -->
                                            <div class="col-xl-12 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-start align-items-center gap-custom col-xl-12" style="padding-right: 0px !important; padding-left: 0px !important;">
                                                        <div class="d-flex flex-row align-items-center custom-justify-content">
                                                            <div class="col-2">
                                                                <label class="form-check-label">
                                                                    O.D
                                                                  </label>
                                                            </div>
                                                            <div class="col-6">
                                                                <input class="form-control" type="text" name="alcoholismBoolean">
                                                            </div>
                                                          </div>
                                                          <div class="d-flex flex-row align-items-center custom-justify-content">
                                                            <div class="col-2">
                                                                <label class="form-check-label">
                                                                O.I
                                                               </label>
                                                            </div>
                                                            <div class="col-6">
                                                                <input class="form-control" type="text" name="alcoholismBoolean">
                                                            </div>
                                                          </div>
                                                          <div class="d-flex flex-row align-items-center custom-justify-content">
                                                            <div class="col-2">
                                                                <label class="form-check-label">
                                                                S/L
                                                               </label>
                                                            </div>
                                                            <div class="col-6">
                                                                <input class="form-control" type="text" name="alcoholismBoolean">
                                                            </div>
                                                          </div>
                                                    </div>

                                                     <!--  <div class="mr-2 col-xl-8">
                                                        <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="form-group row mt-2">
                                            <!-- <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Cicatrices</label>
                                            </div> -->
                                            <div class="col-xl-12 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-start align-items-center gap-custom col-xl-12" style="padding-right: 0px !important; padding-left: 0px !important;">
                                                        <div class="d-flex flex-row align-items-center custom-justify-content">
                                                            <div class="col-2">
                                                                <label class="form-check-label">
                                                                    O.D
                                                                  </label>
                                                            </div>
                                                            <div class="col-6">
                                                                <input class="form-control" type="text" name="alcoholismBoolean">
                                                            </div>
                                                          </div>
                                                          <div class="d-flex flex-row align-items-center custom-justify-content">
                                                            <div class="col-2">
                                                                <label class="form-check-label">
                                                                O.I
                                                               </label>
                                                            </div>
                                                            <div class="col-6">
                                                                <input class="form-control" type="text" name="alcoholismBoolean">
                                                            </div>
                                                          </div>
                                                          <div class="d-flex flex-row align-items-center custom-justify-content">
                                                            <div class="col-2">
                                                                <label class="form-check-label">
                                                                C/L
                                                               </label>
                                                            </div>
                                                            <div class="col-6">
                                                                <input class="form-control" type="text" name="alcoholismBoolean">
                                                            </div>
                                                          </div>
                                                    </div>
                                                    
                                                     <!--  <div class="mr-2 col-xl-8">
                                                        <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column form-group row my-4">
                                            <div class="col-xl-12 col-md-4">
                                                <label for="validationCustom0" class="">Sentido de profundidad</label>
                                            </div>
                                            <div class="col-xl-12 col-md-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex flex-row align-items-center custom-justify-content">
                                                        <div class="col-2">
                                                            <label class="form-check-label">
                                                            S/L
                                                           </label>
                                                        </div>
                                                        <div class="col-8">
                                                            <input class="form-control" type="text" name="alcoholismBoolean">
                                                        </div>
                                                      </div>
                                                      <div class="d-flex flex-row align-items-center custom-justify-content">
                                                        <div class="col-2">
                                                            <label class="form-check-label">
                                                            C/L
                                                           </label>
                                                        </div>
                                                        <div class="col-8">
                                                            <input class="form-control" type="text" name="alcoholismBoolean">
                                                        </div>
                                                      </div>
                                                     <!--  <div class="mr-2 col-xl-8">
                                                        <select *ngIf="alcoholismBoolean === true" class="select-option-font-size form-control" aria-label="Default select example" [(ngModel)]="alcoholismChosen" name="epilepsyChosen" [ngModelOptions]="{standalone: true}">
                                                            <option [value]='' selected>Selecciona una opción</option>
                                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                          </select>
                                                      </div> -->
                                                </div>
                                                
                                            </div>

                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Vision cromatica</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex gap-custom justify-content-between col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          A
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          N
                                                        </label>
                                                      </div>
                                                </div>
                                                <!-- <div class="mr-2 col-xl-8">
                                                <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                                    <option [value]='' selected>Selecciona una opción</option>
                                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                  </select>
                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Reflejos oculares</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex gap-custom justify-content-between col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          A
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          N
                                                        </label>
                                                      </div>
                                                </div>
                                                <!-- <div class="mr-2 col-xl-8">
                                                <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                                    <option [value]='' selected>Selecciona una opción</option>
                                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                  </select>
                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">O.D</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex gap-custom justify-content-between col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          A
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          N
                                                        </label>
                                                      </div>
                                                </div>
                                                <!-- <div class="mr-2 col-xl-8">
                                                <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                                    <option [value]='' selected>Selecciona una opción</option>
                                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                  </select>
                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row my-2">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">O.I</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <div class="d-flex align-items-center">
                                                <div class="d-flex gap-custom justify-content-between col-xl-2">
                                                    <div>
                                                        <input class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          A
                                                        </label>
                                                      </div>
                                                      <div>
                                                        <input check class="form-check-input" type="radio">
                                                        <label class="form-check-label">
                                                          N
                                                        </label>
                                                      </div>
                                                </div>
                                                <!-- <div class="mr-2 col-xl-8">
                                                <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                                    <option [value]='' selected>Selecciona una opción</option>
                                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                  </select>
                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                       <!--  <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{smoking?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let smoking of smoking?.answers">
                                                    <input type="radio" 
                                                           name="smokingChosen" 
                                                           [id]="'smokingChosen' + smoking.id" 
                                                           [value]="smoking.id" 
                                                           [(ngModel)]="smokingChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'smokingChosen' + smoking.id">{{ smoking.Answer }}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                      <!--   <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{drugAddiction?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let drugAddiction of drugAddiction?.answers">
                                                    <input type="radio" 
                                                           name="drugAddictionChosen" 
                                                           [id]="'drugAddictionChosen' + drugAddiction.id" 
                                                           [value]="drugAddiction.id" 
                                                           [(ngModel)]="drugAddictionChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'drugAddictionChosen' + drugAddiction.id">{{ drugAddiction.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{physicalActivity?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let physicalActivity of physicalActivity?.answers">
                                                    <input type="radio" 
                                                           name="physicalActivityChosen" 
                                                           [id]="'physicalActivityChosen' + physicalActivity.id" 
                                                           [value]="physicalActivity.id" 
                                                           [(ngModel)]="physicalActivityChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'physicalActivityChosen' + physicalActivity.id">{{ physicalActivity.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{healthySleep?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let healthySleep of healthySleep?.answers">
                                                    <input type="radio" 
                                                           name="healthySleepChosen" 
                                                           [id]="'healthySleepChosen' + healthySleep.id" 
                                                           [value]="healthySleep.id" 
                                                           [(ngModel)]="healthySleepChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'healthySleepChosen' + healthySleep.id">{{ healthySleep.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{room?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let room of room?.answers">
                                                    <input type="radio" 
                                                           name="roomChosen" 
                                                           [id]="'roomChosen' + room.id" 
                                                           [value]="room.id" 
                                                           [(ngModel)]="roomChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'roomChosen' + room.id">{{ room.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{feeding?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let feeding of feeding?.answers">
                                                    <input type="radio" 
                                                           name="feedingChosen" 
                                                           [id]="'feedingChosen' + feeding.id" 
                                                           [value]="feeding.id" 
                                                           [(ngModel)]="feedingChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'feedingChosen' + feeding.id">{{ feeding.Answer }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">{{grooming?.Question}}</label>
                                            </div>
                                            <div class="d-flex gap-custom col-xl-8 col-md-8">
                                                <div *ngFor="let grooming of grooming?.answers">
                                                    <input type="radio" 
                                                           name="groomingChosen" 
                                                           [id]="'groomingChosen' + grooming.id" 
                                                           [value]="grooming.id" 
                                                           [(ngModel)]="groomingChosen"
                                                           [ngModelOptions]="{standalone: true}">
                                                    <label [for]="'groomingChosen' + grooming.id">{{ grooming.Answer }}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                       <!--  <div class="d-flex justify-content-center">
                                            <ngb-toast *ngIf="submittedSuccessNoPatologica" [class]="'ngb-toast bg-success'">
                                                {{messageSuccessNoPatologica}}
                                              </ngb-toast>
                                        </div> -->
                                        <div class="d-flex justify-content-end form-group col-12 mb-0 text-right">
                                            <!-- <button type="submit" class="btn btn-red" (click)="restSteps()">
                                                Anterior
                                              <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                           </button> -->
                                            <button type="submit" class="btn btn-red" type='submit'>
                                                Guardar
                                               <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab> <!-- {{listDataNoPatologicas?.Title}} *ngIf="step == 1" -->
                          <ng-template ngbTabTitle>Extremidades</ng-template>
                          <ng-template ngbTabContent>
                              <div class="tab-panel fade show mt-3" role="tabpanel">
                                  <form
                                      class="needs-validation user-add" novalidate>
                                      <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label for="validationCustom0" class=""></label>
                                        </div>
                                        <div class="col-xl-8 col-md-8">
                                            <div class="d-flex align-items-center">
                                            <div class="d-flex gap-custom justify-content-between col-xl-2">
                                                <div>
                                                    <label class="form-check-label mr-5">
                                                      SUP.
                                                    </label>
                                                  </div>
                                                  <div>
                                                    <label class="form-check-label">
                                                      INF.
                                                    </label>
                                                  </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                      <div class="form-group row">
                                          <div class="col-xl-4 col-md-4">
                                              <label for="validationCustom0" class="">Integridad</label>
                                          </div>
                                          <div class="col-xl-8 col-md-8">
                                              <div class="d-flex align-items-center">
                                              <div class="d-flex gap-custom justify-content-between col-xl-2 mr-5">
                                                  <div>
                                                      <input class="form-check-input" type="radio">
                                                      <label class="form-check-label">
                                                        Si
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input check class="form-check-input" type="radio">
                                                      <label class="form-check-label">
                                                        No
                                                      </label>
                                                    </div>
                                              </div>
                                              <div class="d-flex gap-custom justify-content-between col-xl-2">
                                                <div>
                                                    <input class="form-check-input" type="radio">
                                                    <label class="form-check-label">
                                                      Si
                                                    </label>
                                                  </div>
                                                  <div>
                                                    <input check class="form-check-input" type="radio">
                                                    <label class="form-check-label">
                                                      No
                                                    </label>
                                                  </div>
                                            </div>
                                              <!-- <div class="mr-2 col-xl-8">
                                              <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                                  <option [value]='' selected>Selecciona una opción</option>
                                                  <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                                </select>
                                              </div> -->
                                              </div>
                                          </div>
                                      </div>
                                      <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label for="validationCustom0" class="">Articulaciones</label>
                                        </div>
                                        <div class="col-xl-8 col-md-8">
                                            <div class="d-flex align-items-center">
                                            <div class="d-flex gap-custom justify-content-between col-xl-2 mr-5">
                                                <div>
                                                    <input class="form-check-input" type="radio">
                                                    <label class="form-check-label">
                                                      A
                                                    </label>
                                                  </div>
                                                  <div>
                                                    <input check class="form-check-input" type="radio">
                                                    <label class="form-check-label">
                                                      N
                                                    </label>
                                                  </div>
                                            </div>
                                            <div class="d-flex gap-custom justify-content-between col-xl-2">
                                              <div>
                                                  <input class="form-check-input" type="radio">
                                                  <label class="form-check-label">
                                                    A
                                                  </label>
                                                </div>
                                                <div>
                                                  <input check class="form-check-input" type="radio">
                                                  <label class="form-check-label">
                                                    N
                                                  </label>
                                                </div>
                                          </div>
                                            <!-- <div class="mr-2 col-xl-8">
                                            <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                                <option [value]='' selected>Selecciona una opción</option>
                                                <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                              </select>
                                            </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                      <div class="col-xl-4 col-md-4">
                                          <label for="validationCustom0" class="">Arcos de movilidad</label>
                                      </div>
                                      <div class="col-xl-8 col-md-8">
                                          <div class="d-flex align-items-center">
                                          <div class="d-flex gap-custom justify-content-between col-xl-2 mr-5">
                                              <div>
                                                  <input class="form-check-input" type="radio">
                                                  <label class="form-check-label">
                                                    A
                                                  </label>
                                                </div>
                                                <div>
                                                  <input check class="form-check-input" type="radio">
                                                  <label class="form-check-label">
                                                    N
                                                  </label>
                                                </div>
                                          </div>
                                          <div class="d-flex gap-custom justify-content-between col-xl-2">
                                            <div>
                                                <input class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  A
                                                </label>
                                              </div>
                                              <div>
                                                <input check class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  N
                                                </label>
                                              </div>
                                        </div>
                                          <!-- <div class="mr-2 col-xl-8">
                                          <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                              <option [value]='' selected>Selecciona una opción</option>
                                              <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                            </select>
                                          </div> -->
                                          </div>
                                      </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="col-xl-4 col-md-4">
                                        <label for="validationCustom0" class="">Sensibilidad</label>
                                    </div>
                                    <div class="col-xl-8 col-md-8">
                                        <div class="d-flex align-items-center">
                                        <div class="d-flex gap-custom justify-content-between col-xl-2 mr-5">
                                            <div>
                                                <input class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  A
                                                </label>
                                              </div>
                                              <div>
                                                <input check class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  N
                                                </label>
                                              </div>
                                        </div>
                                        <div class="d-flex gap-custom justify-content-between col-xl-2">
                                          <div>
                                              <input class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                A
                                              </label>
                                            </div>
                                            <div>
                                              <input check class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                N
                                              </label>
                                            </div>
                                      </div>
                                        <!-- <div class="mr-2 col-xl-8">
                                        <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                            <option [value]='' selected>Selecciona una opción</option>
                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                          </select>
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-xl-4 col-md-4">
                                      <label for="validationCustom0" class="">R.T.O</label>
                                  </div>
                                  <div class="col-xl-8 col-md-8">
                                      <div class="d-flex align-items-center">
                                      <div class="d-flex gap-custom justify-content-between col-xl-2 mr-5">
                                          <div>
                                              <input class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                A
                                              </label>
                                            </div>
                                            <div>
                                              <input check class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                N
                                              </label>
                                            </div>
                                      </div>
                                      <div class="d-flex gap-custom justify-content-between col-xl-2">
                                        <div>
                                            <input class="form-check-input" type="radio">
                                            <label class="form-check-label">
                                              A
                                            </label>
                                          </div>
                                          <div>
                                            <input check class="form-check-input" type="radio">
                                            <label class="form-check-label">
                                              N
                                            </label>
                                          </div>
                                    </div>
                                      <!-- <div class="mr-2 col-xl-8">
                                      <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                          <option [value]='' selected>Selecciona una opción</option>
                                          <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                        </select>
                                      </div> -->
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-xl-4 col-md-4">
                                    <label for="validationCustom0" class="">Mano</label>
                                </div>
                                <div class="col-xl-8 col-md-8">
                                    <div class="d-flex align-items-center">
                                    <div class="d-flex gap-custom justify-content-between col-xl-2">
                                        <div>
                                            <input class="form-check-input" type="radio">
                                            <label class="form-check-label">
                                              Diestra
                                            </label>
                                          </div>
                                          <div>
                                            <input check class="form-check-input" type="radio">
                                            <label class="form-check-label">
                                              Zurda
                                            </label>
                                          </div>
                                    </div>
                                    <!-- <div class="mr-2 col-xl-8">
                                    <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                        <option [value]='' selected>Selecciona una opción</option>
                                        <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                      </select>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                                      <div class="d-flex justify-content-end form-group col-12 mb-0 text-right">
                                          <!-- <button type="submit" class="btn btn-red" (click)="restSteps()">
                                              Anterior
                                            <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                         </button> -->
                                          <button type="submit" class="btn btn-red" type='submit'>
                                              Guardar
                                             <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                          </button>
                                      </div>
                                  </form>
                              </div>
                          </ng-template>
                      </ngb-tab>
                      <ngb-tab> <!-- {{listDataNoPatologicas?.Title}} *ngIf="step == 1" -->
                        <ng-template ngbTabTitle>Torax</ng-template>
                        <ng-template ngbTabContent>
                            <div class="tab-panel fade show mt-3" role="tabpanel">
                                <form
                                    class="needs-validation user-add" novalidate>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label for="validationCustom0" class="">Region precordial</label>
                                        </div>
                                        <div class="col-xl-8 col-md-8">
                                            <div class="d-flex align-items-center">
                                            <div class="d-flex gap-custom justify-content-between col-xl-2">
                                                <div>
                                                    <input class="form-check-input" type="radio">
                                                    <label class="form-check-label">
                                                      A
                                                    </label>
                                                  </div>
                                                  <div>
                                                    <input check class="form-check-input" type="radio">
                                                    <label class="form-check-label">
                                                      N
                                                    </label>
                                                  </div>
                                            </div>
                                            <!-- <div class="mr-2 col-xl-8">
                                            <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                                <option [value]='' selected>Selecciona una opción</option>
                                                <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                              </select>
                                            </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                      <div class="col-xl-4 col-md-4">
                                          <label for="validationCustom0" class="">Campos pulmonares</label>
                                      </div>
                                      <div class="col-xl-8 col-md-8">
                                          <div class="d-flex align-items-center">
                                          <div class="d-flex gap-custom justify-content-between col-xl-2">
                                              <div>
                                                  <input class="form-check-input" type="radio">
                                                  <label class="form-check-label">
                                                    A
                                                  </label>
                                                </div>
                                                <div>
                                                  <input check class="form-check-input" type="radio">
                                                  <label class="form-check-label">
                                                    N
                                                  </label>
                                                </div>
                                          </div>
                                          <!-- <div class="mr-2 col-xl-8">
                                          <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                              <option [value]='' selected>Selecciona una opción</option>
                                              <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                            </select>
                                          </div> -->
                                          </div>
                                      </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="col-xl-4 col-md-4">
                                        <label for="validationCustom0" class="">Abdomen</label>
                                    </div>
                                    <div class="col-xl-8 col-md-8">
                                        <div class="d-flex align-items-center">
                                        <div class="d-flex gap-custom justify-content-between col-xl-2">
                                            <div>
                                                <input class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  A
                                                </label>
                                              </div>
                                              <div>
                                                <input check class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  N
                                                </label>
                                              </div>
                                        </div>
                                        <!-- <div class="mr-2 col-xl-8">
                                        <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                            <option [value]='' selected>Selecciona una opción</option>
                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                          </select>
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-xl-4 col-md-4">
                                      <label for="validationCustom0" class="">Visceromenglias</label>
                                  </div>
                                  <div class="col-xl-8 col-md-8">
                                      <div class="d-flex align-items-center">
                                      <div class="d-flex gap-custom justify-content-between col-xl-2">
                                          <div>
                                              <input class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                Si
                                              </label>
                                            </div>
                                            <div>
                                              <input check class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                No
                                              </label>
                                            </div>
                                      </div>
                                      <!-- <div class="mr-2 col-xl-8">
                                      <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                          <option [value]='' selected>Selecciona una opción</option>
                                          <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                        </select>
                                      </div> -->
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-xl-4 col-md-4">
                                    <label for="validationCustom0" class="">Hernias</label>
                                </div>
                                <div class="col-xl-8 col-md-8">
                                    <div class="d-flex align-items-center">
                                    <div class="d-flex gap-custom justify-content-between col-xl-2">
                                        <div>
                                            <input class="form-check-input" type="radio">
                                            <label class="form-check-label">
                                              Si
                                            </label>
                                          </div>
                                          <div>
                                            <input check class="form-check-input" type="radio">
                                            <label class="form-check-label">
                                              No
                                            </label>
                                          </div>
                                    </div>
                                    <!-- <div class="mr-2 col-xl-8">
                                    <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                        <option [value]='' selected>Selecciona una opción</option>
                                        <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                      </select>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-xl-4 col-md-4">
                                  <label for="validationCustom0" class="">Genitourinario</label>
                              </div>
                              <div class="col-xl-8 col-md-8">
                                  <div class="d-flex align-items-center">
                                  <div class="d-flex gap-custom justify-content-between col-xl-2">
                                      <div>
                                          <input class="form-check-input" type="radio">
                                          <label class="form-check-label">
                                            A
                                          </label>
                                        </div>
                                        <div>
                                          <input check class="form-check-input" type="radio">
                                          <label class="form-check-label">
                                            N
                                          </label>
                                        </div>
                                  </div>
                                  <!-- <div class="mr-2 col-xl-8">
                                  <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                      <option [value]='' selected>Selecciona una opción</option>
                                      <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                    </select>
                                  </div> -->
                                  </div>
                              </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-xl-4 col-md-4">
                                <label for="validationCustom0" class="">Region perineal</label>
                            </div>
                            <div class="col-xl-8 col-md-8">
                                <div class="d-flex align-items-center">
                                <div class="d-flex gap-custom justify-content-between col-xl-2">
                                    <div>
                                        <input class="form-check-input" type="radio">
                                        <label class="form-check-label">
                                          A
                                        </label>
                                      </div>
                                      <div>
                                        <input check class="form-check-input" type="radio">
                                        <label class="form-check-label">
                                          N
                                        </label>
                                      </div>
                                </div>
                                <!-- <div class="mr-2 col-xl-8">
                                <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                    <option [value]='' selected>Selecciona una opción</option>
                                    <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                  </select>
                                </div> -->
                                </div>
                            </div>
                        </div>
                                    <div class="d-flex justify-content-end form-group col-12 mb-0 text-right">
                                        <!-- <button type="submit" class="btn btn-red" (click)="restSteps()">
                                            Anterior
                                          <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                       </button> -->
                                        <button type="submit" class="btn btn-red" type='submit'>
                                            Guardar
                                           <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab> <!-- {{listDataNoPatologicas?.Title}} *ngIf="step == 1" -->
                      <ng-template ngbTabTitle>Sistema nervioso</ng-template>
                      <ng-template ngbTabContent>
                          <div class="tab-panel fade show mt-3" role="tabpanel">
                              <form
                                  class="needs-validation user-add" novalidate>
                                  <div class="form-group row">
                                      <div class="col-xl-4 col-md-4">
                                          <label for="validationCustom0" class="">Coordinacion</label>
                                      </div>
                                      <div class="col-xl-8 col-md-8">
                                          <div class="d-flex align-items-center">
                                          <div class="d-flex gap-custom justify-content-between col-xl-2">
                                              <div>
                                                  <input class="form-check-input" type="radio">
                                                  <label class="form-check-label">
                                                    A
                                                  </label>
                                                </div>
                                                <div>
                                                  <input check class="form-check-input" type="radio">
                                                  <label class="form-check-label">
                                                    N
                                                  </label>
                                                </div>
                                          </div>
                                          <!-- <div class="mr-2 col-xl-8">
                                          <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                              <option [value]='' selected>Selecciona una opción</option>
                                              <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                            </select>
                                          </div> -->
                                          </div>
                                      </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="col-xl-4 col-md-4">
                                        <label for="validationCustom0" class="">Sensibilidad</label>
                                    </div>
                                    <div class="col-xl-8 col-md-8">
                                        <div class="d-flex align-items-center">
                                        <div class="d-flex gap-custom justify-content-between col-xl-2">
                                            <div>
                                                <input class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  A
                                                </label>
                                              </div>
                                              <div>
                                                <input check class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  N
                                                </label>
                                              </div>
                                        </div>
                                        <!-- <div class="mr-2 col-xl-8">
                                        <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                            <option [value]='' selected>Selecciona una opción</option>
                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                          </select>
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-xl-4 col-md-4">
                                      <label for="validationCustom0" class="">Equilibrio</label>
                                  </div>
                                  <div class="col-xl-8 col-md-8">
                                      <div class="d-flex align-items-center">
                                      <div class="d-flex gap-custom justify-content-between col-xl-2">
                                          <div>
                                              <input class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                A
                                              </label>
                                            </div>
                                            <div>
                                              <input check class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                N
                                              </label>
                                            </div>
                                      </div>
                                      <!-- <div class="mr-2 col-xl-8">
                                      <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                          <option [value]='' selected>Selecciona una opción</option>
                                          <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                        </select>
                                      </div> -->
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-xl-4 col-md-4">
                                    <label for="validationCustom0" class="">Marcha</label>
                                </div>
                                <div class="col-xl-8 col-md-8">
                                    <div class="d-flex align-items-center">
                                    <div class="d-flex gap-custom justify-content-between col-xl-2">
                                        <div>
                                            <input class="form-check-input" type="radio">
                                            <label class="form-check-label">
                                              A
                                            </label>
                                          </div>
                                          <div>
                                            <input check class="form-check-input" type="radio">
                                            <label class="form-check-label">
                                              N
                                            </label>
                                          </div>
                                    </div>
                                    <!-- <div class="mr-2 col-xl-8">
                                    <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                        <option [value]='' selected>Selecciona una opción</option>
                                        <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                      </select>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                                  <div class="d-flex justify-content-end form-group col-12 mb-0 text-right">
                                      <!-- <button type="submit" class="btn btn-red" (click)="restSteps()">
                                          Anterior
                                        <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                     </button> -->
                                      <button type="submit" class="btn btn-red" type='submit'>
                                          Guardar
                                         <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                      </button>
                                  </div>
                              </form>
                          </div>
                      </ng-template>
                  </ngb-tab>
                  <ngb-tab> <!-- {{listDataNoPatologicas?.Title}} *ngIf="step == 1" -->
                    <ng-template ngbTabTitle>Sistema vascular perif.</ng-template>
                    <ng-template ngbTabContent>
                        <div class="tab-panel fade show mt-3" role="tabpanel">
                            <form
                                class="needs-validation user-add" novalidate>
                                <div class="form-group row">
                                    <div class="col-xl-4 col-md-4">
                                        <label for="validationCustom0" class="">Insuf. venosa</label>
                                    </div>
                                    <div class="col-xl-8 col-md-8">
                                        <div class="d-flex align-items-center">
                                        <div class="d-flex gap-custom justify-content-between col-xl-2">
                                            <div>
                                                <input class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  Si
                                                </label>
                                              </div>
                                              <div>
                                                <input check class="form-check-input" type="radio">
                                                <label class="form-check-label">
                                                  No
                                                </label>
                                              </div>
                                        </div>
                                        <!-- <div class="mr-2 col-xl-8">
                                        <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                            <option [value]='' selected>Selecciona una opción</option>
                                            <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                          </select>
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-xl-4 col-md-4">
                                      <label for="validationCustom0" class="">Ulceraciones</label>
                                  </div>
                                  <div class="col-xl-8 col-md-8">
                                      <div class="d-flex align-items-center">
                                      <div class="d-flex gap-custom justify-content-between col-xl-2">
                                          <div>
                                              <input class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                Si
                                              </label>
                                            </div>
                                            <div>
                                              <input check class="form-check-input" type="radio">
                                              <label class="form-check-label">
                                                No
                                              </label>
                                            </div>
                                      </div>
                                      <!-- <div class="mr-2 col-xl-8">
                                      <select *ngIf="epilepticsBoolean === true" class="form-control" aria-label="Default select example" [(ngModel)]="epilepticsChosen" name="epilepticsChosen" [ngModelOptions]="{standalone: true}">
                                          <option [value]='' selected>Selecciona una opción</option>
                                          <option *ngFor="let item of arrayFamiliares" value="{{item}}">{{item}}</option>
                                        </select>
                                      </div> -->
                                      </div>
                                  </div>
                              </div>
                                <div class="d-flex justify-content-end form-group col-12 mb-0 text-right">
                                    <!-- <button type="submit" class="btn btn-red" (click)="restSteps()">
                                        Anterior
                                      <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> 
                                   </button> -->
                                    <button type="submit" class="btn btn-red" type='submit'>
                                        Guardar
                                       <!--  <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i> -->
                                    </button>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>