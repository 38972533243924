import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuizsService } from 'src/app/shared/service/quizs.service';
import { RallysService } from 'src/app/shared/service/rallys.service';
import * as moment from 'moment';
import { AccountService } from 'src/app/shared/service/authentication.service';
import { timer } from 'rxjs';

@Component({
    templateUrl: './rally.component.html',
    styleUrls: ['./rally.component.scss']
})
export class RallysComponent implements OnInit, OnDestroy {
    public quizs = [];

    private subs: Array<Subscription> = [];
    private modalContent: any;
    private modalConfig: any = {
        ariaLabelledBy: 'modal-basic'
    };

    public quizId: number;
    public form: FormGroup;
    public count: number;
    public page = 1;
    public limit = 10;
    public offset = 0;
    public rally: any;
    public showStageOne: boolean;
    public showSelectQuiz: boolean = false;
    public showQuiz: boolean = false;
    public showStageTwo: boolean = false;
    public showRally: boolean = false;
    public showStageFinish: boolean = false;
    public test: any;
    public currentQuestion: number;
    public error: string;
    public isLoading: boolean = false;
    public timeLeft: number = 0;
    public timeLeftPause: number = 0;
    public subscribeTimer: any;
    public finishTime: any;
    public formattedTimeDuration;
    public interval;
    public showButtonRally: any;
    public rallyQuiz: any;
    public step: number = 1;
    public codeForm: FormGroup;
    public job: string;

    constructor(
        private rallysService: RallysService,
        private authService: AuthenticationService,
        private formBuilder: FormBuilder,
    ) {
        this.job = this.authService.currentUserValue.Job
    }

    ngOnInit() {
        this.showStageFinish = false;
       /*  this.subs.push(
            this.rallysService
                .getRally()
                .pipe(
                    catchError(err => this.authService.handleUnauthorizedError(err))
                )
                .subscribe(data => {
                    this.rally = data;
                    console.log(this.rally)
                    this.quizs = data.stages[0].quizes.filter(x => x.Type === 'Quiz');
                    this.showButtonRally = this.quizs.find(el => el.Answered === false);
                    this.rallyQuiz = data.stages[0].quizes.filter(x => x.Type === 'Rally');
                    const answeredQuiz = this.quizs.find(el => el.Answered === true);

                    if(answeredQuiz !== undefined){
                        this.showStageOne = false;
                        this.showSelectQuiz = true;
                        return;
                    }

                    this.showStageOne = true;
                })
        ); */

        this.codeForm = this.formBuilder.group({
            authCode: ['', Validators.required]
        })
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    public get formState() {
        return this.codeForm.controls;
    }

    public nextInstructionClick(step: number): void {
        switch (step) {
			case 1: {
                this.showStageOne = false;
                this.step = 2;
				break;
			}
			case 2: {
				this.step = 3;
				break;
			}
			case 3: {
				this.step = 4;
                break;
			}
			case 4: {
				this.step = 5;
				break;
			}
			case 5: {
				this.starQuiz();
				break;
			}
		}
    }

    public starQuiz(): void {
        this.showStageOne = false;
        this.step = 6;
        this.showSelectQuiz = true;
    }

    public viewStageTwo(): void {
        this.showSelectQuiz = false;
        this.showStageTwo = true;
    }

    public startRally(): void {
        this.showStageTwo = false;
        this.showSelectQuiz = false;
        this.subs.push(
            this.rallysService
                .getQuiz(this.rallyQuiz[0].id)
                .pipe(
                    catchError(err => this.authService.handleUnauthorizedError(err))
                )
                .subscribe(data => {
                    this.currentQuestion = 0;
                    this.test = data;
                    this.showRally = true;
                })
        );
        this.observableTimer();
        const init = setTimeout(() => {
            this.startTimer();
        }, 1000)
    }

    public finishRally(): void {
        this.ngOnInit();
    }

    public observableTimer() {
        const source = timer(1000, 1000);
        const x = source.subscribe(val => {
          this.subscribeTimer = this.timeLeft + val;
          const duration = moment.duration(this.subscribeTimer, 'seconds');
          this.formattedTimeDuration = moment.utc(duration.as('milliseconds')).format('mm:ss');
        });
    }

    startTimer() {
        this.interval = setInterval(() => {
            if(this.timeLeftPause > 0) {
              this.timeLeftPause++;
            } else {
              this.timeLeftPause = 1;
            }
          },1000)
      }

    stopTimer() {
        clearInterval(this.interval);
    }

    public onStartQuizClick(id: number): void {
        this.showSelectQuiz = false;
        this.subs.push(
            this.rallysService
                .getQuiz(id)
                .pipe(
                    catchError(err => this.authService.handleUnauthorizedError(err))
                )
                .subscribe(data => {
                    this.currentQuestion = 0;
                    this.test = data;
                    this.showQuiz = true;
                })
        );
    }

    public nextQuestion() {
        this.error = '';
        let currentQ = this.test.questions[this.currentQuestion];
        let questions = document.getElementsByName(currentQ.id);
        let i;
        for (i = 0; i < questions.length; i++) {
          let answer = questions[i] as HTMLInputElement;
          if (answer.checked) break;

        }
        if (i != questions.length) {
          this.currentQuestion++;
        } else {
          this.error = 'Por favor responda la pregunta.'
        }
    }
    
    public getResponses() {
        let userResponses = [];
        let questions = document.getElementsByClassName("inputQuestion");
        for (let x = 0; x < questions.length; x++) {
            let answer = questions[x] as HTMLInputElement;

            if (answer.type === 'radio' && answer.checked) {
                let response = { QuestionId: Number(answer.name), AnswerId: Number(answer.value), Answers: [] }
                userResponses.push(response);
            }
            if (answer.type === 'checkbox' && answer.checked) {
            const index = userResponses.find(el => el.QuestionId == answer.name);
                if (index) {
                    index.Answers.push(Number(answer.value));
                }
                else{
                    let response = { QuestionId: Number(answer.name), Answers: [Number(answer.value)] }
                    userResponses.push(response);
                }
            }
        }
        return userResponses;
    }

    public saveExam(type: string): void {
        let userEvaluation = {
            QuizTime: this.formattedTimeDuration ? this.formattedTimeDuration : null,
            QuizId: this.test.id,
            UserId: this.authService.currentUserValue.id,
            authCode: this.codeForm.controls['authCode'].value ? Number(this.codeForm.controls['authCode'].value) : null,
            answers: this.getResponses()
        }
        this.isLoading = true;
        
        if (type === 'quiz'){

            if (this.codeForm.invalid) {
                this.isLoading = false;
                return;
            }

            this.subs.push(
                this.rallysService.createExam(userEvaluation)
                .subscribe(resolve => {
                    if(resolve.message === 'Código de autorización incorrecto'){
                        this.error = resolve.message;
                        this.isLoading = false;
                        return;
                    }
                    
                    if(resolve){
                        this.handleEvaluationCreation();
                        setTimeout(() => {
                            this.ngOnInit();
                            this.showQuiz = false;
                            this.showSelectQuiz = true;
                            this.isLoading = false;
                        }, 2000);
                    }
                }, error => {
                this.handleError(error);
                this.isLoading = false;
                })
            );
        }
        if (type === 'rally'){
            this.stopTimer();
            this.subs.push(
                this.rallysService.createExam(userEvaluation)
                .subscribe(resolve => {
                    if(resolve){
                        const duration = moment.duration(this.timeLeftPause, 'seconds');
                        this.finishTime = moment.utc(duration.as('milliseconds')).format('mm:ss');
                        this.showRally = false;
                        this.showStageFinish = true;
                        this.isLoading = false;
                    }
                }, error => {
                    this.handleError(error);
                    this.isLoading = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1800);
                })
            );
        }
    }

    private handleEvaluationCreation() {
     
    }

    private handleError(error: any) {
      
    }

}