import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-physicalExamination',
    templateUrl: './physicalExamination.component.html',
    styleUrls: ['./physicalExamination.component.scss']
  })
  export class PhysicalExaminationComponent implements OnInit{
    constructor(){

    }

    ngOnInit(): void {
        
    }
  }