<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="px-3 pb-3">
                <h4 class="px-2">Nuevo colaborador</h4>
              </div>
            <div class="card tab2-card">
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab>
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm" (ngSubmit)="onSubmit()"
                                        class="needs-validation user-add" novalidate>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Nombre(s)</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="firstName"
                                                placeholder="Nombre(s)"
                                                type="text" [ngClass]="{'error': accountForm.controls['firstName'].touched && accountForm.controls['firstName'].errors?.required
                                            }">
                                            <div *ngIf="accountForm.controls['firstName'].touched && accountForm.controls['firstName'].errors?.required" class="text-danger">
                                                El nombre es obligatorio.
                                              </div>
                                            </div>
                                            
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Apellido(s)</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="lastName"
                                                placeholder="Apellido(s)"
                                                type="text" [ngClass]="{'error': accountForm.controls['lastName'].touched && accountForm.controls['lastName'].errors?.required
                                            }">
                                            <div *ngIf="accountForm.controls['lastName'].touched && accountForm.controls['lastName'].errors?.required" class="text-danger">
                                                El apellido es obligatorio.
                                              </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Puesto</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="job"
                                                placeholder="Puesto"
                                                type="text" [ngClass]="{'error': accountForm.controls['job'].touched && accountForm.controls['job'].errors?.required
                                            }">
                                             <div *ngIf="accountForm.controls['job'].touched && accountForm.controls['job'].errors?.required" class="text-danger">
                                                El puesto es obligatorio.
                                              </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Estado civil</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <select name="civiledo" class="form-control">
                                                    <option value="" selected>Selecciona una opción</option>
                                                    <option value="masculino">Casado/a</option>
                                                    <option value="femenino">Soltero/a</option>
                                                    <option value="femenino">Separado/a</option>
                                                    <option value="femenino">Viudo/a</option>
                                                  </select>
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Genero</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <select name="gender" class="form-control">
                                                    <option value="" selected>Selecciona una opción</option>
                                                    <option value="masculino">Masculino</option>
                                                    <option value="femenino">Femenino</option>
                                                    <option value="otro">Otro</option>
                                                  </select>
                                            </div>
                                          </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Fecha de nacimiento</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" type="date"
                                                placeholder="Fecha de nacimiento"
                                                formControlName="birthday" [ngClass]="{'error': accountForm.controls['birthday'].touched && accountForm.controls['birthday'].errors?.required
                                            }">
                                            <div *ngIf="accountForm.controls['birthday'].touched && accountForm.controls['birthday'].errors?.required" class="text-danger">
                                                La fecha es obligatoria.
                                              </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Correo electrónico</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="email"
                                                placeholder="Correo electrónico"
                                                type="email" [ngClass]="{'error': accountForm.controls['email'].touched && accountForm.controls['email'].errors?.required
                                            }"/>
                                                <span *ngIf="accountForm.controls['email'].touched && accountForm.controls['email'].errors" class="text-danger">
                                                    Ingresa un correo válido.
                                                </span>
                                                <div *ngIf="accountForm.controls['email'].touched && accountForm.controls['email'].errors?.required" class="text-danger">
                                                    El correo es obligatorio.
                                                  </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label class="">Numero telefónico</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="phoneNumber"
                                                placeholder="Numero telefónico"
                                                type="tel" maxlength="10" [ngClass]="{'error': accountForm.controls['phoneNumber'].touched && accountForm.controls['phoneNumber'].errors?.required
                                            }"/>
                                                <div *ngIf="accountForm.controls['phoneNumber'].touched && accountForm.controls['phoneNumber'].errors?.required" class="text-danger">
                                                    El numero telefónico es obligatorio.
                                                  </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-12 mb-0 text-right" >
                                            <button class="btn btn-red"
                                            type="submit" (click)="createUser()"
                                                >
                                                 Guardar
                                                <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<!-- <ng2-toasty></ng2-toasty> -->