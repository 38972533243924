<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12 col-sm-6 centertb">
            <p class="no-mb">Total de noticias: <strong>{{news.length}}</strong></p>
        </div>
        <div class="col-xs-12 col-sm-6 text-right">
            <div class="action">
                <a class="btn btn-red btn-sm"
                  [routerLink]="['add-news']">
                  <i class="ti-plus"></i>
                  <span>Agregar Noticia</span>
                </a>
            </div>
        </div>
    </div>
    <div class="row products-admin ratio_asos">
        <div class="col-xl-4 col-sm-6" *ngFor="let new of news">
            <br>
            <div class="card">
                <div class="card-body product-box">
                    <div class="img-wrapper">
                        <div class="front">
                            <a href="javascript:void(0)"><img [src]="[new.ImageUrl]" *ngIf="new.ImageUrl !== null"
                                class="img-fluid blur-up lazyload bg-img img" alt=""></a>
                            <a href="javascript:void(0)"><img [src]="'../../../../../assets/images/no-image.png'" *ngIf="new.ImageUrl === null"
                                  class="img-fluid blur-up lazyload bg-img img" alt=""></a>
                    </div>
                    </div>
                    <div class="product-detail">
                        <h6>{{new.Title}}</h6>
                        <br>
                        <p class="intro" [innerHTML]="new.ShortDescription.slice(0,250).concat('...')"></p>
                        <br>
                        <hr>
                        <table>
                            <tbody>
                                <tr>
                                    <td><i class="fa fa-id-card-o"></i></td>
                                    <td>{{new.Type}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-user"></i></td>
                                    <td>{{new.Author}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-copyright"></i></td>
                                    <td>{{new.Source}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-eye-slash"></i></td>
                                    <td><span [ngClass]="{'show': new.Active === true, 'hide': new.Active === false}">{{new.Active === true ? 'Publicado' : 'Oculto'}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 fright">
                            <p>
                                <button type="button" class="btn btn-primary mr-1 mat-btn" (click)="new.isCollapsed = !new.isCollapsed"
                                    [attr.aria-expanded]="new.isCollapsed">
                                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                </button>
                            </p>
                            <div [ngbCollapse]="!new.isCollapsed">
                                <div class="product-hover">
                                    <ul class="">
                                        <li>
                                            <button [routerLink]="['show', new.id]" class="btn bt-action" type="button" data-original-title="">Ver noticia</button>
                                        </li>
                                        <li>
                                            <button [routerLink]="['edit', new.id]" class="btn bt-action" type="button" data-original-title="">Editar noticia</button>
                                        </li>
                                        <li>
                                            <button (click)="setModalContent(content, new.id, new.Active)" class="btn bt-action" type="button" data-original-title="">{{new.Active === true ? 'Ocultar noticia' : 'Mostrar noticia'}}</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">{{active === true ? 'Ocultar noticia' : 'Publicar noticia'}}</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">{{active === true ? 'Se ocultarán todos los datos de la noticia.' : 'Se publicarán todos los datos de la noticia.'}}</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>{{active === true ? 'Noticia publicada correctamente' : 'Noticia mostrada correctamente'}}</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger" [ngClass]="{'btn-success': active === false, 'btn-danger': active === true}"
        (click)="deleteCourse()">{{active === true ? 'Ocultar' : 'Publicar'}}</button>
    </div>
  </ng-container>
</ng-template>
