<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Mis cursos impartidos</h5>
                </div>
                <div class="card-body">
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <div class="table-responsive">
                                <table 
                                class=" table table-lg table-hover row-border hover">
                                <thead>
                                    <tr>
                                        <th>Familia</th>
                                        <th>Nombre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of invoice">
                                    <td>{{user.Family == 'Course' ? 'Curso' : 'Capacitación'}}</td>
                                    <td>{{user.Title}}</td>
                                    </tr>
                                </tbody>
                                </table>
                               <!--  <ngb-pagination [collectionSize]="10" [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
