import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../services/users/users.service';

@Component({
  selector: 'app-detail-collaborator',
  templateUrl: './detail-collaborator.component.html',
  styleUrls: ['./detail-collaborator.component.scss'],
})
export class DetailCollaboratorComponent implements OnInit, OnDestroy {
  
    currentUserId: any;
    collaborator: any;
    firstName: string;
    lastName: string;
    businessname: string;
    phoneNumber: string;
    email: string;
    collaborators: any;
  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ){
    this.route.params.subscribe(params => {
      this.currentUserId = params['id'];
    });
  }
  
    ngOnInit(): void {
      this.usersService.getEnterpriseId(this.currentUserId).subscribe((data: any) => {
        this.collaborator = [data]
        this.firstName = data['firstName'];
        this.lastName = data['lastName'];
        this.businessname = data['businessname'];
        this.phoneNumber = data['phoneNumber'];
        this.email = data['email'];
        this.collaborators = data['collaborators'];
      })

      this.usersService.getUser(this.currentUserId).subscribe((data: any) => {
        const user = data;
      })
    }

  ngOnDestroy(): void {}
}
