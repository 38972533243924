import { Component, OnInit, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserModel, AuthModel, UserCreateModel } from '../auth.models';
import { NotificationType } from '../../interfaces/notification.enums';
import { NgbToast, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { UsersService } from '../../services/users/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
    selector: 'app-signup',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent implements OnInit, OnDestroy {
    @ViewChild('toast') toastElement: ElementRef;
    public values: any;
    public form: FormGroup;
    public submitted: boolean = false;
    public isLoading = false;
    public items = [];
    public selectedOption: any;
    public showForm: boolean = true;
    public showRegisterThanks: boolean = false;
    toasts: NgbToast[] = [];
    personalDetails!: FormGroup;
    messageExito: string = 'Usuario creado correctamente';
    messageError: string = '';
    public submittedExito: boolean = false;
    public submittedError: boolean = false;
    public isChecked: boolean = false;
    public isChecked1: boolean = false;
    passwordMatch: boolean = false;
    listEnterprise: any;
    emailMatch: boolean = false;
    private modalContent: any;
    private modalConfig: any = {
        ariaLabelledBy: 'modal-basic',
        width: '650px'
    };
    private subs: Array<Subscription> = [];

    constructor(
        private fb: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        private zone: NgZone,
        private _snackBar: MatSnackBar,
        public modalService: NgbModal,
        private usersService: UsersService,
    ) {
        this.items = [
            { name: 'Pertenece a', value: '' },
            { name: 'CRO', value: 'CRO' },
            { name: 'Farmacéutica', value: 'Farmacéutica' },
            { name: 'Centro de Investigación', value: 'Centro de Investigación' }
        ]
        this.selectedOption = this.items[0].name;

        this.usersService.getEnterprise(9999, 0, 'business', 'firstName-asc').subscribe((data: any) => {
            this.listEnterprise = data.List;
        })
    }

    ngOnInit(): void {
        this.personalDetails = this.fb.group({
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            PhoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), this.validateEmail.bind(this)]],
            BusinessName: ['', Validators.required],
            Colabs: ['', Validators.required],
            Password: ['', [Validators.required, Validators.minLength(8)]],
            ConfirmedPassword: ['', [Validators.required, Validators.minLength(8), this.validatePasswords.bind(this)]],

        });

    }
    toggleCheck() {
        this.isChecked = !this.isChecked;
    }

    toggleButton() {
        this.isChecked1 = !this.isChecked1
    }
    ngOnDestroy(): void {
        let sub: Subscription;
        while (sub = this.subs.shift()) {
            sub.unsubscribe();
        }
    }

    public get formState() {
        return this.personalDetails.controls;
    }

    public onSubmit(): void {
        this.validatePasswords();

        if (this.personalDetails.invalid) {
            this.addToast('Debes llenar todos los datos', 'error-snackbar')
            return;
        }
        this.createUser();
        this.submittedError = false;
    }



    private createUser() {
        const user: UserCreateModel = this.buildUser();
        this.isLoading = true;
        this.subs.push(
            this.authService.createUserPerson(user).subscribe(
                () => {
                    this.showRegisterThanks = true;
                    this.showForm = false;
                    this.submittedError = false;
                    this.isLoading = false;
                },
                (errors) => {
                    this.handleError(errors);

                }
            )
        );


    }


    private handleError(error: any) {

        this.submittedError = true;
        setTimeout(() => {
            this.submittedError = false;
        }, 2000)
        this.messageError = error.error.message;
        this.submittedExito = false;
        this.isLoading = false;
    }


    setModalContent(content: any): void {
        this.modalContent = content;
        this.modalService.open(this.modalContent, this.modalConfig);
    }




    validatePasswords() {
        if (this.personalDetails && this.personalDetails.controls && this.personalDetails.controls['Password'] && this.personalDetails.controls['ConfirmedPassword']) {
            const password: string = this.personalDetails.controls['Password'].value;
            const PasswordConfirm: string = this.personalDetails.controls['ConfirmedPassword'].value;
            if (password !== PasswordConfirm) {
                this.passwordMatch = true;
            } else if (password === PasswordConfirm) {
                this.passwordMatch = false;
            };
        }
    }

    private buildUser(): UserCreateModel {
        return {
            firstName: this.personalDetails.controls['FirstName'].value,
            lastName: this.personalDetails.controls['LastName'].value,
            email: this.personalDetails.controls['Email'].value,
            password: this.personalDetails.controls['Password'].value,
            phoneNumber: this.personalDetails.controls['PhoneNumber'].value,
            businessname: this.personalDetails.controls['BusinessName'].value,
            collaborators: this.personalDetails.controls['Colabs'].value,
            roles: 'business'
        }
    }

    public onClickRedirectLogin(): void {
        this.router.navigateByUrl('/auth/login');
    }



    private handleUserCreation() {
        /* this.isLoading = false;
        const toastOptions: ToastOptions = {
            title: 'Registro',
            msg: 'Usuario creado correctamente',
            showClose: true,
            timeout: 1700
          };
          this.toastyService.success(toastOptions); */
    }



    private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {
        /*  const toastOptions: ToastOptions = {
             title,
             msg: message,
             showClose: true,
             timeout: 3500,
             theme: 'bootstrap'
         };
 
         this.toastyService[type](toastOptions); */
    }


    validateEmail() {
        if (Array.isArray(this.listEnterprise) && this.personalDetails && this.personalDetails.controls && this.personalDetails.controls['Email']) {
            const email: string = this.personalDetails.controls['Email'].value;
            let emailMatch = false;
            for (let i = 0; i < this.listEnterprise.length; i++) {
                const item = this.listEnterprise[i];
                if (item.email === email) {
                    emailMatch = true;
                    break;
                }
            }
            this.emailMatch = emailMatch;
        }
    }

    addToast(message: any, className: string) {
        this.zone.run(() => {
            this._snackBar.open(message, null, {
                duration: 5000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: [className],
            });
        });

    }

}