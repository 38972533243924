<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="row product-adding">
                <div class="col-xl-12">
                    <form class="needs-validation" [formGroup]="quizForm" (ngSubmit)="onSubmit()" novalidate>
                        <div class="card bordertop">
                            <div class="add-product-form">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12">
                                        <div class="form-group">
                                            <h5>Título del cuestionario:</h5>
                                            <input class="form-control" id="validationCustom01" type="text" placeholder="Título del cuestionario" [formControl]="quizForm.controls['Title']"
                                            [ngClass]="{'error': submitted && formState?.Title?.errors?.required}">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xs-12 col-sm-12">
                                        <div class="form-group">
                                            <h5>Descripción</h5>
                                            <textarea class="form-control" [formControl]="quizForm.controls['Description']" placeholder="Descripción" name="AwardDescription" id="" cols="40%" rows="10"
                                            [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="questionsQuiz.length !== 0">
                            <div class="card borderleft" *ngFor="let question of questionsQuiz; let i = index">
                                <div class="col-xs-12">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-4">
                                                <h4>Pregunta Nº {{i+1}}</h4>
                                                <p>{{question.Question}}</p>
                                                <p>Tipo: {{question.Type}}</p>
                                            </div>
                                            <div class="col-xs-12 col-sm-6" style="display: inherit;">
                                                <table class="table table-striped">
                                                    <tbody>
                                                        <tr *ngFor="let answer of answerResults[i]; let x = index">
                                                            <td><span [ngClass]="{'true': answer.Correct == true, 'false': answer.Correct == false}">{{x+1}}</span></td>
                                                            <td><span [ngClass]="{'true': answer.Correct == true, 'false': answer.Correct == false}">{{answer.Answer}}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-xs-12 col-sm-2 tright">
                                                <span>
                                                    <button type="button" class="btn btn-secondary mr-1 mat-btn" (click)="removeQuestion(question)">
                                                        Borrar
                                                    </button>
                                                </span>
                                                <span>
                                                    <button type="button" class="btn btn-primary mr-1 mt-3 mat-btn" (click)="editQuestion(question, i)">
                                                        Editar
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                      </div>
                                </div>
                            </div>
                        </div>
                        <div class="card borderleft">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                                    <div class="row">
                                        <div class="col-xs-12 col-lg-5">
                                            <div class="form-group">
                                                <label for="validationCustom05">Pregunta</label>
                                                <input class="form-control" id="validationCustom05" type="text" placeholder="Pregunta" [(ngModel)]="valueQuestion" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-lg-4">
                                            <div class="form-group">
                                                <label for="validationCustom05">Tipo de pregunta</label>
                                                <select class="form-control" id="validationCustom04" type="text" placeholder="Tipo" [(ngModel)]="typeQuestion" [ngModelOptions]="{standalone: true}">
                                                    <option value="Múltiple">Opción múltiple</option>
                                                    <option value="Única">Opción única</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-lg-3">
                                            <button type="button" class="btn btn-primary mr-1 mat-btn" (click)="addQuestion()" *ngIf="!editingQuestion">
                                                Agregar pregunta
                                            </button>
                                            <button type="button" class="btn btn-primary mr-1 mat-btn" (click)="changeQuestion()" *ngIf="editingQuestion">
                                                Finalizar edición
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-lg-12 paddd" *ngIf="answerQuestion?.length !== 0">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr class="row">
                                                <th class="col-6">Respuesta</th>
                                                <th class="col-3">Correcta</th>
                                                <th class="col-3">Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="row" *ngFor="let answer of answerQuestion; let i = index">
                                                <td class="col-6">{{answer.Answer}}</td>
                                                <td class="col-3">
                                                    <span *ngIf="answer.Correct"><i class="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                                    <span *ngIf="!answer.Correct"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
                                                </td>
                                                <td class="col-3">
                                                    <span>
                                                        <a (click)="removeAnswer(answer)"
                                                            title="Eliminar">
                                                            <i class="fa fa-times"></i>
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <a (click)="editAnswer(answer, i)"
                                                            title="Editar">
                                                            <i class="fa fa-pencil-square-o"></i>
                                                        </a>
                                                    </span>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-3 justify-content-between">
                                        <div class="col-xs-12 col-lg-5">
                                            <div class="form-group">
                                                <h5>Respuesta:</h5>
                                                <input [disabled]="typeQuestion === null" class="form-control" id="validationCustom07" type="text" placeholder="Respuesta" [(ngModel)]="valueAnswer" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-lg-3 d-flex align-items-center">
                                            <div class="form-group">
                                                <div class="custom-control custom-switch">
                                                    <input [disabled]="typeQuestion === null" type="checkbox" class="custom-control-input" id="validationCustom08" [(ngModel)]="valueCorrect" [ngModelOptions]="{standalone: true}">
                                                    <label for="validationCustom08" class="custom-control-label"></label>
                                                    <span>¿Respuesta correcta?</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-lg-3">
                                            <button [disabled]="typeQuestion === null" type="button" class="btn btn-primary mr-1 mat-btn-answers" (click)="addAnswer()" *ngIf="!editingAnswer">
                                                Agregar respuesta
                                            </button>
                                            <button [disabled]="typeQuestion === null" type="button" class="btn btn-primary mr-1 mat-btn-answers" (click)="changeAnswer()" *ngIf="editingAnswer">
                                                Finalizar edición
                                            </button>
                                        </div>
                                    </div>
                                </div>
                               </div>
                        </div>
                        <div class="form-group col-12 mb-0 pr-0">
                            <div class="product-buttons col-xs-12 button-save text-right pr-0">
                                <button class="btn btn-primary mright"
                                    [disabled]="isLoading" *ngIf="onEdit == false">
                                    {{ isLoading ? 'Creando cuestionario' : 'Agregar cuestionario'}}
                                    <i class="fa fa-spinner fa-spin"
                                    [hidden]="!isLoading"></i>
                                </button>
                            </div>

                            <div class="product-buttons col-xs-12 button-save text-right pr-0">
                                <button type="submit" class="btn btn-primary mright" *ngIf="onEdit == true">
                                    {{ isLoading ? 'Editando cuestionario' : 'Editar cuestionario'}}
                                    <i class="fa fa-spinner fa-spin"
                                    [hidden]="!isLoading"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <ng2-toasty></ng2-toasty> -->
