import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../components/services/auth/authentication.service';
import { AuthModel } from '../../../components/auth/auth.models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;
  public currentUser: AuthModel;
  currentUser2: any;
  public userId: number;
  public user: any;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) {
     
        /* this.activatedRoute.paramMap.subscribe((data: any) => {
          if (data.params.id) {
            this.userId = data.params.id;
            this.authService.getUser(data.params.id)
              .subscribe(user => {
                this.user = user;
               
              })
          }
        }) */
      this.currentUser2 = this.authService.currentUserValue;
    }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe((authenticatedUser: AuthModel) => this.currentUser = authenticatedUser);

  
  }

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/auth/login');
  }

}
