<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-12 pl-0">
                                                    <h4>Cargar imagen de portada</h4>
                                                    <div *ngIf="previewImg" class="box-input-file">
                                                        <img class="imgUpload" alt="" [src]="previewImg.base">
                                                    </div>
                                                    <div *ngIf="viewLoaderImg" class="spinner-border text-danger" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <input type="file" class="file-input" accept=".png, .jpg, .jpeg" (change)="onImgSelected($event)" #fileImgUpload>
                                                    <div class="file-upload mt-3">
                                                        <button class="btn btn-primary" (click)="fileImgUpload.click()">
                                                            {{previewImg ? 'Reemplazar imagen' : "Cargar imagen"}}
                                                            <i class="ml-3 fa fa-cloud-upload" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom01"
                                                    class="col-form-label pt-0">Nombre</label>
                                                <input class="form-control" id="validationCustom01" type="text"
                                                    [formControl]="productForm.controls['Title']"
                                                    [ngClass]="{'error': submitted && formState?.Title?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom02" class="col-form-label pt-0">SKU</label>
                                                <input class="form-control" id="validationCustom02" type="text"
                                                    [formControl]="productForm.controls['SKU']"
                                                    [ngClass]="{'error': submitted && formState?.SKU?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom03" class="col-form-label pt-0">Modelo del
                                                    curso</label>
                                                <input class="form-control" id="validationCustom03" type="text"
                                                    [formControl]="productForm.controls['CourseModel']"
                                                    [ngClass]="{'error': submitted && formState?.CourseModel?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom04"
                                                    class="col-form-label pt-0">Precio</label>
                                                <input class="form-control" id="validationCustom04" type="number" min="0" oninput="validity.valid||(value='');"
                                                    [formControl]="productForm.controls['UnitPrice']"
                                                    [ngClass]="{'error': submitted && formState?.UnitPrice?.errors?.required || formState?.UnitPrice?.errors?.min}">
                                                <span *ngIf="formState?.UnitPrice?.errors?.min" class="text-danger">
                                                    No se permiten valores negativos
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom05"
                                                    class="col-form-label pt-0">Moneda</label>
                                                <select class="form-control custom-select"
                                                    [formControl]="productForm.controls['CurrencyCode']"
                                                    [ngClass]="{'error': submitted && formState?.CurrencyCode?.errors?.required}">
                                                    <option value="">Selecciona moneda</option>
                                                    <option value="MXN">MXN</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom06" class="col-form-label pt-0">Tipo de
                                                    acceso</label>
                                                <select class="form-control custom-select"
                                                    [formControl]="productForm.controls['AccessType']"
                                                    [ngClass]="{'error': submitted && formState?.AccessType?.errors?.required}">
                                                    <option value="">Selecciona acceso</option>
                                                    <option value="Online">Online</option>
                                                    <option value="Presencial">Presencial</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom07" class="col-form-label pt-0">Categoría</label>
                                                <ng-select name="Category" id="Category" [formControl]="productForm.controls['Category']" [ngClass]="{'error': submitted && formState?.Category?.errors?.required}">
                                                    <ng-option *ngFor="let category of categories" [value]="category.ValueAlpha">
                                                        {{category.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom08" class="col-form-label pt-0">Sub categoría</label>
                                                <ng-multiselect-dropdown
                                                    [placeholder]="'Seleccionar subcategorías'"
                                                    [settings]="dropdownSettings"
                                                    [formControl]="productForm.controls['SubCategory']"
                                                    [data]="subcategoryList"
                                                    (onSelect)="selectSubCategory($event)"
                                                    (onDeSelect)="deselectSubCategory($event)">
                                                    >
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom09"
                                                    class="col-form-label pt-0">Nivel</label>
                                                <select class="form-control custom-select"
                                                    [formControl]="productForm.controls['Level']"
                                                    [ngClass]="{'error': submitted && formState?.Level?.errors?.required}">
                                                    <option value="">Selecciona nivel</option>
                                                    <option value="Principiante">Principiante</option>
                                                    <option value="Intermedio">Intermedio</option>
                                                    <option value="Avanzado">Avanzado</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom10" class="col-form-label pt-0">Total de horas</label>
                                                <input class="form-control" id="validationCustom10" type="number" min="0" oninput="validity.valid||(value='');"
                                                    [formControl]="productForm.controls['TotalHours']"
                                                    [ngClass]="{'error': submitted && formState?.TotalHours?.errors?.required || formState?.TotalHours?.errors?.min}">
                                                <span *ngIf="formState?.TotalHours?.errors?.min" class="text-danger">
                                                    No se permiten valores negativos
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom11"
                                                    class="col-form-label pt-0">Lenguaje</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['Language']"
                                                [ngClass]="{'error': submitted && formState?.Language?.errors?.required}">
                                                    <option value="">Selecciona lenguaje</option>
                                                    <option value="Español">Español</option>
                                                    <option value="Inglés">Inglés</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom12" class="col-form-label pt-0">Lenguaje de
                                                    subtítulos</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['LanguageSubtitles']"
                                                [ngClass]="{'error': submitted && formState?.LanguageSubtitles?.errors?.required}">
                                                    <option value="">Selecciona subtítulos</option>
                                                    <option value="Español">Español</option>
                                                    <option value="Inglés">Inglés</option>
                                                    <option value="N/A">N/A</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom14" class="col-form-label pt-0">Selecciona el maestro</label>
                                                <ng-select name="Teacher" id="Teacher" class="form-control"
                                                    placeholder="Selecciona el maestro"
                                                    [formControl]="productForm.controls['Teacher']"
                                                    [ngClass]="{'error': submitted && formState?.Teacher?.errors?.required}">
                                                    <ng-option *ngFor="let teacher of teachers" [value]="teacher.id">
                                                        {{teacher.firstName}} {{teacher.lastName}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom17" class="col-form-label pt-0">Fecha de
                                                    inicio</label>
                                                <input class="form-control" id="validationCustom17"
                                                    type="datetime-local" 
                                                    [formControl]="productForm.controls['StartDate']"
                                                    [ngClass]="{'error': submitted && formState?.StartDate?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom18" class="col-form-label pt-0">Fecha de
                                                    finalización</label>
                                                <input class="form-control" id="validationCustom18"
                                                    type="datetime-local"
                                                    [formControl]="productForm.controls['EndDate']"
                                                    [ngClass]="{'error': submitted && formState?.EndDate?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom13"
                                                    class="col-form-label pt-0">Descripción</label>
                                                <br>
                                                <textarea class="form-control"
                                                    [formControl]="productForm.controls['Description']"
                                                    name="Description" id="validationCustom13" cols="40%" rows="5"
                                                    [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom14"
                                                    class="col-form-label pt-0">Introducción</label>
                                                <br>
                                                <textarea class="form-control"
                                                    [formControl]="productForm.controls['Introduction']"
                                                    name="Introduction" id="validationCustom14" cols="40%" rows="5"
                                                    [ngClass]="{'error': submitted && formState?.Introduction?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom15"
                                                    class="col-form-label pt-0">Objetivo</label>
                                                <br>
                                                <textarea class="form-control"
                                                    [formControl]="productForm.controls['Objective']" name="Objective"
                                                    id="validationCustom15" cols="40%" rows="5"
                                                    [ngClass]="{'error': submitted && formState?.Objective?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom16" class="col-form-label pt-0">Dirigido
                                                    a</label>
                                                <br>
                                                <textarea class="form-control"
                                                    [formControl]="productForm.controls['TargetMarket']"
                                                    name="TargetMarket" id="validationCustom16" cols="40%" rows="5"
                                                    [ngClass]="{'error': submitted && formState?.TargetMarket?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12 mb-0 pr-0">
                                    <div class="product-buttons button-save text-right pr-0">
                                        <button class="btn btn-primary" [disabled]="isLoading" *ngIf="onEdit == false">
                                            {{ isLoading ? 'Creando webinar' : 'Agregar webinar'}}
                                            <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                        </button>
                                    </div>

                                    <div class="product-buttons button-save text-right pr-0">
                                        <button type="submit" class="btn btn-primary" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando webinar' : 'Editar webinar'}}
                                            <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <ng2-toasty></ng2-toasty> -->