<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card tab2-card custom-card-margin">
        <div class="card-body tab2-card">
          <h4 class="pl-3 pt-3 pb-4">Detalle de colaborador</h4>
          <ngb-tabset class="tab-coupon">
            <ngb-tab>
              <ng-template ngbTabContent>
                <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                  <div class="form-group row">
                    <div class="col-xl-4 col-md-4">
                      <label class="">Nombre(s)</label>
                    </div>
                    <div class=" col-xl-8 col-md-8">
                      <input class="form-control" value="{{firstName}}" disabled />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-4 col-md-4">
                      <label class="">Apellido(s)</label>
                    </div>
                    <div class=" col-xl-8 col-md-8">
                      <input class="form-control" value="{{lastName}}" disabled />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-4 col-md-4">
                      <label class="">Teléfono</label>
                    </div>
                    <div class=" col-xl-8 col-md-8">
                      <input class="form-control" value="{{phoneNumber}}" disabled />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-4 col-md-4">
                      <label class="">Correo electrónico</label>
                    </div>
                    <div class=" col-xl-8 col-md-8">
                      <input class="form-control" value="{{email}}" disabled />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-4 col-md-4">
                      <label class="">Empresa</label>
                    </div>
                    <div class=" col-xl-8 col-md-8">
                      <input class="form-control" value="{{businessname}}" disabled />
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>