import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel, AuthModel, EmailModel, PasswordModel, UserCreateModel, PasswordProfileModel } from '../../auth/auth.models';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../../utils/headers.functions';
import { ClientRoles } from './auth.models';
import { BusinessData } from 'src/app/shared/interfaces/data.interface';
import * as moment from 'moment';

const BASE_URL: string = environment.API_URL;


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isLoggedIn() {
    if (localStorage['currentUser']) {
      const user = JSON.parse(localStorage['currentUser']);
      return user && moment().diff(user.expiresIn, 'minutes') > 0;
    }
    return true;
  }

  private currentUserSubject: BehaviorSubject<AuthModel>;

  public currentUser: Observable<AuthModel>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AuthModel {
    return this.currentUserSubject.value;
  }

  public login(user: UserModel): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${BASE_URL}/auth/signin`, user, {
    })
      .pipe(map(currentUser => this.setUser(currentUser)));
  }

  public logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  public signUp(user: UserModel): Observable<AuthModel> {
    const headers: HttpHeaders = getHeaders();

    user.roles = ClientRoles.Admin;
    return this.http.post<AuthModel>(`${BASE_URL}/auth/signup`, user, { headers })
      .pipe(map(currentUser => this.setUser(currentUser)));
  }

  public handleUnauthorizedError(err: any) {
    if (err.error && err.error.status === 401) {
      this.logout();
      /* this.router.navigate(['/auth/login']); */
    }

    return throwError('Session Expired');
  }

  public forgotPassword(email: EmailModel): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/auth/changenew-password`, email);
  }

  public resetPassword(body: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/newpassworduptate`, body, { headers });
  }

  public createUser(user: UserModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();

    return this.http.post<any>(`${BASE_URL}/auth/signup`, user, { headers });
  }

  public getUser(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/user/${id}`, { headers });
  }

  private setUser(user: AuthModel) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return user;
  }

  public createUserPerson(user: UserCreateModel | BusinessData): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/signup`, user, { headers });
  }

  public changePassword(user: PasswordProfileModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<Observable<any>>(`${BASE_URL}/auth/change-password`, user, { headers });
  }

  public updatePassword(user: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<Observable<any>>(`${BASE_URL}/auth/newpassworduptate`, user, { headers });
  }

  public activateUser(token: string, body: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<Observable<any>>(`${BASE_URL}/auth/activate/${token}`, body, { headers });
  }


  public mapRequiredValues(user: any): any {
    const userInfo: any = {};

    userInfo.firstName = user.firstName;
    userInfo.lastName = user.lastName;
    userInfo.email = user.email;
    userInfo.phoneNumber = user.phoneNumber;
    userInfo.city = user.city;
    userInfo.country = user.country;
    userInfo.birthday = user.birthday;
    userInfo.businessname = user.businessname;
    userInfo.collaborators = user.collaborators;
    return userInfo;
  }

  public mapRequiredValuesForPassword(user: any): any {
    const userInfo: any = {};
    userInfo.email = user.email;
    return userInfo;
  }

  private change(user: any): any {
    const changePassword = {
      password: user.password
    };
    return changePassword;
  }

  private addBackendStructure(user: any) {
    return {
      email: user.email,
      password: user.password
    };
  }
}
