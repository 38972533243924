<!-- Container-fluid starts-->
<div class="container-fluid">

      <div class="card">
        <div class="card-body d-flex justify-content-between align-items-end w-100">
          <div class="row d-flex flex-column col-lg-9">
            <label>Filtrar</label>
            <div class="input-group w-100">
              <input type="text" id="filterText" placeholder="Buscar por nombre, correo o teléfono" class="form-control">
              <div class="input-group-append">
                <button *ngIf="!deleteFilters" value="Buscar" (click)="filter()" class="btn btn-primary">Buscar</button>
                <button *ngIf="deleteFilters" value="Borrar" (click)="getUsers()" class="btn btn-primary">Borrar filtros</button>
              </div>
            </div>
          </div>
            <div class="row action pr-0 mx-0 col-lg-3">
                      <a class="btn btn-red btn-sm w-100 px-2"
                        [routerLink]="['create-user']">
                        <i class="ti-plus"></i>
                        <span><i class="fa fa-plus"></i> Colaborador</span>
                      </a>
            </div>
        </div>
      </div>
  <div class="card">
      <div class="card-body">
          <div id="batchDelete" class="">
              <div class="table-responsive">
                  <table datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class=" table table-lg table-hover row-border hover">
                  <thead>
                      <tr>
                      <th>Nombre(s)</th>
                      <th>Correo electrónico</th>
                      <th>Teléfono</th>
                      <th>Status</th>
                      <th>Acciones</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                      <td>Matias Sabbadini</td>
                      <td>matias@matias.com</td>
                      <td>123456789</td>
                      <td>activo</td>
                      <td class="actions-cell">
                          <span>
                          <a [routerLink]="['show']"
                              title="Ver maestro">
                              <i class="fa fa-eye"></i>
                          </a>
                          </span>
                          <span>
                            <a [routerLink]="['medicalHistory']"
                                title="Agregar historial clinico">
                                <i class="fa fa-plus"></i>
                            </a>
                            </span>
                          <span>
                          <a [routerLink]="['edit']"
                              title="Editar maestro">
                              <i class="fa fa-edit"></i>
                          </a>
                          </span>
                          <span
                          title="Borrar maestro">
                          <i class="fa fa-trash"></i>
                          </span>
                          <span
                            title="Activar usuario">
                            <i class="fa fa-check"></i>
                            </span>
                      </td>
                      </tr>
                  </tbody>
                  </table>
                  <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="count" [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
              </div>
          </div>
      </div>
  </div>
</div>


<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Eliminar Maestro</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del maestro.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Maestro borrado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteUser()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>

<ng-template #contentactive
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Activar Instructor</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Activara a este instructor dentro de la plataforma</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Instructor activado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-success"
        (click)="activeUser()">Activar</button>
    </div>
  </ng-container>
</ng-template>