import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AddEnterpriseComponent } from "./add-enterprise/add-enterprise.component";
import { ListEnterpriseComponent } from "./list-enterprise/list-enterprise.component";
import { EnterpriseRoutingModule } from "./enterprise-routing.module";
import { EditEnterpriseComponent } from "./edit-enterprise/edit-enterprise.component";
import { SharedModule } from "src/app/shared/shared.module";
import { DetailEnterpriseComponent } from "./detail-enterprise/detail-enterprise.component";
import { NavService } from "src/app/shared/service/nav.service";
import { DataTablesModule } from 'angular-datatables';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AddCollaboratorComponent } from "./collaborator/add-collaborator/add-collaborator.component";
import { ListCollaboratorComponent } from "./collaborator/list-collaborator/list-collaborator.component";
import { EditCollaboratorComponent } from "./collaborator/edit-collaborator/edit-collaborator.component";
import { DetailCollaboratorComponent } from "./collaborator/detail-collaborator/detail-collaborator.component";
import { HistoryClinicComponent } from "./historyClinic/add-history-clinic/historyClinic.component";
import { DetailHistoryClinicComponent } from './historyClinic/detail-history-clinic/detail-history-clinic.component';
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { PhysicalExaminationComponent } from "./historyClinic/physicalExamination/physicalExamination.component";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { ListComponent } from './historyClinic/list/list.component';

@NgModule({
  declarations: [
    AddEnterpriseComponent,
    ListEnterpriseComponent,
    EditEnterpriseComponent,
    DetailEnterpriseComponent,
    AddCollaboratorComponent,
    ListCollaboratorComponent,
    EditCollaboratorComponent,
    DetailCollaboratorComponent,
    HistoryClinicComponent,
    DetailHistoryClinicComponent,
    PhysicalExaminationComponent,
    ListComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    EnterpriseRoutingModule,
    DataTablesModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ],
  providers: [NavService]
})
export class EnterpriseModule { }
