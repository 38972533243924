<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-3">
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">Generales</h4>
        </div>
        <div class="card-body">
          <div>
            <div class="row col-xl-12 col-md-4 mb-3">
              <div class="d-flex align-items-center">
                <i class="fa fa-briefcase mr-2"></i>
                <div>
                  <span>Empresa</span><br>
                  <b>{{ businessname }}</b>
                </div>
              </div>
            </div>
            <div class="row col-xl-12 col-md-4 mb-3">
              <div class="d-flex align-items-center">
                <i class="fa fa-user mr-2"></i>
                <div>
                  <span>Representante</span><br>
                  <b>{{ firstName }} {{lastName}}</b>
                </div>
              </div>
            </div>
            <div class="row col-xl-12 col-md-4 mb-3">
              <div class="d-flex align-items-center">
                <i class="fa fa-envelope mr-2"></i>
                <div>
                  <span>Correo electrónico</span><br>
                  <b>{{ email }}</b>
                </div>
              </div>
            </div>
            <div class="row col-xl-12 col-md-4 mb-3">
              <div class="d-flex align-items-center">
                <i class="fa fa-phone mr-2"></i>
                <div>
                  <span>Número telefónico</span><br>
                  <b>{{ phoneNumber }}</b>
                </div>
              </div>
            </div>
            <div class="row col-xl-12 col-md-4 mb-3">
              <div class="d-flex align-items-center">
                <i class="fa fa-users mr-2"></i>
                <div>
                  <span>Total de colaboradores</span><br>
                  <b>{{ collaborators }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>    
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div class="card">
        <div class="card-header p-4">
          <div class="row">
            <div class="col-12 col-md-9 d-flex align-items-center">
              <h4 class="mb-0">Lista de colaboradores</h4>
            </div>
            <div class="col-12 col-md-3">
              <a *ngIf="!buttonDisabled" class="btn btn-red btn-sm w-100" [routerLink]="['add-colaborador']">
                <i class="ti-plus"></i>
                <span><i class="fa fa-plus"></i>Nuevo Colaborador</span>
              </a>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-5 d-flex custom-margin-collaborators-input-mobile">
            <div class="col-12">
              <div class="input-group w-100">
                <input type="text" id="filterText" [(ngModel)]="collaboratorFilter"
                  placeholder="Buscar por nombre, correo o teléfono" class="input-font-size form-control">
                <div class="input-group-append">
                  <button *ngIf="!deleteFilters" value="Buscar" (click)="filter(collaboratorFilter)"
                    class="button-font-size btn btn-primary">Buscar</button>
                  <button *ngIf="deleteFilters" value="Borrar" (click)="loadDataFilter()"
                    class="button-font-size btn btn-primary">Borrar filtros</button>
                </div>
              </div>
            </div>
          </div>
          <div id="batchDelete" class="row body overflow-auto">
            <div *ngIf="collaboratorTotal !== 0" class="table-responsive">
              <table mat-table (matSortChange)="announceSortChange($event)" #table [dataSource]="dataSource" matSort
                class="table table-responsive table-lg table-hover row-border hover">
                <ng-container class="custom-font-size-th" matColumnDef="firstName">
                  <th *matHeaderCellDef mat-sort-header>Nombre(s)</th>
                  <td *matCellDef="let row">{{row.firstName}}</td>
                </ng-container>
                <ng-container class="custom-font-size-th" matColumnDef="lastName">
                  <th *matHeaderCellDef mat-sort-header>Apellido(s)</th>
                  <td *matCellDef="let row">{{row.lastName}}</td>
                </ng-container>
                <ng-container class="custom-font-size-th custom-widt-fecha" matColumnDef="birthday">
                  <th *matHeaderCellDef mat-sort-header>Fecha de nacimiento</th>
                  <td *matCellDef="let row">{{row.dateOfBirth | date: 'dd/MM/YYYY'}}</td>
                </ng-container>
                <ng-container class="custom-font-size-th" matColumnDef="Job">
                  <th *matHeaderCellDef mat-sort-header>Puesto</th>
                  <td *matCellDef="let row">{{row.position}}</td>
                </ng-container>
                <ng-container class="custom-font-size-th" matColumnDef="phoneNumber">
                  <th *matHeaderCellDef mat-sort-header>Número telefónico</th>
                  <td *matCellDef="let row">
                    {{ row.phone }}
                  </td>
                </ng-container>
                <ng-container class="custom-font-size-th" matColumnDef="actions">
                  <th *matHeaderCellDef mat-sort-header>Acciones</th>
                  <td *matCellDef="let row" style="font-size: 16px;">
                    <span>
                      <a [routerLink]="['edit-collaborator', row.id]" (click)="sendPhone(row.phoneNumber)"
                        title="Editar colaborador">
                        <i class="fa fa-edit"></i>
                      </a>
                    </span>
                    <span>
                      <a [routerLink]="['/colaboradores/history-clinic', row.id]" (click)="obtenerId(row.id)"
                        title="Ver historial clinico">
                        <i class="fa fa-clipboard"></i>
                      </a>
                    </span>
                    <span title="Eliminar empresa" (click)="setModalContent(content, row.id)" *ngIf="row.active">
                      <i class="fa fa-trash"></i>
                    </span>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <ngb-pagination (pageChange)="loadPage($event)" [(collectionSize)]="collaboratorTotal" [(page)]="page"
                [pageSize]="limit" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
              <div class="d-flex justify-content-center w-100">
                <ngb-toast *ngIf="submittedError" [class]="'ngb-toast bg-error'">
                  {{messageError}}
                </ngb-toast>
                <ngb-toast *ngIf="submittedSuccess" [class]="'ngb-toast bg-success'">
                  {{messageSuccess}}
                </ngb-toast>
              </div>
            </div>
            <div class="text-center w-100 pb-5" *ngIf="collaboratorTotal === 0">
              <h5>Esta empresa aún no cuenta con colaboradores registrados</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Confirmar baja</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">¿Realmente deseas dar de baja a este colaborador?</p>
      <p class="no-mrg">Esta acción no es posible revertir.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Empresa borrada correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-danger" (click)="deleteUser()">Sí, Confirmar</button>
    </div>
  </ng-container>
</ng-template>