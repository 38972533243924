import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../../auth/auth.models';
import { getHeaders } from '../../utils/headers.functions';
import { StudyData } from 'src/app/shared/interfaces/data.interface';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  updateCollaborator(id: any, user: { enfermedad: any; padecimiento: any; actividad: any; }) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/collaborator/${id}`, user, { headers });
  }
  updateQuizAnswers(userResponse: any[]) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/quiz-results`, userResponse, { headers });
  }
  getHistoryData(historyId: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/history-data/${historyId}`, { headers });
  }
  getQuizsByHistory(historyId: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/quiz-history-results/${historyId}`, { headers });
  }
  saveCollaboratorHistory(body: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/collaborator-history`, body, { headers });
  }
  getCollaboratorHistories(userId: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/collaborator-history/${userId}`, { headers });
  }
  getCollaboratorFiles(userId: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/collaborator-files/${userId}`, { headers });
  }
  getCollaboratorExperience(userId: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/collaborator-experience/${userId}`, { headers });
  }
  saveCollaboratorFile(userId: any, study: StudyData) {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/collaborator-files/${userId}`, study, { headers });
  }

  saveCollaboratorExperience(userId: any, study: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/collaborator-experience/${userId}`, study, { headers });
  }

  uploadFile(formData: FormData) {
    return this.http.post<any>(`${BASE_URL}/uploadFile`, formData);
  }
  editCollaborator(userId: any, user: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/collaborator/${userId}`, user, { headers });
  }

  editUser(userId: any, user: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/user/${userId}`, user, { headers });
  }

  getCollaborator(idUser: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/collaborator/${idUser}`, { headers });
  }
  createCollaborator(user: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/collaborators`, user, { headers });
  }
  private id: number;
  private phone: number;
  private businessname: string;
  private findColaborator: boolean;
  private idUser: number;
  constructor(
    private http: HttpClient
  ) { }

  setId(id: number) {
    this.id = id;
  }

  getId() {
    return this.id;
  }

  setPhone(phone: number) {
    this.phone = phone;
  }

  getPhone() {
    return this.phone;
  }


  setidUser(idUser: number) {
    this.idUser = idUser;
  }

  getidUser() {
    return this.idUser;
  }

  setBusinessname(businessname: string) {
    this.businessname = businessname;
  }

  getBusinessname() {
    return this.businessname;
  }


  setfindColaborator(findColaborator: boolean) {
    this.findColaborator = findColaborator;
  }

  getfindColaborator() {
    return this.findColaborator;
  }

  public createAdmin(user: UserModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/users`, user, { headers });
  }


  public getAdmins(limit: number, offset: number, order: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/users?limit=${limit}&offset=${offset}&order=${order}&roles=admin`, { headers });
  }

  public getAdminsFiltered(value: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/users?limit=500&offset=0&q=${value}`, { headers });
  }

  public getTeachers(limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/teachers?limit=${limit}&offset=${offset}`, { headers });
  }

  getTeachersFiltered(value: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/teachers?limit=500&offset=0&q=${value}`, { headers });
  }

  public createTeacher(user: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/teachers`, user, { headers });
  }


  public getListCollaborator2(limit: number, offset: number, id: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/teachers?limit=${limit}&offset=${offset}&q=${id}`, { headers });
  }

  public getListCollaborator(id: any, limit: number, offset: number, order: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/collaborators/${id}?limit=${limit}&offset=${offset}&order=${order}`, { headers });
  }

  public getListCollaborator3(id: any, limit: number, offset: number, order: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/v2/collaborators/${id}?limit=${limit}&offset=${offset}&order=${order}`, { headers });
  }

  public getTeacherById(id: any) {
    return this.http.get<any>(`${BASE_URL}/teacher?limit=${id}`);
  }

  public getBusiness(roles: string, limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business?roles=${roles}&limit=${limit}&offset=${offset}`, { headers });
  }

  getBusinessFiltered(value: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business?limit=500&offset=0&q=${value}`, { headers });
  }


  public getUsers(limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/users?limit=${limit}&offset=${offset}`, { headers });
  }

  getUsersFiltered(limit: number, offset: number, value: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/students?limit=${limit}&offset=${offset}&q=${value}`, { headers });
  }

  public getStudent(thisUserId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/student/${thisUserId}`);
  }

  public getEnterpriseId(thisUserId: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business/${thisUserId}`, { headers });
  }

  public putEnterpriseId(thisUserId: any, enterprise: any): Observable<any> {
    return this.http.put<any>(`${BASE_URL}/business/${thisUserId}`, enterprise);
  }

  public getEnterprise(limit: number, offset: number, roles: string, order: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business?limit=${limit}&offset=${offset}&roles=${roles}&order=${order}`, { headers });
  }


  public getEnterprise2(limit: number, offset: number, roles: string, order: any, q: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business?limit=${limit}&offset=${offset}&roles=${roles}&order=${order}&q=${q}`, { headers });
  }

  public getCollaboratorsList(limit: number, offset: number, order: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/collaborators?limit=${limit}&offset=${offset}&order=${order}`, { headers });
  }

  public getCollaboratorsList2(limit: number, offset: number, order: any, q: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/collaborators?limit=${limit}&offset=${offset}&order=${order}&q=${q}`, { headers });
  }

  public getCollaboratorsListFilter(limit: number, offset: number, q: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/collaborators?limit=${limit}&offset=${offset}&q=${q}`, { headers });
  }

  public getUser(thisUserId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/user/${thisUserId}`);
  }

  public deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/user/${id}`);
  }

  public deleteTeacher(id: string): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/teacher/${id}`);
  }

  public updateTeacher(id: number, user: any): Observable<any> {
    return this.http.put<any>(`${BASE_URL}/teacher/${id}`, user);
  }

  public updateUser(id: number, user: any): Observable<any> {
    return this.http.put<any>(`${BASE_URL}/user/${id}`, user);
  }


  public generatePdf(body: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/generatePDF`, body, { headers, responseType: 'blob' as 'json' });
  }


  public getQuizsByUser(thisUserId: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/quiz-results/${thisUserId}`, { headers });
  }

  public addHistoryClinic(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/quiz-results`, formData, { headers });
  }

  public addHistoryClinicExtremidades(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/evaluation`, formData, { headers });
  }

  public getCatalogFamiliar(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/catalogs/Familiar`);
  }

  public getCatalogFactor(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/catalogs/Factores`);
  }

  public getEstudio(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Estudio'}`, {headers});
  }

  public getAnswer(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/checks`);
  }

  public getAnswersByQuiz(thisQuizId: number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/quiz-results-detail/${thisQuizId}`);
  }

  public getWinner(quizId: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/winner/${quizId}`, { headers });
  }

  public getQuiz(quizId: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/quiz/${quizId}`, { headers });
  }


  public getExplorationPhysic(rallyId: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/rally?rallyId=${rallyId}`, { headers });
  }

  public changePasswordProfile(body: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/change-password`, body, { headers });
  }


  public mapRequiredValues(user: any): any {
    const userInfo: any = {};
    userInfo.id = user.id;
    userInfo.firstName = user.firstName;
    userInfo.lastName = user.lastName;
    userInfo.email = user.email;
    userInfo.dateOfBirth = user.dateOfBirth;
    userInfo.position = user.position;
    userInfo.shift = user.shift;
    userInfo.schedule = user.schedule;
    userInfo.daysOff = user.daysOff;
    userInfo.phone = user.phone;
    userInfo.address = user.address;
    userInfo.jobSeniority = user.jobSeniority;
    userInfo.maritalStatus = user.maritalStatus;
    userInfo.gender = user.gender;
    userInfo.person_id = user.person_id;


    return userInfo;
  }
}
