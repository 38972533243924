import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UserCollaborator } from 'src/app/shared/models/user';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UsersService } from '../../services/users/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Component({
  selector: 'app-addCollaborator',
  templateUrl: './addCollaborator.component.html',
  styleUrls: ['./addCollaborator.component.scss']
})
export class AddCollaboratorComponent implements OnInit, OnDestroy {
  public accountForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public userId;
  public rol: string;
  public model: NgbDateStruct;
  public url: string;
  private subs: Array<Subscription> = [];
  public type: string;
  public enterprisesList: any[] = [];
  public arrayBusiness: any[] = [];
  public businessName: string;
  civiledoSelected = '';
  genderSelected: string;
  businessId: string;
  age: number = 0;
  name: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _userService: UsersService,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
  ) {

    this.userId = this._userService.getId();
    this.businessName = this._userService.getBusinessname()
  }

  calculateAge(event) {
    const birthday = event.target.value;
    const m1 = moment(birthday);
    this.age = moment().diff(m1, 'years');
  }

  ngOnInit() {
    this.type = this.router.url.indexOf('admins') != -1 ? 'admin' : 'teacher';
    this.url = this.router.url;
    this.getBusinessData()
    this.userId = this.activatedRoute.snapshot.paramMap.get('idUser');
    const idUser = this.activatedRoute.snapshot.paramMap.get('idUser');
    if (idUser) {
      this.onEdit = true;
      this._userService.getCollaborator(idUser)
        .subscribe(user => this.setValues(user));
      this.accountForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        gender: ['', Validators.required],
        position: ['', Validators.required],
        shift: ['', Validators.required],
        schedule: ['', Validators.required],
        daysOff: ['', Validators.required],
        phone: ['', Validators.required],
        address: ['', Validators.required],
        jobSeniority: ['', Validators.required],
      })
    } else {
      this.onEdit = false;
      this.accountForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        gender: ['', Validators.required],
        position: ['', Validators.required],
        shift: ['', Validators.required],
        schedule: ['', Validators.required],
        daysOff: ['', Validators.required],
        phone: ['', Validators.required],
        address: ['', Validators.required],
        jobSeniority: ['', Validators.required],
      })
    }
  }
  async getBusinessData() {
    this.businessId = this.activatedRoute.snapshot.paramMap.get('id');
    const businessData = await this._userService.getEnterpriseId(this.businessId).toPromise();
    this.name = businessData.businessname ?? businessData.firstName;
  }

  toggleLabel(id: string) {
    const select = document.getElementById(id) as any;
    console.log(select.selectedIndex);
    const label = select.nextElementSibling;
    label.style.top = select.selectedIndex === 0 ? '50%' : '-11px';
    label.style.fontSize = select.selectedIndex === 0 ? '14px' : '12px';
    label.style.color = select.selectedIndex === 0 ? '#888' : '#888';
  }

  changeDate(event): void {
    const select = document.getElementById(event) as any;
    select.type = 'date';
    const label = select.nextElementSibling;
    label.style.top = '-21px';
    label.style.fontSize = '12px';
    label.style.color = '#888';
  }

  onSelectChangeGender(event: string) {
    this.genderSelected = event;
  }

  onSelectChangeCiviledo(event: string) {
    console.log(event)
    this.civiledoSelected = event;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.accountForm.controls
  }

  private validatePasswords(): void {
    const password: string = this.accountForm.get('password').value;
    const confirmPassword: string = this.accountForm.get('confirmedPassword').value;

    if (password !== confirmPassword) {
      this.accountForm.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  private setValues(user: any): void {
    const values = this._userService.mapRequiredValues(user);
    const { controls } = this.accountForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.accountForm.controls[value].setValue(values[value]);
      }
    }

    this.toggleLabel('select-field1');
    this.toggleLabel('select-field2');

  }

  private buildUser(): UserCollaborator {
    return {
      firstName: this.accountForm.controls['firstName'].value,
      lastName: this.accountForm.controls['lastName'].value,
      birthday: this.accountForm.controls['birthday'].value,
      gender: this.genderSelected,
      civiledo: this.civiledoSelected,
      password: this.accountForm.controls['password'].value,
      email: this.accountForm.controls['email'].value,
      Job: this.accountForm.controls['job'].value,
      phoneNumber: this.accountForm.controls['phoneNumber'].value,
      person_id: this.userId,
      roles: 'user'
    }
  }

  private buildUserUpdate(): UserCollaborator {
    return {
      firstName: this.accountForm.controls['firstName'].value,
      lastName: this.accountForm.controls['lastName'].value,
      birthday: this.accountForm.controls['birthday'].value,
      gender: this.accountForm.controls['gender'].value,
      civiledo: this.accountForm.controls['civiledo'].value,
      phoneNumber: this.accountForm.controls['phoneNumber'].value,
      password: this.accountForm.controls['password'].value,
      email: this.accountForm.controls['email'].value,
      Job: this.accountForm.controls['job'].value,
      person_id: this.userId,
      roles: 'user'
    }
  }


  onSubmit() {

    if (this.accountForm.invalid) {
      return;
    }

    if (this.onEdit) {
      this.updateUser();
    } else {
      this.validatePasswords();
      this.createUser();
    }
  }

  createUser() {
    this.submitted = true;

    const user = this.accountForm.value;
    if (user.phone.length < 10) {
      this.addToast('El número teléfonico debe ser de 10 digitos', 'error-snackbar');
      return;
    }

    if (this.accountForm.invalid) {
      this.addToast('Debes llenar todos los campos requeridos', 'error-snackbar');
      return;
    }
    if (!this.onEdit) {
      this.createCollaborator();
    } else {
      this.editCollaborator();
    }
  }

  editCollaborator() {
    const user = this.accountForm.value;
    this._userService.editCollaborator(this.userId, user)
      .pipe(
        map(() => {
          this.addToast('Colaborador modificado exitosamente', 'success-snackbar');
          setTimeout(() => {
            if (this.businessId) {
              this.router.navigateByUrl('/empresa/detalle/' + this.businessId);
            } else {
              this.router.navigateByUrl('/colaboradores');
            }
          }, 1800);
        }),
      )
      .subscribe();
  }

  createCollaborator() {
    const user = this.accountForm.value;
    user.person_id = this.businessId;
    this.isLoading = true;
    this._userService.createCollaborator(user)
      .pipe(
        map(() => {
          this.addToast('Colaborador agregado exitosamente', 'success-snackbar');
          setTimeout(() => {
            this.router.navigateByUrl('/empresa/detalle/' + this.businessId);
          }, 1800);
        }),
      )
      .subscribe();
  }

  updateUser() {
    const user = this.buildUserUpdate();
    this.isLoading = true;
    this.subs.push(
      this._userService.updateTeacher(this.userId, user)
        .pipe(
          map(() => {
            this.addToast('Colaborador editardo exitosamente', 'success-snackbar');
            setTimeout(() => {
              this.router.navigateByUrl('/empresa');
            }, 1800);
          }),
          //catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  addToast(message: any, className: string) {
    this.zone.run(() => {
      this._snackBar.open(message, null, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [className],
      });
    });
  }

}
