<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                            <th>Curso/Capacitación</th>
                            <th>Evaluación</th>
                            <th>Fecha de creación</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let quiz of quizs">
                            <td>{{ quiz.Product.Title }}</td>
                            <td>{{quiz.Title}}</td>
                            <td>{{quiz.created_at | date:'dd/MM/yyyy'}}</td>
                            <td class="actions-cell">
                                <span>
                                <a [routerLink]="['show', quiz.id]"
                                    title="Ver Orden">
                                    <i class="fa fa-eye"></i>
                                </a>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="count" [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->