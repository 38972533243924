<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-3">  </div>
                <div class="col-md-6 p-0">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="../../../../assets/images/logo.png" style="width: 30%;" alt="">
                            </div>
                            <div class="mb-4 text-center">
                                <small class="">Por favor ingresa tu nueva contraseña.</small>
                            </div>
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="resetPasswordForm" 
                                                (ngSubmit)="onSubmit()"
                                                class="form-horizontal auth-form" novalidate="">
                                                <div class="form-group">
                                                    <input required="" formControlName="password" type="password"
                                                        class="form-control" placeholder="Nueva contraseña"
                                                        [ngClass]="{'error': submittedPassword && formState?.password?.errors?.required}">
                                                </div>
                                                <div class="form-group">
                                                    <input required="" formControlName="ConfirmedPassword" type="password"
                                                    class="form-control" placeholder="Confirmar contraseña"
                                                    [ngClass]="{'error': submittedPassword && formState?.ConfirmedPassword?.errors?.required}">
                                                    <label class="errorRed"
                                                        *ngIf="submittedPassword && formState?.ConfirmedPassword?.errors">
                                                        Las contraseñas no coinciden.
                                                    </label>
                                                </div>
                                                <div class="d-flex justify-content-center w-100">
                                                    <ngb-toast *ngIf="submittedSuccess" [class]="'ngb-toast bg-success'">
                                                        {{messageSuccess}}
                                                      </ngb-toast>
                                                </div>
                                                <button class="btn btn-primary"
                                                [disabled]="isLoading">
                                                {{ isLoading ? 'Actualizando contraseña' : 'Actualizar contraseña'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">  </div>
            </div>
            <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                back
            </a> -->
        </div>
    </div>
</div>
