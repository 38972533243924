import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { NewsService } from 'src/app/shared/service/news.service';
import { ProductsService } from 'src/app/shared/service/products.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsComponent implements OnInit {

  public news = []
  public isCollapsed = true;
  public newsId: number;
  public active: boolean;
  public deleteIsSuccess = false;

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  constructor(
    private newsService: NewsService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.newsService
        .getNews()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.news = data;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setModalContent(content: any, newId: number, status: boolean): void {
    this.newsId = newId;
    this.active = status;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteCourse() {
    this.subs.push(
      this.newsService.deleteNews(this.newsId)
        .subscribe(resolve => {
          if (resolve == 'Status Changed') {
            this.deleteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
            }, 1000);
            setTimeout(() => {
              this.ngOnInit();
            }, 2000);
          }
          this.deleteIsSuccess = false;
        })
    );
  }
  
}
