<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="m-3">
        <div class=" pt-0 pb-0">
            <div class="row mt-0 mb-0 animate__animated animate__fadeInLeft" *ngIf="job === 'Centro de Investigación'">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 pl-0 pr-0">
                    <img src="../../../../assets/images/finish-register.png" alt="" class="mb-3 border-radius-cards">
                </div>
                <div class="col-sm-3"></div>
            </div>
            <div class="row mt-0 mb-0 animate__animated animate__fadeInLeft" *ngIf="showStageOne && job !== 'Centro de Investigación'">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 pl-0 pr-0">
                    <img src="../../../../assets/images/instrucciones-1.png" alt="" class="mb-3 border-radius-cards">
                    <button (click)="nextInstructionClick(1)" type="button" class="btn btn-primary btn-rally mb-4 position-btn-instructions pt-3 pb-3">
                        Continuar
                    </button>
                </div>
                <div class="col-sm-3"></div>
            </div>
            <div class="row mt-0 mb-0 animate__animated animate__fadeInRight" *ngIf="step === 2">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 pl-0 pr-0">
                    <img src="../../../../assets/images/instrucciones-2.png" alt="" class="mb-3 border-radius-cards">
                    <button (click)="nextInstructionClick(2)" type="button" class="btn btn-primary btn-rally mb-4 position-btn-instructions pt-3 pb-3">
                        Continuar
                    </button>
                </div>
                <div class="col-sm-3"></div>
            </div>
            <div class="row mt-0 mb-0 animate__animated animate__fadeInLeft" *ngIf="step === 3">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 pl-0 pr-0">
                    <img src="../../../../assets/images/instrucciones-3.png" alt="" class="mb-3 border-radius-cards">
                    <button (click)="nextInstructionClick(3)" type="button" class="btn btn-primary btn-rally mb-4 position-btn-instructions pt-3 pb-3">
                        Continuar
                    </button>
                </div>
                <div class="col-sm-3"></div>
            </div>
            <div class="row mt-0 mb-0 animate__animated animate__fadeInRight" *ngIf="step === 4">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 pl-0 pr-0">
                    <img src="../../../../assets/images/instrucciones-4.png" alt="" class="mb-3 border-radius-cards">
                    <button (click)="nextInstructionClick(4)" type="button" class="btn btn-primary btn-rally mb-4 position-btn-instructions pt-3 pb-3">
                        Continuar
                    </button>
                </div>
                <div class="col-sm-3"></div>
            </div>
            <div class="row mt-0 mb-0 animate__animated animate__fadeInLeft" *ngIf="step === 5">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 pl-0 pr-0">
                    <img src="../../../../assets/images/instrucciones-5.png" alt="" class="mb-3 border-radius-cards">
                    <button (click)="nextInstructionClick(5)" type="button" class="btn btn-primary btn-rally mb-4 position-btn-instructions pt-3 pb-3">
                        Continuar
                    </button>
                </div>
                <div class="col-sm-3"></div>
            </div>

            <div class="row mt-0 mb-0" *ngIf="showSelectQuiz">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 background-principal animate__animated animate__fadeIn">
                    <div class="text-center mt-5 mb-4">
                        <img src="../../../../assets/images/logo_blanco.png" style="width: 90%;" alt="" class="mb-3">
                    </div>
                    <div class="text-left mb-5">
                        <div class="row">
                            <div class="col-6" *ngFor="let quiz of quizs">
                                <button [disabled]="quiz.Answered" (click)="onStartQuizClick(quiz.id)" type="button" class="btn col-12 text-center mb-2 shadow fs-size"
                                [ngClass]="{'btn-white': quiz.Answered, 'btn-block': !quiz.Answered}">
                                    <img *ngIf="quiz.imgUrl" [src]="quiz.imgUrl" style="width: 100%;" alt="" class="mb-3">
                                    <img *ngIf="!quiz.imgUrl" src="../../../../assets/images/faicic.png" style="width: 100%;" alt="" class="mb-3">
                                    <br>
                                    {{quiz.Title}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <button *ngIf="showButtonRally === undefined" style="cursor: pointer;" (click)="viewStageTwo()" class="btn btn-primary btn-white mt-5 mb-5 pt-3 pb-3">Continuar</button>
                </div>
                <div class="col-sm-3"></div>
            </div>

            <div class="row mt-0 mb-0" *ngIf="showQuiz">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 background-quiz">
                    <div class="text-center mt-5 mb-4 pt-4">
                        <img src="../../../../assets/images/logo.png" style="width: 80%;" alt="">
                    </div>
                    <div class="text-left mb-3">
                        <div class="col-12 text-left animate__animated animate__fadeIn p-0"
                            [ngClass]="{'displaying': i == currentQuestion, 'hidding': i !== currentQuestion }"
                            *ngFor="let question of test?.questions; let i = index;">
                            <h4 id="questionText" class="mb-3 ft-24 text-white questions-style">{{(i+1)}}.- {{ question.Question }}</h4>
                            <form class="mb-5 pl-1 pr-1">
                                <div class="form-row answers-style mb-3" *ngFor="let answer of question.answers;">
                                    <div *ngIf="question.Type === 'Multiple'">
                                        <div id="questionDiv" class="col-12 text-left text-white d-flex align-items-center">
                                            <input class="inputQuestion" id="{{ answer.id }}" type="radio"
                                            name="{{ question.id }}" value="{{answer.id}}">
                                            <label for="{{ answer.id }}" class="ml-3 mb-0 pt-2 pb-2 ft-20">{{ answer.Answer }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="question.Type === 'Checkbox'">
                                        <div id="questionDiv" class="col-12 text-left text-white d-flex align-items-center">
                                            <input class="inputQuestion" id="{{ answer.id }}" type="checkbox"
                                            name="{{ question.id }}" value="{{answer.id}}">
                                            <label for="{{ answer.id }}" class="ml-3 mb-0 pt-2 pb-2 ft-20">{{ answer.Answer }}</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form [formGroup]="codeForm" class="form-horizontal auth-form mt-3 mb-4" novalidate="" *ngIf="currentQuestion == test?.questions.length-1">
                                <div class="form-group">
                                    <label>Solicita que ingresen la clave correspondiente para completar el módulo</label>
                                    <input required="" formControlName="authCode" type="number"
                                        class="form-control" placeholder="Código"
                                        [ngClass]="{'error': isLoading && formState?.authCode?.errors?.required}">
                                </div>
                            </form>
                            <small class="text-danger">{{error}}</small>
                            <div class="row justify-content-end mt-3">
                                <a style="cursor: pointer;" (click)="nextQuestion()" *ngIf="currentQuestion != test?.questions.length-1"
                                class="btn btn-primary btn-rally mb-4 pt-3 pb-3">Siguiente</a>
                                <button style="cursor: pointer;" (click)="saveExam('quiz')" [disabled]="isLoading"
                                *ngIf="currentQuestion == test?.questions.length-1" class="btn btn-primary btn-rally mb-4 pt-3 pb-3">Finalizar <i
                                    class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3"></div>
            </div>

            <div class="row mt-0 mb-0" *ngIf="showStageTwo">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 animate__animated animate__fadeInRight stage2">
                    <img src="../../../../assets/images/stage2.png" alt="" class="mb-3 border-radius-cards">
                    <button (click)="startRally()" type="button" class="btn btn-primary btn-rally mb-4 position-btn-instructions pt-3 pb-3">
                        Continuar
                    </button>
                </div>
                <div class="col-sm-3"></div>
            </div>

            <div class="row mt-0 mb-0" *ngIf="showRally">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 background-quiz">
                    <div class="text-center mt-5 mb-4 pt-4">
                        <img src="../../../../assets/images/logo.png" style="width: 80%;" alt="">
                    </div>
                    <div class="text-right mb-4 mr-5">
                        <i class="fa fa-clock-o mr-0" aria-hidden="true"></i> {{formattedTimeDuration ? formattedTimeDuration : '00:00'}} min
                    </div>
                    <div class="text-left mb-3 pl-4 pr-4">
                        <div class="col-12 text-left animate__animated animate__fadeIn p-0"
                            [ngClass]="{'displaying': i == currentQuestion, 'hidding': i !== currentQuestion }"
                            *ngFor="let question of test?.questions; let i = index;">
                            <h4 id="questionText" class="mb-3 ft-24 text-white questions-style">{{(i+1)}}.- {{ question.Question }}</h4>
                            <form class="mb-4 pl-1 pr-1">
                                <div class="form-row answers-style mb-3" *ngFor="let answer of question.answers;">
                                    <div *ngIf="question.Type === 'Multiple'">
                                        <div id="questionDiv" class="col-12 text-left text-white d-flex align-items-center">
                                            <input class="inputQuestion" id="{{ answer.id }}" type="radio"
                                            name="{{ question.id }}" value="{{answer.id}}">
                                            <label for="{{ answer.id }}" class="ml-3 mb-0 pt-2 pb-2 ft-20">{{ answer.Answer }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="question.Type === 'Checkbox'">
                                        <div id="questionDiv" class="col-12 text-left text-white d-flex align-items-center">
                                            <input class="inputQuestion" id="{{ answer.id }}" type="checkbox"
                                            name="{{ question.id }}" value="{{answer.id}}">
                                            <label for="{{ answer.id }}" class="ml-3 mb-0 pt-2 pb-2 ft-20">{{ answer.Answer }}</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <small class="text-danger">{{error}}</small>
                            <div class="row justify-content-end mt-3">
                                <a style="cursor: pointer;" (click)="nextQuestion()" *ngIf="currentQuestion != test?.questions.length-1"
                                class="btn btn-primary btn-rally mb-4 ml-3 mr-3 pt-3 pb-3">Siguiente</a>
                                <button style="cursor: pointer;" (click)="saveExam('rally')" [disabled]="isLoading"
                                *ngIf="currentQuestion == test?.questions.length-1" class="btn btn-primary btn-rally mb-4 ml-3 mr-3 pt-3 pb-3">Finalizar <i
                                    class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3"></div>
            </div>

            <div class="row mt-0 mb-0" *ngIf="showStageFinish">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 col-xs-12 animate__animated animate__fadeIn">
                    <img src="../../../../assets/images/final.png" alt="" class="mb-3 border-radius-cards">
                </div>
                <div class="col-sm-3"></div>
            </div>
        </div>
    </div>
</div>

<!-- <ng2-toasty></ng2-toasty> -->