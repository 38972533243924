import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { EventListComponent } from './event/event-list/event-list.component';
import { AddEventComponent } from './event/event-add/add-event.component';
import { AddProgramComponent } from '../programs/add-programs/add-program.component';
import { ProgramsCourseComponent } from '../programs/programs-course/programs-course.component';
import { AddContentComponent } from '../programs/add-contents/add-content.component';
import { ProgramsDetailsComponent } from '../programs/program-detail/program-detail.component';
import { FeaturesComponent } from './physical/features/features.component';
import { AddQuizComponent } from '../quizs/add-quiz/add-quiz.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'category',
        component: CategoryComponent,
        data: {
          title: "Categoría",
          breadcrumb: "Categoría"
        }
      },
      {
        path: 'sub-category',
        component: SubCategoryComponent,
        data: {
          title: "Sub Categoría",
          breadcrumb: "Sub Categoría"
        }
      },
      {
        path: 'catalogs',
        component: FeaturesComponent,
        data: {
          title: "Catálogos",
          breadcrumb: "Catálogos"
        }
      },
    
      {
        path: 'courses',
        component: ProductListComponent,
        data: {
          title: "Cursos",
          breadcrumb: "Cursos"
        }
      },
      {
        path: 'courses/view-programs/:id/program',
        component: AddProgramComponent,
        data: {
          title: "Agregar Módulo",
          breadcrumb: "Agregar Módulo"
        }
      },
      {
        path: 'courses/view-programs/:id/edit/:programId',
        component: AddProgramComponent,
        data: {
          title: "Editar Módulo",
          breadcrumb: "Editar Módulo"
        }
      },
      {
        path: 'courses/view-programs/:id',
        component: ProgramsCourseComponent,
        data: {
          title: "Módulos del curso",
          breadcrumb: "Módulos del curso"
        }
      },
      {
        path: 'courses/view-programs/:id/content/:programId',
        component: ProgramsDetailsComponent,
        data: {
          title: "Detalle del módulo",
          breadcrumb: "Detalle del módulo"
        }
      },
      {
        path: 'courses/view-programs/:id/content/:programId/content/:topicId',
        component: AddContentComponent,
        data: {
          title: "Contenido de la Unidad",
          breadcrumb: "Contenido de la Unidad"
        }
      },
      {
        path: 'courses/show/:id',
        component: ProductDetailComponent,
        data: {
          title: "Detalle Curso",
          breadcrumb: "Detalle Curso"
        }
      },
      {
        path: 'courses/edit/:id',
        component: AddProductComponent,
        data: {
          title: "Editar Curso",
          breadcrumb: "Editar Curso"
        }
      },
      {
        path: 'courses/add-course',
        component: AddProductComponent,
        data: {
          title: "Agregar Curso",
          breadcrumb: "Agregar Curso"
        }
      },
      {
        path: 'courses/quiz/:id',
        component: AddQuizComponent,
        data: {
          title: "Agregar Cuestionario",
          breadcrumb: "Agregar Cuestionario"
        }
      },

      {
        path: 'webinars',
        component: DigitalListComponent,
        data: {
          title: "Webinars",
          breadcrumb: "Webinars"
        }
      },
      {
        path: 'webinars/show/:id',
        component: ProductDetailComponent,
        data: {
          title: "Detalle Webinar",
          breadcrumb: "Detalle Webinar"
        }
      },
      {
        path: 'webinars/edit/:id',
        component: DigitalAddComponent,
        data: {
          title: "Editar Webinar",
          breadcrumb: "Editar Webinar"
        }
      },
      {
        path: 'webinars/add-webinar',
        component: DigitalAddComponent,
        data: {
          title: "Agregar Webinar",
          breadcrumb: "Agregar Webinar"
        }
      },


      {
        path: 'events',
        component: EventListComponent,
        data: {
          title: "Eventos",
          breadcrumb: "Eventos"
        }
      },
      {
        path: 'events/show/:id',
        component: ProductDetailComponent,
        data: {
          title: "Detalle Evento",
          breadcrumb: "Detalle Evento"
        }
      },
      {
        path: 'events/edit/:id',
        component: AddEventComponent,
        data: {
          title: "Editar Evento",
          breadcrumb: "Editar Evento"
        }
      },
      {
        path: 'events/add-event',
        component: AddEventComponent,
        data: {
          title: "Agregar Evento",
          breadcrumb: "Agregar Evento"
        }
      },


      {
        path: 'add-product',
        component: AddProductComponent,
        data: {
          title: "Agregar Productos",
          breadcrumb: "Agregar Producto"
        }
      },
      {
        path: 'digital/digital-category',
        component: DigitalCategoryComponent,
        data: {
          title: "Categoría",
          breadcrumb: "Categoría"
        }
      },
      {
        path: 'digital/digital-sub-category',
        component: DigitalSubCategoryComponent,
        data: {
          title: "Sub Categoría",
          breadcrumb: "Sub Categoría"
        }
      },
      {
        path: 'digital/digital-product-list',
        component: DigitalListComponent,
        data: {
          title: "Lista Productos",
          breadcrumb: "Lista Productos"
        }
      },
      {
        path: 'digital/digital-add-product',
        component: DigitalAddComponent,
        data: {
          title: "Agregar Productos",
          breadcrumb: "Agregar Producto"
        }
      },

   
      {
        path: 'capacitations',
        component: ProductListComponent,
        data: {
          title: "Capacitaciones",
          breadcrumb: "Capacitaciones"
        }
      },
      {
        path: 'capacitations/view-programs/:id/program',
        component: AddProgramComponent,
        data: {
          title: "Agregar Módulo",
          breadcrumb: "Agregar Módulo"
        }
      },
      {
        path: 'capacitations/view-programs/:id/edit/:programId',
        component: AddProgramComponent,
        data: {
          title: "Editar Módulo",
          breadcrumb: "Editar Módulo"
        }
      },
      {
        path: 'capacitations/view-programs/:id',
        component: ProgramsCourseComponent,
        data: {
          title: "Módulos de la capacitación",
          breadcrumb: "Módulos de la capacitación"
        }
      },
      {
        path: 'capacitations/view-programs/:id/content/:programId',
        component: ProgramsDetailsComponent,
        data: {
          title: "Detalle del módulo",
          breadcrumb: "Detalle del módulo"
        }
      },
      {
        path: 'capacitations/view-programs/:id/content/:programId/content/:topicId',
        component: AddContentComponent,
        data: {
          title: "Contenido de la Unidad",
          breadcrumb: "Contenido de la Unidad"
        }
      },
      {
        path: 'capacitations/show/:id',
        component: ProductDetailComponent,
        data: {
          title: "Detalle de la capacitación",
          breadcrumb: "Detalle de la capacitación"
        }
      },
      {
        path: 'capacitations/edit/:id',
        component: AddProductComponent,
        data: {
          title: "Editar Capacitación",
          breadcrumb: "Editar Capacitación"
        }
      },
      {
        path: 'capacitations/add-capacitation',
        component: AddProductComponent,
        data: {
          title: "Agregar Capacitación",
          breadcrumb: "Agregar Capacitación"
        }
      },
      {
        path: 'capacitations/quiz/:id',
        component: AddQuizComponent,
        data: {
          title: "Agregar Cuestionario",
          breadcrumb: "Agregar Cuestionario"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
