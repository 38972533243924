import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SalesService } from 'src/app/shared/service/sales.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-course-order',
  templateUrl: './course-order.component.html',
  styleUrls: ['./course-order.component.scss']
})
export class CoursesOrderComponent implements OnInit, OnDestroy {
  public courses: any;
  public subscriptions: any;
  public temp = [];

  public CodeQR: string;

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalInvite: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public submitted = false;
  public guideIsSuccess = false;
  public orderCloseSuccess = false;
  public orderId: number;
  public productId: number;
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private salesService: SalesService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order:[[2, 'desc']]
    };

    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('orderId')),
        switchMap(id => this.salesService.getCoursesByOrder(id)),
        catchError(error => {
          this.router.navigate(['/users/business']);
          return throwError(error);
        })
      ).subscribe(courses => {
        this.courses = courses;
        this.dtTrigger.next();
      })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public setModalContent(content: any, orderId: number): void {
    this.orderId = orderId;
    this.CodeQR = 'http://localhost:4200/'+this.orderId+'/'+this.orderId
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public setModalInvites(invites: any, orderId: number, productId: number): void {
    this.orderId = orderId;
    this.productId = productId;
    this.modalInvite = invites;
    this.modalService.open(this.modalInvite, this.modalConfig);
    this.getSubscriptions();
  }

  public getSubscriptions(){
    this.subs.push(
      this.salesService
        .getSubscriptions(this.orderId, this.productId)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.subscriptions = data;
        })
    );
  }

  public get formState() {
    return this.form.controls;
  }

}
