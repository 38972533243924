export class listCouponsDB {
    static list_coupons = [
        {
            title: "Dummy",
            code: "Code 1",
            category: "Cat 1",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
        },
        {
            title: "Dummy",
            code: "Code 2",
            category: "Cat 2",
            status: "<i class=\"fa fa-circle font-warning f-12\"></i>",
        },
        {
            title: "Dummy",
            code: "Code 3",
            category: "Cat 3",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
        },
        {
            title: "Dummy",
            code: "Code 4",
            category: "Cat 4",
            status: "<i class=\"fa fa-circle font-warning f-12\"></i>",
        },
        {
            title: "Dummy",
            code: "Code 5",
            category: "Cat 5",
            status: "<i class=\"fa fa-circle font-danger f-12\"></i>",
        },
        {
            title: "Dummy",
            code: "Code 6",
            category: "Cat 6",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
        },
        {
            title: "Dummy",
            code: "Code 7",
            category: "Cat 7",
            status: "<i class=\"fa fa-circle font-danger f-12\"></i>",
        },
        {
            title: "Dummy",
            code: "Code 8",
            category: "Cat 8",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
        },

    ]
}
