import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '@ks89/angular-modal-gallery';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WikiService}  from 'src/app/shared/service/wiki.service';
import * as moment from 'moment';

@Component({
  selector: 'app-wiki-detail',
  templateUrl: './wiki-detail.component.html',
  styleUrls: ['./wiki-detail.component.scss'],
  providers: [NgbRatingConfig]
})
export class WikiDetailComponent implements OnInit {
  public closeResult: string;

  private subs: Array<Subscription> = [];
  public news: any;

  public dtTrigger: Subject<any> = new Subject()

  constructor(
    private wikiService: WikiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    config: NgbRatingConfig,) {
    config.max = 5;
    config.readonly = false;
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.wikiService.getWiki(id)),
        catchError(error => {
          this.router.navigate(['/wiki']);
          return throwError(error);
        })
      ).subscribe(news => {
        news.Subtitles = news.Subtitles.map( (element: any) => {
          element.ImageURL = JSON.parse( element.ImageURL );
          return element;
        });
        this.news = news;
        this.dtTrigger.next();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
