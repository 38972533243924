<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/profile-user.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                            <div class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Nombreeee:</span>
                                <input type="text" class="form-control" placeholder='{{user?.firstName}}' aria-label="Username" aria-describedby="basic-addon1" disabled>
                             <input *ngIf="!user?.firstName" type="text" class="form-control" placeholder='Necesita completar este campo' aria-label="Username" aria-describedby="basic-addon1" disabled> 
                            </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Apellido:</span>
                                <input type="text" class="form-control" placeholder='{{user?.lastName}}' aria-label="Username" aria-describedby="basic-addon1" disabled>
                             <input *ngIf="!user?.lastName" type="text" class="form-control" placeholder='Necesita completar este campo' aria-label="Username" aria-describedby="basic-addon1" disabled> 
                            </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Correo Electrónico:</span>
                                <input type="text" class="form-control" placeholder='{{user?.email}}' aria-label="Username" aria-describedby="basic-addon1" disabled>
                             <input *ngIf="!user?.email" type="text" class="form-control" placeholder='Necesita completar este campo' aria-label="Username" aria-describedby="basic-addon1" disabled> 
                            </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Numero telefónico:</span>
                                <input type="text" class="form-control" placeholder='{{user?.phoneNumber}}' aria-label="Username" aria-describedby="basic-addon1" disabled>
                             <input *ngIf="!user?.phoneNumber" type="text" class="form-control" placeholder='Necesita completar este campo' aria-label="Username" aria-describedby="basic-addon1" disabled> 
                            </div>
                              <div *ngIf="user?.roles === 'user'" class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Perfil:</span>
                                <input type="text" class="form-control" placeholder='Usuario' aria-label="Username" aria-describedby="basic-addon1" disabled>
                              </div>
                              <div *ngIf="user?.roles === 'admin'"class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Perfil:</span>
                                <input type="text" class="form-control" placeholder='Administrador' aria-label="Username" aria-describedby="basic-addon1" disabled>
                              </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Pais:</span>
                                <input type="text" class="form-control" placeholder='{{user?.country}}' aria-label="Username" aria-describedby="basic-addon1" disabled>
                                <input *ngIf="!user?.country" type="text" class="form-control" placeholder='Necesita completar este campo' aria-label="Username" aria-describedby="basic-addon1" disabled>
                            </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Ciudad:</span>
                                <input *ngIf="user?.city" type="text" class="form-control" placeholder='{{user?.city}}' aria-label="Username" aria-describedby="basic-addon1" disabled>
                                <input *ngIf="!user?.city" type="text" class="form-control" placeholder='Necesita completar este campo' aria-label="Username" aria-describedby="basic-addon1" disabled>
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text col-3" id="basic-addon1">Fecha de nacimiento:</span>
                                <input *ngIf="user?.birthday" type="text" class="form-control" placeholder='{{user?.birthday | date: "dd-MM-YYYY"}}' aria-label="Username" aria-describedby="basic-addon1" disabled>
                                <input *ngIf="!user?.birthday" type="text" class="form-control" placeholder='Necesita completar este campo' aria-label="Username" aria-describedby="basic-addon1" disabled>
                            </div>
                       <h5 class="f-w-600 mb-0">{{user?.firstName}} {{user?.lastName}}</h5> 
                        <span>{{user?.email}}</span> 
                         <span>{{user?.phoneNumber}}
</span>
                        <br>
                        <span *ngIf="user?.roles === 'admin'">Perfil: Administrador</span>
                        <span *ngIf="user?.roles === 'user'">Perfil: Usuario</span> 
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div *ngIf="user?.roles === 'user'" class="col-xl-12">
            <p>Cuestionarios:</p>
            <div class="card tab2-card">
                <div class="card-body">
                    <div id="batchDelete" class="">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                class=" table table-lg table-hover row-border hover">
                                <thead>
                                    <tr>
                                        <th>Cuestionario</th>
                                        <th>Fecha</th>
                                        <th>Ver respuestas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of quizs">
                                        <td>{{item.quiz.Title}}</td>
                                        <td>{{item.created_at | date:'dd/MM/yyyy h:mm:ss a'}}</td>
                                        <td class="actions-cell">
                                            <span class="pointer" (click)="setModalContent(content, item.id)"
                                                title="Ver cuestionario">
                                                <i class="fa fa-eye"></i>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <!--  <div class="card">
              <div class="card-body d-flex justify-content-end align-items-end w-100">
                  <div class="row action pr-0 mx-0 col-lg-3">
                            <a class="btn btn-red btn-sm w-100 px-2"
                              [routerLink]="['create-user']">
                              <i class="ti-plus"></i>
                              <span><i class="fa fa-plus"></i> Colaborador</span>
                            </a>
                  </div>
              </div>
            </div> -->
            <div class="card tab2-card">
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Cuenta">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation user-add"
                                        novalidate>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom0" class="">Nombre</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" id="validationCustom0"
                                                    value="{{user?.firstName}}" disabled type="text" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom1" class="">Apellido</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" id="validationCustom1"
                                                    value="{{user?.lastName}}" disabled type="text" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom2" class="">Correo electrónico</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" id="validationCustom2"
                                                    value="{{user?.email}}" disabled type="email" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom2" class="">Teléfono</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" id="validationCustom2"
                                                    value="{{user?.phoneNumber}}" disabled type="tel" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-4 col-md-4">
                                                <label for="validationCustom3" class="">Empresa</label>
                                            </div>
                                            <div class=" col-xl-8 col-md-8">
                                                <input class="form-control" formControlName="country"
                                                    id="validationCustom3" value="{{user?.businessname}}" disabled
                                                    type="text"
                                                    [ngClass]="{'error': submitted && formState?.country?.errors?.required}">
                                            </div>
                                        </div>
                                        <!-- <div class="form-group row">
                                          <div class="col-xl-4 col-md-4">
                                              <label for="validationCustom3" class="">País</label>
                                          </div>
                                          <div class=" col-xl-8 col-md-8">
                                              <input class="form-control" formControlName="country" id="validationCustom3"
                                              value="{{user?.country}}" disabled
                                              type="text" [ngClass]="{'error': submitted && formState?.country?.errors?.required}">
                                          </div>
                                      </div>
                                      <div class="form-group row">
                                        <div class="col-xl-4 col-md-4">
                                            <label for="validationCustom4" class="">Fecha de nacimiento</label>
                                        </div>
                                        <div class=" col-xl-8 col-md-8">
                                            <input class="form-control" type="text" 
                                            value="{{user?.birthday}}"
                                            disabled formControlName="birthday" id="validationCustom4">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                      <div class="col-xl-4 col-md-4">
                                          <label for="validationCustom3" class="">Rol</label>
                                      </div>
                                      <div class=" col-xl-8 col-md-8">
                                          <input class="form-control" formControlName="roles" id="validationCustom3"
                                         disabled value="{{user?.roles}}"
                                          type="text" [ngClass]="{'error': submitted && formState?.roles?.errors?.required}">
                                      </div>
                                  </div> -->
                                        <!-- <div class="form-group row">
                                          <div class="col-xl-4 col-md-4">
                                              <label for="validationCustom4" class="">Fecha de nacimiento</label>
                                          </div>
                                          <div class=" col-xl-8 col-md-8">
                                              <input class="form-control" type="date" placeholder="yyyy-mm-dd" formControlName="birthday" id="validationCustom4">
                                          </div>
                                      </div> 
                                      <div class="form-group row">
                                          <div class="col-xl-4 col-md-4">
                                              <label for="validationCustom5" class="">Correo electrónico</label>
                                          </div>
                                          <div class=" col-xl-8 col-md-8">
                                              <input class="form-control" id="validationCustom5"
                                              value="{{this.email}}" disabled type="email">
                                          </div>
                                      </div>
                                      <div class="form-group row">
                                          <div class="col-xl-4 col-md-4">
                                              <label for="validationCustom2" class="">Colaboradores</label>
                                          </div>
                                          <div class=" col-xl-8 col-md-8">
                                              <input class="form-control" id="validationCustom2"
                                              value="{{this.collaborators}}" disabled type="number">
                                          </div>
                                      </div>
                                      <div class="form-group row">
                                          <div class="col-xl-4 col-md-4">
                                              <label for="validationCustom2" class="">Empresa</label>
                                          </div>
                                          <div class=" col-xl-8 col-md-8">
                                              <input class="form-control" id="validationCustom2"
                                              value="{{this.businessname}}" disabled type="text">
                                          </div>
                                      </div>
                                    <div class="form-group row" *ngIf="onEdit == false">
                                          <div class="col-xl-4 col-md-4">
                                              <label for="validationCustom6" class="">Contraseña</label>
                                          </div>
                                          <div class=" col-xl-8 col-md-8">
                                              <input class="form-control" formControlName="password" id="validationCustom6" minlength="8"
                                              value="{{this.firstName}}"
                                              type="password" [ngClass]="{'error': submitted && formState?.password?.errors?.required || submitted && formState?.password?.errors}">
                                              <span *ngIf="submitted && formState?.password?.errors" class="text-danger">
                                                  La contraseña debe tener mínimo 8 caracteres.
                                              </span>
                                          </div>                                            
                                      </div>
                                      <div class="form-group row" *ngIf="onEdit == false">
                                          <div class="col-xl-4 col-md-4">
                                              <label for="validationCustom7" class="">Confirmar contraseña</label>
                                          </div>
                                          <div class=" col-xl-8 col-md-8">
                                              <input class="form-control" formControlName="confirmedPassword" id="validationCustom7" minlength="8"
                                              value="{{this.firstName}}"
                                              type="password" [ngClass]="{'error': submitted && formState?.confirmedPassword?.errors?.required || submitted && formState?.password?.errors}">
                                              <span *ngIf="submitted && formState?.confirmedPassword?.errors" class="text-danger">
                                                  Las contraseñas no coinciden.
                                              </span>
                                              <span *ngIf="submitted && formState?.confirmedPassword?.errors" class="text-danger">
                                                  La contraseña debe tener mínimo 8 caracteres.
                                              </span>
                                          </div>
                                      </div>     -->
                                        <!-- <div class="form-group col-12 mb-0 text-right" >
                                           <button class="btn btn-red"
                                              [disabled]="isLoading"  *ngIf="onEdit == false">
                                              {{ isLoading ? 'Creando cuenta' : 'Registrarse'}}
                                              <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                          </button> 
                                          <button type="submit" class="btn btn-red">
                                              {{ isLoading ? 'Editando cuenta' : 'Editar usuario'}}
                                              <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                          </button>
                                      </div> -->
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title">Respuestas cuestionario</h4>
    </div>
    <div class="modal-body text-center">
        <ng-container>
            <h4><strong>{{quizResult?.quiz.Title}}</strong></h4>
            <p class="mb-2">{{quizResult?.quiz.Description}}</p>
            <p>{{quizResult?.quiz.created_at | date:'dd/MM/yyyy h:mm:ss a'}}</p>
            <hr>
            <div *ngFor="let item of quizResult?.answers">
                <p class="mb-2">{{item.Question}}</p>
                <p [ngClass]="{'text-success': item.Correct, 'text-danger': !item.Correct}">{{item.Answer}}
                    <i *ngIf="item.Correct" class="fa fa-check text-success" aria-hidden="true"></i>
                    <i *ngIf="!item.Correct" class="fa fa-times text-danger" aria-hidden="true"></i>
                </p>
            </div>
        </ng-container>
    </div>
    <ng-container>
        <div class="modal-footer">
            <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cerrar</a>
        </div>
    </ng-container>
</ng-template>