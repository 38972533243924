import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';

import { ProgramsRoutingModule } from './programs-routing.module';
import { ProgramsComponent } from './programs-list/programs.component';
import { ProgramsDetailsComponent } from './program-detail/program-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddContentComponent } from './add-contents/add-content.component';

@NgModule({
  declarations: [
    ProgramsComponent, ProgramsDetailsComponent, AddContentComponent],
  imports: [
    CommonModule,
    DataTablesModule,
    ProgramsRoutingModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    FormsModule,
    NgbModule,
    NgbModule
  ],
})
export class ProgramsModule { }
