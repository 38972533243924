import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddQuizComponent } from '../quizs/add-quiz/add-quiz.component';
import { AddContentComponent } from './add-contents/add-content.component';
import { ProgramsDetailsComponent } from './program-detail/program-detail.component';
import { ProgramsComponent } from './programs-list/programs.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProgramsComponent,
        data: {
          title: "Módulos",
          breadcrumb: "Módulos"
        }
      },
      {
        path: 'show/:programId',
        component: ProgramsDetailsComponent,
        data: {
          title: "Detalle del módulo",
          breadcrumb: "Detalle del módulo"
        }
      },
      {
        path: 'quiz/:id',
        component: AddQuizComponent,
        data: {
          title: "Agregar Cuestionario",
          breadcrumb: "Agregar Cuestionario"
        }
      },
      {
        path: 'show/:id/content/:topicId',
        component: AddContentComponent,
        data: {
          title: "Contenido de la Unidad",
          breadcrumb: "Contenido de la Unidad"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramsRoutingModule { }