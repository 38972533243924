import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { UserModel } from '../../auth/auth.models';
import { ActivatedRoute, Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UsersService } from '../../services/users/users.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {
  public accountForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public userId: number;
  public rol: string;
  public model: NgbDateStruct;
  public url: string;
  private subs: Array<Subscription> = [];
  public type: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UsersService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit() {
    this.type = this.router.url.indexOf('admins') != -1 ? 'admin' : 'teacher';
    this.url = this.router.url;
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.userId = data.params.id;
          this.onEdit = true;
          this.userService.getUser(data.params.id)
            .subscribe(user => this.setValues(user));
          this.accountForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            phoneNumber: [''],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
          })
        } else {
          this.onEdit = false;
          this.accountForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            phoneNumber: [''],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmedPassword: ['', [Validators.required, Validators.minLength(8)]],
          })
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.accountForm.controls
  }

  private validatePasswords(): void {
    const password: string = this.accountForm.get('password').value;
    const confirmPassword: string = this.accountForm.get('confirmedPassword').value;

    if (password !== confirmPassword) {
      this.accountForm.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  private setValues(user: any): void {
    const values = this.userService.mapRequiredValues(user);
    this.rol = values.roles;
    const { controls } = this.accountForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.accountForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildUser(): UserModel {
    if (this.url.includes("admin")) {
      return {
        firstName: this.accountForm.controls['firstName'].value,
        lastName: this.accountForm.controls['lastName'].value,
        password: this.accountForm.controls['password'].value,
        email: this.accountForm.controls['email'].value,
        phoneNumber: this.accountForm.controls['phoneNumber'].value,
        roles: 'admin'
      }
    } else if (this.url.includes("user")) {
      return {
        firstName: this.accountForm.controls['firstName'].value,
        lastName: this.accountForm.controls['lastName'].value,
        password: this.accountForm.controls['password'].value,
        email: this.accountForm.controls['email'].value,
        phoneNumber: this.accountForm.controls['phoneNumber'].value,
        roles: 'users'
      }
    }
  }

  private buildUserUpdate(): UserModel {
    if (this.url.search("admin") !== -1) {
      return {
        firstName: this.accountForm.controls['firstName'].value,
        lastName: this.accountForm.controls['lastName'].value,
        country: this.accountForm.controls['country'].value,
        birthday: this.accountForm.controls['birthday'].value,
        email: this.accountForm.controls['email'].value,
        phoneNumber: this.accountForm.controls['phoneNumber'].value,
        roles: 'admin'
      }
      /* } else if (this.url.search("users") !== -1) {
        return {
          firstName: this.accountForm.controls['firstName'].value,
          lastName: this.accountForm.controls['lastName'].value,
          country: this.accountForm.controls['country'].value,
          birthday: this.accountForm.controls['birthday'].value,
          email: this.accountForm.controls['email'].value,
          phoneNumber: this.accountForm.controls['phoneNumber'].value,
          roles: 'users'
        } */
    }
  }

  private handleError(error: any) {
    /* const toastOptions: ToastOptions = {
      title: 'Error',
      msg: error?.error.message,
      showClose: true,
      timeout: 2000
    };
    this.isLoading = false;
     this.toastyService.error(toastOptions);  */

    return [];
  }

  private handleUserCreation() {
    /*    const toastOptions: ToastOptions = {
         title: 'Administrador',
         msg: 'Usuario creado correctamente',
         showClose: true,
         timeout: 1700
       }; */

    /* this.toastyService.success(toastOptions); */
  }

  private handleUserEdit() {
    /*  const toastOptions: ToastOptions = {
       title: 'Administrador',
       msg: 'Usuario actualizado correctamente',
       showClose: true,
       timeout: 1700
     }; */

    /*  this.toastyService.success(toastOptions); */
  }

  private handleTeacherCreation() {
    /*   const toastOptions: ToastOptions = {
        title: 'Usuario',
        msg: 'Usuario creado correctamente',
        showClose: true,
        timeout: 1700
      }; */

    /*  this.toastyService.success(toastOptions); */
  }

  private handleTeacherEdit() {
    /* const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: 'Usuario actualizado correctamente',
      showClose: true,
      timeout: 1700
    }; */

    /* this.toastyService.success(toastOptions); */
  }

  onSubmit() {
    this.submitted = true;

    if (this.accountForm.invalid) {
      return;
    }

    if (this.onEdit) {
      this.updateUser();
    } else {
      this.validatePasswords();
      this.createUser();
    }
  }

  createUser() {
    const user: UserModel = this.buildUser();
    this.isLoading = true;
    if (user.roles === 'admin') {
      this.subs.push(
        this.userService.createAdmin(user)
          .pipe(
            map(() => {
              this.handleUserCreation();
              setTimeout(() => {
                this.router.navigateByUrl('/users/admins');
              }, 1800);
            }),
            catchError(error => this.handleError(error))
          )
          .subscribe()
      );
    } /* else {
      this.subs.push(
        this.userService.createTeacher(user)
          .pipe(
            map(() => {
              this.handleTeacherCreation();
              setTimeout(() => {
                this.router.navigateByUrl('/empresa');
              }, 1800);
            }),
            catchError(error => this.handleError(error))
          )
          .subscribe()
      );
    } */
  }

  updateUser() {
    const user: UserModel = this.buildUserUpdate();
    this.isLoading = true;
    if (user.roles === 'admin') {
      this.subs.push(
        this.userService.updateUser(this.userId, user)
          .pipe(
            map(() => {
              this.handleUserEdit();
              setTimeout(() => {
                this.router.navigateByUrl('empresa');
              }, 1800);
            }),
            catchError(error => this.handleError(error))
          )
          .subscribe()
      );
    } /* else {
      this.subs.push(
        this.userService.updateTeacher(this.userId, user)
          .pipe(
            map(() => {
              this.handleTeacherEdit();
              setTimeout(() => {
                this.router.navigateByUrl('/empresa');
              }, 1800);
            }),
            catchError(error => this.handleError(error))
          )
          .subscribe()
      );
    } */
  }

}
